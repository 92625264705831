import {
    IChooseLocationModal,
    ILocation,
    ILocationGroup,
    IOrganisation,
} from "../interfaces";
import {
    Avatar,
    Icon,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    Box,
} from "@mui/material";
import { modalHide } from "app/App/actions";
import trans from "helpers/trans";
import { orderBy } from "lodash";
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Modal, ModalActions, ModalContent, ModalTitle } from "ui";

const LOCATION_TYPE_ICON = {
    STORE: "storefront",
    REGULAR: "home",
    SUPPLIER: "local_shipping",
    SATELLITE: "satellite_alt",
};

const ChooseLocationModal: FC<IChooseLocationModal> = ({ organisations }) => {
    const dispatch = useDispatch();
    const locationGroups = useSelector(
        (state: any) => state.me.location_groups
    );

    const groupsWithOrganisations = useMemo(() => {
        const locations = organisations.flatMap(
            (organisation: IOrganisation) => organisation.locations
        );

        const groups = locationGroups.map((group: ILocationGroup) => ({
            ...group,
            locations: locations.filter(
                (location: ILocation) => location.location_group_id === group.id
            ),
        }));

        const orgLocationsWithoutGroup = organisations.map(
            (organisation: IOrganisation) => ({
                ...organisation,
                locations: organisation.locations.filter(
                    (location: ILocation) =>
                        !groups.some(
                            (group: ILocationGroup) =>
                                group.id === location.location_group_id
                        )
                ),
            })
        );

        return orderBy([...groups, ...orgLocationsWithoutGroup], ["name"]);
    }, [locationGroups, organisations]);

    const handleClose = () => {
        dispatch(modalHide("ChooseLocationModal"));
    };

    const handleLocation = (item: ILocation) => (event: any) => {
        window.location.href = "/" + item.uid;
        dispatch(modalHide("ChooseLocationModal"));
    };

    const renderLocations = (locations: ILocation[]) => {
        return locations.map((item: ILocation) => {
            return (
                <ListItem
                    button
                    sx={{ width: "100%" }}
                    onClick={handleLocation(item)}
                    key={`choose-location-${item.id}`}
                >
                    <ListItemAvatar>
                        <Avatar>
                            <Icon>{LOCATION_TYPE_ICON[item.type]}</Icon>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={item.name} />
                </ListItem>
            );
        });
    };

    const renderOrganisation = (organisation: IOrganisation) => {
        if (organisation.locations.length === 0) return null;

        return (
            <Box key={organisation.id}>
                <Typography
                    variant="h6"
                    fontWeight={500}
                    sx={{
                        px: 2,
                        py: 1,
                    }}
                >
                    {organisation.name}
                </Typography>
                <List sx={{ width: "100%" }}>
                    {renderLocations(organisation.locations)}
                </List>
            </Box>
        );
    };

    return (
        <>
            <Modal
                maxWidth="xs"
                fullWidth
                onClose={handleClose}
                open
                PaperProps={{ sx: { position: "fixed", top: 0 } }}
            >
                <ModalTitle onClose={handleClose} fontWeight={500}>
                    {trans("inv.me.modal.chooseLocationModal.title")}
                </ModalTitle>
                <ModalContent
                    sx={{
                        px: 0,
                        py: 1,
                    }}
                >
                    {groupsWithOrganisations.map((item) =>
                        renderOrganisation(item)
                    )}
                </ModalContent>
                <ModalActions></ModalActions>
            </Modal>
        </>
    );
};

export default ChooseLocationModal;
