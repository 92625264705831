import { Box, Typography } from "@mui/material";
import useRequest from "api/useRequest";
import { modalHide } from "app/App/actions";
import { AxiosResponse } from "axios";
import trans from "helpers/trans";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Loader,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
    RequestMessage,
} from "ui";
import {
    bulkRestaurantProductsHide,
    restaurantProductsFetch,
    restaurantProductsTableUpdate,
} from "../actions";
import { RestaurantProductModel } from "../interfaces";

interface IRestaurantProductsHideModal {
    selected: number[];
    onSuccess: Function;
}

const RestaurantProductsHideModal: FC<IRestaurantProductsHideModal> = ({
    onSuccess,
    selected,
}) => {
    const dispatch = useDispatch();
    const table = useSelector((state: any) => state.RestaurantProductsTable);

    const {
        request: hideProductsRequest,
        isLoading: hideProductsLoading,
        status: hideProductsStatus,
        message: hideProductsMessage,
    } = useRequest();

    const { currentData, isError, isLoading, status } = useRequest(
        restaurantProductsFetch({
            params: {
                _filters: { ids_filter: selected, ...table.filters },
                _with: "product",
            },
        })
    );

    const handleCloseModal = () => {
        dispatch(modalHide("RestaurantProductsHideModal"));
    };

    const handleSubmitHideProducts = () => {
        hideProductsRequest(
            bulkRestaurantProductsHide(
                { ids: selected },
                (response: AxiosResponse) => {
                    if (response.status < 300) {
                        dispatch(modalHide("RestaurantProductsHideModal"));
                        dispatch(
                            restaurantProductsTableUpdate("selection", [])
                        );
                        onSuccess();
                    }
                }
            )
        );
    };

    if (isLoading || hideProductsLoading) return <Loader />;

    if (isError) return <RequestMessage status={status} />;

    return (
        <Modal open fullWidth maxWidth="xl">
            <ModalTitle onClose={handleCloseModal}>
                {trans(
                    "inv.restaurantProducts.modal.RestaurantProductsHideModal.modalTitle"
                )}
            </ModalTitle>
            <ModalContent sx={{ p: 2 }}>
                <RequestMessage
                    status={hideProductsStatus}
                    message={hideProductsMessage}
                    sx={{ mb: 1 }}
                />
                <Typography>
                    {trans(
                        `inv.restaurantProducts.modal.RestaurantProductsHideModal.${
                            table.filters.hasOwnProperty("show_hidden_filter")
                                ? "actionTextShow"
                                : "actionTextHide"
                        }`
                    )}
                </Typography>
                {currentData?.data.map((item: RestaurantProductModel) => {
                    return (
                        <Typography
                            sx={{ pl: 2 }}
                            fontWeight={600}
                            key={item.id}
                        >
                            - {item.name}
                        </Typography>
                    );
                })}
            </ModalContent>
            <ModalActions>
                <Box display="flex" width="100%" textAlign="right">
                    <Button
                        onClick={handleCloseModal}
                        color="inherit"
                        variant="text"
                        sx={{ mr: 1 }}
                    >
                        {trans("global.button.cancel")}
                    </Button>
                    <Button
                        color="primary"
                        sx={{ flex: 1 }}
                        onClick={handleSubmitHideProducts}
                    >
                        {trans("global.button.save")}
                    </Button>
                </Box>
            </ModalActions>
        </Modal>
    );
};

export default RestaurantProductsHideModal;
