import { tableResponse } from "helpers/reducers";
import { AnyAction } from "redux";
import { FETCH_SUCCESS } from "../types";
import { MANAGE_TABLE } from "app/ManageTables/types";

function DeliverySlipApproveDetailsTable(
    state: any = {
        id: "DeliverySlipLinesApprove",
        search: "",
        selection: {
            id: "id",
            selected: [],
        },
        selectionActions: null,
    },
    action: AnyAction
) {
    switch (action.type) {
        case FETCH_SUCCESS:
            if (action.response.config.params._table_id === state.id) {
                return tableResponse(state, action);
            }
            return state;

            case MANAGE_TABLE:
                if (action.data.id === state.id) {
                    const columnType = action.data.type;
                    return {
                        ...state,
                        [columnType]: action.data
                    };
                }
                return state;
        default:
            return state;
    }
}

export default DeliverySlipApproveDetailsTable;
