import queryString from "query-string";

export const addPrefixFilters = (params: any, isFilter: boolean) => {
    return Object.entries(params).reduce((acc: any, [key, value]) => {
        if (isFilter) {
            acc[`menu_list_${key}_filter`] = value;
        } else acc[key] = value;
        return acc;
    }, {});
};

export const removePrefixFilters = (filters: any, isFilter: boolean) => {
    return Object.keys(filters).reduce((newFilters: any, key) => {
        const newKey = isFilter
            ? key.replace(/^menu_list_/, "").replace(/_filter$/, "")
            : key.replace("_", "");
        if (filters[key] !== undefined) {
            newFilters[newKey] = filters[key];
        }
        return newFilters;
    }, {});
};

export const generateParams = (params: any) => {
    const { filters, sort, search, pagination } = params;
    const price = filters?.menu_list_price_range_filter;
    const volume = filters?.menu_list_volume_filter;
    let newPrice;
    let newVolume: string[] = [];
    if (price) {
        newPrice = filters.menu_list_price_range_filter.map((price: any) =>
            queryString.stringify(price)
        );
    }
    if (volume) {
        filters.menu_list_volume_filter?.map((volume: string[]) =>
            volume.map((vol: string) => newVolume.push(vol))
        );
    }

    const preparedFilters = removePrefixFilters(
        {
            ...filters,
            ...(price && { menu_list_price_range_filter: newPrice }),
            ...(volume && { menu_list_volume_filter: newVolume }),
        },
        true
    );

    const paramsObj = {
        ...preparedFilters,
        ...(sort && { sort: queryString.stringify(sort) }),
        ...(search && { search }),
        ...(pagination.page && { page: pagination.page }),
    };
    const queryParams = queryString.stringify(paramsObj, {
        arrayFormat: "bracket",
    });
    return { paramsObj, queryParams };
};
