import {
    RestaurantProductModel,
    RestaurantProductUpdateModel,
    PortionsAddModel,
    PortionsDeleteModel,
} from "./interfaces";
import types from "./types";
import { AxiosRequestConfig } from "axios";
import { request } from "api/actions";

export const restaurantProductsCreateFromProduct = (
    id: number,
    onResponse?: Function
) =>
    request(
        types.PRODUCT_REQUEST,
        types.PRODUCT_FAILED,
        types.PRODUCT_SUCCESS,
        "restaurantProductsCreateFromProduct",
        { url: { id } },
        onResponse
    );

export const restaurantProductsCreate = (
    data: RestaurantProductModel,
    onResponse?: Function
) =>
    request(
        types.STORE_REQUEST,
        types.STORE_FAILED,
        types.STORE_SUCCESS,
        "restaurantProductsCreate",
        { data },
        onResponse
    );

export const restaurantProductsDelete = (id: number, onResponse?: Function) =>
    request(
        types.DESTROY_REQUEST,
        types.DESTROY_FAILED,
        types.DESTROY_SUCCESS,
        "restaurantProductsDelete",
        {
            url: {
                id,
            },
        },
        onResponse
    );

export const restaurantProductsFetch = (config?: AxiosRequestConfig) =>
    request(
        types.FETCH_REQUEST,
        types.FETCH_FAILED,
        types.FETCH_SUCCESS,
        "restaurantProductsFetch",
        {
            config,
        }
    );

export const restaurantProductsGet = (
    id: number,
    config?: AxiosRequestConfig,
    onResponse?: Function
) =>
    request(
        types.GET_REQUEST,
        types.GET_FAILED,
        types.GET_SUCCESS,
        "restaurantProductGet",
        {
            config,
            url: {
                id,
            },
        },
        onResponse
    );

export const restaurantProductPriceHistoryGet = (
    id: string,
    config?: AxiosRequestConfig
) =>
    request(
        types.GET_PRICE_HISTORY_REQUEST,
        types.GET_PRICE_HISTORY_SUCCESS,
        types.GET_PRICE_HISTORY_FAILED,
        "restaurantProductPriceHistoryGet",
        {
            config,
            url: {
                id,
            },
        }
    );

export const restaurantProductsTableUpdate = (action: string, data: any) => ({
    type: types.TABLE_UPDATE,
    action,
    data,
});

export const alertPortionIsSelected = () => ({
    type: types.PORTION_HAS_BEEN_SELECTED,
});

export const restaurantProductsUpdate = (
    id: number,
    data: RestaurantProductUpdateModel,
    onResponse?: Function
) => {
    return request(
        types.UPDATE_REQUEST,
        types.UPDATE_FAILED,
        types.UPDATE_SUCCESS,
        "restaurantProductsUpdate",
        { data, url: { id } },
        onResponse
    );
};

export const restaurantProductsCombine = (
    target: number,
    data: any,
    onResponse: Function
) => {
    return request(
        types.COMBINE_PRODUCTS_REQUEST,
        types.COMBINE_PRODUCTS_FAILED,
        types.COMBINE_PRODUCTS_SUCCESS,
        "restaurantProductsCombine",
        { data, url: { id: target } },
        onResponse
    );
};

export const restaurantProductHide = (
    id: number,
    config?: AxiosRequestConfig,
    onResponse?: Function
) => {
    return request(
        types.HIDE_PRODUCT_REQUEST,
        types.HIDE_PRODUCT_FAILED,
        types.HIDE_PRODUCT_SUCCESS,
        "restaurantProductsHide",
        {
            config,
            url: {
                id,
            },
        },
        onResponse
    );
};

export const bulkRestaurantProductsHide = (
    data: any,
    onResponse?: Function,
    config?: AxiosRequestConfig
) => {
    return request(
        types.BULK_HIDE_PRODUCTS_REQUEST,
        types.BULK_HIDE_PRODUCTS_FAILED,
        types.BULK_HIDE_PRODUCTS_SUCCESS,
        "bulkRestaurantProductsHide",
        { config, data },
        onResponse
    );
};

export const bulkRestaurantProductsCategory = (
    data: any,
    onResponse?: Function,
    config?: AxiosRequestConfig
) => {
    return request(
        types.BULK_CATEGORY_REQUEST,
        types.BULK_CATEGORY_FAILED,
        types.BULK_CATEGORY_SUCCESS,
        "bulkRestaurantProductsCategory",
        { config, data },
        onResponse
    );
};
export const bulkRestaurantProductsShowUnit = (
    data: any,
    onResponse?: Function,
    config?: AxiosRequestConfig
) => {
    return request(
        types.BULK_SHOW_UNIT_REQUEST,
        types.BULK_SHOW_UNIT_FAILED,
        types.BULK_SHOW_UNIT_SUCCESS,
        "bulkRestaurantProductsShowUnit",
        { config, data },
        onResponse
    );
};

export const bulkRestaurantProductsSizeShowUnit = (
    data: any,
    onResponse?: Function,
    config?: AxiosRequestConfig
) => {
    return request(
        types.BULK_SIZE_SHOW_UNIT_REQUEST,
        types.BULK_SIZE_SHOW_UNIT_FAILED,
        types.BULK_SIZE_SHOW_UNIT_SUCCESS,
        "bulkRestaurantProductsSizeShowUnit",
        { config, data },
        onResponse
    );
};

export const restaurantProductsDeletePortions = (
    data: PortionsDeleteModel,
    onResponse?: Function
) => {
    return request(
        types.DESTROY_PORTIONS_REQUEST,
        types.DESTROY_PORTIONS_FAILED,
        types.DESTROY_PORTIONS_SUCCESS,
        "restaurantProductsDeletePortions",
        { data },
        onResponse
    );
};

export const restaurantProductsCreatePortions = (
    data: PortionsAddModel,
    onResponse?: Function
) =>
    request(
        types.STORE_PORTIONS_REQUEST,
        types.STORE_PORTIONS_FAILED,
        types.STORE_PORTIONS_SUCCESS,
        "restaurantProductsCreatePortions",
        { data },
        onResponse
    );
