import { request } from "api/apiSaga";
import { snackbarShow } from "app/App/actions";
import { AxiosResponse } from "axios";
import trans from "helpers/trans";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import api from "./api";
import types from "./types";

function* menuCategoriesFetch(action: AnyAction) {
    yield call(request, api.menuCategoriesFetch, action);
}

function* menuCategoriesCreate(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.menuCategoryCreate,
        action,
        "post"
    );

    if (response.status < 300) {
        yield put(
            snackbarShow({
                content: trans("inv.menusCategories.saga.content.create"),
            })
        );
    }
}

function* menuCategoriesGet(action: AnyAction) {
    yield call(request, api.menuCategoryGet, action);
}

function* menuCategoriesUpdate(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.menuCategoryUpdate,
        action,
        "put"
    );

    if (response.status < 300) {
        yield put(
            snackbarShow({
                content: trans("inv.menusCategories.saga.content.update"),
            })
        );
    }
}

function* menuCategoriesDelete(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.menuCategoryDelete,
        action,
        "delete"
    );

    if (response.status < 300) {
        yield put(
            snackbarShow({
                content: trans("inv.menusCategories.saga.content.delete"),
            })
        );
    }
}

export default function* saga() {
    yield takeLatest(types.FETCH_REQUEST, menuCategoriesFetch);
    yield takeLatest(types.CREATE_REQUEST, menuCategoriesCreate);
    yield takeLatest(types.GET_REQUEST, menuCategoriesGet);
    yield takeLatest(types.UPDATE_REQUEST, menuCategoriesUpdate);
    yield takeLatest(types.DELETE_REQUEST, menuCategoriesDelete);
}
