import { MenuItem } from "@mui/material";
import { unitSymbol } from "helpers/units";
import { FC } from "react";

interface IMenuItemsCreateModal {
    portions: any;
    onClick: Function;
}

const MenuItemSelectPortion: FC<IMenuItemsCreateModal> = ({
    onClick,
    portions,
}) => {
    return (
        <>
            <MenuItem
                value="whole_product"
                onClick={() => onClick(null)}
                sx={{
                    borderBottomColor: "grey.300",
                    borderBottomStyle: "solid",
                    borderBottomWidth: 1,
                    height: 66,
                    py: 1,
                    "&:last-child": {
                        border: "none",
                    },
                }}
            >
                <em>Whole Product</em>
            </MenuItem>
            {portions.map((portion: any) => (
                <MenuItem
                    key={portion.id}
                    value={portion.id}
                    sx={{
                        borderBottomColor: "grey.300",
                        borderBottomStyle: "solid",
                        borderBottomWidth: 1,
                        height: 66,
                        py: 1,
                        "&:last-child": {
                            border: "none",
                        },
                    }}
                    onClick={() => onClick(portion)}
                >
                    {portion.portion.name} {portion.portion.size}
                    {
                        unitSymbol[
                            portion.portion.unit as keyof typeof unitSymbol
                        ]
                    }
                </MenuItem>
            ))}
        </>
    );
};

export default MenuItemSelectPortion;
