import { ME_SUCCESS } from "./types";
import { AnyAction } from "redux";

function general(
    state = {
        applications: [],
        email: "",
        first_name: "",
        last_name: "",
        organisations: [],
        permissions: [],
        phone: "",
        role: "",
    },
    action: AnyAction
) {
    switch (action.type) {
        case ME_SUCCESS:
            return {
                ...state,
                ...action.response.data.data,
            };

        default:
            return state;
    }
}

export default general;
