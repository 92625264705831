export function shapeOfSlider(category) {
    if (!category) return null;

    if (category.id === 7) {
        return "champagne";
    } else if (
        category.id === 3 ||
        category.id === 1 ||
        category.parent_id === 1 ||
        category.parent_id === 4
    ) {
        return "wine";
    }

    return "rectangle";
}
