import SettingsUsersSaga from "./saga";
import UsersTable from "./reducers/SettingsUsersTableReducer";
import AsyncComponent from "ui/AsyncComponent";
import { registerRoutings } from "rootRouting";
import store from "rootStore";
import { url as settingsUrl } from "app/Settings/config";
import { configType, permissionsType, routingType } from "types/configTypes";

export const permissionKey = "settings";

export const url = `${settingsUrl}/users`;

const getConfig = (permissions: permissionsType) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: configType = {
        permissions: {
            read: false,
            delete: false, 
            update: false,
            create: false
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    // create, update, read, delete
    if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
        config.permissions.update = true;
        routing.push({
            path: `${url}/create`,
            element: (
                <AsyncComponent path="SettingsUsers/pages/SettingsUsersCreatePage" />
            ),
        });

        store.reducerManager?.add("UsersTable", UsersTable);
        store.injectSaga?.("SettingsUsersSaga", SettingsUsersSaga);

        routing.push({
            path: url,
            element: (
                <AsyncComponent path="SettingsUsers/pages/SettingsUsersIndexPage" />
            ),
        });

        routing.push({
            path: `${url}/:id/edit`,
            element: (
                <AsyncComponent path="SettingsUsers/pages/SettingsUsersEditPage" />
            ),
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
