export type ProductModel = {
    id: number;
    category_id?: number;
    name?: string;
    is_global: boolean;
    location_id?: string;
    unit?: string;
    size?: number;
    vintage?: number;
    show_unit?: string;
    ean_dpak?: any;
    country?: string;
    product_number?: any;
    gtin?: any;
    source?: string;
    category?: {
        created_at?: string;
        id?: number;
        name?: string;
        parent_id?: number;
        updated_at?: string;
    };
    images?: any;
    updated_at?: string;
    created_at?: string;
    restaurant_product: any;
};

export type ProductUpdateModel = {
    category_id?: number;
    name?: string;
    is_global?: boolean;
    unit?: string;
    size?: number;
    vintage?: number;
    show_unit?: string;
    country?: string;
    product_number?: any;
    gtin?: any;
    source?: string;
    images?: any;
    updated_at?: string;
    created_at?: string;
};

export interface IProduct extends ProductModel {
    allergens: IAllergens;
    _idx: number;
}

export interface IAllergens {
    [key: string]: boolean | null;
}

export const FETCH_FAILED = "PRODUCTS/FETCH_FAILED";
export const FETCH_SUCCESS = "PRODUCTS/FETCH_SUCCESS";

export const STORE_FAILED = "PRODUCTS/STORE_FAILED";
export const STORE_SUCCESS = "PRODUCTS/STORE_SUCCESS";

export const TABLE_UPDATE = "PRODUCTS/TABLE_UPDATE";

export const VINTAGE_FAILED = "PRODUCTS/VINTAGE_FAILED";
export const VINTAGE_REQUEST = "PRODUCTS/VINTAGE_REQUEST";
export const VINTAGE_SUCCESS = "PRODUCTS/VINTAGE_SUCCESS";

const types = {
    DESTROY_FAILED: "PRODUCTS/DESTROY_FAILED",
    DESTROY_REQUEST: "PRODUCTS/DESTROY_REQUEST",
    DESTROY_SUCCESS: "PRODUCTS/DESTROY_SUCCESS",

    FETCH_FAILED,
    FETCH_REQUEST: "PRODUCTS/FETCH_REQUEST",
    FETCH_SUCCESS,

    GET_FAILED: "PRODUCTS/GET_FAILED",
    GET_REQUEST: "PRODUCTS/GET_REQUEST",
    GET_SUCCESS: "PRODUCTS/GET_SUCCESS",

    PRODUCT_SUPPLIERS_REQUEST: "RESTAURANT_PRODUCTS/PRODUCT_SUPPLIERS_REQUEST",
    PRODUCT_SUPPLIERS_FAILED: "RESTAURANT_PRODUCTS/PRODUCT_SUPPLIERS_FAILED",
    PRODUCT_SUPPLIERS_SUCCESS: "RESTAURANT_PRODUCTS/PRODUCT_SUPPLIERS_SUCCESS",
    
    VINTAGE_FAILED,
    VINTAGE_REQUEST,
    VINTAGE_SUCCESS,

    STORE_FAILED,
    STORE_REQUEST: "PRODUCTS/STORE_REQUEST",
    STORE_SUCCESS,

    UPDATE_FAILED: "PRODUCTS/UPDATE_FAILED",
    UPDATE_REQUEST: "PRODUCTS/UPDATE_REQUEST",
    UPDATE_SUCCESS: "PRODUCTS/UPDATE_SUCCESS",

    TABLE_UPDATE,
};

export default types;
