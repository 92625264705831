import { Box, Grid, Stack, Typography, SxProps, Theme } from "@mui/material";

interface IGPSettingsItem {
    actions?: React.ReactNode;
    children: React.ReactNode;
    description?: string;
    headSx?: SxProps<Theme>;
    itemSx?: SxProps<Theme>;
    label?: string;
    long?: boolean;
}

function GPSettingsItem({
    actions,
    children,
    description,
    headSx,
    itemSx,
    label,
}: IGPSettingsItem) {
    return (
        <Grid container alignItems="stretch" spacing={0}>
            <Grid
                item
                md={4}
                xs={12}
                sx={{
                    backgroundColor: "grey.100",
                    borderBottom: "1px solid",
                    borderBottomColor: "grey.300",
                    borderRight: "1px solid",
                    borderRightColor: "grey.300",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    px: 2,
                    py: 1,
                    ...headSx,
                }}
            >
                <Stack
                    alignItems="flex-start"
                    direction="row"
                    justifyContent="space-between"
                >
                    <Stack>
                        {!!label && (
                            <Typography
                                component="strong"
                                display="block"
                                sx={{
                                    fontWeight: 500,
                                }}
                            >
                                {label}
                            </Typography>
                        )}
                        {!!description && (
                            <Typography variant="caption" display="block">
                                {description}
                            </Typography>
                        )}
                    </Stack>

                    {!!actions && (
                        <Box sx={{ marginLeft: "auto" }}>{actions}</Box>
                    )}
                </Stack>
            </Grid>
            <Grid
                item
                md={8}
                xs={12}
                sx={{
                    borderBottom: "1px solid",
                    borderBottomColor: "grey.300",
                    padding: 1,
                    ...itemSx,
                }}
            >
                {children}
            </Grid>
        </Grid>
    );
}

GPSettingsItem.defaultProps = {
    actions: null,
    headSx: {},
    itemSx: {},
    long: false,
};

export default GPSettingsItem;
