import { Select, RequestMessage } from "ui";
import { MenuItem } from "@mui/material";
import useRequest from "api/useRequest";
import { suppliersFetch } from "../actions";
import trans from "helpers/trans";
import { IGPSelect } from "ui/Form/Select";
import { FC, useMemo } from "react";

const LocationSupplierSelect: FC<IGPSelect> = (props) => {
    const { currentData, status, isError } = useRequest(
        suppliersFetch({
            params: { _with: "supplier", _sort: { "supplier.name": "asc" } },
        })
    );

    const supplierList = useMemo(() => {
        if (currentData) {
            const suppliersList = currentData.data.map((item: any) => {
                return (
                    <MenuItem key={item.id} value={item.id}>
                        {item.supplier.name}
                    </MenuItem>
                );
            });

            return suppliersList;
        }

        return [];
    }, [currentData]);

    if (isError) return <RequestMessage status={status} />;

    return (
        <Select {...props}>
            <MenuItem value="">
                <em>
                    {trans(
                        "inv.locationSuppliers.component.locationSupplierSelect.select"
                    )}
                </em>
            </MenuItem>
            {supplierList}
        </Select>
    );
};

export default LocationSupplierSelect;
