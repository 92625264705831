import { registerRoutings } from "rootRouting";
import store from "rootStore";
import RecipesTable from "./reducers/RecipesTableReducer";
import RecipesSaga from "./saga";
import { permissionsType, IConfig, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";

export const permissionKey = "recipes";
export const url = "/recipes";

const getConfig = (permissions: permissionsType) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: IConfig = {
        permissions: {
            create: false,
            delete: false,
            read: false,
            update: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (modulePermissions.indexOf(permissionKey + ".delete") !== -1) {
        config.permissions.delete = true;
    }

    if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
        config.permissions.update = true;

        routing.push({
            path: `${url}/:id/edit`,
            element: <AsyncComponent path="Recipes/pages/RecipesEditPage" />,
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;

        store.reducerManager?.add("RecipesTable", RecipesTable);

        store.injectSaga?.("RecipesSaga", RecipesSaga);

        routing.push({
            path: url,
            element: <AsyncComponent path="Recipes/pages/RecipesIndexPage" />,
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".create") !== -1) {
        config.permissions.create = true;

        routing.push({
            path: `${url}/create`,
            element: <AsyncComponent path="Recipes/pages/RecipesCreatePage" />,
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
