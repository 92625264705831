export const MANAGE_TABLE = "MANAGE_TABLE/MANAGE_TABLE";

export const MANAGE_TABLE_REQUEST = "MANAGE_TABLE/GET_REQUEST";
export const MANAGE_TABLE_FAILED = "MANAGE_TABLE/GET_FAILED";
export const MANAGE_TABLE_SUCCESS = "MANAGE_TABLE/GET_SUCCESS";

export const MANAGE_TABLE_DELETE_REQUEST = "MANAGE_TABLE/DELETE_REQUEST";
export const MANAGE_TABLE_DELETE_FAILED = "MANAGE_TABLE/DELETE_FAILED";
export const MANAGE_TABLE_DELETE_SUCCESS = "MANAGE_TABLE/DELETE_SUCCESS";

export const MANAGE_TABLE_UPDATE_REQUEST = "MANAGE_TABLE/UPDATE_REQUEST";
export const MANAGE_TABLE_UPDATE_FAILED = "MANAGE_TABLE/UPDATE_FAILED";
export const MANAGE_TABLE_UPDATE_SUCCESS = "MANAGE_TABLE/UPDATE_SUCCESS";

const types = {
    MANAGE_TABLE,

    MANAGE_TABLE_REQUEST,
    MANAGE_TABLE_FAILED,
    MANAGE_TABLE_SUCCESS,

    MANAGE_TABLE_DELETE_REQUEST,
    MANAGE_TABLE_DELETE_FAILED,
    MANAGE_TABLE_DELETE_SUCCESS,

    MANAGE_TABLE_UPDATE_REQUEST,
    MANAGE_TABLE_UPDATE_FAILED,
    MANAGE_TABLE_UPDATE_SUCCESS,
};

export default types;
