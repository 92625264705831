import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlId from "types/RequestUrlId";

const baseUrl = "/menus";

const requests = {
    menuFetch: (config: AxiosRequestConfig) => api.get(`${baseUrl}`, config),
    menuGet: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`${baseUrl}/${url.id}`, config),
    menuUpdate: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.put(`${baseUrl}/${url.id}`, data, config),
    menuDelete: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.delete(`${baseUrl}/${url.id}`, config),
    menuCreate: (data: object, config: AxiosRequestConfig) =>
        api.post(`${baseUrl}`, data, config),
    menuDuplicate: (
        data: any,
        config: AxiosRequestConfig,
        url: RequestUrlId
    ) => {
        return api.post(`${baseUrl}/${url.id}/duplicate`);
    },
};

export default requests;
