import { request } from "api/actions";
import { AxiosRequestConfig } from "axios";
import types from "./types";

export const suppliersFetch = (config?: AxiosRequestConfig) =>
    request(
        types.FETCH_REQUEST,
        types.FETCH_FAILED,
        types.FETCH_SUCCESS,
        "suppliersFetch",
        {
            config,
        }
    );

export const suppliersAll = (config?: AxiosRequestConfig) =>
    request(
        types.FETCH_ALL_REQUEST,
        types.FETCH_ALL_FAILED,
        types.FETCH_ALL_SUCCESS,
        "suppliersAll",
        {
            config,
        }
    );
