import RecipeCategoriesSaga from "./saga";
import { registerRoutings } from "rootRouting";
import AsyncComponent from "ui/AsyncComponent";
import store from "rootStore";
import RecipeCategoriesTable from "./reducers/RecipeCategoriesTableReducer";

interface IConfig {
    permissions: {
        create: boolean,
        delete: boolean,
        read: boolean,
        update: boolean,
    },
    routing: Array<string>,
    url: string,
}

export const permissionKey = "recipes.categories";
export const url = "/recipe/categories";

const getConfig = (permissions: Array<string>) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: IConfig = {
        permissions: {
            create: false,
            delete: false,
            read: false,
            update: false,
        },
        routing: [],
        url,
    };

    let routing = [];

    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;

        store.reducerManager?.add(
            "RecipeCategoriesTable",
            RecipeCategoriesTable
        );
        store.injectSaga?.("RecipeCategoriesSaga", RecipeCategoriesSaga);

        routing.push({
            path: url,
            element: (
                <AsyncComponent path="RecipeCategories/pages/RecipeCategoriesIndexPage" />
            ),
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".delete") !== -1) {
        config.permissions.delete = true;
    }

    if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
        config.permissions.update = true;

        routing.push({
            path: `${url}/:id/edit`,
            element: (
                <AsyncComponent path="RecipeCategories/pages/RecipeCategoriesEditPage" />
            ),
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".create") !== -1) {
        config.permissions.create = true;

        routing.push({
            path: `${url}/create`,
            element: (
                <AsyncComponent path="RecipeCategories/pages/RecipeCategoriesCreatePage" />
            ),
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
