import api from "./api";
import types from "./types";
import { request } from "api/apiSaga";
import { snackbarShow } from "app/App/actions";
import trans from "helpers/trans";
import { put, call, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";
import { AxiosResponse } from "axios";

function* get(action: AnyAction) {
    yield call(request, api.get, action);
}

function* fetch(action: AnyAction) {
    yield call(request, api.fetch, action);
}

function* deleteSupplier(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.delete,
        action,
        "delete"
    );

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("inv.locationSuppliers.saga.content.destroy"),
            })
        );
    }
}

function* create(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.create,
        action,
        "post"
    );

    if (response.status === 201) {
        yield put(
            snackbarShow({
                content: trans("inv.locationSuppliers.saga.content.store"),
            })
        );
    }
}

function* update(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.update,
        action,
        "put"
    );

    if (response.status === 200) {
        yield put(
            snackbarShow({
                content: trans("inv.locationSuppliers.saga.content.update"),
            })
        );
    }
}

export default function* saga() {
    yield takeLatest(types.FETCH_REQUEST, fetch);
    yield takeLatest(types.DELETE_REQUEST, deleteSupplier);
    yield takeLatest(types.CREATE_REQUEST, create);
    yield takeLatest(types.GET_REQUEST, get);
    yield takeLatest(types.UPDATE_REQUEST, update);
}
