import { DialogContent, DialogContentProps } from "@mui/material";

interface IGPModalContent extends DialogContentProps {}

function GPModalContent(props: IGPModalContent) {
    const { children, ...restProps } = props;

    return <DialogContent {...restProps}>{children}</DialogContent>;
}

GPModalContent.defaultProps = {
    sx: {
        px: 2,
        py: 1,
    },
};

export default GPModalContent;
