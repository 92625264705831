import SettingsPortionsSaga from "./saga";
import PortionsTable from "./reducers/SettingsPortionsTableReducer";
import AsyncComponent from "ui/AsyncComponent";
import { registerRoutings } from "rootRouting";
import store from "rootStore";
import { url as settingsUrl } from "app/Settings/config";
import { permissionsType, routingType } from "types/configTypes";

interface IConfig {
    permissions: {
        update: boolean;
    };
    routing: Array<string>;
    url: string;
}

export const permissionKey = "settings";

export const url = `${settingsUrl}/portions`;

const getConfig = (permissions: permissionsType) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: IConfig = {
        permissions: {
            update: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    // create, update, read, delete
    if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
        config.permissions.update = true;
        routing.push({
            path: `${url}/create`,
            element: (
                <AsyncComponent path="SettingsPortions/pages/SettingsPortionsCreatePage" />
            ),
        });

        store.reducerManager?.add("PortionsTable", PortionsTable);
        store.injectSaga?.("SettingsPortionsSaga", SettingsPortionsSaga);

        routing.push({
            path: url,
            element: (
                <AsyncComponent path="SettingsPortions/pages/SettingsPortionsIndexPage" />
            ),
        });

        routing.push({
            path: `${url}/:id/edit`,
            element: (
                <AsyncComponent path="SettingsPortions/pages/SettingsPortionsEditPage" />
            ),
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
