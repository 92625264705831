import SvgIcon from "@mui/material/SvgIcon";

function NoImageIcon(props: any) {
    return (
        <SvgIcon viewBox="0 0 827 826" {...props}>
            <path
                d="M3865 8244c-688-55-1289-243-1855-581-713-426-1304-1094-1642-1857C135 5279 19 4729 18 4140c0-442 51-804 173-1207 190-634 526-1202 989-1677C1845 575 2658 168 3615 39c194-26 643-37 847-20 1216 101 2300 707 3012 1684 420 577 675 1242 763 1985 24 205 24 679 0 884-114 964-516 1805-1183 2472-662 662-1501 1067-2449 1182-124 15-625 27-740 18zm565-534c612-53 1183-251 1685-585 140-94 355-258 355-273 0-4-305-312-679-686-498-498-681-675-687-665-5 8-59 106-120 218-71 131-122 212-140 225-28 21-39 21-694 24-467 2-673-1-691-9-45-18-63-45-202-299l-134-245-614-5c-609-5-614-5-670-28-151-61-244-166-284-319-12-45-15-125-15-370v-313h1676l44 66c55 83 175 206 261 267 261 185 588 244 897 161l64-17-673-673c-370-370-676-671-680-668-15 9-50 188-55 282-5 88 10 242 32 320l6 22H1540v-727c0-466 4-744 11-773 18-80 68-166 130-225 55-52 164-115 201-115 9 0 19-4 22-8 3-5-107-120-243-256l-249-249-87 109c-726 912-963 2110-639 3231 139 480 373 917 705 1313 121 145 360 379 504 493 533 427 1197 701 1850 766 61 6 124 13 140 15 70 8 439 5 545-4zm2520-1350c834-1048 1014-2473 466-3699-246-551-659-1060-1161-1432-473-350-1039-577-1656-664-152-22-615-31-775-16-624 60-1163 250-1669 587-140 93-355 257-355 272 0 4 195 202 433 439l432 433 1850 2 1850 3 66 26c126 49 203 121 262 246l32 68 3 758 2 757h-785c-760 0-786-1-780-18 18-60 35-187 35-272 0-504-356-941-852-1045-224-48-464-17-676 87-79 39-232 142-232 156 0 4 337 344 748 755l748 748 57-76c31-42 57-81 57-86s338-9 840-9h840v324c0 354-4 389-63 489-54 92-179 181-288 206-24 6-162 11-306 11-145 0-263 3-263 7 0 15 1044 1052 1053 1046 5-3 44-49 87-103zm-638-1347c16-14 18-34 18-189 0-167-1-173-22-188-19-13-61-16-258-16s-239 3-258 16c-21 15-22 21-22 189 0 144 3 176 16 189 13 14 51 16 261 16 215 0 249-2 265-17z"
                transform="matrix(.1 0 0 -.1 0 826)"
            ></path>
        </SvgIcon>
    );
}

export default NoImageIcon;
