import {
    FC,
    useLayoutEffect,
    useRef,
    useCallback,
    useEffect,
    useState,
} from "react";
import { Box } from "@mui/system";
import Pickr from "@simonwep/pickr";
import "@simonwep/pickr/dist/themes/nano.min.css";
import "./style.css";

interface IGPColorPicker {
    color: string;
    onChange?: Function;
}

const ColorPicker: FC<IGPColorPicker> = (props) => {
    const { onChange, color } = props;
    const [hex, setHex] = useState<string>(color);

    const pickerRef = useRef<any>(null);
    const picker = useRef<any>(null);

    const handleChange = useCallback(
        (hexColor: string) => {
            if (!!onChange) {
                onChange(hexColor);
            }
        },
        [onChange]
    );

    useEffect(() => {
        if (!!picker.current) {
            picker.current.setColor(hex);
        }
        handleChange(hex);

        //eslint-disable-next-line
    }, [hex]);

    useLayoutEffect(() => {
        const handleChangeColor = (color: any, instance: any) => {
            // setHEX(color.toHEXA().toString());
            setHex(instance.getColor().toHEXA().toString());
        };

        if (!picker.current) {
            Pickr.create({
                el: pickerRef.current,
                theme: "nano",
                closeOnScroll: true,
                default: color,
                lockOpacity: true,

                components: {
                    preview: false,
                    opacity: false,
                    hue: true,
                },
            })
                .on("init", (instance: any) => {
                    picker.current = instance;
                })
                .on("changestop", handleChangeColor);
        } else {
            picker.current.setColor(color);
        }
    }, [color]);

    return <Box ref={pickerRef} />;
};

export default ColorPicker;
