import { FC, useContext, useEffect, useState } from "react";
import { IFormChangeResponse } from "./Form";
import FormContext from "./FormContext";
import { getValue } from "./helpers";
import TextField, { IGPTextField } from "./TextField";

export interface INumberField extends IGPTextField {
    maxPrecision?: number;
    precision?: number;
    value?: number | string;
}

const calculateFloatString = (
    val: string,
    precision: number,
    maxPrecision: number
) => {
    let floatNumber = "";
    if (val !== "") {
        floatNumber = (+val + 0.0).toFixed(precision);
        if (maxPrecision === 0) {
            floatNumber = (+val + 0.0).toFixed(0);
        } else if (precision === 0) {
            floatNumber = val;
        }
    }

    return floatNumber;
};

const NumberField: FC<INumberField> = ({
    id,
    onChange,
    value,
    onBlur,
    precision = 0,
    maxPrecision = 3,
    ...restProps
}) => {
    const context = useContext(FormContext);
    const inputValue = getValue(id, context, value);

    const [val, setVal] = useState(
        inputValue || inputValue === 0
            ? calculateFloatString(inputValue, precision, maxPrecision)
            : ""
    );

    useEffect(() => {
        const prepareInputVal =
            inputValue || inputValue === 0
                ? calculateFloatString(inputValue, precision, maxPrecision)
                : "";
        if (+prepareInputVal !== +val) {
            handleChange({ id, value: prepareInputVal.toString() });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue, precision, maxPrecision, val]);

    const handleChange = ({ value }: IFormChangeResponse) => {
        const targetValue = value.replace(",", ".");
        let regex = new RegExp(`^\\d{0,16}([.]\\d{0,${precision}})?$`);
        if (maxPrecision === 0) {
            regex = new RegExp(`^\\d{0,16}?$`);
        } else if (precision === 0) {
            regex = new RegExp(`^\\d{0,16}([.]\\d{0,${maxPrecision}})?$`);
        }

        if (targetValue.length === 0 || regex.test(targetValue)) {
            const intValue = parseFloat(targetValue);

            if (!!onChange) {
                onChange({
                    id,
                    value: intValue,
                });
            } else {
                context?.onChange({
                    id,
                    value: intValue,
                });
            }

            setVal(targetValue);
        }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (!!onBlur) {
            onBlur(e);
        }

        setVal(calculateFloatString(val, precision, maxPrecision));
    };

    return (
        <TextField
            {...restProps}
            id={id}
            inputProps={{ inputMode: "numeric" }}
            onChange={handleChange}
            value={val}
            onBlur={handleBlur}
            InputLabelProps={{
                shrink: true,
            }}
        />
    );
};

export default NumberField;
