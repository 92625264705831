import Link from "@mui/material/Link";
import { LinkProps } from "@mui/material";

interface IGPLink extends LinkProps {
    component?: any;
    to?: string;
}

function GPLink({ children, ...restProps }: IGPLink) {
    return <Link {...restProps}>{children}</Link>;
}

GPLink.defaultProps = {
    underline: "none",
};

export default GPLink;
