import { SupplierType } from "app/Suppliers/types";

export type LocationSupplierModel = {
    id: number;
    location_id: number;
    supplier_id: number;
    contact_name?: string;
    contact_phone?: string;
    contact_email?: string;
    delivery_customer_numbers?: [];
    forward_to?: string[];
    notes?: string;
    created_at?: string;
    updated_at?: string;
    supplier: SupplierType;
};

export const GET_FAILED = "LOCATION_SUPPLIERS/GET_FAILED";
export const GET_SUCCESS = "LOCATION_SUPPLIERS/GET_SUCCESS";
export const GET_REQUEST = "LOCATION_SUPPLIERS/GET_REQUEST";

export const FETCH_FAILED = "LOCATION_SUPPLIERS/FETCH_FAILED";
export const FETCH_SUCCESS = "LOCATION_SUPPLIERS/FETCH_SUCCESS";
export const FETCH_REQUEST = "LOCATION_SUPPLIERS/FETCH_REQUEST";

export const UPDATE_FAILED = "LOCATION_SUPPLIERS/UPDATE_FAILED";
export const UPDATE_REQUEST = "LOCATION_SUPPLIERS/UPDATE_REQUEST";
export const UPDATE_SUCCESS = "LOCATION_SUPPLIERS/UPDATE_SUCCESS";

export const DELETE_FAILED = "LOCATION_SUPPLIERS/DELETE_FAILED";
export const DELETE_REQUEST = "LOCATION_SUPPLIERS/DELETE_REQUEST";
export const DELETE_SUCCESS = "LOCATION_SUPPLIERS/DELETE_SUCCESS";

export const CREATE_FAILED = "LOCATION_SUPPLIERS/CREATE_FAILED";
export const CREATE_REQUEST = "LOCATION_SUPPLIERS/CREATE_REQUEST";
export const CREATE_SUCCESS = "LOCATION_SUPPLIERS/CREATE_SUCCESS";

export const TABLE_UPDATE = "LOCATION_SUPPLIERS/TABLE_UPDATE";

const types = {
    GET_FAILED,
    GET_SUCCESS,
    GET_REQUEST,

    FETCH_FAILED,
    FETCH_SUCCESS,
    FETCH_REQUEST,

    UPDATE_FAILED,
    UPDATE_REQUEST,
    UPDATE_SUCCESS,

    DELETE_FAILED,
    DELETE_REQUEST,
    DELETE_SUCCESS,

    CREATE_FAILED,
    CREATE_REQUEST,
    CREATE_SUCCESS,

    TABLE_UPDATE,
};

export default types;
