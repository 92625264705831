export const FETCH_SUCCESS = "REVIEWS/FETCH_SUCCESS";

const types = {
    DESTROY_FAILED: "REVIEWS/DESTROY_FAILED",
    DESTROY_REQUEST: "REVIEWS/DESTROY_REQUEST",
    DESTROY_SUCCESS: "REVIEWS/DESTROY_SUCCESS",

    FETCH_FAILED: "REVIEWS/FETCH_FAILED",
    FETCH_REQUEST: "REVIEWS/FETCH_REQUEST",
    FETCH_SUCCESS,

    GET_FAILED: "REVIEWS/GET_FAILED",
    GET_REQUEST: "REVIEWS/GET_REQUEST",
    GET_SUCCESS: "REVIEWS/GET_SUCCESS",

    STORE_FAILED: "REVIEWS/STORE_FAILED",
    STORE_REQUEST: "REVIEWS/STORE_REQUEST",
    STORE_SUCCESS: "REVIEWS/STORE_SUCCESS",

    UPDATE_FAILED: "REVIEWS/UPDATE_FAILED",
    UPDATE_REQUEST: "REVIEWS/UPDATE_REQUEST",
    UPDATE_SUCCESS: "REVIEWS/UPDATE_SUCCESS",
};

export default types;
