import { registerModals } from "modals";
import { registerRoutings } from "rootRouting";
import store from "rootStore";
import { permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";
import PortionsAddModal from "./modals/PortionsAddModal";
import PortionsDeleteModal from "./modals/PortionsDeleteModal";
import PortionsModal from "./modals/PortionsModal";
import RestaurantProductConnectModal from "./modals/RestaurantProductConnectModal";
import RestaurantProductsHideModal from "./modals/RestaurantProductsHideModal";
import RestaurantProductsTable from "./reducers/RestaurantProductsTableReducer";
import RestaurantProductsSaga from "./saga";
import BulkCategoryModal from "./modals/BulkCategoryModal";
import BulkSizeUnitModal from "./modals/BulkSizeUnitModal";
import BulkShowUnitModal from "./modals/BulkShowUnitModal";

export const permissionKey = "restaurant-products";
export const permissionKeyMeta = "product.meta";
export const url = "/restaurant-products";

interface IConfig {
    permissions: {
        create: boolean;
        delete: boolean;
        read: boolean;
        update: boolean;
        metaUpdate: boolean;
        metaGlobalUpdate: boolean;
    };
    routing: string[];
    url: string;
}
const getConfig = (permissions: permissionsType) => {
    const modulePermissions = permissions.filter(
        (item) =>
            item.indexOf(permissionKey) === 0 ||
            item.indexOf(permissionKeyMeta) === 0
    );

    const config: IConfig = {
        permissions: {
            create: false,
            delete: false,
            read: false,
            update: false,
            metaUpdate: false,
            metaGlobalUpdate: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (modulePermissions.indexOf(permissionKey + ".create") !== -1) {
        config.permissions.create = true;

        routing.push({
            path: `${url}/create`,
            element: (
                <AsyncComponent path="RestaurantProducts/pages/RestaurantProductsCreatePage" />
            ),
        });
    }

    // if (modulePermissions.indexOf(permissionKey + ".delete") !== -1) {
    //     config.permissions.delete = true;
    // }

    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;

        store.reducerManager?.add(
            "RestaurantProductsTable",
            RestaurantProductsTable
        );
        store.injectSaga?.("RestaurantProductsSaga", RestaurantProductsSaga);

        routing.push({
            path: url,
            element: (
                <AsyncComponent path="RestaurantProducts/pages/RestaurantProductsIndexPage" />
            ),
        });
    }

    if (
        config.permissions.read &&
        modulePermissions.indexOf(permissionKey + ".update") === -1
    ) {
        routing.push({
            path: `${url}/:id`,
            element: (
                <AsyncComponent path="RestaurantProducts/pages/RestaurantProductsShowPage" />
            ),
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
        config.permissions.update = true;

        registerModals({
            BulkCategoryModal,
            BulkSizeUnitModal,
            BulkShowUnitModal,
            RestaurantProductsHideModal,
            RestaurantProductConnectModal,
            PortionsAddModal,
            PortionsDeleteModal,
            PortionsModal,
        });
        routing.push({
            path: `${url}/:id/edit`,
            element: (
                <AsyncComponent path="RestaurantProducts/pages/RestaurantProductsEditPage" />
            ),
        });
    }

    if (modulePermissions.indexOf(permissionKeyMeta + ".update") !== -1) {
        config.permissions.metaUpdate = true;
    }

    if (
        modulePermissions.indexOf(permissionKeyMeta + ".global.update") !== -1
    ) {
        config.permissions.metaGlobalUpdate = true;
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
