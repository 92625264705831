export const UPDATE_SETTINGS_SUCCESS = "SETTINGS/UPDATE_SUCCESS";

const types = {

    UPDATE_SETTINGS_FAILED: "SETTINGS/UPDATE_FAILED",
    UPDATE_SETTINGS_REQUEST: "SETTINGS/UPDATE_REQUEST",
    UPDATE_SETTINGS_SUCCESS: "SETTINGS/UPDATE_SUCCESS",
};

export default types;
