import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";

const requests = {
    store: (data: object, config: AxiosRequestConfig) =>
        api.post("settings/product-import/headers", data, config),
    update: (data: object, config: AxiosRequestConfig) =>
        api.put("settings/product-import", data, config),
};

export default requests;
