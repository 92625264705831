export const superAdmins = [
    "fbm@crma.no",
    "emanuele@gastroplanner.no",
    "pawel@gastroplanner.no",
    "karl.fredrik@gastroplanner.no",
    "frank@ditlaroche.no",
    "daria@crma.no",
    "augustas@engenrestaurant.no",
    "erlend@bjerck.no",
    "joachim@dognvillburger.no",
];
