import { registerModule } from "moduleConfig";
import serviceProviders from "serviceProviders";

export function loadModules(permissions, settings, activeLocation) {
    for (let serviceProviderName of Object.keys(serviceProviders)) {
        const serviceProvider = serviceProviders[serviceProviderName];
        const moduleConfig = serviceProvider(
            permissions,
            settings,
            activeLocation
        );

        if (
            !Object.keys(moduleConfig.permissions).reduce(
                (allowed, permission) => {
                    if (moduleConfig.permissions[permission]) {
                        return ++allowed;
                    }

                    return allowed;
                },
                0
            ) === 0
        ) {
            continue;
        }
        registerModule(serviceProviderName, moduleConfig);
    }
}

export function loadWebModules() {
    const serviceProvider = serviceProviders["WebMenu"];
    const moduleConfig = serviceProvider();
    registerModule("WebMenu", moduleConfig);
}
