import { MANAGE_TABLE } from "app/ManageTables/types";
import { FETCH_SUCCESS, TABLE_UPDATE } from "../types";
import { tableResponse, tableUpdate } from "helpers/reducers";
import { IGPTable } from "ui/Table/Table";

function DeliverySlipsTable(
    state: IGPTable = {
        id: "DeliverySlipsTable",
        columns: [],
        availableColumns: [],
        filters: {},
        filterData: [],
        filterSettings: {
            open: false,
            total: 0,
        },
        pagination: {
            isLastPage: true,
            page: 1,
            per_page: 20,
            total: 0,
        },
        search: "",
        selection: {
            id: "id",
            selected: [],
        },
        selectionActions: null,
        sort: { delivery_date: "desc" },
        with: [],
    },
    action: any
) {
    switch (action.type) {
        case FETCH_SUCCESS:
            if (action.response.config.params._table_id === state.id) {
                return tableResponse(state, action);
            }
            return state;

        case MANAGE_TABLE:
            if (action.data.id === state.id) {
                return {
                    ...state,
                    ...action.data,
                };
            }
            return state;

        case TABLE_UPDATE:
            return tableUpdate(state, action);

        default:
            return state;
    }
}

export default DeliverySlipsTable;
