const types = {
    SYNC_FAILED: "KDR/SYNC_FAILED",
    SYNC_REQUEST: "KDR/SYNC_REQUEST",
    SYNC_SUCCESS: "KDR/SYNC_SUCCESS",

    UPDATE_FAILED: "KDR/UPDATE_FAILED",
    UPDATE_REQUEST: "KDR/UPDATE_REQUEST",
    UPDATE_SUCCESS: "KDR/UPDATE_SUCCESS",
};

export default types;
