import { FC } from "react";
import useRequest from "api/useRequest";
import { modalHide } from "app/App/actions";
import { AxiosResponse } from "axios";
import { productVintage } from "../actions";
import { IProductsVintageListModal } from "../interfaces";
import trans from "helpers/trans";
import _ from "lodash";
import moment from "momentApp";
import { List, ListItemButton, ListItemText, Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import { Modal, ModalContent, ModalTitle } from "ui";

const ProductsVintageListModal: FC<IProductsVintageListModal> = ({
    onChange,
    product,
}) => {
    const dispatch = useDispatch();
    const { request: productVintageRequest } = useRequest();

    const listOfYears = () => {
        const currentYear = moment().year();
        const minYear = 1814;
        const r = _.range(currentYear, minYear);
        return r.map((e, id) => {
            return (
                <ListItemButton
                    key={id}
                    sx={{ width: "100%" }}
                    onClick={() => handleYearChoose(e)}
                >
                    <ListItemText
                        sx={{ cursor: "pointer", textAlign: "center" }}
                    >
                        {e}
                    </ListItemText>
                </ListItemButton>
            );
        });
    };

    const handleClose = () => {
        dispatch(modalHide("ProductsVintageListModal"));
    };

    const handleYearChoose = (year: number) => {
        productVintageRequest(
            productVintage(
                product.id,
                {
                    vintage: year,
                },
                (response: AxiosResponse) => {
                    if (response.status === 201 || response.status === 200) {
                        if (product.vintage !== year) {
                            onChange(response.data);
                        }
                        dispatch(modalHide("ProductsVintageListModal"));
                    }
                }
            )
        );
    };

    return (
        <>
            <Modal
                maxWidth="xs"
                fullWidth
                onClose={handleClose}
                open
                sx={{ zIndex: 1000, mt: "56px" }}
            >
                <ModalTitle onClose={handleClose} fontWeight={500}>
                    {trans("inv.products.modal.productVintageListModal.title")}
                </ModalTitle>
                <ModalContent
                    sx={{
                        px: 0,
                        py: 1,
                    }}
                >
                    <Stack alignItems="center">
                        <List sx={{ width: "100%" }}>{listOfYears()}</List>
                    </Stack>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ProductsVintageListModal;
