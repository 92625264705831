import api from "./api";
import types, {
    COUNT_STOP,
    PRODUCT_STORE_SUCCESS,
    PRODUCT_UPDATE_SUCCESS,
} from "./types";
import { request } from "api/apiSaga";
import { modalHide, modalShow, snackbarShow } from "app/App/actions";
import {
    call,
    put,
    race,
    take,
    takeEvery,
    takeLatest,
} from "redux-saga/effects";
import { productsFetch } from "app/Products/actions";
import {
    FETCH_FAILED,
    FETCH_SUCCESS,
    STORE_FAILED,
    STORE_SUCCESS,
} from "app/Products/types";
import trans from "helpers/trans";
import rootStore from "rootStore";
import { BROADCASTER_MESSAGE, CONFIRMATION_CONFIRMED, MODAL_HIDE } from "types";
import {
    inventoryCountProduct,
    inventoryGet,
    inventoryProductChange,
} from "./actions";
import { url } from "./config";
import { AnyAction } from "redux";
import { AxiosResponse } from "axios";

interface IRaceEffect {
    failed?: any;
    success?: any;
}

function* broadcasterMessage({ event, data }: AnyAction): any {
    const inventoryCount = rootStore.getState().InventoryCount;
    if (
        event === "inventory.updated" &&
        data.id === inventoryCount.inventory.id &&
        data.area_id === inventoryCount.areaId
    ) {
        yield call(countStart, {
            type: types.COUNT_START,
            inventoryId: inventoryCount.inventory.id,
            areaId: inventoryCount.areaId,
        });
    }
}

function* destroy(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.destroy,
        action,
        "delete"
    );

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("inv.inventory.saga.content.destroy"),
            })
        );
    }
}

function* fetch(action: AnyAction) {
    yield call(request, api.fetch, action);
}

function* finalize(action: AnyAction) {
    yield call(request, api.finalize, action, "put");
}

function* get(action: AnyAction) {
    yield call(request, api.get, action);
}

function* getOpen(action: AnyAction) {
    yield call(request, api.getOpen, action);
}

function* areas(action: AnyAction) {
    yield call(request, api.areas, action);
}
function* lastInventory(action: AnyAction) {
    yield call(request, api.lastInventory, action);
}
function* productDestroy(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.products.destroy,
        action,
        "delete"
    );

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("inv.inventory.saga.content.productDestroy"),
            })
        );
    }
}

function* productStore(action: AnyAction) {
    yield call(request, api.products.store, action, "post");
}

function* productChange(action: AnyAction) {
    yield call(request, api.products.change, action, "post");
}

function* productUpdate(action: AnyAction) {
    yield call(request, api.products.update, action, "put");
}

function* store(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.store,
        action,
        "post"
    );

    if (response.status === 201) {
        yield put(
            snackbarShow({
                content: trans("inv.inventory.saga.content.store"),
            })
        );
    }
}

function* update(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.update,
        action,
        "put"
    );

    if (response.status === 202) {
        yield put(
            snackbarShow({
                content: trans("inv.inventory.saga.content.update"),
            })
        );
    }
}

function* countStart({
    inventoryId,
    areaId,
}: {
    type: typeof types.COUNT_START;
    inventoryId: number;
    areaId: number;
}) {
    globalThis.broadcaster
        .get()
        .subscribe(`private-inventories.${inventoryId}`);
    yield put(
        inventoryGet(inventoryId, {
            params: {
                _with: [
                    "inventory_products",
                    "inventory_products.restaurant_product.product.category",
                    "inventory_products.restaurant_product.product.gtin",
                    "inventory_products.inventory_product_subquantities",
                ],
                _filters: { inventory_area_id_filter: areaId },
            },
        })
    );

    const raceResult: IRaceEffect = yield race({
        failed: take(types.GET_FAILED),
        success: take(types.GET_SUCCESS),
    });

    if (raceResult.failed) {
        window.location.href = url;
        return;
    }

    yield put({
        type: types.COUNT_INVENTORY_LOADED,
        inventory: raceResult.success.response.data.data,
    });
}

function* countReload() {
    const inventoryCount = rootStore.getState().InventoryCount;

    yield call(countStart, {
        type: types.COUNT_START,
        inventoryId: inventoryCount.inventory.id,
        areaId: inventoryCount.areaId,
    });
}

function* countProduct({
    addQuantity,
    gtin,
    productId,
}: {
    type: typeof types.COUNT_PRODUCT;
    addQuantity: boolean;
    gtin?: number;
    productId?: number;
}) {
    let params: { gtin?: number; product_id?: number } = { gtin };
    const locationType =
        rootStore.getState().app.locations[rootStore.getState().app.locationId]
            .type;

    if (!!productId) {
        params = { product_id: productId };
    }
    yield put(productsFetch({ params }));

    const raceResult: IRaceEffect = yield race({
        failed: take(FETCH_FAILED),
        success: take(FETCH_SUCCESS),
    });

    if (raceResult.failed) {
        if (raceResult.failed.response.status === 404) {
            if (locationType === "SATELLITE") {
                alert(trans("inv.inventory.saga.notFound"));
                return;
            }
            yield put(
                modalShow("ProductsCreateModal", {
                    gtin,
                })
            );

            const createProductRace: IRaceEffect = yield race({
                failed: take((action: AnyAction) => {
                    if (action.type === STORE_FAILED) {
                        return true;
                    }
                    if (
                        action.type === MODAL_HIDE &&
                        action.id === "ProductsCreateModal"
                    ) {
                        return true;
                    }
                    return false;
                }),
                success: take(STORE_SUCCESS),
            });

            if (createProductRace.success) {
                yield put(
                    inventoryCountProduct(
                        createProductRace.success.response.data.data.id,
                        undefined,
                        true
                    )
                );
            }
        }

        return;
    }

    if (raceResult.success.response.data.data.length === 1) {
        yield call(
            showCountProductModal,
            raceResult.success.response.data.data[0],
            gtin,
            addQuantity
        );
    } else {
        yield put(
            modalShow("InventoryChooseProductModal", {
                products: raceResult.success.response.data.data,
                gtin,
            })
        );
    }
}

function* countProductChangeVintage({
    inventoryProductId,
    isUpdate,
    product,
}: {
    type: typeof types.COUNT_PRODUCT_CHANGE_VINTAGE;
    inventoryProductId: number;
    isUpdate: boolean;
    product: any;
}) {
    if (!isUpdate) {
        yield put(inventoryCountProduct(product.id));
        return;
    }

    const counting = rootStore.getState().InventoryCount;
    yield put(
        inventoryProductChange(
            counting.inventory.id,
            inventoryProductId,
            product.restaurant_product?.id
        )
    );

    const createProductRace: IRaceEffect = yield race({
        failed: take(types.PRODUCT_CHANGE_FAILED),
        success: take(types.PRODUCT_CHANGE_SUCCESS),
    });

    if (createProductRace.failed) {
        alert("Can not update vintage");
        return;
    }

    yield put(
        snackbarShow({
            content: trans("inv.inventory.saga.content.updateVintage"),
        })
    );

    yield call(countReload);
}

function* showCountProductModal(product: any, gtin: any, addQuantity: any) {
    const showAlert =
        rootStore.getState().app.settings.location_settings.show_counting_alert;
    const counting = rootStore.getState().InventoryCount;
    const searchValue = counting.searchValue;

    const inventoryProduct = counting.inventory.products.find(
        (inventoryProduct: any) =>
            inventoryProduct.restaurant_product?.id ===
            product.restaurant_product?.id
    );

    if (!!inventoryProduct && !addQuantity) {
        yield put(
            modalShow("InventoryCountModal", {
                addQuantity,
                areaId: counting.areaId,
                gtin,
                inventoryId: counting.inventory.id,
                inventoryProductId: inventoryProduct.id,
                product,
                quantity: inventoryProduct.quantity,
                subquantities: inventoryProduct.inventory_product_subquantities,
            })
        );
    } else {
        if (inventoryProduct && showAlert) {
            yield put(
                modalShow("ConfirmationModal", {
                    agreeButton: trans("global.button.next"),
                    disagreeButton: trans("global.button.cancel"),
                    message: trans("inv.inventory.saga.confirmScanned", {
                        name: inventoryProduct.name,
                        quantity: inventoryProduct.quantity,
                    }),
                })
            );
            const confirmed: { type: string } = yield take(
                CONFIRMATION_CONFIRMED
            );
            if (confirmed) {
                yield put(
                    modalShow("InventoryCountModal", {
                        addQuantity,
                        areaId: counting.areaId,
                        gtin,
                        inventoryId: counting.inventory.id,
                        product,
                        quantity: 1,
                    })
                );
            }
        } else {
            yield put(
                modalShow("InventoryCountModal", {
                    addQuantity,
                    areaId: counting.areaId,
                    gtin,
                    inventoryId: counting.inventory.id,
                    product,
                    quantity: 1,
                })
            );
        }
    }

    const raceResult: IRaceEffect = yield race({
        failed: take((action: AnyAction) => {
            if (action.type === COUNT_STOP) {
                return true;
            }

            if (
                action.type === MODAL_HIDE &&
                action.id === "InventoryCountModal"
            ) {
                return true;
            }

            return false;
        }),
        success: take([PRODUCT_UPDATE_SUCCESS, PRODUCT_STORE_SUCCESS]),
    });

    if (!!raceResult.failed) {
        if (raceResult.failed.type === COUNT_STOP) {
            yield put(modalHide("InventoryCountModal"));
        }
        if (
            raceResult.failed.type === MODAL_HIDE &&
            searchValue &&
            raceResult.failed.props?.showSearch
        ) {
            yield put(
                modalShow("InventorySearchProductModal", {
                    areaId: counting.areaId,
                })
            );
        }
    }

    if (!!raceResult.success) {
        yield call(countReload);
        if (!gtin) {
            yield put(
                modalShow("InventorySearchProductModal", {
                    areaId: counting.areaId,
                })
            );
        }
    }
}

function* fetchSuggestedProducts(action: AnyAction) {
    yield call(request, api.suggestedProductsFetch, action);
}

export default function* saga() {
    yield takeLatest(types.COUNT_PRODUCT, countProduct);
    yield takeLatest(
        types.COUNT_PRODUCT_CHANGE_VINTAGE,
        countProductChangeVintage
    );
    yield takeLatest(types.COUNT_RELOAD, countReload);
    yield takeLatest(types.COUNT_START, countStart);
    yield takeLatest(
        types.FETCH_SUGGESTED_PRODUCTS_REQUEST,
        fetchSuggestedProducts
    );
    yield takeLatest(types.DESTROY_REQUEST, destroy);
    yield takeLatest(types.FETCH_REQUEST, fetch);
    yield takeLatest(types.FINALIZE_REQUEST, finalize);
    yield takeLatest(types.GET_REQUEST, get);
    yield takeLatest(types.GET_OPEN_REQUEST, getOpen);
    yield takeLatest(types.PRODUCT_CHANGE_REQUEST, productChange);
    yield takeLatest(types.PRODUCT_DESTROY_REQUEST, productDestroy);
    yield takeLatest(types.PRODUCT_STORE_REQUEST, productStore);
    yield takeLatest(types.PRODUCT_UPDATE_REQUEST, productUpdate);
    yield takeLatest(types.STORE_REQUEST, store);
    yield takeLatest(types.UPDATE_REQUEST, update);
    yield takeLatest(types.FETCH_AREAS_REQUEST, areas);
    yield takeLatest(types.GET_LAST_INVENTORY_REQUEST, lastInventory);
    yield takeEvery(BROADCASTER_MESSAGE, broadcasterMessage);
}
