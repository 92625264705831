import { Box, Icon, IconButton } from "@mui/material";
import trans from "helpers/trans";
import { get } from "lodash";
import { FC } from "react";
import { Table, TextField } from "ui";

export interface IPosProductsConnectReplacementTable {
    context: any;
    id: string;
}

const PosProductsConnectReplacementTable: FC<
    IPosProductsConnectReplacementTable
> = ({ context, id }) => {
    const handleDeleteRow = (itemIdx: number) => {
        context.onChange([
            {
                id,
                value: get(context.data, id, []).filter(
                    (item: any, idx: number) => idx !== itemIdx
                ),
            },
        ]);
    };

    const renderSearch = (idx: number) => (
        <Box sx={{ width: "100%", minWidth: "150px", display: "flex" }}>
            <TextField
                id={`${id}.${idx}.search`}
                variant="outlined"
                fullWidth
                size="small"
            />
        </Box>
    );

    const renderReplace = (idx: number) => (
        <Box sx={{ width: "100%", minWidth: "150px", display: "flex" }}>
            <TextField
                id={`${id}.${idx}.replace`}
                variant="outlined"
                fullWidth
                size="small"
            />
        </Box>
    );

    return (
        <Table
            calculateHeight={false}
            id="PosProductsConnectReplacementTable"
            calculateWidth={true}
            columns={[
                {
                    field: "search",
                    headName: trans(
                        "inv.posProducts.table.PosProductsConnectReplacementTable.label.search"
                    ),
                    cellProps: {
                        size: "small",
                        sx: { whiteSpace: "nowrap" },
                    },
                    renderCell: (item, column, idx) => ({
                        children: renderSearch(idx),
                    }),
                },
                {
                    field: "replace",
                    headName: trans(
                        "inv.posProducts.table.PosProductsConnectReplacementTable.label.replace"
                    ),
                    cellProps: {
                        size: "small",
                        sx: { whiteSpace: "nowrap" },
                    },
                    renderCell: (item, column, idx) => ({
                        children: renderReplace(idx),
                    }),
                },
                {
                    field: "actions",
                    renderCell: (item, column, idx) => {
                        return {
                            children: (
                                <IconButton
                                    size="small"
                                    onClick={() => handleDeleteRow(idx)}
                                >
                                    <Icon>delete</Icon>
                                </IconButton>
                            ),
                        };
                    },
                },
            ]}
            rows={get(context.data, id, [])}
        />
    );
};

export default PosProductsConnectReplacementTable;
