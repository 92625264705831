import { Box } from "@mui/material";
import useRequest from "api/useRequest";
import { modalHide } from "app/App/actions";
import trans from "helpers/trans";
import { FC, useRef } from "react";
import { useDispatch } from "react-redux";
import {
    Button,
    DatePickerInput,
    Form,
    LoadingButton,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
} from "ui";
import { orderPost } from "../actions";
import moment from "moment";
import dayjs from "dayjs";

interface IOrderDeliveryDateModal {
    deliveryDate: Date;
    data: any;
    id: string;
    reload: Function;
    onFail: (message: string, errors: any, status: number) => void;
    onSuccess: (message: string, errors: any, status: number) => void;
}
const OrderDeliveryDateModal: FC<IOrderDeliveryDateModal> = ({
    deliveryDate,
    data,
    id,
    reload,
    onFail,
    onSuccess,
}) => {
    const dispatch = useDispatch();
    const formRef = useRef<any>(null);
    const { request, errors, isLoading } = useRequest();

    const handleCloseModal = () => {
        dispatch(modalHide("OrderDeliveryDateModal"));
    };
    const handleSave = () => {
        if (formRef.current) {
            const newLines = data.order_lines.filter((line: any) => line.id);
            request(
                orderPost(
                    +id,
                    {
                        ...data,
                        delivery_date: formRef.current.getData().delivery_date,
                        order_lines: newLines,
                    },
                    (response: any) => {
                        if (response.status === 204) {
                            onSuccess("", {}, 204);
                        } else {
                            onFail(
                                response.data.message,
                                response.data.errors,
                                response.status
                            );
                        }
                    }
                )
            );
        }
    };

    return (
        <Modal open fullWidth maxWidth="xs">
            <ModalTitle onClose={handleCloseModal}>
                {trans("inv.orders.modal.orderDeliveryDateModal.title")}
            </ModalTitle>
            <ModalContent>
                <Form
                    ref={formRef}
                    data={{}}
                    fields={{
                        delivery_date: {
                            default: deliveryDate,
                        },
                    }}
                    errors={errors}
                    onSubmit={() => {}}
                    unsaved={false}
                >
                    <Box>
                        <DatePickerInput
                            minDate={dayjs(moment().format("YYYY-MM-DD"))}
                            id="delivery_date"
                            fullWidth
                            format="DD/MM/YYYY"
                            label={trans(
                                "inv.orders.modal.orderDeliveryDateModal.deliveryDate"
                            )}
                        />
                    </Box>
                </Form>
            </ModalContent>
            <ModalActions>
                <Box display="flex" width="100%" textAlign="right">
                    <Button
                        onClick={handleCloseModal}
                        color="inherit"
                        variant="text"
                        sx={{ mr: 1 }}
                    >
                        {trans("global.button.cancel")}
                    </Button>
                    <LoadingButton
                        sx={{ flex: 1 }}
                        onClick={handleSave}
                        loading={isLoading}
                    >
                        {trans("inv.orders.component.orderStatus.post")}
                    </LoadingButton>
                </Box>
            </ModalActions>
        </Modal>
    );
};
export default OrderDeliveryDateModal;
