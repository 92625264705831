export type DeliverySlipsModel = {
    id?: number;
    type?: string;
    customer_name?: string;
    customer_number?: number;
    delivery_date?: string;
};

export const FETCH_SUCCESS = "DELIVERY_SLIP/FETCH_SUCCESS";
export const ORDER_DELIVERY_SUCCESS = "DELIVERY_SLIP/ORDER_DELIVERY_SUCCESS";
export const TABLE_UPDATE = "DELIVERY_SLIP/TABLE_UPDATE";
export const TABLE_APPROVE_UPDATE = "DELIVERY_SLIP/TABLE_APPROVE_UPDATE";
export const TABLE_DETAILS_UPDATE = "DELIVERY_SLIP/TABLE_DETAILS_UPDATE";

const types = {
    APPROVE_FAILED: "DELIVERY_SLIP/APPROVE_FAILED",
    APPROVE_REQUEST: "DELIVERY_SLIP/APPROVE_REQUEST",
    APPROVE_SUCCESS: "DELIVERY_SLIP/APPROVE_SUCCESS",

    FETCH_STATS_SUCCESS: "DELIVERY_SLIP/FETCH_STATS_SUCCESS",
    FETCH_STATS_REQUEST: "DELIVERY_SLIP/FETCH_STATS_REQUEST",
    FETCH_STATS_FAILED: "DELIVERY_SLIP/FETCH_STATS_FAILED",

    UPDATE_FAILED: "DELIVERY_SLIP/UPDATE_FAILED",
    UPDATE_REQUEST: "DELIVERY_SLIP/UPDATE_REQUEST",
    UPDATE_SUCCESS: "DELIVERY_SLIP/UPDATE_SUCCESS",

    FETCH_FAILED: "DELIVERY_SLIP/FETCH_FAILED",
    FETCH_REQUEST: "DELIVERY_SLIP/FETCH_REQUEST",
    FETCH_SUCCESS,

    ORDER_DELIVERY_FAILED:"DELIVERY_SLIP/ORDER_DELIVERY_FAILED",
    ORDER_DELIVERY_REQUEST: "DELIVERY_SLIP/ORDER_DELIVERY_REQUEST",
    ORDER_DELIVERY_SUCCESS,
    
    GET_FAILED: "DELIVERY_SLIP/GET_FAILED",
    GET_REQUEST: "DELIVERY_SLIP/GET_REQUEST",
    GET_SUCCESS: "DELIVERY_SLIP/GET_SUCCESS",

    GET_LINES_FAILED: "DELIVERY_SLIP/GET_LINES_FAILED",
    GET_LINES_REQUEST: "DELIVERY_SLIP/GET_LINES_REQUEST",
    GET_LINES_SUCCESS: "DELIVERY_SLIP/GET_LINES_SUCCESS",

    APPROVE_ALL_FAILED: "DELIVERY_SLIPS/APPROVE_ALL_FAILED",
    APPROVE_ALL_REQUEST: "DELIVERY_SLIPS/APPROVE_ALL_REQUEST",
    APPROVE_ALL_SUCCESS: "DELIVERY_SLIPS/APPROVE_ALL_SUCCESS",

    STORE_FAILED: "DELIVERY_SLIPS/STORE_FAILED",
    STORE_REQUEST: "DELIVERY_SLIPS/STORE_REQUEST",
    STORE_SUCCESS: "DELIVERY_SLIPS/STORE_SUCCESS",

    TABLE_UPDATE,
    TABLE_APPROVE_UPDATE,
    TABLE_DETAILS_UPDATE,
};

export default types;
