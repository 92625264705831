export const FETCH_SUCCESS = "SETTINGS_PORTIONS/FETCH_SUCCESS";
export const TABLE_UPDATE = "SETTINGS_PORTIONS/TABLE_UPDATE";

const types = {
    FETCH_SUCCESS,
    FETCH_FAILED: "SETTINGS_PORTIONS/FETCH_FAILED",
    FETCH_REQUEST: "SETTINGS_PORTIONS/FETCH_REQUEST",

    UPDATE_FAILED: "SETTINGS_PORTIONS/UPDATE_FAILED",
    UPDATE_REQUEST: "SETTINGS_PORTIONS/UPDATE_REQUEST",
    UPDATE_SUCCESS: "SETTINGS_PORTIONS/UPDATE_SUCCESS",

    CREATE_REQUEST: "SETTINGS_PORTIONS/CREATE_REQUEST",
    CREATE_SUCCESS: "SETTINGS_PORTIONS/CREATE_SUCCESS",
    CREATE_FAILED: "SETTINGS_PORTIONS/CREATE_FAILED",

    DELETE_REQUEST: "SETTINGS_PORTIONS/DELETE_REQUEST",
    DELETE_SUCCESS: "SETTINGS_PORTIONS/DELETE_SUCCESS",
    DELETE_FAILED: "SETTINGS_PORTIONS/DELETE_FAILED",

    GET_FAILED: "SETTINGS_PORTIONS/GET_FAILED",
    GET_REQUEST: "SETTINGS_PORTIONS/GET_REQUEST",
    GET_SUCCESS: "SETTINGS_PORTIONS/GET_SUCCESS",

    TABLE_UPDATE,
};

export default types;
