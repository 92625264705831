import { modalHide } from "app/App/actions";
import { IConfirmationModal } from "app/App/interfaces";
import trans from "helpers/trans";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalActions, ModalContent, ModalTitle } from "ui";

const DeliverySlipApproveDateModal: FC<IConfirmationModal> = ({
    message = trans(
        "inv.deliverySlips.modals.deliverySlipApproveDateModal.message"
    ),
    onClickAgree,
    onClickDisagree,
}) => {
    const dispatch = useDispatch();

    const handleClickAgree = () => {
        if (!!onClickAgree) {
            onClickAgree();
        }

        dispatch(modalHide("DeliverySlipApproveDateModal"));
    };

    const handleClickDisagree = () => {
        if (!!onClickDisagree) {
            onClickDisagree();
        }

        dispatch(modalHide("DeliverySlipApproveDateModal"));
    };

    const handleCloseModal = () =>
        dispatch(modalHide("DeliverySlipApproveDateModal"));

    return (
        <Modal maxWidth="sm" fullWidth open>
            <ModalTitle onClose={handleCloseModal}>
                {trans(
                    "inv.deliverySlips.modals.deliverySlipApproveDateModal.title"
                )}
            </ModalTitle>
            <ModalContent>{message}</ModalContent>
            <ModalActions>
                <Button
                    onClick={handleClickAgree}
                    variant="contained"
                    color="primary"
                    sx={{ fontWeight: 500 }}
                >
                    {trans(
                        "inv.deliverySlips.modals.deliverySlipApproveDateModal.agree"
                    )}
                </Button>
                <Button
                    onClick={handleClickDisagree}
                    variant="contained"
                    color="warning"
                    sx={{ fontWeight: 500 }}
                >
                    {trans(
                        "inv.deliverySlips.modals.deliverySlipApproveDateModal.disagree"
                    )}
                </Button>
            </ModalActions>
        </Modal>
    );
};

export default DeliverySlipApproveDateModal;
