import { request } from "api/apiSaga";
import { snackbarShow } from "app/App/actions";
import { AxiosResponse } from "axios";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import api from "./api";
import types from "./types";

function* autoConnectProducts(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.autoConnectProducts,
        action,
        "post"
    );

    if (response.status < 300) {
        yield put(
            snackbarShow({
                content: `Connected ${response.data.data.connected} products`,
            })
        );
    }
}

function* connectProducts(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.connectProducts,
        action,
        "post"
    );

    if (response.status < 300) {
        yield put(
            snackbarShow({
                content: `Connected`,
            })
        );
    }
}

function* posProductsFetch(action: AnyAction) {
    yield call(request, api.fetchPosProducts, action);
}

function* posProductCategoriesFetch(action: AnyAction) {
    yield call(request, api.fetchPosProductCategories, action);
}

export default function* saga() {
    yield takeLatest(types.AUTO_CONNECT_PRODUCTS_REQUEST, autoConnectProducts);
    yield takeLatest(types.POS_PRODUCTS_FETCH_REQUEST, posProductsFetch);
    yield takeLatest(types.CONNECT_PRODUCTS_REQUEST, connectProducts);
    yield takeLatest(
        types.POS_PRODUCT_CATEGORIES_FETCH_REQUEST,
        posProductCategoriesFetch
    );
}
