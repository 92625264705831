import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlId from "types/RequestUrlId";

type InventoryProductsStore = {
    inventoryId: number;
};
type InventoryProductsUpdate = {
    inventoryId: number;
    inventoryProductId: number;
};

type InventoryProductsChange = {
    inventoryId: number;
    inventoryProductId: number;
    productId: number;
};

const requests = {
    destroy: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.delete(`/inventory/${url.id}`, config),
    fetch: (config: AxiosRequestConfig) => api.get(`/inventory`, config),
    finalize: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.put(`/inventory/${url.id}/finalize`, data, config),
    get: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/inventory/${url.id}`, config),
    lastInventory: (config: AxiosRequestConfig) =>
        api.get(`/inventory/last-finished`, config),
    getOpen: (config: AxiosRequestConfig) => api.get("/inventory/open", config),
    products: {
        change: (
            data: object,
            config: AxiosRequestConfig,
            url: InventoryProductsChange
        ) =>
            api.post(
                `/inventory/${url.inventoryId}/products/${url.inventoryProductId}/change/${url.productId}`,
                data,
                config
            ),

        destroy: (config: AxiosRequestConfig, url: InventoryProductsUpdate) =>
            api.delete(
                `/inventory/${url.inventoryId}/products/${url.inventoryProductId}`,
                config
            ),
        store: (
            data: object,
            config: AxiosRequestConfig,
            url: InventoryProductsStore
        ) => api.post(`/inventory/${url.inventoryId}/products`, data, config),

        update: (
            data: object,
            config: AxiosRequestConfig,
            url: InventoryProductsUpdate
        ) =>
            api.put(
                `/inventory/${url.inventoryId}/products/${url.inventoryProductId}`,
                data,
                config
            ),
    },
    store: (data: object, config: AxiosRequestConfig) =>
        api.post(`/inventory`, data, config),
    update: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.put(`/inventory/${url.id}`, data, config),
    areas: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/inventory/${url.id}/areas`, config),
    suggestedProductsFetch: (config: AxiosRequestConfig) =>
        api.get(`/inventory/suggest-products`, config),
};

export default requests;
