import { FETCH_SUCCESS, TABLE_UPDATE } from "../types";
import { tableResponse, tableUpdate } from "helpers/reducers";
import trans from "helpers/trans";
import { IGPTable } from "ui/Table/Table";
import { AnyAction } from "redux";

function AreasTableReducer(
    state: IGPTable = {
        id: "AreasTable",
        columns: [
            {
                field: "name",
                headName: trans("inv.areas.reducer.headName.name"),
                sortable: true,
            },
            {
                field: "actions",
            },
        ],
        pagination: {
            isLastPage: true,
            page: 1,
            per_page: 20,
            total: 0,
        },
        search: "",
        sort: { name: "asc" },
    },
    action: AnyAction
) {
    switch (action.type) {
        case FETCH_SUCCESS:
            if (action.response.config.params._table_id === state.id) {
                return tableResponse(state, action);
            }
            return state;
        case TABLE_UPDATE:
            return tableUpdate(state, action);

        default:
            return state;
    }
}

export default AreasTableReducer;
