import { Chip } from "@mui/material";
import { FC } from "react";
import { TInventoryProductSubquantity } from "../interfaces";
import { QTY_PRECISION } from "./Counter";
import { unitSymbol } from "helpers/units";

type TCounterSubquantity = {
    active: boolean;
    item: TInventoryProductSubquantity;
    onClick: Function;
    onDelete: Function;
};

const CounterSubquantity: FC<TCounterSubquantity> = ({
    active,
    item,
    onClick,
    onDelete,
}) => {
    const itemLabel =
        item.subquantity % 1 === 0
            ? +item.subquantity
            : (+item.subquantity).toFixed(QTY_PRECISION);

    //@ts-ignore
    const unit = item.unit !== "UNIT" ? unitSymbol[item.unit] : "";

    const handleClick = () => {
        if (!!onClick) {
            onClick(item.id);
        }
    };

    const handleDelete = () => {
        if (!!onDelete) {
            onDelete(item.id);
        }
    };

    return (
        <Chip
            color="primary"
            variant={active ? "filled" : "outlined"}
            key={`sub-quantity-${item.id}`}
            label={`${itemLabel} ${unit}`}
            onClick={handleClick}
            onDelete={handleDelete}
            sx={{
                minWidth: "40px",
            }}
        />
    );
};

export default CounterSubquantity;
