import api from "./api";
import types from "./types";
import { request } from "api/apiSaga";
import { call, put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";
import { AxiosResponse } from "axios";
import { snackbarShow } from "app/App/actions";
import trans from "helpers/trans";

function* approve(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.approve,
        action,
        "put"
    );

    if (response.status === 200) {
        yield put(
            snackbarShow({
                content: trans("inv.deliverySlips.saga.content.update"),
            })
        );
    }
}

function* approveAll(action: AnyAction): any {
    const response = yield call(request, api.approveAll, action);
    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("inv.deliverySlips.saga.content.approveAll"),
            })
        );
    }
}

function* fetchStats(action: AnyAction) {
    yield call(request, api.fetchStats, action);
}

function* fetch(action: AnyAction) {
    yield call(request, api.fetch, action);
}

function* orderDeliverySlips(action: AnyAction) {
    yield call(request, api.orderDeliverySlips, action);
}

function* get(action: AnyAction) {
    yield call(request, api.get, action);
}

function* getLines(action: AnyAction) {
    yield call(request, api.getLines, action);
}

function* update(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.update,
        action,
        "put"
    );

    if (response.status === 200) {
        yield put(
            snackbarShow({
                content: trans("inv.deliverySlips.saga.content.update"),
            })
        );
    }
}

function* store(action: AnyAction): any {
    const response = yield call(request, api.store, action, "post");

    if (response.status === 201) {
        yield put(
            snackbarShow({
                content: trans("inv.deliverySlips.saga.content.store"),
            })
        );
    }
}

export default function* saga() {
    yield takeLatest(types.APPROVE_REQUEST, approve);
    yield takeLatest(types.FETCH_REQUEST, fetch);
    yield takeLatest(types.ORDER_DELIVERY_REQUEST, orderDeliverySlips);
    yield takeLatest(types.FETCH_STATS_REQUEST, fetchStats);
    yield takeLatest(types.GET_REQUEST, get);
    yield takeLatest(types.GET_LINES_REQUEST, getLines);
    yield takeLatest(types.UPDATE_REQUEST, update);
    yield takeLatest(types.APPROVE_ALL_REQUEST, approveAll);
    yield takeLatest(types.STORE_REQUEST, store);
}
