import Pusher from "pusher-js";

class Broadcaster {
    broadcaster: Pusher;

    constructor(appKey: string, testMode = false) {
        if (testMode) {
            Pusher.logToConsole = true;
        }

        this.broadcaster = new Pusher(appKey, {
            cluster: "eu",
            authEndpoint: `${process.env.REACT_APP_DOMAIN_BASE}/broadcasting/auth`
        });
    }

    // connect(): Pusher {
    //     this.broadcaster = new Pusher(window.pusherAppKey, {
    //         cluster: "eu",
    //     });
    //     return this.broadcaster;
    // }

    disconnect() {
        if (this.broadcaster !== undefined) {
            this.broadcaster.disconnect();
        }
    }

    get(): Pusher {
        // if (!this.broadcaster) {
        //     return this.connect();
        // }

        return this.broadcaster;
    }
}

// const broadcaster = new Broadcaster();
export default Broadcaster;
