import { inventoryCountProduct } from "../actions";
import SearchProductListItem from "../components/SearchProductListItem";
import { IInventoryChooseProductModal } from "../interfaces";

import { List } from "@mui/material";
import { modalHide } from "app/App/actions";
import trans from "helpers/trans";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalTitle, ModalContent } from "ui";

const InventoryChooseProductModal: FC<IInventoryChooseProductModal> = ({
    gtin,
    products,
}) => {
    const dispatch = useDispatch();

    const handleOnClose = () => {
        dispatch(modalHide("InventoryChooseProductModal"));
    };

    const handleChooseProduct = (product: any) => {
        dispatch(inventoryCountProduct(product.id, gtin, true));
        dispatch(modalHide("InventoryChooseProductModal"));
    };

    const renderItem = (product: any) => {
        return (
            <SearchProductListItem
                key={`choose-product-${product.id}`}
                product={product}
                onClick={handleChooseProduct}
            />
        );
    };

    return (
        <Modal
            maxWidth="xs"
            fullWidth
            open
            PaperProps={{ sx: { position: "fixed", top: 0 } }}
        >
            <ModalTitle onClose={handleOnClose}>
                {trans("inv.inventory.modal.inventoryChooseProductModal.title")}
            </ModalTitle>
            <ModalContent sx={{ py: 0, position: "relative" }}>
                <List>{products.map((item: any) => renderItem(item))}</List>
            </ModalContent>
        </Modal>
    );
};

export default InventoryChooseProductModal;
