import { call, cancelled, put } from "redux-saga/effects";
import pathToAuth from "helpers/pathToAuth";
import trans from "helpers/trans";

export let requests = {};

export function handleError(error) {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        if (error.response.status === 401) {
            window.location.href = pathToAuth();
            return error.response;
        }

        if (error.response.status === 403) {
            return {
                ...error.response,
                data: {
                    ...error.response?.data,
                    message: trans("global.apiSaga.message403"),
                },
            };
        }

        if (error.response.status === 426) {
            const reloadedApp = localStorage.getItem("reloadedApp");
            if (reloadedApp === null) {
                localStorage.setItem("reloadedApp", "true");
                window.location.reload();
            } else {
                alert(trans("global.apiSaga.message426"));
            }

            return error.response;
        }

        return error.response;
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        return {
            status: 500,
            statusText: "Server error",
            headers: {},
            config: error.config,
            request: error.request,
        };
    } else {
        // Something happened in setting up the request that triggered an Error
        if (error.code === "ERR_CANCELED") {
            return {
                status: 500,
                statusText: "ERR_CANCELED",
                headers: {},
                config: {},
                request: {},
            };
        } else {
            return {
                data: {
                    message: error.message,
                },
                status: 500,
                statusText: "App error",
                headers: {},
                config: error.config,
                request: error.request,
            };
        }
    }
}

export function* request(endpoint, action, type = "get") {
    const cancelSource = new AbortController(); //axios.CancelToken.source();

    if (!!action.uid) {
        requests[action.uid] = cancelSource;
    }

    let config = action.axiosConfig?.config;
    if (config === undefined) {
        config = {};
    }

    let data = action.axiosConfig?.data;
    if (data === undefined) {
        data = {};
    }

    let url = action.axiosConfig?.url;
    if (url === undefined) {
        url = {};
    }

    const { meta, ...query } = action.query;
    try {
        let response = null;

        if (type === "get" || type === "delete") {
            response = yield call(
                endpoint,
                {
                    ...config,
                    signal: cancelSource.signal,
                    // cancelToken: cancelSource.token,
                },
                url
            );
        } else if (type === "post" || type === "put" || type === "patch") {
            response = yield call(
                endpoint,
                data,
                {
                    ...config,
                    signal: cancelSource.signal,
                    // cancelToken: cancelSource.token,
                },
                url
            );
        }

        if (action.modifyResponse) {
            response = action.modifyResponse(response);
        }

        yield put({ type: action.typeSuccess, query, response });
        if (!!action.onResponse) {
            action.onResponse(response);
        }

        delete requests[action.uid];
        return response;
    } catch (err) {
        const error = yield call(handleError, err);

        yield put({ type: action.typeFailed, response: error, query });
        if (!!action.onResponse) {
            action.onResponse(error);
        }

        delete requests[action.uid];
        return error;
    } finally {
        if (yield cancelled()) {
            delete requests[action.uid];
            cancelSource.abort();
            // cancelSource.cancel();
        }
    }
}
