import {
    Grid,
    Icon,
    IconButton,
    TableCell,
    TableRow,
    TableRowProps,
    Typography,
} from "@mui/material";
import { deliverySlipsGetLines } from "../actions";
import useRequest from "api/useRequest";
import { modalHide } from "app/App/actions";
import trans from "helpers/trans";
import { createElement, FC, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Loader,
    Modal,
    ModalContent,
    ModalTitle,
    RequestMessage,
    Table,
} from "ui";
import { IGPTableColumn } from "ui/Table/Table";
import { green, orange, red } from "@mui/material/colors";
import momentApp from "momentApp";
import { IDeliverySlipModal, IApproveStatusChanges } from "../interfaces";
import { manageTableGet } from "app/ManageTables/actions";

const DeliverySlipDetailsLoadColumns: FC<any> = ({ item, lines }) => {
    const dispatch = useDispatch();
    const tableId = item.order_id
        ? "OrderTable"
        : `${item.type.toCamelCase().capitalize()}Table`;
    const columns = useSelector(
        (state: any) => state.DeliverySlipLines[tableId]?.columns
    );

    useEffect(() => {
        if (!columns || columns.length === 0)
            dispatch(manageTableGet(`DeliverySlipLines.${tableId}`));
    }, [columns, dispatch, tableId]);

    if (!columns || columns.length === 0) {
        return <Loader />;
    }

    return (
        <DeliverySlipModal
            type={tableId}
            item={item}
            id={item.id}
            lines={lines}
        />
    );
};

const DeliverySlipModal: FC<IDeliverySlipModal> = ({
    id,
    item,
    lines,
    type,
}) => {
    const dispatch = useDispatch();
    const table = useSelector((state: any) => state.DeliverySlipLines[type]);
    const { types } = useSelector((state: any) => ({
        types: state.app.settings.deliverySources,
    }));
    const { currentData, isPending, isError, status, request } = useRequest();

    useEffect(() => {
        if (!lines) {
            request(
                deliverySlipsGetLines(id, {
                    params: {
                        _with: table?.with,
                    },
                })
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const data: any = useMemo(() => {
        if (lines) {
            return lines
        } 
        if (!currentData) {
            return [];
        }
        return currentData.data;
    }, [currentData, lines]);

    const prepareColumns = (columns: any) => {
        if (!columns) return [];

        return columns.map((column: IGPTableColumn) => {
            if (
                column.field === "total_netto" ||
                column.field === "unit_netto" ||
                column.field === "base_price"
            ) {
                column.renderCell = (item: any) => {
                    if (item[column.field] !== null) {
                        return {
                            children: item[column.field].price(),
                            sx: { whiteSpace: "nowrap" },
                        };
                    }
                };
            } else if (column.field === "restaurant_product.price") {
                column.renderCell = (item: any) => {
                    if (item.restaurant_product?.price) {
                        return {
                            children: `~${item.restaurant_product?.price.price()}`,
                            sx: { whiteSpace: "nowrap", color: orange[800] },
                        };
                    }
                };
            } else if (column.field === "approve_note") {
                column.renderCell = (item: any) => {
                    return {
                        children: item[column.field],
                        sx: { borderBottom: "none" },
                    };
                };
            } else if (column.field === "approve_status") {
                column.renderCell = (item: any) => {
                    if (item[column.field] !== null) {
                        return {
                            children: item.approve_status ? (
                                <Icon
                                    sx={{
                                        color: green[400],
                                    }}
                                >
                                    checkcircleoutline
                                </Icon>
                            ) : (
                                <Icon
                                    sx={{
                                        color: red[400],
                                    }}
                                >
                                    close_icon
                                </Icon>
                            ),
                            sx: { py: 0 },
                        };
                    }
                };
            }
            return column;
        });
    };
    const handleCloseModal = () => dispatch(modalHide("DeliverySlipModal"));

    const renderDownload = () => {
        if (!item.storage) {
            return null;
        }

        return (
            <Typography>
                {trans(
                    "inv.deliverySlips.modals.deliverySlipModal.text.download"
                )}
                <IconButton
                    size="small"
                    key={`delivery-slip-to-${id}`}
                    id={data.id}
                    href={item.storage.link}
                    target="_blank"
                >
                    <Icon>download</Icon>
                </IconButton>
            </Typography>
        );
    };

    const renderApproveDeliverySlip = () => {
        if (item.approve_status === null) {
            return null;
        }

        return (
            <table>
                <tbody>
                    <tr>
                        <td>
                            {trans(
                                "inv.deliverySlips.modals.deliverySlipModal.text.status"
                            )}
                        </td>
                        <td style={{ position: "relative" }}>
                            {item.approve_status ? (
                                <Icon
                                    sx={{
                                        color: green[400],
                                    }}
                                >
                                    checkcircleoutline
                                </Icon>
                            ) : (
                                <Icon
                                    fontSize="small"
                                    sx={{
                                        color: red[400],
                                        left: 0,
                                        position: "absolute",
                                        top: 3,
                                    }}
                                >
                                    close_icon
                                </Icon>
                            )}
                        </td>
                    </tr>

                    {item.approve_note && (
                        <tr>
                            <td>
                                {trans(
                                    "inv.deliverySlips.modals.deliverySlipModal.text.note"
                                )}
                            </td>
                            <td>{item.approve_note}</td>
                        </tr>
                    )}

                    {renderApprovedBy()}
                </tbody>
            </table>
        );
    };

    const renderApprovedBy = () => {
        if (!data.length) {
            return null;
        }

        const approvedBy: IApproveStatusChanges | null =
            item.approve_status_changes?.find((item: IApproveStatusChanges) => {
                if ("approve_status" in item.new_values) {
                    return item;
                }
                return null;
            });

        if (!approvedBy) {
            return null;
        }

        return (
            <>
                <tr>
                    <td>
                        {trans(
                            "inv.deliverySlips.modals.deliverySlipModal.text.approvedBy"
                        )}
                    </td>
                    <td>{approvedBy.user.first_name} {approvedBy.user.last_name}</td>
                </tr>
                <tr>
                    <td>
                        {trans(
                            "inv.deliverySlips.modals.deliverySlipModal.text.approvedAt"
                        )}
                    </td>
                    <td>
                        {momentApp(approvedBy.created_at).format(
                            "DD/MM/YYYY HH:mm"
                        )}
                    </td>
                </tr>
            </>
        );
    };

    const renderRow = (
        row: any,
        idx: number,
        content: TableRowProps,
        columns: IGPTableColumn[]
    ) => {
        return [
            createElement(TableRow, {
                ...content,
            }),
            row.approve_note !== null && (
                <TableRow key={`gui-body-row-${row.id}`}>
                    <TableCell colSpan={columns.length} sx={{ pt: 0 }}>
                        <b>
                            {trans(
                                "inv.deliverySlips.modals.deliverySlipModal.text.note"
                            )}
                        </b>
                        : {row.approve_note}
                    </TableCell>
                </TableRow>
            ),
        ];
    };

    if (isPending && !lines) {
        return <Loader />;
    }

    if (isError && !lines) {
        return <RequestMessage status={status} />;
    }

    return (
        <Modal maxWidth="xl" fullWidth open>
            <ModalTitle onClose={handleCloseModal}>
                {trans(
                    "inv.deliverySlips.modals.deliverySlipModal.text.order",
                    { order: item.order_number }
                )}
            </ModalTitle>
            <ModalContent sx={{ display: "flex", flexDirection: "column" }}>
                <Grid container spacing={2} sx={{ mb: 4 }}>
                    <Grid item xs={12} md={4}>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        {trans(
                                            "inv.deliverySlips.modals.deliverySlipModal.text.deliveryNumber"
                                        )}
                                    </td>
                                    <td>{item.delivery_number}</td>
                                </tr>
                                <tr>
                                    <td>
                                        {trans(
                                            "inv.deliverySlips.modals.deliverySlipModal.text.deliveryDate"
                                        )}
                                    </td>
                                    <td>
                                        {momentApp(item.delivery_date).format(
                                            "DD/MM/YYYY"
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {trans(
                                            "inv.deliverySlips.modals.deliverySlipModal.text.source"
                                        )}
                                    </td>
                                    <td>
                                        {item.order_id
                                            ? item.type
                                            : types[item.type].name}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {renderApproveDeliverySlip()}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        {renderDownload()}
                    </Grid>
                </Grid>
                <Table
                    calculateHeight={false}
                    {...table}
                    columns={prepareColumns(table?.columns)}
                    rows={data}
                    renderRow={renderRow}
                    tableProps={{ size: "small" }}
                />
            </ModalContent>
        </Modal>
    );
};

export default DeliverySlipDetailsLoadColumns;
