import types from "./types";
import { request } from "api/actions";
import { AxiosRequestConfig } from "axios";
import { IDeliverySlipsCreateToSend } from "./interfaces";

export const deliverySlipsApproveAll = (data: any, onResponse?: Function) =>
    request(
        types.APPROVE_ALL_REQUEST,
        types.APPROVE_ALL_FAILED,
        types.APPROVE_ALL_SUCCESS,
        "deliverySlipsApproveAll",
        { data },
        onResponse
    );

export const deliverySlipsFetch = (config?: AxiosRequestConfig) =>
    request(
        types.FETCH_REQUEST,
        types.FETCH_FAILED,
        types.FETCH_SUCCESS,
        `DeliverySlipsFetch()`,
        {
            config,
        }
    );

export const orderDeliverySlips = (id: number, config?: AxiosRequestConfig) =>
    request(
        types.ORDER_DELIVERY_REQUEST,
        types.ORDER_DELIVERY_FAILED,
        types.ORDER_DELIVERY_SUCCESS,
        `orderDeliverySlips()`,
        {
            config,
            url: {
                id,
            },
        }
    );

export const deliverySlipsStatsFetch = (config?: AxiosRequestConfig) =>
    request(
        types.FETCH_STATS_REQUEST,
        types.FETCH_STATS_FAILED,
        types.FETCH_STATS_SUCCESS,
        `DeliverySlipsStatsFetch()`,
        {
            config,
        }
    );

export const deliverySlipsGet = (id: number, config?: AxiosRequestConfig) =>
    request(
        types.GET_REQUEST,
        types.GET_FAILED,
        types.GET_SUCCESS,
        `DeliverySlipGet(${id})`,
        {
            config,
            url: {
                id,
            },
        }
    );

export const deliverySlipsGetLines = (
    id: number,
    config?: AxiosRequestConfig
) =>
    request(
        types.GET_LINES_REQUEST,
        types.GET_LINES_FAILED,
        types.GET_LINES_SUCCESS,
        `deliverySlipsGetLines`,
        {
            config,
            url: {
                id,
            },
        }
    );

export const deliverySlipsTableUpdate = (action: string, data: any) => ({
    type: types.TABLE_UPDATE,
    action,
    data,
});

export const deliverySlipsApproveTableUpdate = (action: string, data: any) => ({
    type: types.TABLE_APPROVE_UPDATE,
    action,
    data,
});

export const deliverySlipApprove = (
    id: number,
    data: any,
    onResponse?: Function
) =>
    request(
        types.APPROVE_REQUEST,
        types.APPROVE_FAILED,
        types.APPROVE_SUCCESS,
        "DeliverySlipApprove",
        {
            data,
            url: { id },
        },
        onResponse
    );

export const deliverySlipUpdate = (
    id: number,
    data: any,
    onResponse?: Function
) =>
    request(
        types.UPDATE_REQUEST,
        types.UPDATE_FAILED,
        types.UPDATE_SUCCESS,
        "DeliverySlipUpdate",
        {
            data,
            url: { id },
        },
        onResponse
    );

export const deliverySlipsCreate = (
    data: IDeliverySlipsCreateToSend,
    onResponse?: Function
) =>
    request(
        types.STORE_REQUEST,
        types.STORE_FAILED,
        types.STORE_SUCCESS,
        "deliverySlipsCreate",
        { data },
        onResponse
    );
