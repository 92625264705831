import { useContext, useEffect, useRef, useState } from "react";
import {
    OutlinedInput,
    InputAdornment,
    Icon,
    IconButton,
    CircularProgress,
    Box,
} from "@mui/material";
import { FC } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import usePrevions from "hooks/usePrevious";
import { Button } from "ui";
import { yellow } from "@mui/material/colors";
import AppContext, { IAppContext } from "app/App/context";

import { ISearch } from "../interfaces";

const StyledOutlinedInput = styled(OutlinedInput)({
    width: "100%",
});

let timeout: ReturnType<typeof setTimeout>;

const Search: FC<ISearch> = ({
    autoFocus,
    cancelRequest,
    isLoading,
    onChange,
    placeholder,
}) => {
    const appContext = useContext(AppContext) as IAppContext;
    const [keyboardType, setKeyboardType] = useState<string>(
        localStorage.getItem("searchKeyboardType") ?? "abc"
    );
    const search = useSelector(
        (state: any) => state.InventoryCount.searchValue
    );

    const [value, setValue] = useState<string>(search);

    const prevValue = usePrevions(value);
    const inputRef = useRef<HTMLInputElement>();

    useEffect(() => {
        if (prevValue === undefined || prevValue === value) {
            return;
        }

        clearTimeout(timeout);
        cancelRequest();

        if (value.length > 0) {
            timeout = setTimeout(() => {
                onChange(value);
            }, 500);
        } else {
            onChange(value);
        }
    }, [cancelRequest, value, onChange, prevValue]);

    useEffect(() => {
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    const handleChangeKeyboardType = (e: React.MouseEvent) => {
        let newKeyboardType = "abc";
        if (keyboardType === "abc") {
            newKeyboardType = "123";
        }

        localStorage.setItem("searchKeyboardType", newKeyboardType);
        setKeyboardType(newKeyboardType);
    };

    const handleClearInput = () => {
        setValue("");
    };

    useEffect(() => {
        if (prevValue === undefined || prevValue === value) {
            return;
        }

        clearTimeout(timeout);
        cancelRequest();

        if (value.length > 0) {
            timeout = setTimeout(() => {
                onChange(value);
            }, 500);
        } else {
            onChange(value);
        }
    }, [cancelRequest, value, onChange, prevValue]);

    useEffect(() => {
        if (!!inputRef.current) {
            inputRef.current.focus();
        }
    }, [keyboardType]);

    const renderEndAdornment = () => {
        if (isLoading) {
            return (
                <InputAdornment position="end">
                    <CircularProgress size={20} />
                </InputAdornment>
            );
        }

        if (value.length > 0) {
            return (
                <InputAdornment position="end">
                    <IconButton
                        onClick={handleClearInput}
                        sx={{
                            position: "absolute",
                            right: "0",
                        }}
                    >
                        <Icon>close</Icon>
                    </IconButton>
                </InputAdornment>
            );
        }
    };

    return (
        <Box display="flex" flex={1}>
            <StyledOutlinedInput
                autoFocus={autoFocus}
                endAdornment={renderEndAdornment()}
                inputProps={{
                    inputMode: keyboardType === "abc" ? "text" : "numeric",
                }}
                inputRef={inputRef}
                margin="dense"
                onChange={handleChangeInput}
                placeholder={placeholder}
                size="small"
                startAdornment={
                    <InputAdornment position="start">
                        <Icon sx={{ color: "grey.400" }}>search</Icon>
                    </InputAdornment>
                }
                sx={
                    appContext.isMobile
                        ? {
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                          }
                        : {}
                }
                value={value}
            />

            {appContext.isMobile && (
                <Button
                    color="gray2"
                    onClick={handleChangeKeyboardType}
                    variant="outlined"
                    sx={{
                        backgroundColor: yellow[200],
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        paddingX: 2,
                        minWidth: 32,
                    }}
                >
                    <Icon>{keyboardType === "abc" ? "123" : "abc"}</Icon>
                </Button>
            )}
        </Box>
    );
};

export default Search;
