import { Box, Icon, List, Typography } from "@mui/material";
import useRequest from "api/useRequest";
import { modalHide, modalShow } from "app/App/actions";
import { productsFetch } from "app/Products/actions";
import trans from "helpers/trans";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { productModel } from "types/productType";
import {
    Button,
    Checkbox,
    Loader,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
    RequestMessage,
} from "ui";
import {
    inventoryCountProduct,
    inventorySearch,
    suggestedProductsFetch,
} from "../actions";
import Search from "../components/Search";
import SearchProductListItem from "../components/SearchProductListItem";

interface IInventorySearchProductModal {
    onClickAddProduct?: Function;
    onClickProduct?: Function;
    areaId?: number;
}

const InventorySearchProductModal = (props: IInventorySearchProductModal) => {
    const [data, setData] = useState([]);
    const [searchGlobalProducts, setSearchGlobalProducts] = useState(false);
    const dispatch = useDispatch();
    const areaId = props.areaId;

    const locationType = useSelector(
        (state: any) => state.app.locations[state.app.locationId].type
    );

    const search = useSelector(
        (state: any) => state.InventoryCount.searchValue
    );

    const { cancel, currentData, isError, isLoading, request, status } =
        useRequest();

    const {
        currentData: suggestedProductsCurrentData,
        isLoading: suggestedProductsIsLoading,
        request: suggestedProductsRequest,
    } = useRequest();

    useEffect(() => {
        if (areaId) {
            suggestedProductsRequest(
                suggestedProductsFetch({ params: { area_id: areaId } })
            );
        }
    }, [areaId, suggestedProductsRequest]);

    useEffect(() => {
        if (search) {
            onChangeSearch(search);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const onClose = () => {
        dispatch(modalHide("InventorySearchProductModal"));
        dispatch(inventorySearch(""));
    };

    const onChangeSearch = (value: string) => {
        if (value.length > 0) {
            dispatch(inventorySearch(value));

            request(
                productsFetch({
                    params: {
                        _search: value,
                        local: searchGlobalProducts ? 0 : 1,
                    },
                })
            );
        } else {
            setData([]);
        }

        // setSearchValue(value);
    };
    const onChooseProduct = (product: productModel) => {
        if (!!props.onClickProduct) {
            dispatch(modalHide("InventorySearchProductModal"));
            return props.onClickProduct(product);
        } else {
            dispatch(inventoryCountProduct(product.id, undefined, true));
        }

        dispatch(modalHide("InventorySearchProductModal"));
    };

    useEffect(() => {
        if (!isLoading && status === 200) {
            setData(currentData.data);
        }
    }, [currentData?.data, isLoading, status]);

    const handleClickAddProduct = () => {
        dispatch(modalHide("InventorySearchProductModal"));

        if (props.onClickAddProduct) {
            return props.onClickAddProduct();
        }

        dispatch(
            modalShow("ProductsCreateModal", {
                onCreated: (product: productModel) => {
                    onChooseProduct(product);
                },
            })
        );
    };

    const handleChangeSearchGlobalProducts = () => {
        if (search) {
            request(
                productsFetch({
                    params: {
                        _search: search,
                        local: !searchGlobalProducts ? 0 : 1,
                    },
                })
            );
        }
        setSearchGlobalProducts((prev) => {
            return !prev;
        });
    };

    const renderContent = () => {
        if (isLoading) return null;

        if (isError)
            return status === 404 ? (
                <Typography variant="body1" sx={{ textAlign: "center", mb: 2 }}>
                    {trans(
                        "inv.inventory.modal.inventorySearchProductModal.notFound"
                    )}
                </Typography>
            ) : (
                <RequestMessage status={status} />
            );

        return (
            <>
                {data.map((item: productModel) => {
                    return (
                        <SearchProductListItem
                            key={`search-product-${item.id}`}
                            product={item}
                            onClick={onChooseProduct}
                        />
                    );
                })}
            </>
        );
    };

    const renderSuggestedProducts = () => {
        if (data.length > 0) return null;

        if (suggestedProductsIsLoading) return <Loader />;

        return (
            <Box>
                <Typography variant="subtitle2">
                    {trans(
                        "inv.inventory.modal.inventorySearchProductModal.suggestedProducts"
                    )}
                    :
                </Typography>
                {suggestedProductsCurrentData.data.map((item: productModel) => (
                    <SearchProductListItem
                        key={`suggestrd-product-${item.id}`}
                        product={item}
                        onClick={onChooseProduct}
                    />
                ))}
            </Box>
        );
    };

    return (
        <Modal
            maxWidth="xs"
            fullWidth
            open
            PaperProps={{ sx: { position: "fixed", top: 0 } }}
        >
            <ModalTitle onClose={onClose}>
                {" "}
                {trans(
                    "inv.inventory.modal.inventorySearchProductModal.search"
                )}
            </ModalTitle>
            <ModalActions
                sx={{
                    px: 2,
                    gap: 1,
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        gap: 1,
                    }}
                >
                    <Search
                        autoFocus={true}
                        cancelRequest={cancel}
                        isLoading={isLoading || suggestedProductsIsLoading}
                        placeholder={trans(
                            "inv.inventory.modal.inventorySearchProductModal.placeholder"
                        )}
                        onChange={onChangeSearch}
                    />
                    {locationType !== "SATELLITE" && (
                        <Button
                            onClick={handleClickAddProduct}
                            startIcon={<Icon>add</Icon>}
                        >
                            {trans(
                                "inv.inventory.modal.inventorySearchProductModal.product"
                            )}
                        </Button>
                    )}
                </Box>
                <Checkbox
                    id="search-global-products"
                    onChange={handleChangeSearchGlobalProducts}
                    checked={searchGlobalProducts}
                    sx={{ p: 0, ml: -1, pr: 1 }}
                    label={trans(
                        "inv.inventory.modal.inventorySearchProductModal.checkbox",
                        {
                            place:
                                locationType === "SATELLITE"
                                    ? "main store"
                                    : "global product",
                        }
                    )}
                />
            </ModalActions>
            <ModalContent sx={{ py: 0, px: 2, position: "relative" }}>
                {suggestedProductsCurrentData?.data?.length > 0 &&
                    renderSuggestedProducts()}
                <List>{renderContent()}</List>
            </ModalContent>
        </Modal>
    );
};

export default InventorySearchProductModal;
