import { OrderModel } from "./interfaces";
import types from "./types";

import { request } from "api/actions";
import { AxiosRequestConfig } from "axios";

export const ordersCreate = (data: OrderModel, onResponse?: Function) =>
    request(
        types.STORE_REQUEST,
        types.STORE_FAILED,
        types.STORE_SUCCESS,
        "ordersCreate",
        { data },
        onResponse
    );

export const ordersDelete = (id: number, onResponse?: Function) =>
    request(
        types.DESTROY_REQUEST,
        types.DESTROY_FAILED,
        types.DESTROY_SUCCESS,
        "ordersDelete",
        {
            url: {
                id,
            },
        },
        onResponse
    );

export const ordersFetch = (config?: AxiosRequestConfig) =>
    request(
        types.FETCH_REQUEST,
        types.FETCH_FAILED,
        types.FETCH_SUCCESS,
        "ordersFetch",
        {
            config,
        }
    );

export const ordersGet = (id: number, config?: AxiosRequestConfig) =>
    request(
        types.GET_REQUEST,
        types.GET_FAILED,
        types.GET_SUCCESS,
        "ordersGet",
        {
            config,
            url: {
                id,
            },
        }
    );

export const ordersTableUpdate = (action: string, data: any) => ({
    type: types.TABLE_UPDATE,
    action,
    data,
});

export const ordersUpdate = (id: number, data: any, onResponse?: Function) => {
    return request(
        types.UPDATE_REQUEST,
        types.UPDATE_FAILED,
        types.UPDATE_SUCCESS,
        "ordersUpdate",
        { data, url: { id } },
        onResponse
    );
};

export const orderPost = (id: number, data: any, onResponse?: Function) => {
    return request(
        types.POST_REQUEST,
        types.POST_FAILED,
        types.POST_SUCCESS,
        "orderPost",
        { data, url: { id } },
        onResponse
    );
};

export const locationsFetch = (config?: AxiosRequestConfig) =>
    request(
        types.FETCH_LOCATIONS_REQUEST,
        types.FETCH_LOCATIONS_FAILED,
        types.FETCH_LOCATIONS_SUCCESS,
        "locationsFetch",
        {
            config,
        }
    );

export const ordersProposalsFetch = (config?: AxiosRequestConfig) =>
    request(
        types.ORDERS_PROPOSALS_REQUEST,
        types.ORDERS_PROPOSALS_FAILED,
        types.ORDERS_PROPOSALS_SUCCESS,
        "ordersProposalsFetch",
        {
            config,
        }
    );
