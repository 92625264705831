import React from "react";
import { Box, Drawer } from "@mui/material";
import { DrawerProps } from "@mui/material";

interface ISideNav extends DrawerProps {
    drawerOpen?: boolean;
    children?: React.ReactNode;
}

const SideNav = ({ children, drawerOpen, ...restProps }: ISideNav) => {
    return (
        <Drawer
            {...restProps}
            sx={[
                {
                    width: (theme) => theme.sideNav.width,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        background: (theme) => theme.sideNav.background,
                        color: (theme) => theme.sideNav.color,
                        width: (theme) =>
                            drawerOpen ? theme.sideNav.width : 20,

                        "&>#side-nav-content": {
                            display: drawerOpen ? "block" : "none",
                        },

                        "&:hover": {
                            width: (theme) => theme.sideNav.width,

                            "&>#side-nav-content": {
                                display: "block",
                            },
                        },
                    },
                    zIndex: (theme) => theme.zIndex.drawer,
                },
                !drawerOpen && { width: 20 },
            ]}
        >
            <Box id="side-nav-content" sx={{ overflow: "auto" }}>
                {children}
            </Box>
        </Drawer>
    );
};

SideNav.defaultProps = {
    color: "primary",
    disabled: false,
    drawerOpen: true,
    size: "medium",
    variant: "permanent",
};

export default SideNav;
