import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";

const baseUrl = "/reports";

const requests = {
    compareInventoriesFetch: (config: AxiosRequestConfig) =>
        api.get(`${baseUrl}/compare-inventories`, config),
    costAndVarianceFetch: (config: AxiosRequestConfig) =>
        api.get(`${baseUrl}/cost-and-variance`, config),
};

export default requests;
