import {
    TableBody,
    TableCell,
    TableRow,
    TableCellProps,
    TableRowProps,
} from "@mui/material";
import { createElement, useMemo } from "react";

import { get } from "lodash";
import TableSelectorBody from "./TableSelectorBody";
import { IGPTableColumn, IGPTableSelection } from "./Table";

interface IGPTableBody {
    columns: IGPTableColumn[];
    id: string;
    idKey?: string;
    onClickRow?: (event: React.MouseEvent, item: any) => void;
    renderRow?: (
        row: any,
        idx: number,
        content: TableRowProps,
        columns: IGPTableColumn[]
    ) => React.ReactNode;
    rows?: any;
    rowHover?: boolean;
    selection?: IGPTableSelection;
    tableUpdate?: Function;
}

function GPTableBody({
    columns,
    id,
    idKey,
    onClickRow,
    renderRow,
    rows,
    selection,
    tableUpdate,
}: IGPTableBody) {
    const rowSx: any = useMemo(() => {
        if (!!onClickRow) {
            return {
                "&:hover": {
                    cursor: "pointer",
                },
            };
        }

        return {};
    }, [onClickRow]);

    const handleClickRow = (item: any) => (event: React.MouseEvent) => {
        if (!!onClickRow) {
            onClickRow(event, item);
        }
    };

    const renderCell = (
        column: IGPTableColumn,
        data: any,
        idx: number,
        cIdx: number
    ) => {
        let content = {} as TableCellProps;

        if (column.renderCell !== undefined) {
            content = {
                children: get(data, column.field as string),
                ...column.renderCell(data, column, idx),
            };
        } else {
            content.children = get(data, column.field as string);
        }

        if (column.field === "actions") {
            content.align = "right";
            content.sx = {
                backgroundColor: "#FFF",
                borderLeft: "1px solid rgba(224, 224, 224, 1)",
                p: 0,
                paddingLeft: "8px!important",
                paddingRight: "8px!important",
                position: "sticky",
                right: 0,
                whiteSpace: "nowrap",
                width: 52,
                zIndex: 3,
            };
        } else if (
            column.field === "row-selection" &&
            !!selection &&
            !!tableUpdate
        ) {
            content.children = (
                <TableSelectorBody
                    id={id}
                    data={data}
                    selection={selection}
                    tableUpdate={tableUpdate}
                />
            );
            content.sx = {
                alignItems: "center",
                backgroundColor: "#FFF",
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                left: 0,
                position: "sticky",
                padding: "0!important",
                textAlign: "center",
                zIndex: 3,
            };
            content.width = 42;
        }

        return createElement(TableCell, {
            key: `gui-body-row-${idx}-cell-${cIdx}`,
            ...column.cellProps,
            ...content,
        });
    };

    const renderRows = () => {
        if (rows.length === 0) {
            return null;
        }

        return rows.map((row: any, idx: number) => {
            let keyIdx = idx;
            if (!!idKey) {
                keyIdx = row[idKey];
            }

            let content: TableRowProps = {
                key: `gui-body-row-${keyIdx}`,
                children: columns.map((column: IGPTableColumn, cIdx: number) =>
                    renderCell(column, row, idx, cIdx)
                ),
                hover: true,
                onClick: handleClickRow(row),
                sx: rowSx,
            };

            if (!!renderRow) {
                return renderRow(row, idx, content, columns);
            }

            return createElement(TableRow, content);
        });
    };

    return <TableBody>{renderRows()}</TableBody>;
}

GPTableBody.defaultProps = {};

export default GPTableBody;
