export type InvoiceModel = {
    id: number;
    location_id: number;
    storage_id: number;
    status: "new" | "failed" | "parsed";
    type: string;
    invoice_number: string;
    order_number: string;
    due_date: string;
    issue_date: string;
    total_netto: number;
    total_tax: number;
    total_gross: number;
    currency: string;
    customer_name: string;
    customer_org_number: string;
    customer_contact_name: string;
    customer_contact_phone: string;
    customer_contact_email: string;
    customer_contact_url: string;
    customer_address: string;
    customer_zip_code: string;
    customer_city: string;
    customer_country: string;
    customer_number: string;
    seller_name: string;
    seller_org_number: string;
    seller_contact_name: string;
    seller_contact_phone: string;
    seller_contact_email: string;
    seller_contact_url: string;
    seller_address: string;
    seller_zip_code: string;
    seller_city: string;
    seller_country?: string;
    created_at: string;
    updated_at: string;
    storage: {
        id: number;
        disk: string;
        file_name: string;
        path: string;
        created_at: string;
        updated_at: string;
        link: string;
    }
}

const url = "INVOICE"

export const FETCH_SUCCESS = `${url}/FETCH_SUCCESS`;
export const TABLE_UPDATE = `${url}/TABLE_UPDATE`;

const types = {
    FETCH_REQUEST: `${url}/FETCH_REQUEST`,
    FETCH_FAILED: `${url}/FETCH_FAILED`,
    FETCH_SUCCESS,

    GET_REQUEST: `${url}/GET_REQUEST`,
    GET_FAILED: `${url}/GET_FAILED`,
    GET_SUCCESS: `${url}/GET_SUCCESS`,

    TABLE_UPDATE
}

export default types;