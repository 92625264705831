import { IBottle } from "../../interfaces";
import { FC } from "react";

const Rectangle: FC<IBottle> = ({ offset }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="319"
            height="100%"
            data-bx-workspace="master"
            version="1.1"
            viewBox="0 0 319 735"
        >
            <defs>
                <linearGradient
                    id="gradient-1"
                    x1="0%"
                    x2="0%"
                    y1="100%"
                    y2="0%"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={`${offset}%`} stopColor="#8A6A48"></stop>
                    <stop offset="0%" stopColor="#315525"></stop>
                </linearGradient>
            </defs>
            <path
                fill="url(#gradient-1)"
                stroke="#315525"
                strokeWidth="5"
                d="M2.5 2.5H316.5V732.5H2.5z"
            ></path>
        </svg>
    );
};

export default Rectangle;
