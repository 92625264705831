import { request } from "api/actions";
import { AxiosRequestConfig } from "axios";
import types from "./types";

export const autoConnectProducts = (data: any, onResponse?: any) => {
    return request(
        types.AUTO_CONNECT_PRODUCTS_REQUEST,
        types.AUTO_CONNECT_PRODUCTS_FAILED,
        types.AUTO_CONNECT_PRODUCTS_SUCCESS,
        "autoConnectProducts",
        {
            data,
        },
        onResponse
    );
};

export const connectProducts = (id: number, data: any, onResponse?: any) => {
    return request(
        types.CONNECT_PRODUCTS_REQUEST,
        types.CONNECT_PRODUCTS_FAILED,
        types.AUTO_CONNECT_PRODUCTS_SUCCESS,
        "connectProducts",
        {
            data,
            url: {
                id,
            },
        },
        onResponse
    );
};

export const posProductsFetch = (config?: AxiosRequestConfig) =>
    request(
        types.POS_PRODUCTS_FETCH_REQUEST,
        types.POS_PRODUCTS_FETCH_FAILED,
        types.POS_PRODUCTS_FETCH_SUCCESS,
        "posProductsFetch",
        {
            config,
        }
    );

export const posProductCategoriesFetch = (config?: AxiosRequestConfig) =>
    request(
        types.POS_PRODUCT_CATEGORIES_FETCH_REQUEST,
        types.POS_PRODUCT_CATEGORIES_FETCH_FAILED,
        types.POS_PRODUCT_CATEGORIES_FETCH_SUCCESS,
        "posProductCategoriesFetch",
        {
            config,
        }
    );

export const posProductsConnectRestaurantProductsTableUpdate = (
    action: string,
    data?: any
) => ({
    type: types.RESTAURANT_PRODUCTS_TABLE_UPDATE,
    action,
    data,
});

export const posProductsTableUpdate = (action: string, data?: any) => ({
    type: types.POS_PRODUCTS_TABLE_UPDATE,
    action,
    data,
});
