import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlId from "types/RequestUrlId";

const requests = {
    approve: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.put(`delivery-slips/${url.id}/approve`, data, config),
    fetch: (config: AxiosRequestConfig) => api.get(`/delivery-slips`, config),
    orderDeliverySlips: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/delivery-slips/order/${url.id}`, config),
    fetchStats: (config: AxiosRequestConfig) =>
        api.get(`/delivery-slips/approve/stats`, config),
    update: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.put(`delivery-slips/${url.id}`, data, config),
    get: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/delivery-slips/${url.id}`, config),
    getLines: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/delivery-slips/${url.id}/lines`, config),
    approveAll: (data: object, config: AxiosRequestConfig) =>
        api.post("/delivery-slips/approve/all", config),
    store: (data: object, config: AxiosRequestConfig) =>
        api.post(`/delivery-slips`, data, config),
};

export default requests;
