import trans from "helpers/trans";
import { useContext, useMemo } from "react";
import { useDispatch } from "react-redux";
import { IGPTextField } from "./TextField";
import { TextField } from "ui";
import { modalShow, modalHide } from "app/App/actions";
import { InputAdornment, Typography, Box } from "@mui/material";
import { getValue } from "./helpers";
import FormContext from "./FormContext";
import { countriesList, countryPhoneCodes } from "CountriesISO";
import { IFormChangeResponse } from "./Form";
interface IPhone extends IGPTextField {
    defaultCountryCode?: string;
}

function PhoneField({
    id,
    value,
    onChange,
    defaultCountryCode = "+47",
    ...restProps
}: IPhone) {
    const dispatch = useDispatch();

    const context = useContext(FormContext);
    const phoneNumberWithCC = getValue(id, context, value);

    const { countryCode, phoneNumber } = useMemo(() => {
        let countryCode = defaultCountryCode;
        let phoneNumber = "";

        if (phoneNumberWithCC !== "" && phoneNumberWithCC !== null) {
            const temp = phoneNumberWithCC.split(" ");

            if (temp.length === 1) {
                if (countryPhoneCodes[temp[0].replace("+", "")] !== undefined) {
                    countryCode = temp[0];
                }
            } else if (temp.length > 1) {
                if (countryPhoneCodes[temp[0].replace("+", "")] !== undefined) {
                    countryCode = temp[0];
                    temp.shift();
                }

                phoneNumber = temp.join("");
            }
        }

        return {
            countryCode,
            phoneNumber,
        };
    }, [defaultCountryCode, phoneNumberWithCC]);

    const countryData = useMemo(() => {
        const currentCountry = countriesList.find(
            (item) => item.id === countryCode
        );

        return currentCountry;
    }, [countryCode]);

    const handleChangeCountryCode = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        item: any
    ) => {
        if (!!onChange) {
            onChange({ id, value: item.id + " " + phoneNumber }, context);
        } else {
            context?.onChange({ id, value: item.id + " " + phoneNumber });
        }

        dispatch(modalHide("ChooseCountryModal"));
    };

    const handleChangePhoneNumber = ({ value }: IFormChangeResponse) => {
        const numberRegex = new RegExp("^[0-9]+$");
        if (value.length > 0 && !numberRegex.test(value)) {
            return;
        }

        if (!!onChange) {
            onChange({ id, value: countryCode + " " + value }, context);
        } else {
            context?.onChange({ id, value: countryCode + " " + value });
        }
    };

    const handleShowCountryModal = () => {
        dispatch(
            modalShow("ChooseCountryModal", {
                title: trans("ui.form.phone.title"),
                onClickCountry: handleChangeCountryCode,
                searchPlaceHolder: trans("ui.form.phone.search"),
            })
        );
    };

    return (
        <TextField
            {...restProps}
            id={id}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        {countryData && (
                            <Box
                                onClick={handleShowCountryModal}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: 1,
                                }}
                            >
                                <div
                                    className={`fflag ff-md fflag-${countryData.flag.toUpperCase()}`}
                                />
                                <Typography
                                    color="black"
                                    sx={{ marginTop: "1px" }}
                                >
                                    {countryData.id}
                                </Typography>
                            </Box>
                        )}
                    </InputAdornment>
                ),
                sx: {
                    cursor: "pointer",
                },
            }}
            onChange={handleChangePhoneNumber}
            value={phoneNumber}
        />
    );
}

export default PhoneField;
