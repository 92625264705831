import FormContext from "./FormContext";
import TextField from "./TextField";
import { useContext } from "react";
import { IGPTextField } from "./TextField";
import { IFormChangeResponse } from "./Form";

interface IGPIntegerInput extends IGPTextField {}

function GPIntegerInput({ onChange, ...restProps }: IGPIntegerInput) {
    const context = useContext(FormContext);

    const onChangeValue = ({ id, value }: IFormChangeResponse) => {
        if (
            value.length === 0 ||
            (value.length > 0 && /^(\d+)?$/.test(value))
        ) {
            let newValue: string | number = value;

            if (value.length > 0) {
                newValue = parseInt(value, 10);
            }

            if (!!onChange) {
                onChange({ id, value: newValue }, context);
            } else {
                context?.onChange({ id, value: newValue });
            }
        }
    };

    return <TextField {...restProps} onChange={onChangeValue} />;
}

GPIntegerInput.defaultProps = {};

export default GPIntegerInput;
