import { useState, useContext, useEffect } from "react";
import { InputAdornment } from "@mui/material";
import { TextField } from "ui";
import { IGPTextField } from "./TextField";
import FormContext from "./FormContext";
import { getValue } from "./helpers";
import { modalShow, modalHide } from "app/App/actions";
import { countriesList } from "CountriesISO";
import trans from "helpers/trans";
import { useDispatch } from "react-redux";
interface ICountryPicker extends IGPTextField {
    popularCountries?: string[];
}

const CountryPicker = (props: ICountryPicker) => {
    const {
        id,
        label,
        onChange,
        errorText,
        value,
        popularCountries,
        helperText,
        ...restProps
    } = props;

    const dispatch = useDispatch();

    const [countryFlag, setCountryFlag] = useState("");

    const context = useContext(FormContext);
    const country = getValue(id, context, value);

    useEffect(() => {
        if (country) {
            setCountryFlag(country);
        }
    }, [country]);

    const handleChangeCountry = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        item: any
    ) => {
        if (!!onChange) {
            onChange({ id, value: item.country }, context);
        } else {
            context?.onChange({ id, value: item.country });
        }

        dispatch(modalHide("ChooseCountryModal"));
    };

    const handleShowCountryModal = () => {
        dispatch(
            modalShow("ChooseCountryModal", {
                title: trans("ui.form.countryPicker.title"),
                onClickCountry: handleChangeCountry,
                popularCountries: popularCountries,
            })
        );
    };

    const getCountryValue = (country: string) => {
        const selectedCountry = countriesList.find(
            (item) => item.country === country
        );

        return selectedCountry?.name;
    };

    return (
        <TextField
            {...restProps}
            id={id}
            label={label}
            value={getCountryValue(country)}
            onClick={handleShowCountryModal}
            InputProps={{
                readOnly: true,
                startAdornment: (
                    <InputAdornment position="start">
                        {countryFlag && (
                            <div
                                className={`fflag ff-md fflag-${countryFlag.toUpperCase()}`}
                            />
                        )}
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default CountryPicker;
