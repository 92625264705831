import { productModel } from "types/productType";
import { TInventoryProductSubquantity } from "./interfaces";

export type InventoryProductModel = {
    area_id: number;
    created_at: string;
    gtin: any;
    id: number;
    market_price: number;
    quantity: number;
    updated_at: string;
    restaurant_product: {
        name: string;
        id: number;
        stock: number;
        product: productModel;
        price: number;
    };
    user: {
        created_at: string;
        email: string;
        email_verified_at?: string;
        id: number;
        name: string;
        role: string;
        sso_account_id: number;
        updated_at: string;
    };
};

export type InventoryModel = {
    restaurant_product_id?: number;
    product_id?: number;
    area_id?: number;
    product_gtin_id?: number;
    quantity?: number;
    vintage?: number;
    name?: string;
    subquantities?: TInventoryProductSubquantity[];
};

export type AreaListModel = {
    id?: number;
    name: string;
    stats: {
        scanned_products: number;
        quantity: number;
        count_by: any;
    };
};
export const COUNT_INVENTORY_LOADED = "INVENTORY/COUNT_INVENTORY_LOADED";
export const COUNT_PRODUCT = "INVENTORY/COUNT_PRODUCT";
export const COUNT_PRODUCT_CHANGE_VINTAGE =
    "INVENTORY/COUNT_PRODUCT_CHANGE_VINTAGE";
export const COUNT_RELOAD = "INVENTORY/COUNT_RELOAD";
export const COUNT_START = "INVENTORY/COUNT_START";
export const COUNT_STOP = "INVENTORY/COUNT_STOP";

export const DESTROY_FAILED = "INVENTORY/DESTROY_FAILED";
export const DESTROY_REQUEST = "INVENTORY/DESTROY_REQUEST";
export const DESTROY_SUCCESS = "INVENTORY/DESTROY_SUCCESS";

export const FETCH_FAILED = "INVENTORY/FETCH_FAILED";
export const FETCH_REQUEST = "INVENTORY/FETCH_REQUEST";
export const FETCH_SUCCESS = "INVENTORY/FETCH_SUCCESS";

export const FINALIZE_FAILED = "INVENTORY/FINALIZE_FAILED";
export const FINALIZE_REQUEST = "INVENTORY/FINALIZE_REQUEST";
export const FINALIZE_SUCCESS = "INVENTORY/FINALIZE_SUCCESS";

export const GET_FAILED = "INVENTORY/GET_FAILED";
export const GET_REQUEST = "INVENTORY/GET_REQUEST";
export const GET_SUCCESS = "INVENTORY/GET_SUCCESS";

export const GET_OPEN_FAILED = "INVENTORY/GET_OPEN_FAILED";
export const GET_OPEN_REQUEST = "INVENTORY/GET_OPEN_REQUEST";
export const GET_OPEN_SUCCESS = "INVENTORY/GET_OPEN_SUCCESS";

export const PRODUCT_DESTROY_FAILED = "INVENTORY/PRODUCT_DESTROY_FAILED";
export const PRODUCT_DESTROY_REQUEST = "INVENTORY/PRODUCT_DESTROY_REQUEST";
export const PRODUCT_DESTROY_SUCCESS = "INVENTORY/PRODUCT_DESTROY_SUCCESS";

export const PRODUCT_STORE_FAILED = "INVENTORY/PRODUCT_STORE_FAILED";
export const PRODUCT_STORE_REQUEST = "INVENTORY/PRODUCT_STORE_REQUEST";
export const PRODUCT_STORE_SUCCESS = "INVENTORY/PRODUCT_STORE_SUCCESS";

export const PRODUCT_CHANGE_FAILED = "INVENTORY/PRODUCT_CHANGE_FAILED";
export const PRODUCT_CHANGE_REQUEST = "INVENTORY/PRODUCT_CHANGE_REQUEST";
export const PRODUCT_CHANGE_SUCCESS = "INVENTORY/PRODUCT_CHANGE_SUCCESS";

export const PRODUCT_UPDATE_FAILED = "INVENTORY/PRODUCT_UPDATE_FAILED";
export const PRODUCT_UPDATE_REQUEST = "INVENTORY/PRODUCT_UPDATE_REQUEST";
export const PRODUCT_UPDATE_SUCCESS = "INVENTORY/PRODUCT_UPDATE_SUCCESS";

export const SEARCH_PRODUCTS = "INVENTORY/SEARCH_PRODUCTS";

export const STORE_FAILED = "INVENTORY/STORE_FAILED";
export const STORE_REQUEST = "INVENTORY/STORE_REQUEST";
export const STORE_SUCCESS = "INVENTORY/STORE_SUCCESS";

export const UPDATE_FAILED = "INVENTORY/UPDATE_FAILED";
export const UPDATE_REQUEST = "INVENTORY/UPDATE_REQUEST";
export const UPDATE_SUCCESS = "INVENTORY/UPDATE_SUCCESS";

export const TABLE_UPDATE = "INVENTORY/TABLE_UPDATE";

export const FETCH_AREAS_SUCCESS = "INVENTORY/FETCH_SUCCESS";
export const FETCH_AREAS_FAILED = "INVENTORY/FETCH_FAILED";
export const FETCH_AREAS_REQUEST = "INVENTORY/FETCH_REQUEST";

export const FETCH_SUGGESTED_PRODUCTS_SUCCESS =
    "INVENTORY/FETCH_SUGGESTED_PRODUCTS_SUCCESS";
export const FETCH_SUGGESTED_PRODUCTS_FAILED =
    "INVENTORY/FETCH_SUGGESTED_PRODUCTS_FAILED";
export const FETCH_SUGGESTED_PRODUCTS_REQUEST =
    "INVENTORY/FETCH_SUGGESTED_PRODUCTS_REQUEST";
export const GET_LAST_INVENTORY_SUCCESS = "LAST_INVENTORY/GET_SUCCESS";

const types = {
    COUNT_INVENTORY_LOADED,
    COUNT_PRODUCT,
    COUNT_PRODUCT_CHANGE_VINTAGE,
    COUNT_RELOAD,
    COUNT_START,
    COUNT_STOP,

    SEARCH_PRODUCTS,

    DESTROY_FAILED,
    DESTROY_REQUEST,
    DESTROY_SUCCESS,

    FETCH_FAILED,
    FETCH_REQUEST,
    FETCH_SUCCESS,

    FINALIZE_FAILED,
    FINALIZE_REQUEST,
    FINALIZE_SUCCESS,

    GET_FAILED,
    GET_REQUEST,
    GET_SUCCESS,

    GET_OPEN_FAILED,
    GET_OPEN_REQUEST,
    GET_OPEN_SUCCESS,

    STORE_FAILED,
    STORE_REQUEST,
    STORE_SUCCESS,

    PRODUCT_DESTROY_FAILED,
    PRODUCT_DESTROY_REQUEST,
    PRODUCT_DESTROY_SUCCESS,

    PRODUCT_UPDATE_FAILED,
    PRODUCT_UPDATE_REQUEST,
    PRODUCT_UPDATE_SUCCESS,

    PRODUCT_STORE_FAILED,
    PRODUCT_STORE_REQUEST,
    PRODUCT_STORE_SUCCESS,

    PRODUCT_CHANGE_FAILED,
    PRODUCT_CHANGE_REQUEST,
    PRODUCT_CHANGE_SUCCESS,

    UPDATE_FAILED,
    UPDATE_REQUEST,
    UPDATE_SUCCESS,

    TABLE_UPDATE,

    FETCH_AREAS_FAILED: "AREAS_LIST/FETCH_FAILED",
    FETCH_AREAS_REQUEST: "AREAS_LIST/FETCH_REQUEST",
    FETCH_AREAS_SUCCESS,

    FETCH_SUGGESTED_PRODUCTS_SUCCESS,
    FETCH_SUGGESTED_PRODUCTS_FAILED,
    FETCH_SUGGESTED_PRODUCTS_REQUEST,

    GET_LAST_INVENTORY_SUCCESS,
    GET_LAST_INVENTORY_FAILED: "LAST_INVENTORY/GET_FAILED",
    GET_LAST_INVENTORY_REQUEST: "LAST_INVENTORY/GET_REQUEST",
};

export default types;
