import { bulkRestaurantProductsSizeShowUnit } from "../actions";
import { IBulkActionModal } from "../interfaces";

import { Box, MenuItem } from "@mui/material";
import useRequest from "api/useRequest";
import { modalHide } from "app/App/actions";
import { AxiosResponse } from "axios";
import trans from "helpers/trans";
import { useUnitGroupsSelectOptions } from "helpers/units";
import { FC, useRef } from "react";
import { useDispatch } from "react-redux";
import {
    Button,
    Form,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
    Select,
    Settings,
    SettingsItem,
    TextField,
} from "ui";
import { IGPFormRef } from "ui/Form/Form";

const BulkSizeUnitModal: FC<IBulkActionModal> = ({ selected, onSuccess }) => {
    const dispatch = useDispatch();
    const formRef = useRef<IGPFormRef | null>();

    const { currentData, request, errors, isLoading, isSuccess } = useRequest();
    const renderUnitList = useUnitGroupsSelectOptions();

    const handleCloseModal = () => {
        dispatch(modalHide("BulkSizeUnitModal"));
    };

    const handleSubmit = () => {
        if (formRef.current !== null) {
            const data: any = formRef?.current?.getData();
            request(
                bulkRestaurantProductsSizeShowUnit(
                    {
                        ...data,
                        ids: selected,
                    },
                    (response: AxiosResponse) => {
                        if (response.status === 204) {
                            dispatch(modalHide("BulkSizeUnitModal"));
                            onSuccess();
                        }
                    }
                )
            );
        }
    };

    return (
        <Modal open fullWidth maxWidth="sm">
            <ModalTitle onClose={handleCloseModal}>
                {" "}
                {trans("inv.restaurantProducts.modal.BulkSizeUnitModal.title")}
            </ModalTitle>
            <ModalContent>
                <Form
                    ref={formRef}
                    data={currentData}
                    errors={errors}
                    fields={{
                        size: { type: "string" },
                        show_unit: { type: "string" },
                    }}
                    loading={isLoading}
                    onSubmit={handleSubmit}
                    onSuccess={isSuccess}
                    unsaved={false}
                >
                    <Settings>
                        <SettingsItem
                            label={trans(
                                "inv.restaurantProducts.form.restaurantProductsForm.label.size"
                            )}
                        >
                            <TextField
                                fullWidth
                                id="size"
                                type="number"
                                inputProps={{ min: 0 }}
                            />
                        </SettingsItem>
                        <SettingsItem
                            label={trans(
                                "inv.restaurantProducts.form.restaurantProductsForm.label.unit"
                            )}
                        >
                            <Select fullWidth id="show_unit">
                                <MenuItem value=" ">
                                    <em>
                                        {trans(
                                            "inv.restaurantProducts.form.restaurantProductsForm.select.unit"
                                        )}
                                    </em>
                                </MenuItem>
                                {renderUnitList}
                            </Select>
                        </SettingsItem>
                    </Settings>
                </Form>
            </ModalContent>
            <ModalActions>
                <Box display="flex" width="100%" textAlign="right">
                    <Button
                        onClick={handleCloseModal}
                        color="inherit"
                        variant="text"
                        sx={{ mr: 1 }}
                    >
                        {trans("global.button.cancel")}
                    </Button>
                    <Button sx={{ flex: 1 }} onClick={handleSubmit}>
                        {trans("global.button.save")}
                    </Button>
                </Box>
            </ModalActions>
        </Modal>
    );
};

export default BulkSizeUnitModal;
