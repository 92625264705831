import { Avatar, Typography } from "@mui/material";
import { modalHide } from "app/App/actions";
import trans from "helpers/trans";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalTitle, ModalContent } from "ui";

const AvaliableApplicationsModal: FC = () => {
    const dispatch = useDispatch();

    const avaliableApplications = useSelector(
        (state: any) => state.me.applications
    );

    const handleClose = () => {
        dispatch(modalHide("AvaliableApplicationsModal"));
    };

    const renderAvaliableApps = () => {
        return avaliableApplications.map((item: any) => (
            <Typography
                key={item.id}
                href={item.url}
                component="a"
                sx={{
                    textDecoration: "none",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "black",
                    width: "120px",
                    height: "120px",
                    border: "1px solid #e0e0e0",
                    borderRadius: 2,
                }}
            >
                <Avatar>{item.key}</Avatar>
                <Typography
                    sx={{
                        mt: 1,
                        textAlign: "center",
                        width: "90%",
                    }}
                >
                    {item.name}
                </Typography>
            </Typography>
        ));
    };

    return (
        <Modal maxWidth="xs" fullWidth open onClose={handleClose}>
            <ModalTitle onClose={handleClose}>
                {trans("inv.me.modal.availableApplicationsModal.title")}
            </ModalTitle>
            <ModalContent
                sx={{
                    display: "flex",
                    gap: 2,
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                }}
            >
                {renderAvaliableApps()}
            </ModalContent>
        </Modal>
    );
};

export default AvaliableApplicationsModal;
