import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlId from "types/RequestUrlId";

const requests = {
    get: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/table-management/${url.id}`, config),
    delete: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.delete(`/table-management/${url.id}`, config),
    update: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.put(`/table-management/${url.id}`, data, config),
};

export default requests;
