import { TABLE_UPDATE } from "../types";
import { FETCH_SUCCESS } from "app/Inventory/types";
import { tableResponse, tableUpdate } from "helpers/reducers";
import { IGPTable } from "ui/Table/Table";
import { AnyAction } from "redux";
import { MANAGE_TABLE } from "app/ManageTables/types";
interface IInventoryReportTable extends IGPTable {
    invClosed: boolean;
}
function InventoryReportTable(
    state: IInventoryReportTable = {
        id: "InventoryReportTable",
        columns: [],
        availableColumns: [],
        filters: {},
        filterData: [],
        filterSettings: {
            open: false,
            total: 0,
        },
        pagination: {
            page: 1,
            per_page: 20,
            isLastPage: true,
            total: 0,
        },
        search: "",
        selection: {
            id: "id",
            selected: [],
        },
        selectionActions: null,
        sort: { created_at: "desc" },
        with: [],
        invClosed: false,
    },
    action = {} as AnyAction
) {
    switch (action.type) {
        case FETCH_SUCCESS:
            if (action.response.config.params._table_id === state.id) {
                const inventoriesClosed = action.response.data.data.every(
                    (item: any) => item.end_date !== null
                );
                return {
                    ...tableResponse(state, action),
                    invClosed: inventoriesClosed,
                };
            }

            return state;

        case MANAGE_TABLE:
            if (action.data.id === state.id) {
                return {
                    ...state,
                    ...action.data,
                };
            }
            return state;

        case TABLE_UPDATE:
            return tableUpdate(state, action);

        default:
            return state;
    }
}

export default InventoryReportTable;
