import api from "./api";
import types from "./types";
import { request } from "api/apiSaga";
import { call, takeLatest, put } from "redux-saga/effects";
import { snackbarShow } from "app/App/actions";
import trans from "helpers/trans";
import { AnyAction } from "redux";
import { AxiosResponse } from "axios";

function* fetch(action: AnyAction) {
    yield call(request, api.fetch, action);
}

function* get(action: AnyAction) {
    yield call(request, api.get, action);
}

function* create(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.create,
        action,
        "post"
    );

    if (response.status === 200) {
        yield put(
            snackbarShow({
                content: trans("inv.recipes.saga.content.store"),
            })
        );
    }
}

function* deleteRecipe(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.delete,
        action,
        "delete"
    );

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("inv.recipes.saga.content.destroy"),
            })
        );
    }
}

function* duplicate(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.duplicate,
        action,
        "post"
    );

    if (response.status === 201) {
        yield put(
            snackbarShow({
                content: trans("inv.recipes.saga.content.duplicate"),
            })
        );
    }
}
function* update(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.update,
        action,
        "put"
    );

    if (response.status === 200) {
        yield put(
            snackbarShow({
                content: trans("inv.recipes.saga.content.update"),
            })
        );
    }
}

export default function* saga() {
    yield takeLatest(types.FETCH_REQUEST, fetch);
    yield takeLatest(types.CREATE_REQUEST, create);
    yield takeLatest(types.DELETE_REQUEST, deleteRecipe);
    yield takeLatest(types.DUPLICATE_REQUEST, duplicate);
    yield takeLatest(types.GET_REQUEST, get);
    yield takeLatest(types.UPDATE_REQUEST, update);
}
