import { LocationSupplierModel } from "./types";
import { IForm } from "types/form";
import { ISupplierModel } from "app/Suppliers/interfaces";

export interface ISuppliersForm extends IForm {
    data: LocationSupplierModel;
    isEdit?: boolean;
    unsaved?: boolean;
    submit?: number;
}

export type DeliveryCustomerNumberModel = {
    id: number;
    number: string;
    type: string;
    color: string;
    description: string | React.ReactNode;
    created_at: string;
    updated_at: string;
};

export interface ICustomerNumberModel {
    color: string;
    description: string;
    number: string;
    source: string;
}

export interface ILocationSupplierModel {
    id: 1;
    location_id: 1;
    supplier_id: 1;
    contact_name?: string;
    contact_phone?: string;
    contact_email?: string;
    notes?: string;
    supplier: ISupplierModel;
}

export interface IDeliveryCustomerNumbersForm extends IForm {
    data: DeliveryCustomerNumberModel;
}

type statusColorsType = "primary" | "secondary" | "error";

interface IStatusColors {
    FULLY: statusColorsType;
    ONLY_FORWARD: statusColorsType;
    SOON: statusColorsType;
}

export const statusColors: IStatusColors = {
    FULLY: "primary",
    ONLY_FORWARD: "secondary",
    SOON: "error",
};

export interface ITypes {
    [key: string]: { name: string; status: "FULLY" | "ONLY_FORWARD" | "SOON" };
}
