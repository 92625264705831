import trans from "helpers/trans";
import { FC } from "react";
import { Modal, ModalContent, ModalTitle } from "ui";
import MenuItemSelectPortion from "../components/MenuItemSelectPortion";

interface IMenusItemsChangePortionModal {
    onClick: Function;
    onClose: Function;
    portions: any;
}

const MenusItemsChangePortionModal: FC<IMenusItemsChangePortionModal> = ({
    onClick,
    onClose,
    portions,
}) => {
    const handleClickPortion = (portion: any) => {
        onClick(portion);
        onClose();
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Modal
            maxWidth="xs"
            fullWidth
            open
            PaperProps={{ sx: { position: "fixed", top: 0 } }}
        >
            <ModalTitle onClose={handleClose}>
                {trans(
                    "inv.menusItems.modal.menusItemsCreateModal.selectPortionTitle"
                )}
            </ModalTitle>
            <ModalContent>
                <MenuItemSelectPortion
                    portions={portions}
                    onClick={handleClickPortion}
                />
            </ModalContent>
        </Modal>
    );
};

export default MenusItemsChangePortionModal;
