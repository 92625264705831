import { FC } from "react";
import { IProductAddZeroStep } from "app/Products/interfaces";
import trans from "helpers/trans";
import { Box, Alert } from "@mui/material";
import { Button } from "ui";

const ProductAddZeroStep: FC<IProductAddZeroStep> = ({
    firstStepText,
    handleNextStep,
}) => {
    return (
        <Box>
            <Alert severity="info">{firstStepText}</Alert>
            <Button sx={{ mt: 3 }} onClick={() => handleNextStep()} fullWidth>
                {trans(
                    "inv.products.form.productAddForm.productAddZeroStep.add"
                )}
            </Button>
        </Box>
    );
};

export default ProductAddZeroStep;
