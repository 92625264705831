import LocationSupplierSelect from "../../../LocationSuppliers/components/LocationSupplierSelect";

import { MenuItem, Box } from "@mui/material";
import useRequest from "api/useRequest";
import { productsCreate } from "app/Products/actions";
import {
    IProductQuickForm,
    IProductQuickFormRef,
} from "app/Products/interfaces";
import { AxiosResponse } from "axios";
import { isWine } from "helpers/isWine";
import trans from "helpers/trans";
import { useUnitGroupsSelectOptions } from "helpers/units";
import { range } from "lodash";
import moment from "momentApp";
import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react";
import { useSelector } from "react-redux";
import { File, Form, Select, TextField, CountryPicker } from "ui";
import { IGPFormRef } from "ui/Form/Form";

const ProductQuickForm = forwardRef<IProductQuickFormRef, IProductQuickForm>(
    ({ data, onLoading, onSaved }, ref) => {
        const [isWineCategorySelected, setIsWineCategorySelected] =
            useState(false);
        const { categories } = useSelector((state: any) => ({
            categories: state.app.settings.categories,
        }));

        const currentYear = moment().year();

        const listOfYears = useMemo(() => {
            const minYear = 1814;
            const r = range(currentYear, minYear);
            return r.map((e, id) => {
                return (
                    <MenuItem key={id} sx={{ width: "100%" }} value={e}>
                        {e}
                    </MenuItem>
                );
            });
        }, [currentYear]);

        const gtinData = useMemo(() => {
            if (data?.gtin?.length === 1) {
                return {
                    gtin: data.gtin[0].gtin,
                    unit_of_measure: data.gtin[0].unit_of_measure,
                    package_quantity: data.gtin[0].package_quantity,
                };
            }
        }, [data]);

        const selectedCategory = useMemo(() => {
            if (data?.category_id) return categories[data.category_id];
        }, [categories, data.category_id]);

        const formRef = useRef<IGPFormRef | null>(null);

        const { errors, isLoading, request } = useRequest();

        useImperativeHandle(ref, () => ({
            submit: handleSubmit,
        }));

        useEffect(() => {
            setIsWineCategorySelected(isWine(selectedCategory));
        }, [selectedCategory]);

        useEffect(() => {
            onLoading(isLoading);
        }, [isLoading, onLoading]);

        const handleSubmit = (data: any) => {
            if (formRef.current !== null) {
                const data: any = formRef.current.getData();

                let locationSuppliersIds = [];
                if (
                    data.location_supplier_ids !== "" &&
                    typeof data.location_supplier_ids === "number"
                ) {
                    locationSuppliersIds.push(data.location_supplier_ids);
                }

                request(
                    productsCreate(
                        {
                            ...data,
                            location_supplier_ids: locationSuppliersIds,
                        },
                        (response: AxiosResponse) => {
                            if (response.status === 201) {
                                onSaved(response.data);
                            }
                        }
                    )
                );
            }
        };

        const unitList = useUnitGroupsSelectOptions(
            selectedCategory.unit_groups
        );

        return (
            <Form
                data={data}
                errors={errors}
                fields={{
                    category_id: { default: "" },
                    gtin: {
                        default: gtinData ? [gtinData] : [],
                    },
                    name: {},
                    size: {},
                    country: {},
                    plu: { type: "string" },
                    location_supplier_ids: {},
                    epd: {},
                    unit: {
                        default: selectedCategory.default_unit
                            ? selectedCategory.default_unit
                            : "",
                    },
                    vintage: {
                        default:
                            !!selectedCategory && isWine(selectedCategory)
                                ? currentYear
                                : "",
                    },
                    image: {},
                }}
                onSubmit={handleSubmit}
                ref={formRef}
                unsaved={false}
                preventLeftForm
                loading
            >
                {(context: any) => {
                    return (
                        <>
                            <TextField
                                fullWidth
                                id="name"
                                label={trans(
                                    "inv.products.form.productAddForm.productQuickForm.label.name"
                                )}
                            />
                            {isWineCategorySelected && (
                                <Select
                                    fullWidth
                                    id="vintage"
                                    label={trans(
                                        "inv.products.form.productAddForm.productQuickForm.label.vintage"
                                    )}
                                    sx={{ mt: 2 }}
                                >
                                    <MenuItem value="">
                                        <em>
                                            {trans(
                                                "inv.products.form.productAddForm.productQuickForm.selectVintage"
                                            )}
                                        </em>
                                    </MenuItem>
                                    {listOfYears}
                                </Select>
                            )}
                            <Box sx={{ display: "flex", my: 2, gap: 1 }}>
                                <TextField
                                    fullWidth
                                    id="size"
                                    label={trans(
                                        "inv.products.form.productAddForm.productQuickForm.label.size"
                                    )}
                                    type="number"
                                    inputProps={{ min: 0 }}
                                />
                                <Select
                                    fullWidth
                                    id="unit"
                                    label={trans(
                                        "inv.products.form.productAddForm.productQuickForm.label.unit"
                                    )}
                                >
                                    <MenuItem value="">
                                        <em>
                                            {trans(
                                                "inv.products.form.productAddForm.productQuickForm.selectUnit"
                                            )}
                                        </em>
                                    </MenuItem>
                                    {unitList}
                                </Select>
                            </Box>
                            <TextField
                                fullWidth
                                id="plu"
                                label={trans(
                                    "inv.products.form.productAddForm.productQuickForm.label.plu"
                                )}
                            />
                            <LocationSupplierSelect
                                id="location_supplier_ids"
                                label={trans(
                                    "inv.products.form.productAddForm.productQuickForm.label.supplier"
                                )}
                                fullWidth
                                sx={{ my: 2 }}
                            />
                            <CountryPicker
                                id="country"
                                label={trans(
                                    "inv.products.form.productAddForm.productQuickForm.label.country"
                                )}
                                fullWidth
                            />
                            <File
                                id="image"
                                accept="image/png, image/jpg, image/jpeg"
                                fullWidth
                                title={trans(
                                    "inv.products.form.productAddForm.productQuickForm.title"
                                )}
                                sx={{ mt: 2 }}
                            />
                        </>
                    );
                }}
            </Form>
        );
    }
);

export default ProductQuickForm;
