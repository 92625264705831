export const TABLE_UPDATE = "COST_VARIANCE/TABLE_UPDATE";

const types = {
    COMPARE_INVENTORIES_FETCH_REQUEST:
        "REPORTS/COMPARE_INVENTORIES_FETCH_REQUEST",
    COMPARE_INVENTORIES_FETCH_SUCCESS:
        "REPORTS/COMPARE_INVENTORIES_FETCH_SUCCESS",
    COMPARE_INVENTORIES_FETCH_FAILED:
        "REPORTS/COMPARE_INVENTORIES_FETCH_FAILED",
    COST_AND_VARIANCE_FETCH_REQUEST: "REPORTS/COST_AND_VARIANCE_FETCH_REQUEST",
    COST_AND_VARIANCE_FETCH_SUCCESS: "REPORTS/COST_AND_VARIANCE_FETCH_SUCCESS",
    COST_AND_VARIANCE_FETCH_FAILED: "REPORTS/COST_AND_VARIANCE_FETCH_FAILED",

    TABLE_UPDATE,
};

export default types;
