import { request } from "api/apiSaga";
import { snackbarShow } from "app/App/actions";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import api from "./api";
import types from "./types";

function* update(action: AnyAction): any {
    const response = yield call(request, api.update, action, "put");

    if (response.status < 300) {
        yield put(
            snackbarShow({
                content: "Taxes updated",
            })
        );
    }
}

function* create(action: AnyAction): any {
    const response = yield call(request, api.create, action, "post");

    if (response.status < 300) {
        yield put(
            snackbarShow({
                content: "Tax has created",
            })
        );
    }
}

function* get(action: AnyAction): any {
    yield call(request, api.get, action);
}

function* deleteTax(action: AnyAction): any {
    const response = yield call(request, api.delete, action, "delete");

    if (response.status < 300) {
        yield put(
            snackbarShow({
                content: "Tax has been deleted",
            })
        );
    }
}

function* fetch(action: AnyAction): any {
    yield call(request, api.fetch, action);
}

export default function* saga() {
    yield takeLatest(types.UPDATE_REQUEST, update);
    yield takeLatest(types.CREATE_REQUEST, create);
    yield takeLatest(types.FETCH_REQUEST, fetch);
    yield takeLatest(types.DELETE_REQUEST, deleteTax);
    yield takeLatest(types.GET_REQUEST, get);
}
