export const FETCH_SUCCESS = "WEB_MENU/FETCH_SUCCESS";
export const FETCH_REQUEST = "WEB_MENU/FETCH_REQUEST";
export const FETCH_FAILED = "WEB_MENU/FETCH_FAILED";

export const RECOMMENDED_SUCCESS = "WEB_MENU/RECOMMENDED_SUCCESS";

export const TABLE_UPDATE = "WEB_MENU/TABLE_UPDATE";
export const SETTINGS_SUCCESS = "WEB_MENU/SETTINGS_SUCCESS";
export const SETTINGS_FAILED = "WEB_MENU/SETTINGS_FAILED";
export const SETTINGS_REQUEST = "WEB_MENU/SETTINGS_REQUEST";
export const SHOW_EXPANDED = "WEB_MENU/SHOW_EXPANDED";
export const MOBILE_FILTERS = "WEB_MENU/MOBILE_FILTERS";
export const SCROLL = "WEB_MENU/SCROLL";

const types = {
    FETCH_SUCCESS,
    FETCH_FAILED: "WEB_MENU/FETCH_FAILED",
    FETCH_REQUEST: "WEB_MENU/FETCH_REQUEST",

    GET_FAILED: "WEB_MENU/GET_FAILED",
    GET_REQUEST: "WEB_MENU/GET_REQUEST",
    GET_SUCCESS: "WEB_MENU/GET_SUCCESS",

    GET_SIMILAR_FAILED: "WEB_MENU/GET_SIMILAR_FAILED",
    GET_SIMILAR_REQUEST: "WEB_MENU/GET_SIMILAR_REQUEST",
    GET_SIMILAR_SUCCESS: "WEB_MENU/GET_SIMILAR_SUCCESS",

    SETTINGS_FAILED,
    SETTINGS_REQUEST,
    SETTINGS_SUCCESS,

    RECOMMENDED_FAILED: "WEB_MENU/RECOMMENDED_FAILED",
    RECOMMENDED_REQUEST: "WEB_MENU/RECOMMENDED_REQUEST",
    RECOMMENDED_SUCCESS,
    
    SCROLL,

    TRANSLATE_FAILED: "WEB_MENU/TRANSLATE_FAILED",
    TRANSLATE_REQUEST: "WEB_MENU/TRANSLATE_REQUEST",
    TRANSLATE_SUCCESS: "WEB_MENU/TRANSLATE_SUCCESS",

    TABLE_UPDATE,
};

export default types;
