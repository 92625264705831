import { Navigate, useRoutes } from "react-router-dom";
import NotFound from "app/App/pages/NotFound";

let routings = [];
let webRoutings = [];

export function registerRoutings(data) {
    routings.push(...data);
}
export function registerWebRoutings(data) {
    webRoutings.push(...data);
}

export const AppRouting = () => {
    const navigatePath =
        routings.find((item) => item.path === "/inventory")?.path ||
        routings.find((item) => item.path.split("/").length === 2)?.path;

    return useRoutes([
        ...routings,
        { path: "/", element: <Navigate to={navigatePath} replace={true} /> },
        { path: "*", element: <NotFound /> },
    ]);
};

export const WebRouting = () => {
    return useRoutes([...webRoutings, { path: "*", element: <NotFound /> }]);
};

export default AppRouting;
