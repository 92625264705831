import { ISuppliersForm } from "../interfaces";
import SupplierSelect from "../../Suppliers/components/SupplierSelect";
import trans from "helpers/trans";
import { FC, useEffect, useRef } from "react";
import {
    Button,
    Form,
    PaperHeader,
    PhoneField,
    Settings,
    SettingsItem,
    Textarea,
    TextField,
} from "ui";
import { Icon, Paper } from "@mui/material";
import { get } from "lodash";
import CustomerNumbersTable from "../tables/CustomerNumbersTable";
import { IGPFormRef } from "ui/Form/Form";

const SuppliersForm: FC<ISuppliersForm> = ({
    data,
    errors,
    isEdit = false,
    loading,
    onSubmit,
    submit,
    unsaved,
}) => {
    const formRef = useRef<IGPFormRef | null>();

    useEffect(() => {
        if (submit && formRef.current !== null) {
            const data = formRef.current?.getData();
            handleSubmit(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submit]);

    const handleSubmit = (data: any) => {
        let forwardTo = data.forward_to;
        if (!Array.isArray(forwardTo)) {
            forwardTo =
                data.forward_to !== "" ? data.forward_to.split(",") : [];
        }
        onSubmit({
            ...data,
            forward_to: forwardTo,
        });
    };

    const handleAddCustomerNumber = (context: any, id: string) => {
        context.onChange([
            {
                id,
                value: [
                    ...get(context.data, id, []),
                    {
                        id: null,
                        number: "",
                        description: "",
                        color: "#000000",
                    },
                ],
            },
        ]);
    };

    return (
        <Form
            ref={formRef}
            errors={errors}
            data={data}
            fields={{
                contact_name: { type: "string" },
                contact_phone: { type: "string" },
                contact_email: { type: "string" },
                forward_to: { type: "string" },
                notes: { type: "string" },
                supplier_id: {},
                delivery_customer_numbers: {
                    default: [],
                },
            }}
            loading={loading}
            onSubmit={handleSubmit}
            onSuccess={onSubmit}
            {...(unsaved === false && { unsaved: false })}
        >
            {(context: any) => {
                return (
                    <>
                        <Settings>
                            <SettingsItem
                                label={trans(
                                    "inv.locationSuppliers.form.locationSupplierForm.label.supplier"
                                )}
                            >
                                <SupplierSelect
                                    fullWidth
                                    disabled={isEdit}
                                    id="supplier_id"
                                />
                            </SettingsItem>
                            <SettingsItem
                                label={trans(
                                    "inv.locationSuppliers.form.locationSupplierForm.label.contactName"
                                )}
                            >
                                <TextField fullWidth id="contact_name" />
                            </SettingsItem>
                            <SettingsItem
                                label={trans(
                                    "inv.locationSuppliers.form.locationSupplierForm.label.contactPhone"
                                )}
                            >
                                <PhoneField fullWidth id="contact_phone" />
                            </SettingsItem>
                            <SettingsItem
                                label={trans(
                                    "inv.locationSuppliers.form.locationSupplierForm.label.contactEmail"
                                )}
                            >
                                <TextField fullWidth id="contact_email" />
                            </SettingsItem>
                            <SettingsItem label="Forward to">
                                <TextField
                                    fullWidth
                                    id="forward_to"
                                    helperText={`Add emails separated by comma`}
                                />
                            </SettingsItem>
                            <SettingsItem
                                label={trans(
                                    "inv.locationSuppliers.form.locationSupplierForm.label.notes"
                                )}
                            >
                                <Textarea
                                    id="notes"
                                    minRows={4}
                                    multiline
                                    fullWidth
                                />
                            </SettingsItem>
                        </Settings>
                        <Paper sx={{ mt: 4 }}>
                            <PaperHeader
                                title={trans(
                                    "inv.locationSuppliers.form.locationSupplierForm.label.customerNumber"
                                )}
                                actions={
                                    <Button
                                        onClick={() =>
                                            handleAddCustomerNumber(
                                                context,
                                                "delivery_customer_numbers"
                                            )
                                        }
                                        startIcon={<Icon>add</Icon>}
                                    >
                                        {trans(
                                            "inv.locationSuppliers.form.locationSupplierForm.action.addCustomerNumber"
                                        )}
                                    </Button>
                                }
                            />
                            {(context.data.delivery_customer_numbers.length !==
                                0 ||
                                (data?.delivery_customer_numbers &&
                                    data?.delivery_customer_numbers.length !==
                                        0)) && (
                                <CustomerNumbersTable
                                    context={context}
                                    id="delivery_customer_numbers"
                                />
                            )}
                        </Paper>
                    </>
                );
            }}
        </Form>
    );
};

export default SuppliersForm;
