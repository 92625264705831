import { Icon, Paper } from "@mui/material";
import { PaperHeader } from "ui";
import { useState } from "react";
import { SX } from "types/SX";
import { PaperProps } from "@mui/material";
interface IGPUIAccordion extends PaperProps {
    children?: React.ReactNode;
    sx?: SX;
    title: string;
    variant?: any;
}

const GPUIAccordion = ({
    children,
    title,
    sx,
    ...restProps
}: IGPUIAccordion) => {
    const [open, setOpen] = useState(false);

    return (
        <Paper sx={sx}>
            <PaperHeader
                title={title}
                actions={
                    <Icon sx={{ color: "rgba(0, 0, 0, 0.54)" }}>
                        {open ? "expand_less" : "expand_more"}
                    </Icon>
                }
                onClick={() => setOpen((prevState) => !prevState)}
                sx={{
                    "&:hover": {
                        cursor: "pointer",
                    },
                }}
                {...restProps}
            />
            {open && children}
        </Paper>
    );
};

export default GPUIAccordion;
