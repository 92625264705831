import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlId from "types/RequestUrlId";

const baseUrl = "/location/suppliers";

const requests = {
    fetch: (config: AxiosRequestConfig) => api.get(`${baseUrl}`, config),
    update: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.put(`${baseUrl}/${url.id}`, data, config),
    delete: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.delete(`${baseUrl}/${url.id}`, config),
    create: (data: object, config: AxiosRequestConfig) =>
        api.post(`${baseUrl}`, data, config),
    get: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`${baseUrl}/${url.id}`, config),
};

export default requests;
