import Button from "../Button/Button";
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
} from "@mui/material";
import trans from "helpers/trans";
import PropTypes from "prop-types";
import { IGPTablePagination } from "./Table";
interface IGPTablePaginationProps {
    pagination: IGPTablePagination;
    rows: number;
    tableUpdate?: Function;
}

function GPTablePagination({
    pagination,
    rows,
    tableUpdate,
}: IGPTablePaginationProps) {
    if (rows === 0 || !tableUpdate) {
        return null;
    }

    const nextPage = () => {
        if (!pagination?.isLastPage) {
            tableUpdate("page", pagination.page + 1);
        }
    };

    const prevPage = () => {
        if (pagination.page > 1) {
            tableUpdate("page", pagination.page - 1);
        }
    };
    const handleChangePerPage = (e: SelectChangeEvent<number>) => {
        tableUpdate("per_page", e.target.value);
    };

    return (
        <Box
            sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                // mb: 2,
                mt: 1.5,
                gap: 1.5,
                flexWrap: "wrap",
            }}
        >
            <Typography>
                <strong>{pagination.total}</strong>{" "}
                {pagination.total && pagination.total > 1
                    ? trans("ui.table.tablePagination.results")
                    : trans("ui.table.tablePagination.result")}
            </Typography>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                }}
            >
                <FormControl
                    sx={{
                        width: 90,
                    }}
                >
                    <InputLabel>
                        {trans("ui.table.tablePagination.perPage")}
                    </InputLabel>
                    <Select
                        id="per page"
                        label={trans("ui.table.tablePagination.perPage")}
                        size="small"
                        value={pagination.per_page}
                        onChange={(e) => handleChangePerPage(e)}
                        sx={{
                            height: 31,
                        }}
                    >
                        {[20, 50, 100].map((page: number) => {
                            return (
                                <MenuItem value={page} key={`perpage-${page}`}>
                                    {page}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <Box>
                    <Button
                        color="gray2"
                        disabled={pagination.page === 1}
                        onClick={prevPage}
                        size="small"
                        sx={{ mr: 1 }}
                        variant="outlined"
                    >
                        {trans("ui.table.tablePagination.previous")}
                    </Button>
                    <Button
                        color="gray2"
                        disabled={pagination.isLastPage}
                        onClick={nextPage}
                        size="small"
                        variant="outlined"
                    >
                        {trans("ui.table.tablePagination.next")}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

GPTablePagination.propTypes = {
    tableUpdate: PropTypes.func,
};

GPTablePagination.defaultProps = {
    tableUpdate: () => null,
};

export default GPTablePagination;
