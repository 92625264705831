import { MenuItem } from "@mui/material";
import trans from "helpers/trans";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Select } from "ui";
import { IGPSelect } from "ui/Form/Select";

const CategoriesFormSelect: FC<IGPSelect> = (props) => {
    const { categories, mainGroups } = useSelector((state: any) => {
        return {
            categories: state.app.settings.categories,
            mainGroups: state.app.settings.categoriesMainGroups,
        };
    });

    const prepareList = (group: number[], arr: any[] = [], lvl: number = 1) => {
        group.forEach((item: number) => {
            const category = categories[item];
            arr.push(
                <MenuItem
                    key={`category-${category.id}`}
                    value={category.id}
                    disableGutters
                    sx={{ pl: 2 * lvl }}
                >
                    {category.name}
                </MenuItem>
            );

            if (category.childrens.length > 0) {
                arr = prepareList(category.childrens, arr, lvl + 1);
            }
        });

        return arr;
    };

    const renderList = prepareList(mainGroups, []);

    return (
        <Select {...props}>
            <MenuItem value="">
                <em>
                    {trans(
                        "inv.categories.component.categoriesFormSelect.text"
                    )}
                </em>
            </MenuItem>
            {renderList}
        </Select>
    );
};

export default CategoriesFormSelect;
