import { registerRoutings } from "rootRouting";
import store from "rootStore";
import { configType, permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";
import MenuTable from "./reducers/MenusTableReducer";
import MenuSaga from "./saga";

export const permissionKey = "menus";
export const menusUrl = "/menus";

const getConfig = (permissions: permissionsType) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: configType = {
        permissions: {
            create: false,
            delete: false,
            read: false,
            update: false,
        },
        routing: [],
        url: menusUrl,
    };

    let routing: routingType = [];

    if (modulePermissions.indexOf(permissionKey + ".create") !== -1) {
        config.permissions.create = true;

        routing.push({
            path: `${menusUrl}/create`,
            element: <AsyncComponent path="Menus/pages/MenusCreatePage" />,
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".delete") !== -1) {
        config.permissions.delete = true;
    }

    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;

        store.reducerManager?.add("MenuTable", MenuTable);
        store.injectSaga?.("MenuSaga", MenuSaga);

        routing.push({
            path: menusUrl,
            element: <AsyncComponent path="Menus/pages/MenusIndexPage" />,
        });

        routing.push({
            path: `${menusUrl}/:id/preview`,
            element: <AsyncComponent path="Menus/pages/MenuPreviewPage" />,
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
        config.permissions.update = true;

        routing.push({
            path: `${menusUrl}/:id/edit`,
            element: <AsyncComponent path="Menus/pages/MenusEditPage" />,
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
