import { ListSubheader, MenuItem } from "@mui/material";
import trans from "./trans";
import { useMemo } from "react";

export const units = {
    //Distance
    DMM: 10000,
    DCM: 100,
    DM: 1,

    //Weight
    WMG: 1000000,
    WG: 1000,
    WDAG: 100,
    WKG: 1,

    //Volume
    VML: 1000,
    VCL: 100,
    VDL: 10,
    VL: 1,

    //Count
    CBU: 1,
    CLO: 1,
    CPA: 1,
    CPC: 1,
    CPR: 1,

    NA: 1,
};

// const unitDefaults = {
//     WKG: "WG",
//     VL: "VML",
//     CEA: "CEA",
// };

export const unitSymbol = {
    DMM: "mm",
    DCM: "dm",
    DM: "m",
    WMG: "mg",
    WG: "g",
    WDAG: "dag",
    WKG: "kg",
    VML: "ml",
    VCL: "cl",
    VDL: "dl",
    VL: "l",
    CBU: "bu",
    CLO: "lot",
    CPA: "pa",
    CPC: "pc",
    CPR: "pr",
    NA: "n/a",
};

export const unitGroups = [
    {
        id: "volume",
        label: "helpers.units.label.volume",
        options: [
            {
                value: "VML",
                label: "ml",
            },
            {
                value: "VCL",
                label: "cl",
            },
            {
                value: "VDL",
                label: "dl",
            },
            {
                value: "VL",
                label: "l",
            },
        ],
    },
    {
        id: "weight",
        label: "helpers.units.label.weight",
        options: [
            {
                value: "WMG",
                label: "mg",
            },
            {
                value: "WG",
                label: "g",
            },
            {
                value: "WDAG",
                label: "dag",
            },
            {
                value: "WKG",
                label: "kg",
            },
        ],
    },
    {
        id: "distance",
        label: "helpers.units.label.distance",
        options: [
            {
                value: "DMM",
                label: "mm",
            },
            {
                value: "DCM",
                label: "cm",
            },
            {
                value: "DM",
                label: "m",
            },
        ],
    },
    {
        id: "count",
        label: "helpers.units.label.count",
        options: [
            {
                value: "CBU",
                label: "helpers.units.label.cbu",
            },
            {
                value: "CLO",
                label: "helpers.units.label.clo",
            },
            {
                value: "CPA",
                label: "helpers.units.label.cpa",
            },
            {
                value: "CPC",
                label: "helpers.units.label.cpc",
            },
            {
                value: "CPR",
                label: "helpers.units.label.cpr",
            },
            {
                value: "NA",
                label: "n/a",
            },
        ],
    },
];

export const pricePerUnit = (price, size, unit, toUnit) => {
    return price / convertUnit(size, unit, toUnit);
};

export const convertUnit = (value, fromUnit, toUnit) => {
    if (!fromUnit || !toUnit) {
        return value;
    }

    return (value * units[toUnit]) / units[fromUnit];
};

export const formatUnit = (value, fromUnit, toUnit, toFixed) => {
    if (!fromUnit || !toUnit) {
        return value;
    }

    if (!!toFixed) {
        return `${convertUnit(value, fromUnit, toUnit).toFixed(toFixed)} ${
            unitSymbol[toUnit]
        }`;
    }

    return `${convertUnit(value, fromUnit, toUnit)} ${unitSymbol[toUnit]}`;
};

export const getUnitGroupByUnit = (unit) => {
    return unitGroups.find((item) => {
        return item.options.find((option) => option.value === unit);
    });
};

/**
 * filteredUnitGroups is a list of unit group ids to present, default is null so we will show all groups
 */
export const useUnitGroupsSelectOptions = (filteredUnitGroups) => {
    const groups = useMemo(() => {
        const ret = [];

        unitGroups
            .filter((group) =>
                filteredUnitGroups
                    ? filteredUnitGroups.includes(group.id)
                    : group
            )
            .forEach((group, groupIdx) => {
                ret.push(
                    <ListSubheader key={`unit-${groupIdx}`}>
                        {trans(group.label)}
                    </ListSubheader>
                );

                group.options.forEach((item, itemIdx) => {
                    ret.push(
                        <MenuItem
                            key={`unit-${groupIdx}-${itemIdx}`}
                            value={item.value}
                        >
                            {trans(item.label)}
                        </MenuItem>
                    );
                });
            });

        return ret;
    }, [filteredUnitGroups]);

    return groups;
};
