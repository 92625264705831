import { FC } from "react";
import { green, red } from "@mui/material/colors";
import { Box, Chip, ListItem, ListItemText } from "@mui/material";
import NoImageIcon from "../../../icons/NoImageIcon";
import { isWine } from "helpers/isWine";
import trans from "helpers/trans";
import { ISearchProductListItem } from "../interfaces";
import { formatUnit } from "helpers/units";
import moment from "moment";
import { SupplierType } from "app/Suppliers/types";

const SearchProductListItem: FC<ISearchProductListItem> = ({
    product,
    onClick,
}) => {
    const handleClickProduct = () => {
        if (!!onClick) {
            onClick(product);
        }
    };

    const renderImage = () => {
        let image = <NoImageIcon sx={{ color: "grey.400", fontSize: 35 }} />;

        if (product.images.length > 0) {
            image = (
                <Box
                    component="img"
                    src={product.images[0].url}
                    sx={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                    }}
                />
            );
        }

        return (
            <Box
                sx={{
                    alignItems: "center",
                    display: "flex",
                    flexShrink: 0,
                    height: 50,
                    justifyContent: "center",
                    minWidth: 50,
                    mr: 1,
                    width: 50,
                }}
            >
                {image}
            </Box>
        );
    };

    const renderVintage = () => {
        if (isWine(product.category) && product.vintage)
            return <Chip color="secondary" label={product.vintage} />;
    };

    const renderProductInfo = () => {
        const restaurantProduct = product.restaurant_product;

        const productProducer = () => {
            if (product.producer) {
                return trans(
                    "inv.inventory.component.searchProductListItem.producer",
                    { producer: product.producer }
                );
            }
        };

        const productName = () => {
            if (String(product.unit).toUpperCase() !== "NA" && product.unit) {
                return `${
                    restaurantProduct?.name
                        ? restaurantProduct.name
                        : product.name
                } ${formatUnit(product.size, product.unit, product.show_unit)}`;
            } else {
                return restaurantProduct?.name
                    ? restaurantProduct.name
                    : product.name;
            }
        };

        const productOriginalName = () => {
            if (
                restaurantProduct?.name &&
                restaurantProduct?.name === product.name
            ) {
                return null;
            }

            if (restaurantProduct?.name) return product.name;
        };

        const productSupplier = () => {
            const suppliers = restaurantProduct?.location_suppliers?.map(
                (item: { supplier: SupplierType }) => item.supplier.name
            );

            return suppliers?.length > 0
                ? trans(
                      "inv.inventory.component.searchProductListItem.supplier",
                      { supplier: suppliers?.join(", ") }
                  )
                : null;
        };

        const productEdpAndArticleNumber = () => {
            const articleNumbers = restaurantProduct?.delivery_slip_line_numbers
                ? restaurantProduct.delivery_slip_line_numbers.join(", ")
                : null;

            if (product.epd && articleNumbers?.length > 0) {
                return trans(
                    "inv.inventory.component.searchProductListItem.epdArticleNumber",
                    { epd: product.epd, articleNumber: articleNumbers }
                );
            }

            if (product.epd) {
                return trans(
                    "inv.inventory.component.searchProductListItem.epd",
                    { epd: product.epd }
                );
            }

            if (articleNumbers) {
                return trans(
                    "inv.inventory.component.searchProductListItem.articleNumber",
                    { articleNumber: articleNumbers }
                );
            }
        };

        const productPLU = () => {
            if (restaurantProduct?.plu) {
                return trans(
                    "inv.inventory.component.searchProductListItem.plu",
                    {
                        plu: restaurantProduct.plu,
                    }
                );
            }
        };

        const productPrice = (): string | null => {
            const priceHistory: { price: number; date: string }[] =
                restaurantProduct?.price_history;

            if (priceHistory?.length > 0) {
                const lastPrice = priceHistory[priceHistory.length - 1];
                return trans(
                    "inv.inventory.component.searchProductListItem.lastPrice",
                    {
                        lastPrice: lastPrice?.price.price(),
                        date: moment(lastPrice?.date).format("DD/MM/YYYY"),
                    }
                );
            }

            return null;
        };

        return (
            <Box
                sx={{
                    flexGrow: 1,
                }}
            >
                <ListItemText primary={productName()} />
                <ListItemText
                    secondary={productOriginalName()}
                    secondaryTypographyProps={{
                        fontSize: 12,
                    }}
                />
                <ListItemText
                    secondary={productSupplier()}
                    secondaryTypographyProps={{
                        fontSize: 12,
                    }}
                />
                <ListItemText
                    secondary={productEdpAndArticleNumber()}
                    secondaryTypographyProps={{
                        fontSize: 12,
                    }}
                />
                <ListItemText
                    secondary={productPLU()}
                    secondaryTypographyProps={{
                        fontSize: 12,
                    }}
                />
                <ListItemText
                    secondary={productPrice()}
                    secondaryTypographyProps={{
                        fontSize: 12,
                    }}
                />
                <ListItemText
                    secondary={productProducer()}
                    secondaryTypographyProps={{ fontSize: 12 }}
                />
            </Box>
        );
    };

    return (
        <ListItem
            button
            sx={{
                borderBottomColor: "grey.300",
                borderBottomStyle: "solid",
                borderBottomWidth: 1,
                "&:last-child": {
                    border: "none",
                },
                backgroundColor: !product.restaurant_product
                    ? red[50]
                    : green[50],
                "&:hover": {
                    backgroundColor: !product.restaurant_product
                        ? red[100]
                        : green[100],
                },
            }}
            onClick={handleClickProduct}
        >
            {renderImage()}
            {renderProductInfo()}
            {renderVintage()}
        </ListItem>
    );
};

export default SearchProductListItem;
