import { IIngredients } from "app/Ingredients/interfaces";
import ICategory from "types/ICategory";

export type IngredientModel = {
    id?: number;
    recipe_id?: number;
    ingredient_id: number;
    quantity: number;
    unit: string;
    ingredient: {
        id?: number;
        name: string;
        price: 1000;
        size: 1;
        unit: string;
        show_unit: string;
        allergens: [];
        nutrients: [];
    };
};

export type RecipeFormData = {
    myPrice: number;
    recipeCost: number;
    ingredients?: IIngredients[];
};

export type ingredientFormType = {
    cost: number;
    created_at: string;
    id: number;
    ingredient: {
        id: number;
        name: string;
        price: number;
        allergens: any[];
        nutrients: any[];
        product: any;
        show_unit: string;
        size: number;
        unit: string;
    };
    ingredient_id: number;
    quantity?: string;
    recipe_id: number;
    unit: string;
    updated_at: string;
};

export type formDataType = {
    ingredients: ingredientFormType[];
    recipe_category_id: number;
    name: string;
    portions: number;
    cost: number;
    prices: { tax_id: number; netto: number; gross: number }[];
    extra_cost: number;
    size: number;
    unit: string;
    cooknote: string;
    restaurant_product: {
        plu: string;
        product: {
            category_id: number;
            category: ICategory;
        };
    };
};

export type RecipeModel = {
    id: number;
    recipe_category_id: number;
    name: string;
    cost: number;
    prices: { tax_id: number; netto: number; gross: number }[];
    size: number;
    unit: string;
    portions: number;
    cooknote: string;
    ingredients: IngredientModel[];
    restaurant_product: {
        plu: string;
        product: {
            category_id: number;
            category: ICategory;
        };
    };
};

export interface RecipeToDBModel {
    id?: number;
    recipe_category_id?: number;
    name?: string;
    cost?: number;
    price?: { tax_id: number; netto: number; gross: number }[];
    size?: number;
    unit?: string;
    portions?: number;
    cooknote?: string;
    ingredients?: {
        ingredient_id: number;
        quantity: number;
        unit: string;
    }[];
}

export const FETCH_SUCCESS = "RECIPES/FETCH_SUCCESS";
export const TABLE_UPDATE = "RECIPES/TABLE_UPDATE";

const types = {
    FETCH_SUCCESS,
    FETCH_FAILED: "RECIPES/FETCH_FAILED",
    FETCH_REQUEST: "RECIPES/FETCH_REQUEST",

    UPDATE_FAILED: "RECIPES/UPDATE_FAILED",
    UPDATE_REQUEST: "RECIPES/UPDATE_REQUEST",
    UPDATE_SUCCESS: "RECIPES/UPDATE_SUCCESS",

    CREATE_REQUEST: "RECIPES/CREATE_REQUEST",
    CREATE_SUCCESS: "RECIPES/CREATE_SUCCESS",
    CREATE_FAILED: "RECIPES/CREATE_FAILED",

    DELETE_REQUEST: "RECIPES/DELETE_REQUEST",
    DELETE_SUCCESS: "RECIPES/DELETE_SUCCESS",
    DELETE_FAILED: "RECIPES/DELETE_FAILED",

    DUPLICATE_REQUEST: "RECIPES/DUPLICATE_REQUEST",
    DUPLICATE_FAILED: "RECIPES/DUPLICATE_FAILED",
    DUPLICATE_SUCCESS: "RECIPES/DUPLICATE_SUCCESS",

    GET_FAILED: "RECIPES/GET_FAILED",
    GET_REQUEST: "RECIPES/GET_REQUEST",
    GET_SUCCESS: "RECIPES/GET_SUCCESS",

    TABLE_UPDATE,
};

export default types;
