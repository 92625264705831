import { AxiosRequestConfig } from "axios";
import { request } from "api/actions";
import types, { ProductModel } from "./types";
import { ProductUpdateModel } from "types/productType";

export const productsCreate = (data: ProductModel, onResponse?: Function) =>
    request(
        types.STORE_REQUEST,
        types.STORE_FAILED,
        types.STORE_SUCCESS,
        "productsCreate",
        {
            data,
        },
        onResponse
    );

export const productsDelete = (id: number, onResponse?: Function) =>
    request(
        types.DESTROY_REQUEST,
        types.DESTROY_FAILED,
        types.DESTROY_SUCCESS,
        "productsDelete",
        {
            url: {
                id,
            },
        },
        onResponse
    );

export const productsFetch = (config?: AxiosRequestConfig, onResponse?: Function) =>
    request(
        types.FETCH_REQUEST,
        types.FETCH_FAILED,
        types.FETCH_SUCCESS,
        "productsFetch",
        {
            config,
        },
        onResponse
    );

export const productsGet = (id: number) =>
    request(
        types.GET_REQUEST,
        types.GET_FAILED,
        types.GET_SUCCESS,
        "productsGet",
        {
            url: {
                id,
            },
        }
    );

export const productsTableUpdate = (action: string, data: any) => ({
    type: types.TABLE_UPDATE,
    action,
    data,
});

export const productsUpdate = (
    id: number,
    data: ProductUpdateModel,
    onResponse?: Function
) => {
    return request(
        types.UPDATE_REQUEST,
        types.UPDATE_FAILED,
        types.UPDATE_SUCCESS,
        "productsUpdate",
        { data, url: { id } },
        onResponse
    );
};

type ProductVintageData = {
    vintage: number;
};
export const productVintage = (
    productId: number,
    data: ProductVintageData,
    onResponse?: Function
) =>
    request(
        types.VINTAGE_REQUEST,
        types.VINTAGE_FAILED,
        types.VINTAGE_SUCCESS,
        "productVintage",
        {
            data,
            url: {
                productId,
            },
        },
        onResponse
    );

export const productSuppliers = (config?: AxiosRequestConfig, onResponse?: Function) =>
    request(
        types.PRODUCT_SUPPLIERS_REQUEST,
        types.PRODUCT_SUPPLIERS_FAILED,
        types.PRODUCT_SUPPLIERS_SUCCESS,
        "productSuppliers",
        {
            config,
        }, 
        onResponse
    );
