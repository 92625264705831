export const FETCH_REQUEST = "MENU/FETCH_REQUEST";
export const FETCH_SUCCESS = "MENU/FETCH_SUCCESS";
export const FETCH_FAILED = "MENU/FETCH_FAILED";

export const CREATE_REQUEST = "MENU/CREATE_REQUEST";
export const CREATE_SUCCESS = "MENU/CREATE_SUCCESS";
export const CREATE_FAILED = "MENU/CREATE_FAILED";

export const GET_REQUEST = "MENU/GET_REQUEST";
export const GET_SUCCESS = "MENU/GET_SUCCESS";
export const GET_FAILED = "MENU/GET_FAILED";

export const UPDATE_REQUEST = "MENU/UPDATE_REQUEST";
export const UPDATE_SUCCESS = "MENU/UPDATE_SUCCESS";
export const UPDATE_FAILED = "MENU/UPDATE_FAILED";

export const DELETE_REQUEST = "MENU/DELETE_REQUEST";
export const DELETE_SUCCESS = "MENU/DELETE_SUCCESS";
export const DELETE_FAILED = "MENU/DELETE_FAILED";

export const DUPLICATE_REQUEST = "MENU/DUPLICATE_REQUEST";
export const DUPLICATE_SUCCESS = "MENU/DUPLICATE_SUCCESS";
export const DUPLICATE_FAILED = "MENU/DUPLICATE_FAILED";

export const TABLE_UPDATE = "MENU/TABLE_UPDATE";

const types = {
    FETCH_REQUEST,
    FETCH_SUCCESS,
    FETCH_FAILED,

    CREATE_REQUEST,
    CREATE_SUCCESS,
    CREATE_FAILED,

    UPDATE_REQUEST,
    UPDATE_SUCCESS,
    UPDATE_FAILED,

    DELETE_REQUEST,
    DELETE_SUCCESS,
    DELETE_FAILED,

    DUPLICATE_REQUEST,
    DUPLICATE_SUCCESS,
    DUPLICATE_FAILED,

    GET_REQUEST,
    GET_SUCCESS,
    GET_FAILED,

    TABLE_UPDATE,
};

export default types;
