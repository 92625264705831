import IngredientsSaga from "./saga";
import store from "rootStore";
import { registerModals } from "modals";
import IngredientsSearchModal from "./modals/IngredientsSearchModal";

export const permissionKey = "location.ingredients";
export const url = "/location/ingredients";

const getConfig = (permissions: Array<string>) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );
    const config = {
        permissions: {
            read: true,
        },
    };
    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;
        store.injectSaga?.("IngredientsSaga", IngredientsSaga);

        registerModals({
            IngredientsSearchModal,
        });
    }
    return config;
};

export default getConfig;
