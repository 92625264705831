import {
    inventoryCountProductChangeVintage,
    inventoryProductsCreate,
    inventoryProductsUpdate,
} from "../actions";
import Counter from "../components/Counter";
import {
    counterViewType,
    IInventoryCountModal,
    TInventoryProductSubquantity,
} from "../interfaces";

import { Box, Chip, Icon, Stack, Typography } from "@mui/material";
import { modalHide, modalShow } from "app/App/actions";
import useRequest from "api/useRequest";
import { AxiosResponse } from "axios";
import { isWine } from "helpers/isWine";
import { convertUnit, formatUnit, getUnitGroupByUnit } from "helpers/units";
import trans from "helpers/trans";
import NoImageIcon from "icons/NoImageIcon";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, LoadingButton, Modal, ModalActions, ModalContent } from "ui";
import { v4 } from "uuid";
import { productModel } from "types/productType";
import CounterUnit from "../components/CounterUnit";
import CounterSubquantity from "../components/CounterSubquantity";

const initSubquantities = (
    subquantities: TInventoryProductSubquantity[],
    addQuantity: boolean,
    quantity: number,
    product: productModel,
    defaultCountingUnit: string
): TInventoryProductSubquantity[] => {
    if (addQuantity || !Array.isArray(subquantities)) {
        const unit =
            defaultCountingUnit === "METRIC" ? product.show_unit : "UNIT";

        let subquantity: TInventoryProductSubquantity = {
            id: v4(),
            subquantity: quantity,
            unit,
        };

        if (defaultCountingUnit === "METRIC") {
            subquantity = {
                ...subquantity,
                subquantity: convertUnit(
                    subquantity.subquantity * product.size,
                    product.unit,
                    unit
                ),
            };
        }

        return [subquantity];
    }

    return subquantities.map((item) => ({
        ...item,
        id: String(item.id),
    }));
};

const generateSubquantity = (
    quantity: number,
    defaultCountingUnit: string,
    productUnit: string
) => {
    let subquantity: TInventoryProductSubquantity = {
        id: v4(),
        subquantity: quantity,
        unit: "UNIT",
    };

    if (defaultCountingUnit === "METRIC") {
        subquantity = {
            ...subquantity,
            unit: productUnit,
        };
    }

    return subquantity;
};

const InventoryCountModal: FC<IInventoryCountModal> = ({
    addQuantity,
    areaId,
    gtin,
    inventoryId,
    inventoryProductId,
    product,
    subquantities,
    ...props
}) => {
    const dispatch = useDispatch();

    const { isLoading: productStoreIsLoading, request: productStoreRequest } =
        useRequest();
    const { isLoading: productUpdateIsLoading, request: productUpdateRequest } =
        useRequest();

    const {
        show_total_cost_on_inventory_product_modal,
        show_stock_on_counting,
    } = useSelector((state: any) => state.app.settings.location_settings);

    const selectDefaultQuantity = () => {
        if (gtin) {
            const productGtin = product.gtin.find(
                (item: any) => item.gtin === gtin
            );

            const quantity = productGtin
                ? (productGtin.package_quantity as number)
                : 1.0;

            return quantity;
        }

        return 1.0;
    };

    const [quantity, setQuantity] = useState(
        addQuantity ? selectDefaultQuantity() : props.quantity
    );

    const defaultCountingUnit = useSelector(
        (state: any) =>
            state.app.settings.categories[product.category_id ?? 16]
                .default_counting_unit
    );

    const defaultUnit = product.category.default_unit || product.show_unit;

    const [subQuantitiesData, setSubQuantites] = useState<
        TInventoryProductSubquantity[]
    >(
        initSubquantities(
            subquantities,
            addQuantity,
            selectDefaultQuantity(),
            product,
            defaultCountingUnit
        )
    );
    const [activeSubquantity, setActiveSubquantity] = useState("");

    useEffect(() => {
        if (activeSubquantity === "") {
            setActiveSubquantity(String(subQuantitiesData[0].id));
        }

        setQuantity(
            subQuantitiesData.reduce(
                (total: number, item: TInventoryProductSubquantity) => {
                    let quantity = 0;

                    if (item.unit === "UNIT") {
                        quantity = item.subquantity;
                    } else {
                        quantity =
                            convertUnit(
                                item.subquantity,
                                item.unit,
                                product.unit
                            ) / product.size;
                    }

                    return total + quantity;
                },
                0
            )
        );
    }, [subQuantitiesData, activeSubquantity, product]);

    const [counterView, setCounterView] = useState<counterViewType>("keyboard");

    const handleUpdateQuantity = useCallback(
        (val: number) => {
            setSubQuantites((prev) =>
                prev.map((item) => {
                    if (item.id === activeSubquantity) {
                        return {
                            ...item,
                            subquantity: val,
                        };
                    }

                    return item;
                })
            );
        },
        [activeSubquantity]
    );

    const activeSubquantityData = useMemo(() => {
        return subQuantitiesData.find((item) => item.id === activeSubquantity);
    }, [activeSubquantity, subQuantitiesData]);

    if (!!!activeSubquantityData) {
        return null;
    }

    const handleChangeVintage = (product: any) => {
        dispatch(modalHide("InventoryCountModal"));

        if (inventoryProductId && !addQuantity) {
            dispatch(
                inventoryCountProductChangeVintage(
                    handleClose,
                    product,
                    inventoryProductId,
                    true
                )
            );
        } else {
            dispatch(
                inventoryCountProductChangeVintage(
                    handleClose,
                    product,
                    inventoryProductId
                )
            );
        }
    };

    const handleChangeUnit = (unit: string) => {
        setSubQuantites((prev) =>
            prev.map((item) => {
                if (item.id === activeSubquantity) {
                    return {
                        ...item,
                        unit,
                    };
                }

                return item;
            })
        );
        setCounterView("keyboard");
    };

    const handleAddSubquantity = () => {
        const subquantity = generateSubquantity(
            counterView === "keyboard" ? 1 : 0,
            defaultCountingUnit,
            counterView === "keyboard" ? product.show_unit : "UNIT"
        );
        setSubQuantites((prev) => [...prev, subquantity]);
        setActiveSubquantity(String(subquantity.id));
    };

    const handleChangeActive = (uuid: string) => {
        setActiveSubquantity(uuid);
        setCounterView("keyboard");
    };

    const handleDelete = (uuidToDelete: string) => {
        setCounterView("keyboard");

        if (uuidToDelete === activeSubquantity) {
            setActiveSubquantity("");
        }

        if (subQuantitiesData.length === 1) {
            setSubQuantites([
                generateSubquantity(1, defaultCountingUnit, product.show_unit),
            ]);
            return;
        }

        setSubQuantites((prev) => {
            return prev.filter(
                (item: TInventoryProductSubquantity) => item.id !== uuidToDelete
            );
        });
    };

    const handleClickSave = () => {
        const gtinObject = product.gtin.find(
            (gtinItem: any) => gtinItem.gtin === gtin
        );

        if (!!inventoryProductId) {
            productUpdateRequest(
                inventoryProductsUpdate(
                    inventoryId,
                    inventoryProductId,
                    {
                        area_id: areaId,
                        product_id: product.id,
                        restaurant_product_id: product.restaurant_product?.id,
                        product_gtin_id: gtinObject?.id,
                        vintage: product.vintage,
                        subquantities: subQuantitiesData.map((item) => ({
                            ...item,
                            id: !isNaN(Number(item.id))
                                ? Number(item.id)
                                : null,
                        })),
                    },
                    (response: AxiosResponse) => {
                        if (response.status === 200) {
                            handleClose();
                        }
                    }
                )
            );
        } else {
            productStoreRequest(
                inventoryProductsCreate(
                    inventoryId,
                    {
                        area_id: areaId,
                        product_id: product.id,
                        restaurant_product_id: product.restaurant_product?.id,
                        product_gtin_id: gtinObject?.id,
                        vintage: product.vintage,
                        subquantities: subQuantitiesData
                            .filter(
                                (item: TInventoryProductSubquantity) =>
                                    item.subquantity !== 0
                            )
                            .map((item) => ({
                                ...item,
                                id: Number.isInteger(item.id) ? item.id : null,
                            })),
                    },
                    (response: AxiosResponse) => {
                        if (
                            response.status === 200 ||
                            response.status === 201
                        ) {
                            handleClose();
                        }
                    }
                )
            );
        }
    };

    const handleClickVintage = () => {
        dispatch(
            modalShow("ProductsVintageListModal", {
                onChange: handleChangeVintage,
                product,
            })
        );
    };

    const handleClose = () => {
        dispatch(modalHide("InventoryCountModal", { showSearch: true }));
    };

    const handleSwitchSlider = (view?: counterViewType) => {
        let finalView: counterViewType = "keyboard";
        if (view) {
            finalView = view;
        } else {
            finalView = counterView === "keyboard" ? "slider" : "keyboard";
        }

        if (finalView === "slider") {
            if (
                !(
                    (activeSubquantityData.unit === "UNIT" ||
                        getUnitGroupByUnit(activeSubquantityData.unit)?.id ===
                            "counter") &&
                    activeSubquantityData.subquantity <= 1
                )
            ) {
                const subquantity = generateSubquantity(
                    0,
                    defaultCountingUnit,
                    "UNIT"
                );
                setSubQuantites((prev) => [...prev, subquantity]);
                setActiveSubquantity(String(subquantity.id));
            }
        }

        setCounterView(finalView);
    };

    const renderImage = () => {
        if (counterView === "keyboard") {
            if (product.images.length === 0) {
                return (
                    <Box
                        sx={{
                            alignItems: "center",
                            display: "flex",
                            height: 200,
                            justifyContent: "center",
                            width: "100%",
                        }}
                    >
                        <NoImageIcon
                            sx={{ color: "grey.300", fontSize: 150 }}
                        />
                    </Box>
                );
            }
            return (
                <Box
                    sx={{
                        height: 200,
                        textAlign: "center",
                        width: "100%",
                    }}
                >
                    <Box
                        component="img"
                        src={product.images[0].url}
                        sx={{
                            maxHeight: "100%",
                            maxWidth: "100%",
                        }}
                    />
                </Box>
            );
        }
    };

    const renderVintage = () => {
        if (isWine(product.category)) {
            return (
                <Stack justifyContent="end" direction="row" sx={{ p: 1 }}>
                    <Chip
                        color="secondary"
                        label={
                            product.vintage
                                ? product.vintage
                                : trans(
                                      "inv.inventory.modal.inventoryCountModal.label"
                                  )
                        }
                        onClick={handleClickVintage}
                    ></Chip>
                </Stack>
            );
        }
    };

    return (
        <Modal
            maxWidth="xs"
            fullWidth
            onClose={handleClose}
            open
            sx={{ zIndex: 1, mt: "56px" }}
        >
            <ModalContent
                sx={{
                    px: 0,
                    py: 1,
                }}
            >
                {renderVintage()}
                <Stack alignItems="center">
                    {renderImage()}
                    <Typography fontWeight={500}>{product.name}</Typography>
                    <Typography>
                        {trans("inv.inventory.modal.inventoryCountModal.size")}
                        {formatUnit(
                            product.size,
                            product.unit,
                            product.show_unit
                        )}
                    </Typography>
                    {show_total_cost_on_inventory_product_modal &&
                        !!product.restaurant_product && (
                            <Typography>
                                {trans(
                                    "inv.inventory.modal.inventoryCountModal.cost"
                                )}
                                {(
                                    quantity *
                                    product?.restaurant_product?.price
                                ).price("NOK")}
                            </Typography>
                        )}
                    {show_stock_on_counting && !!product.restaurant_product && (
                        <Typography>
                            {trans(
                                "inv.inventory.modal.inventoryCountModal.stock"
                            )}
                            {product.restaurant_product.stock}
                        </Typography>
                    )}
                </Stack>
            </ModalContent>
            <ModalActions
                sx={{
                    flexDirection: "column",
                    justifyContent: "center",
                    p: 0,
                }}
            >
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mx={0.5}
                    gap={0.5}
                    flexWrap="wrap"
                >
                    {subQuantitiesData.map(
                        (item: TInventoryProductSubquantity) => (
                            <CounterSubquantity
                                key={`subquantity-${item.id}`}
                                active={item.id === activeSubquantity}
                                item={item}
                                onClick={handleChangeActive}
                                onDelete={handleDelete}
                            />
                        )
                    )}
                </Box>
                <Box my={1}>
                    <CounterUnit
                        productUoM={defaultUnit}
                        unit={activeSubquantityData.unit}
                        onChange={handleChangeUnit}
                    />
                </Box>
                <Counter
                    key={`counter-${activeSubquantity}`}
                    addQuantity={addQuantity}
                    item={product}
                    onAddSubquantity={handleAddSubquantity}
                    onSwitch={handleSwitchSlider}
                    onUpdate={handleUpdateQuantity}
                    quantity={activeSubquantityData.subquantity}
                    totalQuantity={quantity}
                    unit={activeSubquantityData.unit}
                    view={counterView}
                />
                <Stack
                    direction="row"
                    sx={{
                        backgroundColor: "grey.200",
                        marginLeft: "0!important",
                        p: 1,
                        width: "100%",
                    }}
                >
                    <Button
                        fullWidth
                        onClick={handleClose}
                        variant="text"
                        sx={{
                            color: "#000",
                        }}
                        startIcon={<Icon>chevron_left</Icon>}
                    >
                        {trans("global.button.back")}
                    </Button>
                    <LoadingButton
                        loading={
                            productStoreIsLoading || productUpdateIsLoading
                        }
                        loadingPosition="start"
                        fullWidth
                        onClick={handleClickSave}
                        startIcon={<Icon>save</Icon>}
                    >
                        {trans("global.button.save")}
                    </LoadingButton>
                </Stack>
            </ModalActions>
        </Modal>
    );
};

export default InventoryCountModal;
