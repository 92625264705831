import { Dialog, DialogProps, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface IGPModal extends DialogProps {}

function GPModal(props: IGPModal) {
    const { children, fullScreen, ...restProps } = props;
    const theme = useTheme();

    let fullScreenProp = useMediaQuery(theme.breakpoints.down("sm"));
    if (fullScreen !== undefined) {
        fullScreenProp = fullScreen;
    }

    return (
        <Dialog {...restProps} fullScreen={fullScreenProp}>
            {children}
        </Dialog>
    );
}

GPModal.defaultProps = {
    // fullScreen: false
};

export default GPModal;
