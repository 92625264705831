import { IProduct } from "../Products/types";

export type IngredientsModel = {
    id?: number;
    name: string;
    nutrients: any;
    price: number;
    product: IProduct;
    show_unit: string;
    size: number;
    unit: string;
    allergens: any;
    location_suppliers: any;
    delivery_slip_line_numbers?: string[];
    price_history?: { price: number; date: string }[];
};
export const FETCH_SUCCESS = "INGREDIENTS/FETCH_SUCCESS";
export const TABLE_UPDATE = "INGREDIENTS/TABLE_UPDATE";

const types = {
    FETCH_FAILED: "INGREDIENTS/FETCH_FAILED",
    FETCH_REQUEST: "INGREDIENTS/FETCH_REQUEST",
    FETCH_SUCCESS,

    TABLE_UPDATE,
};

export default types;
