import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";

const requests = {
    integrate: (data: object, config: AxiosRequestConfig) =>
        api.post(`/modules/Gastrofix/integrate`, data, config),
    sync: (data: object, config: AxiosRequestConfig) =>
        api.post(`/modules/Gastrofix/sync`, data, config),
    update: (data: object, config: AxiosRequestConfig) =>
        api.put(`/modules/Gastrofix`, data, config),
};

export default requests;
