import types, { MOBILE_FILTERS, SHOW_EXPANDED } from "./types";
import { request } from "api/actions";
import { AxiosRequestConfig } from "axios";

export const webMenuFetch = (id: number, config?: AxiosRequestConfig) =>
    request(
        types.FETCH_REQUEST,
        types.FETCH_FAILED,
        types.FETCH_SUCCESS,
        "webMenuFetch",
        {
            url: {
                id,
            },
            config,
        }
    );

export const webMenuGet = (id: number, config?: AxiosRequestConfig) =>
    request(
        types.GET_REQUEST,
        types.GET_FAILED,
        types.GET_SUCCESS,
        "webMenuGet",
        {
            url: {
                id,
            },
            config,
        }
    );

export const webMenuGetSimilar = (id: number, config?: AxiosRequestConfig) =>
    request(
        types.GET_SIMILAR_REQUEST,
        types.GET_SIMILAR_FAILED,
        types.GET_SIMILAR_SUCCESS,
        "webMenuGetSimilar",
        {
            url: {
                id,
            },
            config,
        }
    );

export const webMenuSettings = (id: string, config?: AxiosRequestConfig, onResponse?: Function) =>
    request(
        types.SETTINGS_REQUEST,
        types.SETTINGS_FAILED,
        types.SETTINGS_SUCCESS,
        "webMenuSettings",
        {
            url: {
                id,
            },
            config,
        },
        onResponse
    );

export const webMenuRecommended = (config?: AxiosRequestConfig) =>
    request(
        types.RECOMMENDED_REQUEST,
        types.RECOMMENDED_FAILED,
        types.RECOMMENDED_SUCCESS,
        "webMenuRecommended",
        {
            config,
        }
    );

export const webMenuTableUpdate = (action: string, data: any) => ({
    type: types.TABLE_UPDATE,
    action,
    data,
});

export const webMenuShowExpanded = (data: any) => ({
    type: SHOW_EXPANDED,
    data,
});

export const webMenuMobileFilters = (data: any) => ({
    type: MOBILE_FILTERS,
    data,
});