import api from "./api";
import types from "./types";
import { request } from "api/apiSaga";
import { snackbarShow } from "app/App/actions";
import trans from "helpers/trans";
import { call, put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";

function* destroy(action: AnyAction): any {
    const response = yield call(request, api.destroy, action, "delete");

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("inv.orders.saga.content.destroy"),
            })
        );
    }
}

function* fetch(action: AnyAction) {
    yield call(request, api.fetch, action);
}

function* get(action: AnyAction) {
    yield call(request, api.get, action);
}

function* store(action: AnyAction): any {
    const response = yield call(request, api.store, action, "post");

    if (response.status === 201) {
        yield put(
            snackbarShow({
                content: trans("inv.orders.saga.content.store"),
            })
        );
    }
}

function* update(action: AnyAction): any {
    const response = yield call(request, api.update, action, "put");

    if (response.status === 202) {
        yield put(
            snackbarShow({
                content: trans("inv.orders.saga.content.update"),
            })
        );
    }
}
function* send(action: AnyAction): any {
    const response = yield call(request, api.send, action, "put");

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("inv.orders.saga.content.send"),
            })
        );
    }
    if (response.status === 422) {
        yield put(
            snackbarShow({
                content: response.data.message,
                severity: "error",
            })
        );
    }
}

function* fetchLocations(action: AnyAction) {
    yield call(request, api.fetchLocations, action);
}

function* ordersProposals(action: AnyAction) {
    yield call(request, api.ordersProposals, action);
}

export default function* saga() {
    yield takeLatest(types.DESTROY_REQUEST, destroy);
    yield takeLatest(types.FETCH_REQUEST, fetch);
    yield takeLatest(types.GET_REQUEST, get);
    yield takeLatest(types.STORE_REQUEST, store);
    yield takeLatest(types.UPDATE_REQUEST, update);
    yield takeLatest(types.POST_REQUEST, send);
    yield takeLatest(types.FETCH_LOCATIONS_REQUEST, fetchLocations);
    yield takeLatest(types.ORDERS_PROPOSALS_REQUEST, ordersProposals);
}
