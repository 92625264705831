import { Paper, PaperProps } from "@mui/material";

interface IGPSettings extends PaperProps {}

function GPSettings(props: IGPSettings) {
    const { children, ...restProps } = props;

    return <Paper {...restProps}>{props.children}</Paper>;
}

export default GPSettings;
