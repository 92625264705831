import DeliverySlipsTable from "./reducers/DeliverySlipsTableReducer";
import DeliverySlipsApproveTable from "./reducers/DeliverySlipsApproveTableReducer";
import DeliverySlipApproveDetailsTable from "./reducers/DeliverySlipsApproveDetailsTableReducer";
import DeliverySlipDetailsTable from "./reducers/DeliverySlipDetailsTableReducer";
import DeliverySlipsSaga from "./saga";
import AsyncComponent from "ui/AsyncComponent";
import { registerModals } from "modals";
import { registerRoutings } from "rootRouting";
import store from "rootStore";
import { routingType, configType } from "types/configTypes";
import DeliverySlipModal from "./modals/DeliverySlipModal";
import DeliverySlipApproveModal from "./modals/DeliverySlipApproveModal";
import DeliverySlipApproveDateModal from "./modals/DeliverySlipApproveDateModal";
import AddSupplierModal from "./modals/AddSupplierModal"
import AddProductModal from "./modals/AddProductModal"

export const permissionKey = "delivery.slips";
export const url = "/delivery-slips";
export const approveUrl = `${url}/approve`;

interface IConfig extends Omit<configType, "permissions"> {
    permissions: {
        approve_all: boolean;
        create: boolean;
        read: boolean;
        readIndexPage: boolean;
        readApprovementPage: boolean;
        update: boolean;
    };
}

const getConfig = (permissions: string[]) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: IConfig = {
        permissions: {
            approve_all: false,
            create: false,
            read: false,
            readIndexPage: false,
            readApprovementPage: false,
            update: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];
    if (modulePermissions.indexOf(permissionKey + ".approve_all") !== -1) {
        config.permissions.approve_all = true;
    }

    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;

        store.injectSaga?.("DeliverySlipsSaga", DeliverySlipsSaga);
    }

    if (
        modulePermissions.indexOf(permissionKey + ".approvementPage") !== -1 &&
        modulePermissions.indexOf(permissionKey + ".read") !== -1
    ) {
        config.permissions.readApprovementPage = true;

        store.reducerManager?.add(
            "DeliverySlipsApproveTable",
            DeliverySlipsApproveTable
        );

        routing.push({
            path: approveUrl,
            element: (
                <AsyncComponent path="DeliverySlips/pages/DeliverySlipsApprovePage" />
            ),
        });
    }

    if (
        modulePermissions.indexOf(permissionKey + ".read") !== -1 &&
        modulePermissions.indexOf(permissionKey + ".indexPage") !== -1
    ) {
        config.permissions.readIndexPage = true;

        registerModals({
            DeliverySlipModal,
        });

        store.reducerManager?.add("DeliverySlipsTable", DeliverySlipsTable);
        store.reducerManager?.add(
            "DeliverySlipLines",
            DeliverySlipDetailsTable
        );

        routing.push({
            path: url,
            element: (
                <AsyncComponent path="DeliverySlips/pages/DeliverySlipsIndexPage" />
            ),
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
        config.permissions.update = true;

        registerModals({
            DeliverySlipApproveModal,
            DeliverySlipApproveDateModal,
        });

        store.reducerManager?.add(
            "DeliverySlipLinesApprove",
            DeliverySlipApproveDetailsTable
        );
    }

    if (modulePermissions.indexOf(permissionKey + ".create") !== -1) {
        config.permissions.create = true;
        registerModals({
            AddSupplierModal,
            AddProductModal
        });
        routing.push({
            path: `${url}/create`,
            element: (
                <AsyncComponent path="DeliverySlips/pages/DeliverySlipsCreatePage" />
            ),
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }
    return config;
};

export default getConfig;
