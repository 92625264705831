import TextField from "@mui/material/TextField";
import React, { forwardRef, useContext, useState } from "react";
import { TextFieldProps } from "@mui/material/TextField";

import FormContext from "./FormContext";
import { getHelperText, getValue, regexValue } from "./helpers";
import { Box, Tab, Tabs } from "@mui/material";
import trans from "helpers/trans";
import { useSelector } from "react-redux";
import { sortBy } from "lodash";
export interface IGPTranslationsTextField
    extends Omit<TextFieldProps, "onChange"> {
    errorText?: string;
    id?: string;
    onChange?: Function;
    regex?: string;
    prefixId: string;
}

const GPTranslationsTextField = forwardRef<any, IGPTranslationsTextField>(
    (props, ref) => {
        const {
            errorText,
            helperText,
            onChange,
            regex,
            value,
            prefixId,
            ...restProps
        } = props;
        const [tabsValue, setTabsValue] = useState<number>(0);
        const defaultLang = useSelector(
            (state: any) => state.app.settings.location_settings.default_lang
        );
        const languages = sortBy(["en", "nb"], (lang) =>
            lang === defaultLang ? 0 : 1
        );
        const id = `${prefixId}_${languages[tabsValue]}`;
        const handleChangeTabs = (
            event: React.SyntheticEvent,
            newValue: number
        ) => {
            setTabsValue(newValue);
        };
        const context = useContext(FormContext);

        const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
            const val = regexValue(id, context, regex, event.target.value);

            if (!!onChange) {
                onChange({ id, value: val }, context);
            } else {
                context?.onChange({ id, value: val });
            }
        };

        const { inputHelperText, isError } = getHelperText(
            id,
            context,
            errorText,
            helperText
        );

        return (
            <>
                <Tabs
                    value={tabsValue}
                    onChange={handleChangeTabs}
                    centered
                    variant="fullWidth"
                >
                    {languages.map((lang: string, idx: number) => {
                        return (
                            <Tab
                                key={`tab-${lang}-${idx}`}
                                label={trans(`global.lang.${lang}`)}
                            />
                        );
                    })}
                </Tabs>
                <Box key={tabsValue} mt={2}>
                    <TextField
                        {...restProps}
                        error={isError}
                        helperText={inputHelperText}
                        onChange={onChangeValue}
                        ref={ref}
                        value={getValue(id, context, value)}
                    />
                </Box>
            </>
        );
    }
);

GPTranslationsTextField.defaultProps = {
    errorText: "",
    helperText: "",
    size: "small",
};

export default GPTranslationsTextField;
