import { Icon, IconButton, Box, InputAdornment } from "@mui/material";
import trans from "helpers/trans";
import { get } from "lodash";
import { FC } from "react";
import { NumberField, Table, TextField } from "ui";
import { IGrapesTable } from "../interfaces";

const GrapesTable: FC<IGrapesTable> = ({ context, disabled = false, id }) => {
    const handleDeleteRow = (itemIdx: number) => {
        context.onChange([
            {
                id,
                value: get(context.data, id, []).filter(
                    (item: string, idx: number) => idx !== itemIdx
                ),
            },
        ]);
    };

    const renderGrapes = (item: string, idx: number) => {
        return (
            <Box sx={{ width: "100%", minWidth: "200px", display: "flex" }}>
                <TextField
                    id={`${id}.${idx}.name`}
                    variant="outlined"
                    fullWidth
                    size="small"
                    disabled={disabled}
                />
            </Box>
        );
    };

    const renderPercent = (item: string, idx: number) => {
        return (
            <Box sx={{ width: "100%", minWidth: "200px", display: "flex" }}>
                <NumberField
                    id={`${id}.${idx}.percent`}
                    fullWidth
                    variant="outlined"
                    size="small"
                    disabled={disabled}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                        ),
                    }}
                />
            </Box>
        );
    };
    return (
        <Table
            calculateHeight={false}
            id="GrapesTable"
            calculateWidth={true}
            columns={[
                {
                    field: "name",
                    headName: trans(
                        "inv.restaurantProducts.form.restaurantProductsForm.label.grapes"
                    ),
                    cellProps: {
                        align: "left",
                        size: "small",
                        sx: { whiteSpace: "nowrap" },
                    },
                    renderCell: (item, column, idx) => ({
                        children: renderGrapes(item, idx),
                    }),
                },
                {
                    field: "percent",
                    headName: trans(
                        "inv.restaurantProducts.form.restaurantProductsForm.label.percent"
                    ),
                    cellProps: {
                        align: "left",
                        size: "small",
                        sx: { whiteSpace: "nowrap" },
                    },
                    renderCell: (item, column, idx) => ({
                        children: renderPercent(item, idx),
                    }),
                },
                {
                    field: "actions",
                    renderCell: (item, column, idx) => {
                        return {
                            children: (
                                <IconButton
                                    size="small"
                                    disabled={disabled}
                                    onClick={() => handleDeleteRow(idx)}
                                >
                                    <Icon>delete</Icon>
                                </IconButton>
                            ),
                        };
                    },
                },
            ]}
            rows={get(context.data, id, [])}
        />
    );
};

export default GrapesTable;
