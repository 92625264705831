import { registerRoutings } from "rootRouting";
import LocationSuppliersTable from "./reducers/LocationSuppliersTableReducer";
import LocationSuppliersSaga from "./saga";
import { url as settingsUrl } from "app/Settings/config";
import { permissionsType, routingType, configType } from "types/configTypes";
import store from "rootStore";
import AsyncComponent from "ui/AsyncComponent";

export const url = `${settingsUrl}/suppliers`;
export const permissionKey = "location.supplier";

const getConfig = (permissions: permissionsType) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: configType = {
        permissions: {
            create: false,
            delete: false,
            read: false,
            update: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (modulePermissions.indexOf(permissionKey + ".delete") !== -1) {
        config.permissions.delete = true;
    }

    if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
        config.permissions.update = true;

        routing.push({
            path: `${url}/:id/edit`,
            element: (
                <AsyncComponent path="LocationSuppliers/pages/LocationSuppliersEditPage" />
            ),
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;

        store.reducerManager?.add(
            "LocationSuppliersTable",
            LocationSuppliersTable
        );

        store.injectSaga?.("LocationSuppliersSaga", LocationSuppliersSaga);

        routing.push({
            path: url,
            element: (
                <AsyncComponent path="LocationSuppliers/pages/LocationSuppliersIndexPage" />
            ),
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".create") !== -1) {
        config.permissions.create = true;

        routing.push({
            path: `${url}/create`,
            element: (
                <AsyncComponent path="LocationSuppliers/pages/LocationSuppliersCreatePage" />
            ),
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
