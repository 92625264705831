import ReportsSaga from "./saga";
import AsyncComponent from "ui/AsyncComponent";
import { registerRoutings } from "rootRouting";
import store from "rootStore";

interface IConfig {
    permissions: {
        read: boolean;
        "compareInventories.read": boolean;
        "costVariance.read": boolean;
    };
    routing: Array<string>;
    url: string;
}

export const permissionKey = "reports";

export const permissionKeys = {
    compareInventoriesPermissionKey: "report.compareInventories",
    costVariancePermissionKey: "report.costAndVariance",
};

export const urls = {
    baseUrl: "/reports",
    compareInventoriesUrl: "/reports/compare-inventories",
    costVarianceUrl: "/reports/cost-and-variance",
};

const getConfig = (permissions: Array<string>) => {
    const compareInventoriesPermission = permissions.filter(
        (item) =>
            item.indexOf(permissionKeys.compareInventoriesPermissionKey) === 0
    );
    const costVariancePermission = permissions.filter(
        (item) => item.indexOf(permissionKeys.costVariancePermissionKey) === 0
    );

    const url = urls.baseUrl;

    const config: IConfig = {
        permissions: {
            read: false,
            "compareInventories.read": false,
            "costVariance.read": false,
        },
        routing: [],
        url,
    };

    let routing = [];

    if (
        compareInventoriesPermission.indexOf(
            permissionKeys.compareInventoriesPermissionKey + ".read"
        ) !== -1
    ) {
        config.permissions["compareInventories.read"] = true;
        if (!config.permissions.read) config.permissions.read = true;

        store.injectSaga?.("ReportSaga", ReportsSaga);

        routing.push({
            path: urls.compareInventoriesUrl,
            element: (
                <AsyncComponent path="Reports/pages/CompareInventoriesIndexPage" />
            ),
        });
    }

    if (
        costVariancePermission.indexOf(
            permissionKeys.costVariancePermissionKey + ".read"
        ) !== -1
    ) {
        config.permissions["costVariance.read"] = true;
        if (!config.permissions.read) config.permissions.read = true;

        store.injectSaga?.("ReportSaga", ReportsSaga);

        routing.push({
            path: urls.costVarianceUrl,
            element: (
                <AsyncComponent path="Reports/pages/CostVarianceIndexPage" />
            ),
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
