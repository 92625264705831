import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlId from "types/RequestUrlId";

const requests = {
    fetch: (config: AxiosRequestConfig) =>
        api.get(`/modules`, config),
    activate: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.post(`/modules/${url.id}/activate`, config),
    destroy: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.delete(`/modules/${url.id}`, config),
};

export default requests;
