import { IAllergens } from "../../Products/types";
import {
    glutenList,
    listOfAllergens,
    nutsList,
} from "app/Products/data/ProductAllergensData";
import { IProductAllergens } from "../interfaces";

import { Icon, IconButton } from "@mui/material";
import trans from "helpers/trans";
import { get } from "lodash";
import { FC, useState } from "react";
import { SX } from "types/SX";
import { Checkbox, Table } from "ui";

export const defaultAllergens: IAllergens = {
    gluten: null,
    wheat_gluten: null,
    spelled_gluten: null,
    khorasan_wheat_gluten: null,
    rye_gluten: null,
    bygg_gluten: null,
    oat_gluten: null,
    shellfish: null,
    egg: null,
    fish: null,
    soya: null,
    milk: null,
    nuts: null,
    peanuts: null,
    almonds: null,
    hazelnuts: null,
    walnuts: null,
    cashew_nuts: null,
    pecan_nuts: null,
    brazil_nuts: null,
    pistachios: null,
    macadamia_nuts: null,
    celery: null,
    mustard: null,
    sesame_seeds: null,
    sulfur_dioxide_or_sulfites: null,
    lupins: null,
    molluscs: null,
};

const AllergensTable: FC<IProductAllergens> = ({ context, id }) => {
    const [openGluten, setOpenGluten] = useState(false);
    const [openNuts, setOpenNuts] = useState(false);

    const handleChangeCheckbox = ({
        id,
        value,
    }: {
        id: string;
        value: boolean;
        event: React.ChangeEvent<HTMLInputElement>;
    }) => {
        context.onChange([
            {
                id,
                value: value,
            },
        ]);
    };

    const rowsPrepare = (rows: Array<string>) => {
        if (!openGluten) {
            rows = rows.filter((value: string) => !glutenList.includes(value));
        }

        if (!openNuts) {
            rows = rows.filter((value: string) => !nutsList.includes(value));
        }

        return rows;
    };

    const renderCollapse = (item: string) => {
        if (item === "gluten") {
            return (
                <>
                    {trans(`global.allergensList.${item}`)}
                    <IconButton size="small" sx={{ p: 0 }}>
                        <Icon sx={{ fontSize: 16 }}>
                            {openGluten ? "expand_less" : "expand_more"}
                        </Icon>
                    </IconButton>
                </>
            );
        } else if (item === "nuts") {
            return (
                <>
                    {trans(`global.allergensList.${item}`)}
                    <IconButton size="small" sx={{ p: 0 }}>
                        <Icon sx={{ fontSize: 16 }}>
                            {openNuts ? "expand_less" : "expand_more"}
                        </Icon>
                    </IconButton>
                </>
            );
        }

        return trans(`global.allergensList.${item}`);
    };

    return (
        <>
            <Table
                calculateHeight={false}
                id="AllergensTable"
                onClickRow={(e, item) =>
                    item === "gluten"
                        ? setOpenGluten((prev) => !prev)
                        : item === "nuts"
                        ? setOpenNuts((prev) => !prev)
                        : undefined
                }
                columns={[
                    {
                        field: "",
                        headName: trans(
                            "inv.restaurantProducts.table.allergensTable.headName.allergens"
                        ),
                        cellProps: {
                            size: "small",
                        },
                        renderCell: (item) => {
                            let sx: SX = { whiteSpace: "nowrap" };

                            if (
                                item.includes("_gluten") ||
                                (item.includes("nuts") && item !== "nuts") ||
                                item === "almonds" ||
                                item === "pistachios"
                            ) {
                                sx.paddingLeft = 4;
                            }
                            return {
                                children: renderCollapse(item),
                                sx,
                            };
                        },
                    },
                    {
                        field: "",
                        headName: trans(
                            "inv.restaurantProducts.table.allergensTable.headName.yes"
                        ),
                        cellProps: {
                            align: "center",
                            size: "small",
                            sx: {
                                verticalAlign: "middle",
                            },
                            width: 40,
                        },
                        renderCell: (item, idx) => ({
                            children: (
                                <Checkbox
                                    label=""
                                    id={`product[allergens].${item}`}
                                    key={`product[allergens]-${item}`}
                                    value={true}
                                    checked={
                                        get(context.data, id, [])[item] === true
                                    }
                                    onChange={handleChangeCheckbox}
                                    onClick={(event) => event.stopPropagation()}
                                    checkedIcon={
                                        <Icon
                                            fontSize="small"
                                            sx={{
                                                display: "block",
                                                margin: "0 auto",
                                                color: "red",
                                            }}
                                        >
                                            circle
                                        </Icon>
                                    }
                                />
                            ),
                        }),
                    },
                    {
                        field: "",
                        headName: trans(
                            "inv.restaurantProducts.table.allergensTable.headName.no"
                        ),
                        cellProps: {
                            align: "center",
                            size: "small",
                            sx: {
                                verticalAlign: "middle",
                            },
                            width: 40,
                        },
                        renderCell: (item, idx) => ({
                            children: (
                                <Checkbox
                                    label=""
                                    id={`product[allergens].${item}`}
                                    key={`product[allergens]-${item}`}
                                    value={false}
                                    checked={
                                        get(context.data, id, [])[item] ===
                                        false
                                    }
                                    onChange={handleChangeCheckbox}
                                    onClick={(event) => event.stopPropagation()}
                                    checkedIcon={
                                        <Icon
                                            fontSize="small"
                                            sx={{
                                                display: "block",
                                                margin: "0 auto",
                                                color: "green",
                                            }}
                                        >
                                            circle
                                        </Icon>
                                    }
                                />
                            ),
                        }),
                    },
                    {
                        field: "",
                        headName: trans(
                            "inv.restaurantProducts.table.allergensTable.headName.undisclosed"
                        ),
                        cellProps: {
                            align: "center",
                            size: "small",
                            sx: {
                                verticalAlign: "middle",
                            },
                            width: 40,
                        },
                        renderCell: (item, idx) => ({
                            children: (
                                <Checkbox
                                    label=""
                                    id={`product[allergens].${item}`}
                                    key={`product[allergens]-${item}`}
                                    value={null}
                                    checked={
                                        get(context.data, id, [])[item] === null
                                    }
                                    onChange={handleChangeCheckbox}
                                    onClick={(event) => event.stopPropagation()}
                                    checkedIcon={
                                        <Icon
                                            fontSize="small"
                                            sx={{
                                                display: "block",
                                                margin: "0 auto",
                                                color: "grey.500",
                                            }}
                                        >
                                            circle
                                        </Icon>
                                    }
                                />
                            ),
                        }),
                    },
                ]}
                rows={rowsPrepare(listOfAllergens)}
            />
        </>
    );
};

export default AllergensTable;
