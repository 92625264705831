import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlId from "types/RequestUrlId";
import { TInvetoryReportDownload } from "./types";

const requests = {
    destroy: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.delete(`/inventory/${url.id}`, config),
    fetch: (config: AxiosRequestConfig) => api.get(`/inventory`, config),
    get: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/inventory/${url.id}`, config),
    store: (data: object, config: AxiosRequestConfig) =>
        api.post(`/inventory`, data, config),
    update: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.put(`/inventory/${url.id}`, data, config),
    reopen: (config: AxiosRequestConfig) => api.put("/inventory/reopen", config),
    reports: {
        download: (config: AxiosRequestConfig, url: TInvetoryReportDownload) =>
            api.get(`/inventory/${url.id}/reports/${url.type}`, {
                ...config,
                responseType: "blob",
            }),
    },
};

export default requests;
