import api from "./api";
import types from "./types";
import { request } from "api/apiSaga";
import { call, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";

function* fetch(action: AnyAction) {
    yield call(request, api.fetch, action);
}

export default function* saga() {
    yield takeLatest(types.FETCH_REQUEST, fetch);
}
