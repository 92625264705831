import { url as settingsUrl } from "app/Settings/config";
import { registerRoutings } from "rootRouting";
import store from "rootStore";
import AsyncComponent from "ui/AsyncComponent";
import TaxesTable from "./reducers/TaxesTableReducer";
import SettingsTaxesSaga from "./saga";

interface IConfig {
    permissions: {
        update: boolean;
    };
    routing: string[];
    url: string;
}

export const permissionKey = "settings";

export const url = `${settingsUrl}/taxes`;

const getConfig = (permissions: string[]) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: IConfig = {
        permissions: {
            update: false,
        },
        routing: [],
        url,
    };

    let routing = [];

    if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
        config.permissions.update = true;
        store.injectSaga?.("SettingsTaxesSaga", SettingsTaxesSaga);
        store.reducerManager?.add("TaxesTable", TaxesTable);

        routing.push({
            path: url,
            element: (
                <AsyncComponent path="SettingsTaxes/pages/TaxesIndexPage" />
            ),
        });

        routing.push({
            path: `${url}/create`,
            element: (
                <AsyncComponent path="SettingsTaxes/pages/TaxesCreatePage" />
            ),
        });

        routing.push({
            path: `${url}/:id/edit`,
            element: (
                <AsyncComponent path="SettingsTaxes/pages/TaxesEditPage" />
            ),
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
