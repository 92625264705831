import { permissionsType, configType } from "types/configTypes";
import store from "rootStore";
import SuppliersSaga from "./saga";

export const url = "/suppliers";
export const permissionKey = "supplier";

const getConfig = (permissions: permissionsType) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: configType = {
        permissions: {
            create: false,
            delete: false,
            read: false,
            update: false,
        },
        routing: [],
        url,
    };

    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;

        store.injectSaga?.("SuppliersSaga", SuppliersSaga);
    }

    return config;
};

export default getConfig;
