import { useMemo, useState, useRef, useCallback, FC } from "react";
import { modalHide } from "app/App/actions";
import ProductQuickForm from "app/Products/forms/ProductAddForm/ProductQuickForm";
import ProductAddZeroStep from "../forms/ProductAddForm/ProductAddZeroStep";
import ChooseCategoryStep from "../forms/ProductAddForm/ChooseCategoryStep";
import {
    IProductQuickFormRef,
    ITempData,
    IProductsCreateModal,
    IData,
} from "../interfaces";
import { Icon, Stack, Typography } from "@mui/material";
import {
    LoadingButton,
    Modal,
    ModalActions,
    ModalContent,
    Button,
    ModalTitle,
} from "ui";
import { useDispatch, useSelector } from "react-redux";
import trans from "helpers/trans";

const ProductsCreateModal: FC<IProductsCreateModal> = ({
    gtin,
    onCreated,
}) => {
    const [step, setStep] = useState(0);
    const storeProductRef = useRef<IProductQuickFormRef | null>(null);
    const [isLoading, setLoading] = useState(false);

    const { categories } = useSelector((state: any) => {
        return {
            categories: state.app.settings.categories,
        };
    });

    const [currentCategory, setCurrentCategory] = useState<
        number | undefined
    >();

    const dispatch = useDispatch();

    const data = useMemo(() => {
        let tempData: ITempData = {};

        if (!!gtin) {
            tempData.gtin = [{ gtin, unit_of_measure: 1, package_quantity: 1 }];
        }

        tempData.category_id = currentCategory;

        return tempData;
    }, [gtin, currentCategory]);

    const handleClickNextStep = (chooseStep?: number) => {
        if (chooseStep) {
            setStep(chooseStep);
        } else {
            setStep((prev) => prev + 1);
        }
    };

    const handleOnClose = () => {
        dispatch(modalHide("ProductsCreateModal"));
    };

    const handleStepBack = () => {
        if (currentCategory) {
            const currentCat = categories[currentCategory];
            setCurrentCategory(currentCat.parent_id);
            if (step === 2) {
                setStep(1);
            }
        } else {
            if (step === 1) setStep(0);
        }
    };

    const handleClickStoreProduct = () => {
        storeProductRef.current?.submit();
    };

    const hadleLoading = useCallback((isLoading: boolean) => {
        setLoading(isLoading);
    }, []);

    const handleSaved = (data: IData) => {
        if (!!onCreated) {
            onCreated(data.data);
        }

        dispatch(modalHide("ProductsCreateModal"));
    };

    const stepDisplay = () => {
        switch (step) {
            case 0:
                return (
                    <ProductAddZeroStep
                        handleNextStep={handleClickNextStep}
                        firstStepText={trans(
                            "inv.products.modal.productCreateModal.text.firstStep"
                        )}
                    />
                );
            case 1:
                return (
                    <ChooseCategoryStep
                        current_category_id={currentCategory}
                        handleNextStep={handleClickNextStep}
                        handleChangeCategory={setCurrentCategory}
                    />
                );
            case 2:
                return (
                    <ProductQuickForm
                        data={data}
                        onLoading={hadleLoading}
                        onSaved={handleSaved}
                        ref={storeProductRef}
                    />
                );
            default:
                return null;
        }
    };

    const renderSubtitle = () => {
        return (
            <>
                <Typography color="grey.500" fontSize={14} sx={{ py: 0 }}>
                    {currentCategory
                        ? categories[currentCategory].parent_id
                            ? `${
                                  categories[
                                      categories[currentCategory].parent_id
                                  ].name
                              }/${categories[currentCategory].name}`
                            : categories[currentCategory].name
                        : null}
                </Typography>
                {gtin && (
                    <Typography color="grey.500" fontSize={14} sx={{ p: 0 }}>
                        {trans(
                            "inv.products.modal.productCreateModal.text.gtin",
                            { gtin: gtin }
                        )}
                    </Typography>
                )}
            </>
        );
    };

    return (
        <Modal maxWidth="xs" fullWidth onClose={handleOnClose} open>
            <ModalTitle onClose={handleOnClose}>
                {trans("inv.products.modal.productCreateModal.text.title")}
                {step === 2 && renderSubtitle()}
            </ModalTitle>
            <ModalContent sx={{ px: 2 }}>{stepDisplay()}</ModalContent>
            <ModalActions
                sx={{
                    flexDirection: "column",
                    justifyContent: "center",
                    p: 0,
                }}
            >
                {step > 0 ? (
                    <Stack
                        direction="row"
                        sx={{
                            backgroundColor: "grey.200",
                            marginLeft: "0!important",
                            p: 1,
                            width: "100%",
                        }}
                    >
                        <Button
                            onClick={handleStepBack}
                            variant="text"
                            sx={{
                                color: "#000",
                                mr: 4,
                            }}
                            startIcon={<Icon>chevron_left</Icon>}
                        >
                            {trans("global.button.back")}
                        </Button>
                        {step === 2 ? (
                            <LoadingButton
                                loading={isLoading}
                                loadingPosition="start"
                                fullWidth
                                onClick={handleClickStoreProduct}
                                startIcon={<Icon>save</Icon>}
                            >
                                {trans("global.button.save")}
                            </LoadingButton>
                        ) : null}
                    </Stack>
                ) : null}
            </ModalActions>
        </Modal>
    );
};

export default ProductsCreateModal;
