import { Box, CircularProgress } from "@mui/material";
import { GPUIColor } from "ui/types";

interface ILoader {
    color?: GPUIColor;
    variant?: "global" | "content";
}

function Loader({ color, variant }: ILoader) {
    let position = "fixed";
    if (variant === "content") {
        position = "absolute";
    }

    return (
        <Box
            sx={{
                alignItems: "center",
                backgroundColor: "rgba(255,255,255,.25)",
                display: "flex",
                height: "100%",
                justifyContent: "center",
                left: 0,
                position,
                top: 0,
                width: "100%",
                zIndex: 100,
            }}
        >
            <CircularProgress color={color} />
        </Box>
    );
}

Loader.defaultProps = {
    color: "primary",
    variant: "global",
};

export default Loader;
