import { Box } from "@mui/material";
import useRequest from "api/useRequest";
import { modalHide } from "app/App/actions";
import { suppliersCreate } from "app/LocationSuppliers/actions";
import Form from "app/LocationSuppliers/forms/LocationSuppliersForm";
import { LocationSupplierModel } from "app/LocationSuppliers/types";
import { AxiosResponse } from "axios";
import trans from "helpers/trans";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Button,
    LoadingButton,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
    RequestMessage,
} from "ui";

interface IAddSupplierModal {
    handleOnClose: Function;
}
const AddSupplierModal: FC<IAddSupplierModal> = ({ handleOnClose }) => {
    const dispatch = useDispatch();
    const [submited, setSubmited] = useState<number>(0);

    const handleCloseModal = () => {
        dispatch(modalHide("AddSupplierModal"));
    };
    const { errors, isLoading, message, request, status } = useRequest();

    const handleSubmit = (data: LocationSupplierModel) => {
        request(
            suppliersCreate(data, (response: AxiosResponse) => {
                if (response.status === 201) {
                    handleCloseModal();
                    handleOnClose(response.data.data);
                }
            })
        );
    };

    return (
        <Modal open fullScreen onClose={handleCloseModal}>
            <ModalTitle onClose={handleCloseModal}>
                {" "}
                {trans("inv.deliverySlips.modals.addSupplierModal.create")}
            </ModalTitle>
            <ModalContent>
                <RequestMessage
                    message={message}
                    status={status}
                    sx={{ mb: 1 }}
                />
                <Form
                    data={{} as LocationSupplierModel}
                    errors={errors}
                    loading={isLoading}
                    onSubmit={handleSubmit}
                    unsaved={false}
                    submit={submited}
                />
            </ModalContent>
            <ModalActions>
                <Box display="flex" width="100%" textAlign="right">
                    <Button
                        onClick={handleCloseModal}
                        color="inherit"
                        variant="text"
                        sx={{ mr: 1 }}
                    >
                        {trans("global.button.cancel")}
                    </Button>
                    <LoadingButton
                        sx={{ flex: 1 }}
                        loading={isLoading}
                        onClick={() => setSubmited((prev) => prev + 1)}
                    >
                        {trans(
                            "inv.deliverySlips.modals.addSupplierModal.save"
                        )}
                    </LoadingButton>
                </Box>
            </ModalActions>
        </Modal>
    );
};

export default AddSupplierModal;
