const types = {
    INTEGRATE_FAILED: "GASTROFIX/INTEGRATE_FAILED",
    INTEGRATE_REQUEST: "GASTROFIX/INTEGRATE_REQUEST",
    INTEGRATE_SUCCESS: "GASTROFIX/INTEGRATE_SUCCESS",

    SYNC_FAILED: "GASTROFIX/SYNC_FAILED",
    SYNC_REQUEST: "GASTROFIX/SYNC_REQUEST",
    SYNC_SUCCESS: "GASTROFIX/SYNC_SUCCESS",

    UPDATE_FAILED: "GASTROFIX/UPDATE_FAILED",
    UPDATE_REQUEST: "GASTROFIX/UPDATE_REQUEST",
    UPDATE_SUCCESS: "GASTROFIX/UPDATE_SUCCESS",
};

export default types;
