import {
    IReducerActionProductConnect,
    IReducerStateProductConnect,
} from "../interfaces";

export const restaurantProductConnectReducerInitState: IReducerStateProductConnect =
    {
        target: "",
        atributes: {
            name: { id: 0, value: "" },
            price: { id: 0, value: 0 },
            market_price: { id: 0, value: 0 },
            plu: { id: 0, value: NaN },
            min_in_stock: { id: 0, value: 0 },
            quantity_to_be_ordered: { id: 0, value: 0 },
        },
    };

function restaurantProductConnectReducer(
    state: IReducerStateProductConnect,
    action: IReducerActionProductConnect
): IReducerStateProductConnect {
    const {
        type,
        payload: { name, value, id },
    } = action;
    switch (type) {
        case "target":
            return {
                ...state,
                target: value,
            };
        case "atributes":
            return {
                ...state,
                atributes: {
                    ...state.atributes,
                    [name]: { id, value },
                },
            };
        case "default_global":
            if (action.payload.default) {
                const attr = action.payload.default.atributes;
                return {
                    target: action.payload.default.target,
                    atributes: {
                        name: attr.name,
                        price: attr.price,
                        market_price: attr.market_price,
                        plu: attr.plu,
                        min_in_stock: attr.min_in_stock,
                        quantity_to_be_ordered: attr.quantity_to_be_ordered,
                    },
                };
            } else {
                return state;
            }
        default:
            return state;
    }
}

export default restaurantProductConnectReducer;
