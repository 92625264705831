import { List } from "@mui/material";
import useRequest from "api/useRequest";
import { modalHide } from "app/App/actions";
import Search from "app/Inventory/components/Search";
import { restaurantProductsFetch } from "app/RestaurantProducts/actions";
import { RestaurantProductModel } from "app/RestaurantProducts/interfaces";
import trans from "helpers/trans";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
    RequestMessage,
} from "ui";
import MenuItemSelectPortion from "../components/MenuItemSelectPortion";
import SearchProductItem from "../components/SearchProductItem";
import {
    SearchModalView,
    SELECT_PORTION_VIEW,
    SELECT_PRODUCT_VIEW,
} from "../interfaces";

interface IMenuItemsCreateModal {
    onClick: Function;
    onClose: Function;
    clearData?: Function;
}

const MenusItemsCreateModal: FC<IMenuItemsCreateModal> = ({
    onClick,
    onClose,
    clearData,
}) => {
    const dispatch = useDispatch();
    const [modalView, setModalView] =
        useState<SearchModalView>(SELECT_PRODUCT_VIEW);
    const [products, setProducts] = useState([]);
    const [portions, setPortions] = useState<any[]>([]);
    const [productId, setProductId] = useState<number | null>(null);

    const { cancel, currentData, isError, isLoading, request, status } =
        useRequest();

    useEffect(() => {
        if (!isLoading && status === 200) {
            setProducts(currentData.data);
        }
    }, [currentData?.data, isLoading, status]);

    const checkProductPortions = (product: RestaurantProductModel) => {
        return product.restaurant_product_portions.length > 0;
    };

    const handleClose = () => {
        onClose();
    };

    const handleClickPortion = (portion: any) => {
        onClick(productId, portion);
        !!clearData && clearData();
        dispatch(modalHide("MenusItemsCreateModal"));
    };

    const handleChooseProduct = (product: RestaurantProductModel) => {
        const hasPortions = checkProductPortions(product);
        if (hasPortions) {
            setModalView(SELECT_PORTION_VIEW);
            setPortions(product.restaurant_product_portions);
            setProductId(product.id);
        } else {
            onClick(product.id);
            dispatch(modalHide("MenusItemsCreateModal"));
        }
    };

    const handleChangeSearch = (value: string) => {
        if (value.length > 0) {
            request(
                restaurantProductsFetch({
                    params: {
                        _search: value,
                        _with: [
                            "product",
                            "restaurant_product_portions",
                            "restaurant_product_portions.portion",
                            "location_suppliers",
                            "product.images",
                            "product.gtin",
                            "product.category",
                            "product.suppliers",
                        ],
                        _filters: {
                            restaurant_product_with_recipes_filter: true,
                        },
                    },
                })
            );
        } else {
            setProducts([]);
        }
    };

    const renderContent = () => {
        if (isLoading) return null;

        if (isError) return <RequestMessage status={status} />;

        return (
            <>
                {products.map((product: any) => (
                    <SearchProductItem
                        key={product.id}
                        onClick={handleChooseProduct}
                        restaurantProduct={product}
                    />
                ))}
            </>
        );
    };

    return (
        <Modal
            maxWidth="xs"
            fullWidth
            open
            PaperProps={{ sx: { position: "fixed", top: 0 } }}
        >
            <ModalTitle onClose={handleClose}>
                {modalView === SELECT_PRODUCT_VIEW &&
                    trans("inv.menusItems.modal.menusItemsCreateModal.search")}
                {modalView === SELECT_PORTION_VIEW &&
                    trans(
                        "inv.menusItems.modal.menusItemsCreateModal.selectPortionTitle"
                    )}
            </ModalTitle>
            {modalView === SELECT_PRODUCT_VIEW && (
                <ModalActions
                    sx={{
                        pl: 2,
                    }}
                >
                    <Search
                        autoFocus={true}
                        cancelRequest={cancel}
                        isLoading={isLoading}
                        onChange={handleChangeSearch}
                        placeholder={trans(
                            "inv.menusItems.modal.menusItemsCreateModal.placeholder"
                        )}
                    />
                </ModalActions>
            )}
            <ModalContent>
                {modalView === SELECT_PRODUCT_VIEW ? (
                    <List
                        sx={{
                            p: 0,
                        }}
                    >
                        {renderContent()}
                    </List>
                ) : modalView === SELECT_PORTION_VIEW ? (
                    <MenuItemSelectPortion
                        onClick={handleClickPortion}
                        portions={portions}
                    />
                ) : null}
            </ModalContent>
        </Modal>
    );
};

export default MenusItemsCreateModal;
