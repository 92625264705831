import { registerModals } from "modals";
import { registerRoutings } from "rootRouting";
import store from "rootStore";
import ProductsSaga from "./saga";
import { IConfig, routingType } from "types/configTypes";
import ProductsCreateModal from "./modals/ProductsCreateModal";
import ProductsVintageListModal from "./modals/ProductsVintageListModal";

export const permissionKey = "products";
export const url = "/products";

const getConfig = (permissions: Array<string>) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: IConfig = {
        permissions: {
            create: false,
            delete: false,
            read: false,
            update: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (modulePermissions.indexOf(permissionKey + ".create") !== -1) {
        config.permissions.create = true;
        registerModals({
            ProductsCreateModal,
            ProductsVintageListModal,
        });

        // routing.push({
        //     path: `${url}/create`,
        //     element: (
        //         <AsyncComponent path="Products/pages/ProductsCreatePage" />
        //     ),
        // });
    }

    if (modulePermissions.indexOf(permissionKey + ".delete") !== -1) {
        config.permissions.delete = true;
    }

    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;

        // store.reducerManager.add("ProductsTable", ProductsTable);
        store.injectSaga?.("ProductsSaga", ProductsSaga);

        // routing.push({
        //     path: url,
        //     element: <AsyncComponent path="Products/pages/ProductsIndexPage" />,
        // });
    }

    if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
        config.permissions.update = true;

        // routing.push({
        //     path: `${url}/:id/edit`,
        //     element: <AsyncComponent path="Products/pages/ProductsEditPage" />,
        // });
    }
    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
