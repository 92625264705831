export const PRODUCT_IMPORT_FAILED = "PRODUCT_IMPORT/ PRODUCT_IMPORT_FAILED";
export const PRODUCT_IMPORT_REQUEST = "PRODUCT_IMPORT/PRODUCT_IMPORT_REQUEST";
export const PRODUCT_IMPORT_SUCCESS = "PRODUCT_IMPORT/PRODUCT_IMPORT_SUCCESS";

export const UPDATE_FAILED = "PRODUCT_IMPORT/UPDATE_FAILED";
export const UPDATE_REQUEST = "PRODUCT_IMPORT/UPDATE_REQUEST";
export const UPDATE_SUCCESS = "PRODUCT_IMPORT/UPDATE_SUCCESS";

const types = {
    PRODUCT_IMPORT_REQUEST,
    PRODUCT_IMPORT_FAILED,
    PRODUCT_IMPORT_SUCCESS,

    UPDATE_FAILED,
    UPDATE_REQUEST,
    UPDATE_SUCCESS,
};

export default types;
