import { IWebMenuPagination } from "./interfaces";
import {
    FETCH_FAILED,
    FETCH_REQUEST,
    FETCH_SUCCESS,
    MOBILE_FILTERS,
    RECOMMENDED_SUCCESS,
    SCROLL,
    SETTINGS_FAILED,
    SETTINGS_REQUEST,
    SETTINGS_SUCCESS,
    SHOW_EXPANDED,
    TABLE_UPDATE,
} from "./types";

import { tableResponse, tableUpdate } from "helpers/reducers";
import { isEqual } from "lodash";
import { AnyAction } from "redux";
import { APP_LOADED } from "types";
import { IGPTable } from "ui/Table/Table";
interface IWebMenu
    extends Omit<
        IGPTable,
        "available_columns" | "filterSettings" | "pagination"
    > {
    uuid: string;
    filterSettings: {
        open: boolean;
        total: number;
    };
    expand: "";
    active: boolean;
    pagination: IWebMenuPagination;
    mobileFilters: boolean;
    name: string;
    show_prices: boolean;
    show_stock: boolean;
    show_rating: boolean;
    settingsLoader: boolean;
    scroll: boolean;
    itemsLoaded: boolean;
    products: any;
    recommendedProducts: any;
    status: string;
}

function WebMenu(
    state: IWebMenu = {
        id: "WebMenu",
        active: false,
        filters: {},
        filterData: [],
        filterSettings: {
            open: false,
            total: 0,
        },
        columns: [],
        pagination: {
            page: 1,
            per_page: 18,
            isLastPage: true,
            total: 0,
            last_page: 1,
        },
        search: "",
        selection: {
            id: "id",
            selected: [],
        },
        selectionActions: null,
        sort: { "restaurant_product.stock": "desc" },
        with: [],
        uuid: "",
        expand: "",
        mobileFilters: false,
        name: "",
        show_prices: false,
        show_stock: false,
        show_rating: false,
        settingsLoader: true,
        scroll: false,
        itemsLoaded: false,
        products: null,
        recommendedProducts: null,
        elasticSearch: true,
        status: "",
    },
    action: AnyAction
) {
    switch (action.type) {
        case FETCH_REQUEST:
            return {
                ...state,
                itemsLoaded: false,
            };
        case FETCH_FAILED:
            return {
                ...state,
                itemsLoaded: true,
            };
        case FETCH_SUCCESS:
            return {
                ...tableResponse(state, action),
                pagination: {
                    ...tableResponse(state, action).pagination,
                    last_page: action.response.data.meta.last_page,
                },
                itemsLoaded: true,
                products: action.response.data.data,
            };

        case RECOMMENDED_SUCCESS:
            return {
                ...state,
                recommendedProducts: action.response.data.data,
            };

        case TABLE_UPDATE:
            const category = action.data?.menu_list_category_id_filter;
            const stateCategory = (state.filters as any)
                ?.menu_list_category_id_filter;
            return {
                ...tableUpdate(state, action),
                scroll: false,
                products: null,
                recommendedProducts:
                    (category && !isEqual(category, stateCategory)) ||
                    (!category &&
                        stateCategory &&
                        (action.action === "filter" ||
                            action.action === "clearFilters"))
                        ? null
                        : state.recommendedProducts,
            };

        case SETTINGS_REQUEST:
            return { ...state, settingsLoader: true };
        case SETTINGS_FAILED:
            return { ...state, settingsLoader: false };
        case SETTINGS_SUCCESS:
            return {
                ...state,
                ...action.response.data,
                filterData: action.response.data.filters_data.filter(
                    (item: any) => item.values?.length > 0 || !item.values
                ),
                active: action.response.data.active,
                settingsLoader: false,
            };
        case APP_LOADED:
            return {
                ...state,
                uuid: action.uuid,
                status: action.status|| "",
            };
        case SHOW_EXPANDED:
            return { ...state, expand: action.data };
        case MOBILE_FILTERS:
            return { ...state, mobileFilters: action.data };
        case SCROLL:
            return {
                ...state,

                scroll: true,
            };
        default:
            return state;
    }
}

export default WebMenu;
