import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { SX } from "types/SX";
interface IPaperHeader {
    actions?: any;
    title?: string;
    onClick?: Function;
    sx?: SX;
    variant?: any;
}
function PaperHeader({ actions, onClick, sx, title, variant }: IPaperHeader) {
    const handleOnClick = (event: React.MouseEvent) => {
        if (!!onClick) {
            onClick(event);
        }
    };

    return (
        <Box
            sx={{
                alignItems: "center",
                borderBottom: "1px solid",
                borderBottomColor: "grey.300",
                display: "flex",
                justifyContent: "space-between",
                height: "100%",
                px: 2,
                py: 1,
                ...sx,
            }}
            onClick={handleOnClick}
        >
            <Typography variant={variant}>{title}</Typography>
            {!!actions && actions}
        </Box>
    );
}

PaperHeader.defaultProps = {
    variant: "h6",
};

export default PaperHeader;
