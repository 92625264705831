import React from "react";
import { Icon } from "@mui/material";
import { DayPicker } from "react-day-picker";
import {
    DayPickerSingleProps,
    CaptionProps,
    useNavigation,
} from "react-day-picker";

interface INavbarElement extends CaptionProps {
    className?: string;
}

interface IGPDayPicker extends DayPickerSingleProps {
    numberOfMonths: number;
}

function NavbarElement({ className }: INavbarElement) {
    const { previousMonth, goToMonth, nextMonth } = useNavigation();
    return (
        <div className={className}>
            <Icon
                onClick={() => previousMonth && goToMonth(previousMonth)}
                sx={{
                    color: "grey.600",
                    cursor: "pointer",
                    fontSize: (theme) => theme.spacing(4),
                    left: 12,
                    position: "absolute",
                    "&:hover": {
                        color: "text.primary",
                    },
                }}
            >
                navigate_before
            </Icon>
            <Icon
                onClick={() => nextMonth && goToMonth(nextMonth)}
                sx={{
                    color: "grey.600",
                    cursor: "pointer",
                    fontSize: (theme) => theme.spacing(4),
                    position: "absolute",
                    right: 12,
                    "&:hover": {
                        color: "text.primary",
                    },
                }}
            >
                navigate_next
            </Icon>
        </div>
    );
}

function GPDayPicker(props: IGPDayPicker) {
    const { numberOfMonths, ...restProps } = props;

    return (
        <React.Fragment>
            <style>
                {`
                    .DayPicker {
                        margin-top: 16px;
                        width: 100%;
                    }
                    .DayPicker-wrapper {
                        outline: none;
                    }
                    .DayPicker-Day {
                        border-radius: 0;
                        outline: none;
                    }
                    .DayPicker-Day--outside {
                      visibility: hidden;
                    }
                    .DayPicker-Month {
                        margin: 0 6px;
                        width: calc(${100 / numberOfMonths}% - 12px);
                    }
                    .DayPicker-Month .DayPicker-Caption {
                        text-align: center;
                    }
                `}
            </style>
            <DayPicker
                weekStartsOn={1}
                fixedWeeks={true}
                components={{
                    Caption: NavbarElement,
                }}
                numberOfMonths={numberOfMonths}
                {...restProps}
            />
        </React.Fragment>
    );
}

GPDayPicker.defaultProps = {
    numberOfMonths: 1,
};

export default GPDayPicker;
