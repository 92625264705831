import { uuid } from "./config";
import { web } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlId from "types/RequestUrlId";

const requests = {
    fetch: (config: AxiosRequestConfig, url: RequestUrlId) =>
        web.get(`/menus/lists/${url.id}/items`, config),
    get: (config: AxiosRequestConfig, url: RequestUrlId) =>
        web.get(`menus/lists/${uuid}/items/${url.id}`, config),
    getSimilar: (config: AxiosRequestConfig, url: RequestUrlId) =>
        web.get(`menus/lists/${uuid}/items/${url.id}/similar`, config),
    translate: (config: AxiosRequestConfig) => web.get(`/menus/lists/${uuid}/translate`, config),
    settings: (config: AxiosRequestConfig, url: RequestUrlId) =>
        web.get(`/menus/lists/${uuid}/web-settings`, config),
    recommended: (config: AxiosRequestConfig) =>
        web.get(`/menus/lists/${uuid}/items/recommended`, config),
};

export default requests;
