import AsyncComponent from "ui/AsyncComponent";
import { registerRoutings } from "rootRouting";
import store from "rootStore";
import { registerModals } from "modals";
import InventoryCountReducer from "./reducers/InventoryCountReducer";
import InventoryTable from "./reducers/InventoryTableReducer";
import InventorySaga from "./saga";
import { permissionsType, configType, routingType } from "types/configTypes";
import InventoryCountModal from "./modals/InventoryCountModal";
import InventorySearchProductModal from "./modals/InventorySearchProductModal";
import InventoryChooseProductModal from "./modals/InventoryChooseProductModal";
import InventoryDetailsModal from "./modals/InventoryDetailsModal";
export const permissionKey = "inventory";
export const url = "/inventory";

interface IconfigInventory extends Omit<configType, "permissions"> {
    permissions: {
        create: boolean;
        delete: boolean;
        read: boolean;
        update: boolean;
        finalize: boolean;
    };
}

const getConfig = (permissions: permissionsType) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: IconfigInventory = {
        permissions: {
            create: false,
            delete: false,
            finalize: false,
            read: false,
            update: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (modulePermissions.indexOf(permissionKey + ".create") !== -1) {
        config.permissions.create = true;
        registerModals({
            InventoryDetailsModal,
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".delete") !== -1) {
        config.permissions.delete = true;
    }

    if (modulePermissions.indexOf(permissionKey + ".finalize") !== -1) {
        config.permissions.finalize = true;
    }

    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;
        store.reducerManager?.add("InventoryTable", InventoryTable);
        store.injectSaga?.("InventorySaga", InventorySaga);

        registerModals({
            InventoryCountModal,
            InventorySearchProductModal,
            InventoryChooseProductModal,
        });

        routing.push({
            path: url,
            element: (
                <AsyncComponent path="Inventory/pages/InventoryIndexPage" />
            ),
        });

        store.reducerManager?.add("InventoryCount", InventoryCountReducer);
    }

    if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
        config.permissions.update = true;

        routing.push({
            path: `${url}/:id/chooseArea`,
            element: (
                <AsyncComponent path="Inventory/pages/InventoryChooseAreaPage" />
            ),
        });
        routing.push({
            path: `${url}/:id/area/:areaId`,
            element: (
                <AsyncComponent path="Inventory/pages/InventoryCountingPage" />
            ),
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
