import {
    Box,
    Icon,
    IconButton,
    FormControl,
    FormHelperText,
    InputLabel,
} from "@mui/material";
import { FormControlProps } from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useState } from "react";

import {
    ContentState,
    convertToRaw,
    Editor,
    EditorState,
    RichUtils,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import FormContext from "./FormContext";
import { getHelperText, getValue } from "./helpers";

interface IGPWysiwyg extends Omit<FormControlProps, "onChange"> {
    errorText?: string;
    formControlProps?: object;
    helperText?: string;
    labelProps?: object;
    stripPastedStyles?: boolean;
    label?: string;
    value?: any;
    id: string;
    onChange?: Function;
}

const initEditor = (value: string) => {
    const contentBlock = htmlToDraft(value);
    if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
        );
        return EditorState.createWithContent(contentState);
    }

    return EditorState.createEmpty();
};

function GPWysiwyg(props: IGPWysiwyg) {
    const {
        errorText,
        formControlProps,
        fullWidth,
        helperText,
        id,
        label,
        labelProps,
        margin,
        onChange,
        value,
        variant,
    } = props;

    const context = useContext(FormContext);

    const [editor, setEditor] = useState(
        initEditor(getValue(id, context, value))
    );
    const onChangeValue = (editor: any) => {
        const inputValue = draftToHtml(
            convertToRaw(editor.getCurrentContent())
        );
        if (!!onChange) {
            onChange(
                {
                    id,
                    value: inputValue,
                },
                context
            );
        } else {
            context?.onChange({
                id,
                value: inputValue,
            });
        }

        setEditor(editor);
    };

    const onClickBold = () => {
        onChangeValue(RichUtils.toggleInlineStyle(editor, "BOLD"));
    };
    const onClickItalic = () => {
        onChangeValue(RichUtils.toggleInlineStyle(editor, "ITALIC"));
    };
    const onClickStrikeThrough = () => {
        onChangeValue(RichUtils.toggleInlineStyle(editor, "STRIKETHROUGH"));
    };
    const onClickUnderline = () => {
        onChangeValue(RichUtils.toggleInlineStyle(editor, "UNDERLINE"));
    };

    const handleKeyCommand = (command: string) => {
        const newState = RichUtils.handleKeyCommand(editor, command);

        if (newState) {
            onChangeValue(newState);
            return "handled";
        }

        return "not-handled";
    };

    const { inputHelperText, isError } = getHelperText(
        id,
        context,
        errorText,
        helperText
    );

    const activeToolbarButtons = editor.getCurrentInlineStyle();

    return (
        <FormControl
            {...formControlProps}
            error={isError}
            fullWidth={fullWidth}
            margin={margin}
            variant={variant}
        >
            {!!label && (
                <InputLabel {...labelProps} id={`${id}-label`} shrink>
                    {label}
                </InputLabel>
            )}

            <Box
                sx={{
                    border: (theme) => `1px solid ${theme.palette.grey[400]}`,
                    borderRadius: 1,
                    "& .public-DraftEditor-content": {
                        minHeight: 48,
                    },
                    "& .public-DraftStyleDefault-block": {
                        margin: "0!important",
                    },
                }}
            >
                <Box
                    sx={{
                        alignItems: "center",
                        borderBottom: (theme) =>
                            `1px solid ${theme.palette.grey[300]}`,
                        color: "text.secondary",
                        display: "flex",
                        width: "100%",
                    }}
                >
                    <IconButton
                        onClick={onClickBold}
                        onMouseDown={(e) => e.preventDefault()}
                    >
                        <Icon
                            sx={[
                                activeToolbarButtons.has("BOLD") && {
                                    color: "#000",
                                },
                            ]}
                        >
                            format_bold
                        </Icon>
                    </IconButton>
                    <IconButton
                        onClick={onClickItalic}
                        onMouseDown={(e) => e.preventDefault()}
                    >
                        <Icon
                            sx={[
                                activeToolbarButtons.has("ITALIC") && {
                                    color: "#000",
                                },
                            ]}
                        >
                            format_italic
                        </Icon>
                    </IconButton>
                    <IconButton
                        onClick={onClickUnderline}
                        onMouseDown={(e) => e.preventDefault()}
                    >
                        <Icon
                            sx={[
                                activeToolbarButtons.has("UNDERLINE") && {
                                    color: "#000",
                                },
                            ]}
                        >
                            format_underline
                        </Icon>
                    </IconButton>
                    <IconButton
                        onClick={onClickStrikeThrough}
                        onMouseDown={(e) => e.preventDefault()}
                    >
                        <Icon
                            sx={[
                                activeToolbarButtons.has("STRIKETHROUGH") && {
                                    color: "#000",
                                },
                            ]}
                        >
                            strikethrough_s
                        </Icon>
                    </IconButton>
                </Box>
                <Box sx={{ p: 1 }}>
                    <Editor
                        editorState={editor}
                        handleKeyCommand={handleKeyCommand}
                        onChange={onChangeValue}
                        stripPastedStyles={props.stripPastedStyles}
                    />
                </Box>
            </Box>

            {inputHelperText.length > 0 && (
                <FormHelperText>{inputHelperText}</FormHelperText>
            )}
        </FormControl>
    );
}

GPWysiwyg.propTypes = {
    errorText: PropTypes.string,
    formControlProps: PropTypes.object,
    helperText: PropTypes.string,
    id: PropTypes.string.isRequired,
    labelProps: PropTypes.object,
    onChange: PropTypes.func,
    stripPastedStyles: PropTypes.bool,
};

GPWysiwyg.defaultProps = {
    errorText: "",
    formControlProps: {},
    helperText: "",
    labelProps: {},
    stripPastedStyles: true,
};

export default GPWysiwyg;
