import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlId from "types/RequestUrlId";
import RequestUrlDoubleId from "types/RequestUrlDoubleId";
import { menusUrl } from "../Menus/config";

const baseUrl = `/categories`;

const requests = {
    menuCategoriesFetch: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`${menusUrl}/${url.id}${baseUrl}`, config),
    menuCategoryGet: (config: AxiosRequestConfig, url: RequestUrlDoubleId) =>
        api.get(`${menusUrl}/${url.prevId}${baseUrl}/${url.id}`, config),
    menuCategoryUpdate: (
        data: object,
        config: AxiosRequestConfig,
        url: RequestUrlDoubleId
    ) => api.put(`${menusUrl}/${url.prevId}${baseUrl}/${url.id}`, data, config),
    menuCategoryDelete: (config: AxiosRequestConfig, url: RequestUrlDoubleId) =>
        api.delete(`${menusUrl}/${url.prevId}${baseUrl}/${url.id}`, config),
    menuCategoryCreate: (
        data: object,
        config: AxiosRequestConfig,
        url: RequestUrlId
    ) => api.post(`${menusUrl}/${url.id}${baseUrl}`, data, config),
};

export default requests;
