import { Box, Tooltip } from "@mui/material";
import { FC } from "react";

interface IHelpDescription {
    children: any;
    align?: string;
    text?: string;
}

const HelpDescription: FC<IHelpDescription> = ({
    children,
    align = "left",
    text,
}) => {
    if (!text) {
        return children;
    }

    let sxPosition: {} = {
        "&:after": {
            fontFamily: "Material Icons",
            content: "'help_outline'",
            WebkitFontFeatureSettings: "'liga' 1",
            MozFontFeatureSettings: "'liga' 1",
            fontFeatureSettings: "'liga' 1",
            marginLeft: "2px",
            position: "relative",
            top: 2,
        },
    };

    if (align === "right") {
        sxPosition = {
            "&:before": {
                fontFamily: "Material Icons",
                content: "'help_outline'",
                WebkitFontFeatureSettings: "'liga' 1",
                MozFontFeatureSettings: "'liga' 1",
                fontFeatureSettings: "'liga' 1",
                marginRight: "2px",
                position: "relative",
                top: 2,
            },
        };
    }

    return (
        <Tooltip arrow disableFocusListener title={text}>
            <Box component="span" sx={sxPosition}>
                {children}
            </Box>
        </Tooltip>
    );
};

export default HelpDescription;
