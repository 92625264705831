import { FC, MouseEvent, useState, useEffect, createElement } from "react";
import useRequest from "api/useRequest";
import { modalHide, modalShow } from "app/App/actions";
import { deliverySlipApprove, deliverySlipsGetLines } from "../actions";
import { IDeliveryLineApprove, ILines } from "../interfaces";
import { Textarea } from "ui";
import { useSelector, useDispatch } from "react-redux";
import {
    Loader,
    Modal,
    ModalContent,
    ModalTitle,
    ModalActions,
    RequestMessage,
    Table,
    Button,
} from "ui";
import { IGPTableColumn } from "ui/Table/Table";
import {
    Box,
    Icon,
    TableCell,
    TableRow,
    TableRowProps,
    TextField,
    Typography,
} from "@mui/material";
import { AxiosResponse } from "axios";
import trans from "helpers/trans";
import { IFormChangeResponse } from "ui/Form/Form";
import { green, red } from "@mui/material/colors";
import { styled } from "@mui/system";
import { IDeliverySlipApproveModal } from "../interfaces";
import moment from "moment";
import { manageTableGet } from "app/ManageTables/actions";

const QtyButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.grey[400],
    borderRadius: 50,
    color: "#000",
    height: 32,
    minWidth: 32,
    padding: 0,
    width: 32,
    "&:hover": {
        backgroundColor: theme.palette.grey[400],
    },
}));
const DeliverySlipApproveDetailsLoadColumns: FC<any> = ({
    item,
    deliverySlipsReload,
}) => {
    const dispatch = useDispatch();
    const tableId = item.order_id
        ? "OrderTable"
        : `${item.type.toCamelCase().capitalize()}Table`;
    const columns = useSelector(
        (state: any) => state.DeliverySlipLinesApprove[tableId]?.columns
    );

    useEffect(() => {
        if (!columns || columns.length === 0)
            dispatch(manageTableGet(`DeliverySlipLinesApprove.${tableId}`));
    }, [columns, dispatch, tableId]);

    if (!columns || columns.length === 0) {
        return <Loader />;
    }

    return (
        <DeliverySlipApproveModal
            type={tableId}
            item={item}
            id={item.id}
            deliverySlipsReload={deliverySlipsReload}
        />
    );
};

const DeliverySlipApproveModal: FC<IDeliverySlipApproveModal> = ({
    id,
    item,
    deliverySlipsReload,
    type,
}) => {
    const dispatch = useDispatch();
    const [deliverySlipNote, setDeliverySlipNote] = useState("");
    const [deliveryLines, setDeliveryLines] = useState<IDeliveryLineApprove[]>(
        []
    );
    const table = useSelector(
        (state: any) => state.DeliverySlipLinesApprove[type]
    );

    const { types } = useSelector((state: any) => ({
        types: state.app.settings.deliverySources,
    }));

    const { currentData, isPending, isError, status } = useRequest(
        deliverySlipsGetLines(id, {
            params: {
                _with: table?.with,
            },
        })
    );

    const { request: updateRequest } = useRequest();

    useEffect(() => {
        let tempDeliveryLines: any = [];

        if (currentData?.data) {
            tempDeliveryLines = currentData.data.map((line: ILines) => {
                return {
                    ...line,
                    approve: {
                        id: line.id,
                        approve_status: true,
                        approve_note: "",
                    },
                };
            });
        }
        setDeliveryLines(tempDeliveryLines);
    }, [currentData]);

    const prepareColumns = (columns: any) => {
        if (!columns) return [];
        return columns.map((column: IGPTableColumn) => {
            if (
                column.field === "total_netto" ||
                column.field === "base_price"
            ) {
                column.renderCell = (item: any) => {
                    if (item[column.field] !== null) {
                        return {
                            children: item[column.field].price(),
                            sx: {
                                whiteSpace: "nowrap",
                                ...(item.approve.approve_status
                                    ? { borderBottomColor: "gray.light" }
                                    : { borderBottom: "none" }),
                            },
                        };
                    }
                };
            } else if (column.field === "discount") {
                column.renderCell = (item: any) => {
                    if (type === "nortura") {
                        return {
                            children: item.restaurant_product.discount.price(),
                            sx: {
                                whiteSpace: "nowrap",
                                ...(item.approve.approve_status
                                    ? { borderBottomColor: "gray.light" }
                                    : { borderBottom: "none" }),
                            },
                        };
                    }
                };
            } else if (column.field === "unit_netto") {
                column.renderCell = (item: any) => {
                    return {
                        children: item[column.field]?.price(),
                        sx: {
                            whiteSpace: "nowrap",
                            ...(item.approve.approve_status
                                ? { borderBottomColor: "gray.light" }
                                : { borderBottom: "none" }),
                        },
                    };
                };
            } else if (column.field === "restaurant_product.price") {
                column.renderCell = (item: any) => {
                    return {
                        children: item.restaurant_product?.price?.price(),
                        sx: {
                            whiteSpace: "nowrap",
                            ...(item.approve.approve_status
                                ? { borderBottomColor: "gray.light" }
                                : { borderBottom: "none" }),
                        },
                    };
                };
            } else {
                column.renderCell = (item: any) => {
                    return {
                        children: item[column.field],
                        sx: {
                            ...(item.approve.approve_status
                                ? { borderBottomColor: "gray.light" }
                                : { borderBottom: "none" }),
                        },
                    };
                };
            }
            return column;
        });
    };

    const handleCloseModal = () =>
        dispatch(modalHide("DeliverySlipApproveModal"));

    const handleClickRow = (
        event: MouseEvent<Element, globalThis.MouseEvent>,
        item: ILines
    ) => {
        const updateDeliveryLines = deliveryLines.map((dsLine) => {
            if (dsLine.id === item.id)
                return {
                    ...dsLine,
                    approve: {
                        ...dsLine.approve,
                        approve_status: !dsLine.approve.approve_status,
                    },
                };

            return dsLine;
        });
        setDeliveryLines(updateDeliveryLines);
    };

    const handleSubmitDeliverySlip = () => {
        let approveStatus = true;
        let lines = [];

        for (let i = 0; i < deliveryLines.length; i++) {
            const line = deliveryLines[i].approve;

            if (line.approve_status) {
                line.approve_note = "";
                line.received = 0;
            } else {
                line.received = deliveryLines[i].received
                    ? deliveryLines[i].received
                    : 0;
            }
            lines.push(line);

            if (line.approve_status === false) {
                approveStatus = false;
            }
        }

        let updateData = {
            approve_status: approveStatus,
            approve_note: deliverySlipNote,
            approve_on_delivery: false,
            lines,
        };

        const handleSubmit = (data: any) => {
            updateRequest(
                deliverySlipApprove(id, data, (response: AxiosResponse) => {
                    if (response.status === 200) {
                        dispatch(modalHide("DeliverySlipApproveModal"));
                        deliverySlipsReload();
                    }
                })
            );
        };

        if (item?.delivery_date !== moment().format("YYYY-MM-DD")) {
            dispatch(
                modalShow("DeliverySlipApproveDateModal", {
                    onClickAgree: () => {
                        handleSubmit(updateData);
                    },
                    onClickDisagree: () => {
                        handleSubmit({
                            ...updateData,
                            approve_on_delivery: true,
                        });
                    },
                })
            );
        } else {
            handleSubmit(updateData);
        }
    };
    const handleClickAdd = (id: number) => {
        const newLines = deliveryLines.map((deliveryLine) => {
            if (deliveryLine.id === id) {
                return {
                    ...deliveryLine,
                    received:
                        deliveryLine.received === "0.00"
                            ? 1
                            : (deliveryLine.received as number) + 1,
                };
            }

            return deliveryLine;
        });
        setDeliveryLines(newLines);
    };
    const handleClickOdd = (id: number) => {
        const newLines = deliveryLines.map((deliveryLine) => {
            if (deliveryLine.id === id) {
                return {
                    ...deliveryLine,
                    received: (deliveryLine.received as number) - 1,
                };
            }

            return deliveryLine;
        });
        setDeliveryLines(newLines);
    };

    const renderRow = (
        row: any,
        idx: number,
        content: TableRowProps,
        columns: IGPTableColumn[]
    ) => {
        return [
            createElement(TableRow, {
                ...content,
                hover: false,
                sx: {
                    ...content.sx,
                    backgroundColor: row.approve.approve_status
                        ? green[100]
                        : red[100],
                },
            }),
            !row.approve.approve_status && (
                <TableRow
                    key={`gui-body-row-${row.id}`}
                    sx={{ backgroundColor: red[100] }}
                >
                    <TableCell
                        colSpan={columns.length}
                        sx={{ borderBottomColor: "gray.light", pt: 0 }}
                    >
                        <TextField
                            variant="outlined"
                            label={trans(
                                "inv.deliverySlips.modals.deliverySlipApproveModal.label.note"
                            )}
                            fullWidth
                            multiline={true}
                            value={row.approve.approve_note}
                            onChange={(e) => {
                                const newLines = deliveryLines.map(
                                    (deliveryLine) => {
                                        if (deliveryLine.id === row.id) {
                                            return {
                                                ...deliveryLine,
                                                approve: {
                                                    ...deliveryLine.approve,
                                                    approve_note:
                                                        e.target.value,
                                                },
                                            };
                                        }

                                        return deliveryLine;
                                    }
                                );
                                setDeliveryLines(newLines);
                            }}
                            size="small"
                            sx={{
                                "& .MuiInputBase-root": {
                                    backgroundColor: "common.white",
                                },
                            }}
                        />
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            gap={2}
                            width="75%"
                            margin="auto"
                            marginTop={2}
                        >
                            <QtyButton
                                onClick={() => handleClickOdd(row.id)}
                                disabled={
                                    row.received === "0.00" || row.received < 1
                                }
                            >
                                <Icon>remove</Icon>
                            </QtyButton>
                            <TextField
                                type="number"
                                variant="outlined"
                                label={trans(
                                    "inv.deliverySlips.modals.deliverySlipApproveModal.label.received"
                                )}
                                fullWidth
                                value={
                                    row.received === "0.00" ? 0 : row.received
                                }
                                onChange={(e) => {
                                    const newLines = deliveryLines.map(
                                        (deliveryLine) => {
                                            if (deliveryLine.id === row.id) {
                                                return {
                                                    ...deliveryLine,
                                                    received:
                                                        e.target.value === ""
                                                            ? 0
                                                            : parseFloat(
                                                                  e.target.value
                                                              ),
                                                };
                                            }

                                            return deliveryLine;
                                        }
                                    );
                                    setDeliveryLines(newLines);
                                }}
                                InputProps={{
                                    inputProps: { min: 0 },
                                }}
                                size="small"
                                sx={{
                                    "& .MuiInputBase-root": {
                                        backgroundColor: "common.white",
                                    },
                                }}
                            />
                            <QtyButton onClick={() => handleClickAdd(row.id)}>
                                <Icon>add</Icon>
                            </QtyButton>
                        </Box>
                    </TableCell>
                </TableRow>
            ),
        ];
    };

    if (isPending) {
        return <Loader />;
    }

    if (isError) {
        return <RequestMessage status={status} />;
    }

    return (
        <Modal maxWidth="xl" fullWidth open>
            <ModalTitle onClose={handleCloseModal}>
                {trans(
                    "inv.deliverySlips.modals.deliverySlipApproveModal.text.number",
                    {
                        number: item.delivery_number,
                    }
                )}
            </ModalTitle>
            <ModalContent
                sx={{
                    pt: 2,
                    px: 1,
                }}
            >
                <Box sx={{ pb: 2, pl: 1 }}>
                    <Typography>
                        {trans(
                            "inv.deliverySlips.modals.deliverySlipApproveModal.text.date",
                            {
                                date: moment(item.delivery_date).format(
                                    "DD/MM/YYYY"
                                ),
                            }
                        )}
                    </Typography>
                    <Typography>
                        {trans(
                            "inv.deliverySlips.modals.deliverySlipApproveModal.text.order",
                            {
                                order: item.order_number,
                            }
                        )}
                    </Typography>
                    <Typography>
                        {trans(
                            "inv.deliverySlips.modals.deliverySlipApproveModal.text.source",
                            {
                                source: item.order_id
                                    ? item.type
                                    : types[item.type].name,
                            }
                        )}
                    </Typography>
                </Box>
                <Table
                    calculateHeight={false}
                    {...table}
                    columns={prepareColumns(table?.columns)}
                    rows={deliveryLines}
                    renderRow={renderRow}
                    onClickRow={handleClickRow}
                />
            </ModalContent>
            <ModalActions
                sx={{
                    flexDirection: "column",
                    pt: 1,
                    px: 1,
                }}
            >
                <Textarea
                    fullWidth
                    id="deliverySlipApproveNote"
                    label={trans(
                        "inv.deliverySlips.modals.deliverySlipApproveModal.label.notes"
                    )}
                    minRows={1}
                    onChange={({ value }: IFormChangeResponse) =>
                        setDeliverySlipNote(value)
                    }
                    sx={{ mb: 2 }}
                />
                <Box display="flex" width="100%" textAlign="right">
                    <Button
                        color="inherit"
                        onClick={handleCloseModal}
                        sx={{
                            mr: 1,
                        }}
                        variant="text"
                    >
                        {trans("global.button.cancel")}
                    </Button>
                    <Button onClick={handleSubmitDeliverySlip} sx={{ flex: 1 }}>
                        {trans("global.button.save")}
                    </Button>
                </Box>
            </ModalActions>
        </Modal>
    );
};

export default DeliverySlipApproveDetailsLoadColumns;
