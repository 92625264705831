import { bulkRestaurantProductsShowUnit } from "../actions";
import { IBulkActionModal } from "../interfaces";

import { Box, MenuItem } from "@mui/material";
import useRequest from "api/useRequest";
import { modalHide } from "app/App/actions";
import { AxiosResponse } from "axios";
import trans from "helpers/trans";
import { useUnitGroupsSelectOptions } from "helpers/units";
import { FC, useRef } from "react";
import { useDispatch } from "react-redux";
import {
    Button,
    Form,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
    Select,
} from "ui";
import { IGPFormRef } from "ui/Form/Form";

const BulkShowUnitModal: FC<IBulkActionModal> = ({ selected, onSuccess }) => {
    const dispatch = useDispatch();
    const formRef = useRef<IGPFormRef | null>();

    const { currentData, request, errors, isLoading, isSuccess } = useRequest();
    const renderUnitList = useUnitGroupsSelectOptions();

    const handleCloseModal = () => {
        dispatch(modalHide("BulkShowUnitModal"));
    };

    const handleSubmit = () => {
        if (formRef.current !== null) {
            const data: any = formRef?.current?.getData();
            request(
                bulkRestaurantProductsShowUnit(
                    {
                        ...data,
                        ids: selected,
                    },
                    (response: AxiosResponse) => {
                        if (response.status === 204) {
                            dispatch(modalHide("BulkShowUnitModal"));
                            onSuccess();
                        }
                    }
                )
            );
        }
    };

    return (
        <Modal open fullWidth maxWidth="sm">
            <ModalTitle onClose={handleCloseModal}>
                {" "}
                {trans("inv.restaurantProducts.modal.BulkShowUnitModal.title")}
            </ModalTitle>
            <ModalContent>
                <Form
                    ref={formRef}
                    data={currentData}
                    errors={errors}
                    fields={{
                        show_unit: { type: "string" },
                    }}
                    loading={isLoading}
                    onSubmit={handleSubmit}
                    onSuccess={isSuccess}
                    unsaved={false}
                >
                    <Select fullWidth id="show_unit">
                        <MenuItem value=" ">
                            <em>
                                {trans(
                                    "inv.restaurantProducts.form.restaurantProductsForm.select.unit"
                                )}
                            </em>
                        </MenuItem>
                        {renderUnitList}
                    </Select>
                </Form>
            </ModalContent>
            <ModalActions>
                <Box display="flex" width="100%" textAlign="right">
                    <Button
                        onClick={handleCloseModal}
                        color="inherit"
                        variant="text"
                        sx={{ mr: 1 }}
                    >
                        {trans("global.button.cancel")}
                    </Button>
                    <Button sx={{ flex: 1 }} onClick={handleSubmit}>
                        {trans("global.button.save")}
                    </Button>
                </Box>
            </ModalActions>
        </Modal>
    );
};

export default BulkShowUnitModal;
