export const listOfAllergens = [
    "gluten",
    "wheat_gluten",
    "spelled_gluten",
    "khorasan_wheat_gluten",
    "rye_gluten",
    "bygg_gluten",
    "oat_gluten",
    "shellfish",
    "egg",
    "fish",
    "soya",
    "milk",
    "nuts",
    "peanuts",
    "almonds",
    "hazelnuts",
    "walnuts",
    "cashew_nuts",
    "pecan_nuts",
    "brazil_nuts",
    "pistachios",
    "macadamia_nuts",
    "celery",
    "mustard",
    "sesame_seeds",
    "sulfur_dioxide_or_sulfites",
    "lupins",
    "molluscs",
];

export const glutenList = [
    "wheat_gluten",
    "spelled_gluten",
    "khorasan_wheat_gluten",
    "rye_gluten",
    "bygg_gluten",
    "oat_gluten",
];

export const nutsList = [
    "peanuts",
    "almonds",
    "hazelnuts",
    "walnuts",
    "cashew_nuts",
    "pecan_nuts",
    "brazil_nuts",
    "pistachios",
    "macadamia_nuts",
];
