import { Icon, IconButton, Menu } from "@mui/material";
import { FC, useState } from "react";

interface IGPTableActions {
    children: React.ReactNode;
}

const GPTableActions: FC<IGPTableActions> = ({ children }) => {
    const [btnRef, setBtnRef] = useState<HTMLElement | null>(null);

    const onClickBtn = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setBtnRef(event.currentTarget);
    };

    const onClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setBtnRef(null);
    };

    return (
        <>
            <IconButton onClick={onClickBtn} size="small">
                <Icon>more_vertical</Icon>
            </IconButton>

            <Menu
                anchorEl={btnRef}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                open={btnRef !== null}
                onClose={onClose}
                onClick={onClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
            >
                {children}
            </Menu>
        </>
    );
};

export default GPTableActions;
