import api from "./api";
import types from "./types";
import { AxiosResponse } from "axios";
import { request } from "api/apiSaga";
import { snackbarShow } from "app/App/actions";
import trans from "helpers/trans";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

function* destroy(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.destroy,
        action,
        "delete"
    );

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("inv.restaurantProducts.saga.content.destroy"),
            })
        );
    }
}

function* destroyPortions(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.destroyPortions,
        action,
        "put"
    );

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans(
                    "inv.restaurantProducts.saga.content.destroyPortions"
                ),
            })
        );
    }
}

function* fetch(action: AnyAction) {
    yield call(request, api.fetch, action);
}

function* get(action: AnyAction) {
    yield call(request, api.get, action);
}

function* product(action: AnyAction) {
    yield call(request, api.product, action, "post");
}

function* store(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.store,
        action,
        "post"
    );

    if (response.status === 201) {
        yield put(
            snackbarShow({
                content: trans("inv.restaurantProducts.saga.content.store"),
            })
        );
    }
}
function* storePortions(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.storePortions,
        action,
        "post"
    );

    if (response.status === 200) {
        yield put(
            snackbarShow({
                content: trans(
                    "inv.restaurantProducts.saga.content.storePortions"
                ),
            })
        );
    }
}

function* update(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.update,
        action,
        "put"
    );

    if (response.status === 202 || response.status === 200) {
        yield put(
            snackbarShow({
                content: trans("inv.restaurantProducts.saga.content.update"),
            })
        );
    }
}

function* hideProduct(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.hideProduct,
        action,
        "put"
    );

    if (response.status < 300) {
        yield put(
            snackbarShow({
                content: trans("inv.restaurantProducts.saga.content.hide"),
            })
        );
    }
}

function* bulkCategory(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.bulk.category,
        action,
        "put"
    );

    if (response.status < 300) {
        yield put(
            snackbarShow({
                content: trans(
                    "inv.restaurantProducts.saga.content.bulkCategory"
                ),
            })
        );
    }
}

function* bulkShowUnit(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.bulk.showUnit,
        action,
        "put"
    );

    if (response.status < 300) {
        yield put(
            snackbarShow({
                content: trans(
                    "inv.restaurantProducts.saga.content.bulkShowUnit"
                ),
            })
        );
    }
}

function* bulkSizeShowUnit(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.bulk.sizeShowUnit,
        action,
        "put"
    );

    if (response.status < 300) {
        yield put(
            snackbarShow({
                content: trans(
                    "inv.restaurantProducts.saga.content.bulkSizeShowUnit"
                ),
            })
        );
    }
}

function* bulkHideProducts(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.bulkHideProducts,
        action,
        "put"
    );

    if (response.status < 300) {
        yield put(
            snackbarShow({
                content: trans("inv.restaurantProducts.saga.content.bulkHide"),
            })
        );
    }
}

function* getPriceHistory(action: AnyAction) {
    yield call(request, api.getPriceHistory, action);
}

function* combineProducts(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.combineProducts,
        action,
        "put"
    );

    if (response.status < 300) {
        yield put(
            snackbarShow({
                content: trans("inv.restaurantProducts.saga.content.combined"),
            })
        );
    }
}

function* aleretPortionIsSelected(action: AnyAction) {
    yield put(
        snackbarShow({
            content: trans(
                "inv.restaurantProducts.saga.content.combinedPortionError"
            ),
            severity: "error",
        })
    );
}

export default function* saga() {
    yield takeLatest(types.BULK_CATEGORY_REQUEST, bulkCategory);
    yield takeLatest(types.BULK_SHOW_UNIT_REQUEST, bulkShowUnit);
    yield takeLatest(types.BULK_SIZE_SHOW_UNIT_REQUEST, bulkSizeShowUnit);
    yield takeLatest(types.DESTROY_REQUEST, destroy);
    yield takeLatest(types.DESTROY_PORTIONS_REQUEST, destroyPortions);
    yield takeLatest(types.FETCH_REQUEST, fetch);
    yield takeLatest(types.GET_PRICE_HISTORY_REQUEST, getPriceHistory);
    yield takeLatest(types.GET_REQUEST, get);
    yield takeLatest(types.PRODUCT_REQUEST, product);
    yield takeLatest(types.STORE_REQUEST, store);
    yield takeLatest(types.STORE_PORTIONS_REQUEST, storePortions);
    yield takeLatest(types.UPDATE_REQUEST, update);
    yield takeLatest(types.COMBINE_PRODUCTS_REQUEST, combineProducts);
    yield takeLatest(types.HIDE_PRODUCT_REQUEST, hideProduct);
    yield takeLatest(types.BULK_HIDE_PRODUCTS_REQUEST, bulkHideProducts);
    yield takeLatest(types.PORTION_HAS_BEEN_SELECTED, aleretPortionIsSelected);
}
