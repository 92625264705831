import { Icon, IconButton } from "@mui/material";
import trans from "helpers/trans";
import { get } from "lodash";
import { FC } from "react";
import { ColorPickerInput, Table, TextField } from "ui";
import { ICustomerNumberModel } from "../interfaces";

interface ICustomerNumbersTable {
    context: any;
    id: string;
}

const CustomerNumbersTable: FC<ICustomerNumbersTable> = ({ context, id }) => {
    const handleDeleteRow = (itemIdx: number) => {
        context.onChange([
            {
                id,
                value: get(context.data, id, []).filter(
                    (item: ICustomerNumberModel, idx: number) => idx !== itemIdx
                ),
            },
        ]);
    };

    const renderNumber = (item: ICustomerNumberModel, idx: number) => {
        return (
            <TextField
                id={`${id}.${idx}.number`}
                fullWidth
                sx={{ minWidth: "150px" }}
            />
        );
    };

    const renderDescription = (item: ICustomerNumberModel, idx: number) => {
        return (
            <TextField
                id={`${id}.${idx}.description`}
                fullWidth
                sx={{ minWidth: "150px" }}
            />
        );
    };

    const renderColor = (item: ICustomerNumberModel, idx: number) => {
        return (
            <ColorPickerInput
                fullWidth
                margin="none"
                id={`${id}.${idx}.color`}
                sx={{ minWidth: "150px" }}
                defaultColor="#000000"
            />
        );
    };

    return (
        <Table
            calculateHeight={false}
            id="customerNumbersTable"
            calculateWidth={true}
            columns={[
                {
                    field: "number",
                    headName: trans(
                        "inv.locationSuppliers.table.customerNumbersTable.label.number"
                    ),
                    cellProps: {
                        size: "small",
                        sx: { whiteSpace: "nowrap" },
                    },
                    renderCell: (item, column, idx) => ({
                        children: renderNumber(item, idx),
                    }),
                },
                {
                    field: "description",
                    headName: trans(
                        "inv.locationSuppliers.table.customerNumbersTable.label.description"
                    ),
                    cellProps: {
                        size: "small",
                        sx: { whiteSpace: "nowrap" },
                    },
                    renderCell: (item, column, idx) => ({
                        children: renderDescription(item, idx),
                    }),
                },
                {
                    field: "color",
                    headName: trans(
                        "inv.locationSuppliers.table.customerNumbersTable.label.color"
                    ),
                    cellProps: {
                        size: "small",
                        sx: { whiteSpace: "nowrap" },
                    },
                    renderCell: (item, column, idx) => ({
                        children: renderColor(item, idx),
                    }),
                },
                {
                    field: "actions",
                    renderCell: (item, column, idx) => {
                        return {
                            children: (
                                <IconButton
                                    size="small"
                                    onClick={() => handleDeleteRow(idx)}
                                >
                                    <Icon>delete</Icon>
                                </IconButton>
                            ),
                        };
                    },
                },
            ]}
            rows={get(context.data, id, [])}
        />
    );
};

export default CustomerNumbersTable;
