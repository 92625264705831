import { Alert, Icon, Paper } from "@mui/material";
import useRequest from "api/useRequest";
import { modalHide } from "app/App/actions";
import { AxiosResponse } from "axios";
import trans from "helpers/trans";
import { get } from "lodash";
import { FC } from "react";
import { useDispatch } from "react-redux";
import {
    Button,
    Form,
    Modal,
    ModalContent,
    ModalTitle,
    PaperHeader,
    ModalActions,
    Checkbox,
} from "ui";
import {
    autoConnectProducts,
    posProductsConnectRestaurantProductsTableUpdate,
    posProductsTableUpdate,
} from "../actions";
import { IAutoConnectData } from "../interfaces";
import PosProductsConnectReplacementTable from "../tables/PosProductsConnectReplacementTable";

const GastrofixAutoConnectProductsModal: FC = () => {
    const dispatch = useDispatch();

    const { request, errors } = useRequest();

    // Handlers
    const handleCloseModal = () => {
        dispatch(modalHide("AutoConnectProductsModal"));
    };

    const handleAddReplace = (context: any, id: string) => {
        context.onChange([
            {
                id,
                value: [
                    ...get(context.data, id, []),
                    { search: "", replace: "" },
                ],
            },
        ]);
    };

    const handleSubmit = (data: IAutoConnectData) => {
        request(
            autoConnectProducts(data, (response: AxiosResponse) => {
                if (response.status < 300) {
                    handleCloseModal();
                    dispatch(posProductsTableUpdate("reload"));
                    dispatch(
                        posProductsConnectRestaurantProductsTableUpdate(
                            "reload"
                        )
                    );
                }
            })
        );
    };

    return (
        <Form
            data={{}}
            fields={{
                override: { default: false },
                replace: {
                    default: [],
                },
            }}
            onSubmit={() => {}}
            errors={errors}
            unsaved={false}
        >
            {(context: any) => {
                return (
                    <Modal maxWidth="sm" fullWidth open>
                        <ModalTitle onClose={handleCloseModal}>
                            {trans("inv.posProducts.actions.autoConnect")}
                        </ModalTitle>

                        <ModalContent>
                            <Alert severity="info" sx={{ mb: 1 }}>
                                Use replacement to modify GastroPlanner
                                Inventory products PLU
                            </Alert>
                            <Paper>
                                <PaperHeader
                                    title={trans("inv.posProducts.modal.title")}
                                    actions={
                                        <Button
                                            startIcon={<Icon>add</Icon>}
                                            onClick={() =>
                                                handleAddReplace(
                                                    context,
                                                    "replace"
                                                )
                                            }
                                        >
                                            {trans(
                                                "inv.posProducts.modal.addReplace"
                                            )}
                                        </Button>
                                    }
                                />
                                {context.data.replace.length !== 0 && (
                                    <PosProductsConnectReplacementTable
                                        context={context}
                                        id="replace"
                                    />
                                )}
                            </Paper>
                            <Checkbox
                                id="override"
                                label="Clear synced products and make a fresh sync"
                                formControlLabelProps={{
                                    sx: { ml: "-10px" },
                                }}
                                formControlProps={{ sx: { mt: 1 } }}
                            />
                        </ModalContent>
                        <ModalActions>
                            <Button
                                type="submit"
                                fullWidth
                                onClick={() => handleSubmit(context.data)}
                            >
                                {trans("inv.posProducts.actions.connect")}
                            </Button>
                        </ModalActions>
                    </Modal>
                );
            }}
        </Form>
    );
};

export default GastrofixAutoConnectProductsModal;
