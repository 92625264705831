import { orderBy } from "lodash";
import {
    COUNT_INVENTORY_LOADED,
    COUNT_START,
    COUNT_STOP,
    GET_LAST_INVENTORY_SUCCESS,
    PRODUCT_STORE_SUCCESS,
    PRODUCT_UPDATE_SUCCESS,
    SEARCH_PRODUCTS,
} from "../types";
import { InventoryProductModel } from "../types";
import { AnyAction } from "redux";
import { MODAL_HIDE, MODAL_SHOW } from "types";

function InventoryCountReducer(
    state: any = {
        areaId: null,
        inventory: {
            countingList: [],
            isCountingList: false,
        },
        isScanActive: true,
        ready: false,
        searchValue: "",
    },
    action: AnyAction
) {
    switch (action.type) {
        case COUNT_START:
            return {
                ...state,
                areaId: action.areaId,
            };

        case COUNT_STOP:
            return {
                ...state,
                areaId: null,
                inventory: {
                    countingList: [],
                    isCountingList: false,
                },
                ready: false,
            };

        case COUNT_INVENTORY_LOADED:
            return {
                ...state,
                inventory: {
                    ...state.inventory,
                    ...action.inventory,
                    products: orderBy(
                        action.inventory.inventory_products.filter(
                            (product: InventoryProductModel) =>
                                product.area_id === state.areaId
                        ),
                        ["id"],
                        ["desc"]
                    ),
                    countingList: orderBy(
                        state.inventory?.last_inventory?.filter(
                            (item: any) =>
                                !action.inventory.inventory_products.some(
                                    (elem: any) =>
                                        elem.restaurant_product.id ===
                                        item.restaurant_product.id
                                )
                        ),
                        ["id"],
                        ["desc"]
                    ),
                },
                ready: true,
            };

        case MODAL_HIDE:
            return {
                ...state,
                isScanActive: true,
            };

        case MODAL_SHOW:
            return {
                ...state,
                isScanActive: false,
            };

        case SEARCH_PRODUCTS:
            return {
                ...state,
                searchValue: action.searchValue,
            };

        case PRODUCT_STORE_SUCCESS:
            return {
                ...state,
                searchValue: "",
            };

        case PRODUCT_UPDATE_SUCCESS:
            return {
                ...state,
                searchValue: "",
            };

        case GET_LAST_INVENTORY_SUCCESS:
            return {
                ...state,
                inventory: {
                    ...state.inventory,
                    last_inventory:
                        action.response.data.data.inventory_products,
                    countingList: orderBy(
                        action.response.data.data.inventory_products.filter(
                            (item: any) =>
                                !state.inventory.products.some(
                                    (elem: any) =>
                                        elem.restaurant_product.id ===
                                        item.restaurant_product.id
                                )
                        ),
                        ["id"],
                        ["desc"]
                    ),
                    isCountingList: true,
                },
            };
        default:
            return state;
    }
}

export default InventoryCountReducer;
