import { registerRoutings } from "rootRouting";
import store from "rootStore";
import { configType, permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";
import { menusUrl } from "../Menus/config";
import MenuCategoriesTable from "./reducers/MenuCategoriesTableReducer";
import MenuCategoriesSaga from "./saga";

export const permissionKey = "menus.categories";
export const baseMenuCategoriesUrl = "/categories";
export const menusCategoriesUrl = `${menusUrl}/:menuId${baseMenuCategoriesUrl}`;

const getConfig = (permissions: permissionsType) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: configType = {
        permissions: {
            create: false,
            delete: false,
            update: false,
            read: false,
        },
        routing: [],
        url: menusCategoriesUrl,
    };
    let routing: routingType = [];

    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;

        store.reducerManager?.add("MenuCategoriesTable", MenuCategoriesTable);
        store.injectSaga?.("MenusCategoriesSaga", MenuCategoriesSaga);

        routing.push({
            path: menusCategoriesUrl,
            element: (
                <AsyncComponent path="MenuCategories/pages/MenusCategoriesIndexPage" />
            ),
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".create") !== -1) {
        config.permissions.create = true;

        routing.push({
            path: `${menusCategoriesUrl}/create`,
            element: (
                <AsyncComponent path="MenuCategories/pages/MenusCategoriesCreatePage" />
            ),
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
        config.permissions.update = true;

        routing.push({
            path: `${menusCategoriesUrl}/:id/edit`,
            element: (
                <AsyncComponent path="MenuCategories/pages/MenusCategoriesEditPage" />
            ),
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".delete") !== -1) {
        config.permissions.delete = true;
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
