import React from "react";

export interface IAppContext {
    drawerOpen: boolean;
    isMobile: boolean;
    isTouchable: boolean;
    onClickSideNavToggle: () => void;
    winSize: {
        contentHeight: number;
        height: number;
        navBarHeight: number;
        width: number;
    };
}
const AppContext = React.createContext<IAppContext | null>(null);

export default AppContext;
