import InventoryReportTable from "./reducers/InventoryReportTableReducer";
import InventoryReportSaga from "./saga";
import AsyncComponent from "ui/AsyncComponent";
import { registerRoutings } from "rootRouting";
import store from "rootStore";

export const permissionKey = "inventory";
export const url = "/inventory/reports";

interface IConfig {
    permissions: {
        delete: boolean;
        read: boolean;
        reopen: boolean;
    };
    routing: Array<string>;
    url: string;
}

const getConfig = (permissions: Array<string>) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: IConfig = {
        permissions: {
            delete: false,
            read: false,
            reopen: false,
        },
        routing: [],
        url,
    };

    let routing = [];

    if (modulePermissions.indexOf(permissionKey + ".reopen") !== -1) {
        config.permissions.reopen = true;
    }

    if (modulePermissions.indexOf(permissionKey + ".delete") !== -1) {
        config.permissions.delete = true;
    }

    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;

        store.reducerManager?.add("InventoryReportTable", InventoryReportTable);
        store.injectSaga?.("InventoryReportSaga", InventoryReportSaga);

        routing.push({
            path: url,
            element: (
                <AsyncComponent path="InventoryReport/pages/InventoryReportIndexPage" />
            ),
        });
        routing.push({
            path: `${url}/:id`,
            element: (
                <AsyncComponent path="InventoryReport/pages/InventoryReportShowPage" />
            ),
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
