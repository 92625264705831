import { KDRSync } from "../actions";

import { Box, Icon } from "@mui/material";
import useRequest from "api/useRequest";
import { modalHide } from "app/App/actions";
import { AxiosResponse } from "axios";
import trans from "helpers/trans";
import { FC, useRef } from "react";
import { useDispatch } from "react-redux";
import {
    Form,
    LoadingButton,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
    TextField,
} from "ui";
import { IGPFormRef } from "ui/Form/Form";
import { IKDRISync } from "../interfaces";

const KDRSyncModal: FC = () => {
    const dispatch = useDispatch();
    const formRef = useRef<IGPFormRef | null>();

    const { request, errors, isLoading, isSuccess } = useRequest();

    const handleCloseModal = () => {
        dispatch(modalHide("KDRSyncModal"));
    };

    const handleSync = () => {
        if (formRef.current !== null) {
            const data: IKDRISync = formRef?.current?.getData();
            request(
                KDRSync(data, (response: AxiosResponse) => {
                    if (response.status === 204) {
                        handleCloseModal();
                    }
                })
            );
        }
    };

    return (
        <Modal open fullWidth maxWidth="xs">
            <ModalTitle onClose={handleCloseModal}>
                {trans("modules.kdr.modal.KDRSyncModal.title")}
            </ModalTitle>
            <ModalContent>
                <Form
                    ref={formRef}
                    data={{}}
                    errors={errors}
                    fields={{
                        menu_id: {},
                    }}
                    loading={isLoading}
                    onSubmit={() => {}}
                    onSuccess={isSuccess}
                    unsaved={false}
                >
                    <TextField
                        id="menu_id"
                        fullWidth
                        label={trans("modules.kdr.modal.KDRSyncModal.menuId")}
                    />
                </Form>
            </ModalContent>
            <ModalActions>
                <Box display="flex" width="100%" textAlign="right">
                    <LoadingButton
                        loading={isLoading}
                        sx={{ flex: 1 }}
                        loadingPosition="start"
                        fullWidth
                        onClick={handleSync}
                        startIcon={<Icon>download</Icon>}
                    >
                        {trans("modules.kdr.modal.KDRSyncModal.syncButton")}
                    </LoadingButton>
                </Box>
            </ModalActions>
        </Modal>
    );
};

export default KDRSyncModal;
