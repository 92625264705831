import { AxiosRequestConfig } from "axios";
import { API_UNSUBSCRIBE } from "./types";

interface AxiosConfig {
    config?: AxiosRequestConfig;
    data?: any;
    url?: any;
}

export const request = (
    type: string,
    typeFailed: string,
    typeSuccess: string,
    queryName?: string,
    axiosConfig: AxiosConfig = {},
    onResponse?: Function,
    modifyResponse?: Function
) => ({
    type,
    typeFailed,
    typeSuccess,
    axiosConfig,
    query: {
        cacheKey: generateQueryCacheKey(axiosConfig, queryName),
    },
    modifyResponse,
    onResponse,
});

export const unsubscribe = (uid: string) => ({
    type: API_UNSUBSCRIBE,
    uid,
});

const generateQueryCacheKey = (
    axiosConfig: AxiosConfig,
    queryName?: string
) => {
    if (!queryName) {
        return queryName;
    }

    return `
        ${queryName}(${JSON.stringify({
        config: axiosConfig.config,
        url: axiosConfig.url,
    })})`;
};
