import moment from 'moment-timezone';

moment.updateLocale("en", {
	week: {
		dow: 1
	}
});

const momentApp = (...args) => moment(...args).tz('Europe/Oslo');

export default momentApp;