import { FETCH_SUCCESS } from "../types";
import { tableResponse, tableUpdate } from "helpers/reducers";
import { MANAGE_TABLE } from "app/ManageTables/types";
import { TABLE_UPDATE } from "types";

function DeliverySlipDetailsTable(
    state: any = {
        id: "DeliverySlipLines",
        pagination: {
            isLastPage: true,
            page: 1,
            per_page: 20,
            total: 0,
        },
        search: "",
        selection: {
            id: "id",
            selected: [],
        },
        selectionActions: null,
    },
    action: any
) {
    switch (action.type) {
        case FETCH_SUCCESS:
            if (action.response.config.params._table_id === state.id) {
                return tableResponse(state, action);
            }
            return state;

        case MANAGE_TABLE:
            if (action.data.id === state.id) {
                const columnType = action.data.type;
                return {
                    ...state,
                    [columnType]: action.data,
                };
            }
            return state;

        case TABLE_UPDATE:
            return tableUpdate(state, action);
        default:
            return state;
    }
}

export default DeliverySlipDetailsTable;
