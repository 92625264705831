import { SettingsPortionsModel, SettingsPortionsToDBModel } from "./interfaces";
import types from "./types";
import { request } from "api/actions";
import { AxiosRequestConfig } from "axios";

export const settingsPortionsFetch = (config?: AxiosRequestConfig) =>
    request(
        types.FETCH_REQUEST,
        types.FETCH_FAILED,
        types.FETCH_SUCCESS,
        "settingsPortionsFetch",
        {
            config,
        }
    );

export const settingsPortionsCreate = (
    data: SettingsPortionsModel,
    onResponse?: Function
) =>
    request(
        types.CREATE_REQUEST,
        types.CREATE_FAILED,
        types.CREATE_SUCCESS,
        "settingsPortionsCreate",
        { data },
        onResponse
    );

export const settingsPortionsDelete = (id: number, onResponse?: Function) =>
    request(
        types.DELETE_REQUEST,
        types.DELETE_FAILED,
        types.DELETE_SUCCESS,
        "settingsPortionsDelete",
        {
            url: {
                id,
            },
        },
        onResponse
    );

export const settingsPortionsGet = (id: number, config?: AxiosRequestConfig) =>
    request(
        types.GET_REQUEST,
        types.GET_FAILED,
        types.GET_SUCCESS,
        "settingsPortionsGet",
        {
            config,
            url: {
                id,
            },
        }
    );

export const settingsPortionsUpdate = (
    id: number,
    data: SettingsPortionsToDBModel,
    onResponse?: Function
) => {
    return request(
        types.UPDATE_REQUEST,
        types.UPDATE_FAILED,
        types.UPDATE_SUCCESS,
        "settingsPortionsUpdate",
        { data, url: { id } },
        onResponse
    );
};

export const settingsPortionsTableUpdate = (action: string, data: any) => ({
    type: types.TABLE_UPDATE,
    action,
    data,
});
