import { ICounterKeyboard } from "../interfaces";

import { Box, Icon, Stack } from "@mui/material";
import { styled } from "@mui/system";
import { FC } from "react";
import { Button } from "ui";

const ClearButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.grey[400],
    color: "#000",
    height: 39,
    padding: "0px 12px",
    "&:hover": {
        backgroundColor: theme.palette.grey[400],
    },
}));

const WhiteButton = styled(Button)({
    backgroundColor: "#FFF",
    color: "#000",
    fontSize: 18,
    fontWeight: 400,
    justifyContent: "flex-start",
    padding: "4px 12px",
    "&:hover": {
        backgroundColor: "#FFF",
    },
});

const CounterKeyboard: FC<ICounterKeyboard> = ({ onClear, onComma, onNumberKey, quantity }) => {
    return (
        <Stack
            sx={{
                alignItems: "center",
                width: "100%",
            }}
        >
            <Box
                sx={{
                    backgroundColor: "grey.200",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                }}
            >
                <Box component="table" sx={{ borderSpacing: 6 }}>
                    <tbody>
                        <tr>
                            <td>
                                <WhiteButton onClick={onNumberKey(1)}>
                                    1
                                </WhiteButton>
                            </td>
                            <td>
                                <WhiteButton onClick={onNumberKey(2)}>
                                    2
                                </WhiteButton>
                            </td>
                            <td>
                                <WhiteButton onClick={onNumberKey(3)}>
                                    3
                                </WhiteButton>
                            </td>
                            <td>
                                <ClearButton onClick={onClear}>
                                    <Icon>backspace</Icon>
                                </ClearButton>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <WhiteButton onClick={onNumberKey(4)}>
                                    4
                                </WhiteButton>
                            </td>
                            <td>
                                <WhiteButton onClick={onNumberKey(5)}>
                                    5
                                </WhiteButton>
                            </td>
                            <td>
                                <WhiteButton onClick={onNumberKey(6)}>
                                    6
                                </WhiteButton>
                            </td>
                            <td>
                                <WhiteButton
                                    disabled={quantity.doubleValue}
                                    onClick={onComma}
                                >
                                    .
                                </WhiteButton>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <WhiteButton onClick={onNumberKey(7)}>
                                    7
                                </WhiteButton>
                            </td>
                            <td>
                                <WhiteButton onClick={onNumberKey(8)}>
                                    8
                                </WhiteButton>
                            </td>
                            <td>
                                <WhiteButton onClick={onNumberKey(9)}>
                                    9
                                </WhiteButton>
                            </td>
                            <td>
                                <WhiteButton onClick={onNumberKey(0)}>
                                    0
                                </WhiteButton>
                            </td>
                        </tr>
                    </tbody>
                </Box>
            </Box>
        </Stack>
    );
}

export default CounterKeyboard;
