import api from "./api";
import { request } from "api/apiSaga";
import { snackbarShow } from "app/App/actions";
import trans from "helpers/trans";
import { call, delay, put, takeLatest, throttle } from "redux-saga/effects";
import { AnyAction } from "redux";
import types from "./types";

function* destroy(action: AnyAction): any {
    const response = yield call(request, api.destroy, action, "delete");

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("inv.products.saga.content.destroy"),
            })
        );
    }
}

export function* fetch(action: AnyAction) {
    yield call(request, api.fetch, action);
}

function* get(action: AnyAction) {
    yield call(request, api.get, action);
}

function* store(action: AnyAction): any {
    const response = yield call(request, api.store, action, "post");

    if (response.status === 201) {
        yield put(
            snackbarShow({
                content: trans("inv.products.saga.content.store"),
            })
        );
    }
}

function* update(action: AnyAction): any {
    const response = yield call(request, api.update, action, "put");

    if (response.status === 200) {
        yield put(
            snackbarShow({
                content: trans("inv.products.saga.content.update"),
            })
        );
    }
}

function* vintage(action: AnyAction) {
    yield call(request, api.vintage, action, "post");
}

function* productSuppliers(action: AnyAction) {
    yield call(request, api.productSuppliers, action);
    yield delay(500);
  }
export default function* saga() {
    yield takeLatest(types.DESTROY_REQUEST, destroy);
    yield takeLatest(types.FETCH_REQUEST, fetch);
    yield takeLatest(types.GET_REQUEST, get);
    yield takeLatest(types.STORE_REQUEST, store);
    yield takeLatest(types.UPDATE_REQUEST, update);
    yield takeLatest(types.VINTAGE_REQUEST, vintage);
    yield throttle(500, types.PRODUCT_SUPPLIERS_REQUEST, productSuppliers);
}
