import { IColumn } from "app/ManageTables/interfaces";
import { useMemo } from "react";

const useTableProps = (table: any) => {
    const params = useMemo(() => {
        let search = undefined;
        if (table.search !== undefined && table.search.length > 0) {
            search = table.search;
        }

        return {
            page: table.pagination?.page,
            per_page: table.pagination?.per_page,
            _columns: table.columns.map((column: IColumn) => column.field),
            _search: search,
            _sort: table.sort,
            _filters: table.filters,
            _table_id: table.id,
            _with: table.with,
            _withCount: table.withCount,
            _r: table.reload,
        };
    }, [table]);

    return params;
};

export default useTableProps;
