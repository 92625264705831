import { IPortionContext, IPortionsTable } from "../interfaces";
import { SettingsPortionsModel } from "app/SettingsPortions/interfaces";
import { Icon, IconButton, Box, MenuItem, Divider } from "@mui/material";
import { settingsPortionsFetch } from "app/SettingsPortions/actions";
import useRequest from "api/useRequest";
import trans from "helpers/trans";
import { unitSymbol } from "helpers/units";
import { get } from "lodash";
import { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Table, Select, Loader, RequestMessage, NumberField } from "ui";
import usePermission from "hooks/usePermission";

const PortionsTable: FC<IPortionsTable> = ({ context, disabled, item, id }) => {
    const [defaultPortion, setDefaultPortion] = useState<number>();
    const permission = usePermission("Settings", "update");
    const { categories } = useSelector((state: any) => {
        return {
            categories: state.app.settings.categories,
        };
    });

    const { data, isError, status, isPending } = useRequest(
        settingsPortionsFetch({ params: { _sort: { name: "asc" } } })
    );

    useEffect(() => {
        if (
            get(context.data, id, [])?.[0]?.portion_id === "" &&
            defaultPortion
        ) {
            context.onChange([
                { id: id + ".0.portion_id", value: defaultPortion },
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultPortion]);

    const productCategoriesHierarchy = useMemo(() => {
        const categoryHierarchy: number[] = [];
        if (item?.product?.category_id) {
            let parentCategory =
                categories[item.product.category_id]?.parent_id;
            categoryHierarchy.push(item.product.category_id);

            while (parentCategory) {
                const category = categories[parentCategory];
                categoryHierarchy.push(category.id);
                parentCategory = category.parent_id;
            }
        }

        return categoryHierarchy;
    }, [item, categories]);

    const isPortionMatching = (categoryId: number) => {
        return productCategoriesHierarchy.includes(categoryId);
    };

    const renderPortion = (portion: SettingsPortionsModel) => {
        return (
            <MenuItem
                value={portion.id}
                key={`portion_id-${portion.id}`}
                id={portion.default_category_id}
            >
                {portion.name} {portion.size}
                {unitSymbol[portion.unit as keyof typeof unitSymbol]}
            </MenuItem>
        );
    };

    const preparePortions = useMemo(() => {
        if (!data) return [];

        const usedCat: any = [];
        const restCat: any = [];

        const defPortion = data?.data
            ?.filter((portion: SettingsPortionsModel) =>
                isPortionMatching(portion.default_category_id)
            )
            .sort((a: SettingsPortionsModel, b: SettingsPortionsModel) => {
                return (
                    productCategoriesHierarchy.indexOf(a.default_category_id) -
                    productCategoriesHierarchy.indexOf(b.default_category_id)
                );
            })[0];

        data?.data
            ?.filter(
                (portion: SettingsPortionsModel) =>
                    defPortion?.id !== portion.id
            )
            .forEach((portion: SettingsPortionsModel) => {
                const usedInCategories = portion.used_in_categories.filter(
                    (used: number) => isPortionMatching(used)
                );

                if (usedInCategories.length > 0) {
                    usedCat.push(portion);
                } else {
                    restCat.push(portion);
                }
            });

        const preparedPortions: Array<React.ReactNode> = [];
        if (defPortion) {
            setDefaultPortion(defPortion.id);
            preparedPortions.push(renderPortion(defPortion));
        }
        if (usedCat.length > 0) {
            usedCat.forEach((item: SettingsPortionsModel) =>
                preparedPortions.push(renderPortion(item))
            );
        }
        if (preparedPortions.length > 0 && restCat.length > 0) {
            preparedPortions.push(<Divider key={`divider-${item.id}`} />);
        }
        restCat.forEach((portion: any) =>
            preparedPortions.push(renderPortion(portion))
        );

        return preparedPortions;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleDeleteRow = (itemIdx: number) => {
        context.onChange([
            {
                id,
                value: get(context.data, id, []).filter(
                    (item: IPortionContext, idx: number) => idx !== itemIdx
                ),
            },
        ]);
    };

    const renderPLU = (item: IPortionContext, idx: number) => {
        return (
            <Box
                sx={{ width: "100%", minWidth: "200px", display: "flex" }}
                key={`portion_plu-${item.portion_id}`}
            >
                <NumberField
                    id={`${id}.${idx}.plu`}
                    variant="outlined"
                    fullWidth
                    size="small"
                    disabled={Boolean(disabled)}
                />
            </Box>
        );
    };

    const renderPortions = (item: IPortionContext, idx: number) => {
        return (
            <Box
                sx={{ width: "100%", minWidth: "200px", display: "flex" }}
                key={`portions-select-${item.portion_id}`}
            >
                <Select
                    fullWidth
                    id={`${id}.${idx}.portion_id`}
                    type="number"
                    disabled={Boolean(disabled)}
                >
                    <MenuItem value="">
                        <em>
                            {trans(
                                "inv.restaurantProducts.table.portionsTable.select.portion"
                            )}
                        </em>
                    </MenuItem>
                    {preparePortions}
                </Select>
            </Box>
        );
    };

    if (!permission) return null;
    if (isPending) return <Loader />;

    if (isError) return <RequestMessage status={status} />;

    return (
        <Table
            calculateHeight={false}
            id="PortionsTable"
            calculateWidth={true}
            columns={[
                {
                    field: "portion_id",
                    headName: trans(
                        "inv.restaurantProducts.table.portionsTable.headName.portion"
                    ),
                    cellProps: {
                        size: "small",
                        sx: { whiteSpace: "nowrap" },
                    },
                    renderCell: (item, column, idx) => ({
                        children: renderPortions(item, idx),
                    }),
                },
                {
                    field: "plu",
                    headName: trans(
                        "inv.restaurantProducts.table.portionsTable.headName.plu"
                    ),
                    cellProps: {
                        size: "small",
                        sx: { whiteSpace: "nowrap" },
                    },
                    renderCell: (item, column, idx) => {
                        return {
                            children: renderPLU(item, idx),
                        };
                    },
                },
                {
                    field: "actions",
                    renderCell: (item, column, idx) => {
                        return {
                            children: (
                                <IconButton
                                    size="small"
                                    onClick={() => handleDeleteRow(idx)}
                                    key={`delete-portion-row-${item.id}`}
                                    disabled={Boolean(disabled)}
                                >
                                    <Icon>delete</Icon>
                                </IconButton>
                            ),
                        };
                    },
                },
            ]}
            rows={get(context.data, id, [])}
        />
    );
};

export default PortionsTable;
