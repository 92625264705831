import { FETCH_SUCCESS, TABLE_UPDATE } from "../types";
import { tableResponse, tableUpdate } from "helpers/reducers";
import trans from "helpers/trans";
import { IGPTable } from "ui/Table/Table";
import { AnyAction } from "redux";

function InvoicesTable(
    state: IGPTable = {
        id: "InvoicesTable",
        columns: [
            {
                field: "customer_name",
                headName: trans(
                    "inv.invoices.reducer.invoicesTableReducer.headName.customerName"
                ),

                sortable: true,
            },
            {
                field: "customer_city",
                headName: trans(
                    "inv.invoices.reducer.invoicesTableReducer.headName.customerCity"
                ),
                sortable: true,
            },
            {
                field: "total_gross",
                headName: trans(
                    "inv.invoices.reducer.invoicesTableReducer.headName.totalGross"
                ),
                sortable: true,
            },
            {
                field: "currency",
                headName: trans(
                    "inv.invoices.reducer.invoicesTableReducer.headName.currency"
                ),
                sortable: true,
            },
            {
                field: "actions",
            },
        ],
        filters: {},
        pagination: {
            page: 1,
            per_page: 20,
            isLastPage: true,
            total: 0,
        },
        search: "",
        sort: {
            // name: "asc"
        },
    },
    action: AnyAction
) {
    switch (action.type) {
        case FETCH_SUCCESS:
            if (action.response.config.params._table_id === state.id) {
                return tableResponse(state, action);
            }
            return state;
        case TABLE_UPDATE:
            return tableUpdate(state, action);
        default:
            return state;
    }
}

export default InvoicesTable;
