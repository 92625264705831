import types from "./types";
import { request } from "api/actions";
import { IKDRISync, IKDRSettings } from "./interfaces";

export const KDRUpdate = (data: IKDRSettings, onResponse?: Function) => {
    return request(
        types.UPDATE_REQUEST,
        types.UPDATE_FAILED,
        types.UPDATE_SUCCESS,
        "KDRUpdate",
        { data },
        onResponse
    );
};

export const KDRSync = (data: IKDRISync, onResponse?: Function) => {
    return request(
        types.SYNC_REQUEST,
        types.SYNC_FAILED,
        types.SYNC_SUCCESS,
        "KDRSync",
        { data },
        onResponse
    );
};
