import { MANAGE_TABLE } from "app/ManageTables/types";
import { tableResponse, tableUpdate } from "helpers/reducers";
import { AnyAction } from "redux";
import { TABLE_UPDATE } from "../types";
import { IGPTable } from "ui/Table/Table";
import { FETCH_SUCCESS } from "../types";

function MenuListsTable(
    state: IGPTable = {
        id: "MenuListsTable",
        columns: [],
        availableColumns: [],
        pagination: {
            page: 1,
            per_page: 20,
            isLastPage: true,
            total: 0,
        },
        search: "",
        filters: {},
        filterData: [],
        filterSettings: {
            open: false,
            total: 0,
        },
        sort: { name: "asc" },
        with: [],
    },
    action: AnyAction
) {
    switch (action.type) {
        case FETCH_SUCCESS:
            if (action.response.config.params._table_id === state.id) {
                return tableResponse(state, action);
            }
            return state;
        case MANAGE_TABLE:
            if (action.data.id === state.id) {
                return {
                    ...state,
                    ...action.data,
                };
            }
            return state;
        case TABLE_UPDATE:
            return tableUpdate(state, action);
        default:
            return state;
    }
}

export default MenuListsTable;
