import { request } from "api/actions";
import { AxiosRequestConfig } from "axios";
import types from "./types";

export const suppliersGet = (id: number, config?: AxiosRequestConfig,  onResponse?: Function) =>
    request(
        types.GET_REQUEST,
        types.GET_FAILED,
        types.GET_SUCCESS,
        "locationSuppliersGet",
        {
            config,
            url: {
                id,
            },
        },
        onResponse
    );

export const suppliersFetch = (config?: AxiosRequestConfig) =>
    request(
        types.FETCH_REQUEST,
        types.FETCH_FAILED,
        types.FETCH_SUCCESS,
        "locationSuppliersFetch",
        {
            config,
        }
    );

export const suppliersDelete = (id: number, onResponse?: Function) =>
    request(
        types.DELETE_REQUEST,
        types.DELETE_FAILED,
        types.DELETE_SUCCESS,
        "locationSuppliersDelete",
        {
            url: {
                id,
            },
        },
        onResponse
    );

export const suppliersCreate = (data: any, onResponse?: Function) => {
    return request(
        types.CREATE_REQUEST,
        types.CREATE_FAILED,
        types.CREATE_SUCCESS,
        "locationSuppliersCreate",
        {
            data,
        },
        onResponse
    );
};

export const suppliersUpdate = (id: number, data: any, onResponse?: Function) =>
    request(
        types.UPDATE_REQUEST,
        types.UPDATE_FAILED,
        types.UPDATE_SUCCESS,
        "locationSuppliersUpdate",
        {
            data,
            url: { id },
        },
        onResponse
    );

export const suppliersTableUpdate = (action: string, data: any) => ({
    type: types.TABLE_UPDATE,
    action,
    data,
});
