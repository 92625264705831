import {
    Box,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { alertPortionIsSelected } from "app/RestaurantProducts/actions";
import {
    IReducerActionProductConnect,
    IReducerStateProductConnect,
    RestaurantProductConnectModel,
    RestaurantProductPortionModel,
} from "app/RestaurantProducts/interfaces";
import trans from "helpers/trans";
import { unitSymbol } from "helpers/units";
import { ChangeEvent, Dispatch, FC, SetStateAction, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Checkbox, Form, NumberField, Price, TextField } from "ui";

const columns = [
    "Product",
    "Master",
    "Name",
    "Price",
    "Market price",
    "PLU",
    "Min in stock",
    "Order Quantity",
    "Portions",
];

interface IRestaurantProductConnectTable {
    rows: RestaurantProductConnectModel[] | undefined;
    state: IReducerStateProductConnect;
    isGlobalProduct: boolean;
    stock: number;
    formErrors: any;
    dispatch: Dispatch<IReducerActionProductConnect>;
    portions?: { id: number; portion_id: number }[];
    setPortions: Dispatch<
        SetStateAction<
            {
                id: number;
                portion_id: number;
            }[]
        >
    >;
}

const RestaurantProductConnectTable: FC<IRestaurantProductConnectTable> = ({
    rows,
    state,
    dispatch,
    formErrors,
    stock,
    portions,
    setPortions,
    isGlobalProduct,
}) => {
    const reduxDispatch = useDispatch();
    const masterSelected = useMemo(() => {
        return state.target !== "";
    }, [state.target]);

    const stickyColStyle = {
        position: "sticky",
        backgroundColor: "#fff",
        left: 0,
        zIndex: 3,
    };

    const cellDefaultStyle = {
        borderRight: "1px solid rgba(224, 224, 224, 1)",
        py: 1,
    };

    const radioCellStyle = {
        px: 0.8,
    };

    const handleChangeInput = (event: any) => {
        const reducerStateKey = event.id.split(
            "."
        )[1] as keyof IReducerStateProductConnect["atributes"];

        if (state.atributes[reducerStateKey].value !== event.value) {
            dispatch({
                type: "atributes",
                payload: {
                    name: reducerStateKey,
                    value: event.value,
                    id: 0,
                },
            });
        }
    };

    const handleChangeRadioAttributes = (
        e: ChangeEvent<HTMLInputElement>,
        value: string | number,
        id: number
    ) => {
        dispatch({
            type: "atributes",
            payload: {
                name: e.target.name,
                value: value,
                id: id,
            },
        });
    };

    const handleChangeMasterProduct = (item: RestaurantProductConnectModel) => {
        const id = item.id;
        if (state.target === "") {
            dispatch({
                type: "default_global",
                payload: {
                    name: "global",
                    value: "",
                    id: id,
                    default: {
                        target: item.id,
                        atributes: {
                            name: {
                                id,
                                value: item.name,
                            },
                            price: {
                                id,
                                value: item.price,
                            },
                            market_price: {
                                id,
                                value: item.market_price,
                            },
                            plu: {
                                id,
                                value: item.plu,
                            },
                            min_in_stock: {
                                id,
                                value: item.min_in_stock,
                            },
                            quantity_to_be_ordered: {
                                id,
                                value: item.quantity_to_be_ordered,
                            },
                        },
                    },
                },
            });
        } else {
            dispatch({
                type: "target",
                payload: {
                    name: "target",
                    value: item.id,
                    id: id,
                },
            });
        }
    };

    const handleClickPortionCheckbox = (id: number, portion_id: number) => {
        const isInSelectedPortions = portions?.find((obj) => obj.id === id);

        const isSelectedTargetPortion = portions?.find(
            (obj) => obj.portion_id === portion_id
        );

        if (isInSelectedPortions) {
            let newPortions = portions?.filter((portion) => portion.id !== id);
            setPortions(newPortions ? newPortions : []);
        }

        if (!isInSelectedPortions && !isSelectedTargetPortion) {
            setPortions((prev) => [...prev, { id, portion_id }]);
        } else {
            reduxDispatch(alertPortionIsSelected());
        }
    };

    const handleCheckIsRadioChecked = (
        id: number,
        key: string,
        itemVal: number | string
    ) => {
        const reducerStateKey =
            key as keyof IReducerStateProductConnect["atributes"];
        const isRadioChecked =
            state.atributes[reducerStateKey].id === id &&
            state.atributes[reducerStateKey].value === itemVal;
        return isRadioChecked;
    };

    return (
        <Form
            data={state.atributes}
            fields={{
                attributes: {
                    name: {},
                    price: {},
                    market_price: {},
                    plu: {},
                    min_in_stock: {},
                    quantity_to_be_ordered: {},
                },
            }}
            errors={formErrors}
            unsaved={false}
            onSubmit={() => {}}
        >
            <Table
                sx={{
                    border: "1px solid rgba(224,224,224,1)",
                    borderBottom: "none",
                    borderCollapse: "separate",
                }}
            >
                <TableHead>
                    <TableRow>
                        {columns.map((column, idx) => {
                            let sx: any = {
                                py: 1,
                                borderRight: "1px solid rgba(224, 224, 224, 1)",
                                minWidth: "140px",
                            };
                            if (column === "Product") {
                                sx = {
                                    ...sx,
                                    ...stickyColStyle,
                                };
                            }
                            return (
                                <TableCell key={`head-cell-${idx}`} sx={sx}>
                                    {column}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows?.map(
                        (item: RestaurantProductConnectModel, idx: number) => {
                            const boxSx = {
                                display: "flex",
                                flexWrap: "nowrap",
                                alignItems: "center",
                                ".MuiTypography-caption": {
                                    fontSize: "14px",
                                },
                            };

                            return (
                                <TableRow
                                    key={idx}
                                    sx={{
                                        "&:hover": {
                                            backgroundColor:
                                                "#ebebeb!important",
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            ...cellDefaultStyle,
                                            ...stickyColStyle,
                                        }}
                                    >
                                        {item.name}{" "}
                                        {item.product?.vintage &&
                                            `(${item.product.vintage})`}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            ...cellDefaultStyle,
                                            width: "50px",
                                        }}
                                    >
                                        <Radio
                                            value={item.name}
                                            checked={state.target === item.id}
                                            onChange={() => {
                                                handleChangeMasterProduct(item);
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            ...cellDefaultStyle,
                                            minWidth: "300px",
                                        }}
                                    >
                                        <Radio
                                            name="name"
                                            sx={{ fontSize: "14px" }}
                                            value={item.name}
                                            checked={handleCheckIsRadioChecked(
                                                item.id,
                                                "name",
                                                item.name
                                            )}
                                            onChange={(e) =>
                                                handleChangeRadioAttributes(
                                                    e,
                                                    item.name,
                                                    item.id
                                                )
                                            }
                                            disabled={!masterSelected}
                                        />
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            ...cellDefaultStyle,
                                            ...radioCellStyle,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                ...boxSx,
                                            }}
                                        >
                                            <Radio
                                                name="price"
                                                value={item.price}
                                                checked={handleCheckIsRadioChecked(
                                                    item.id,
                                                    "price",
                                                    item.price
                                                )}
                                                sx={{}}
                                                onChange={(e) =>
                                                    handleChangeRadioAttributes(
                                                        e,
                                                        item.price,
                                                        item.id
                                                    )
                                                }
                                                disabled={!masterSelected}
                                            />
                                            <Typography variant="caption">
                                                {item.price.price()}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            ...cellDefaultStyle,
                                            ...radioCellStyle,
                                        }}
                                    >
                                        <Box sx={{ ...boxSx }}>
                                            <Radio
                                                name="market_price"
                                                value={item.market_price}
                                                checked={handleCheckIsRadioChecked(
                                                    item.id,
                                                    "market_price",
                                                    item.market_price
                                                )}
                                                disabled={!masterSelected}
                                                onChange={(e) =>
                                                    handleChangeRadioAttributes(
                                                        e,
                                                        item.market_price,
                                                        item.id
                                                    )
                                                }
                                            />
                                            <Typography variant="caption">
                                                {item.market_price.price()}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            ...cellDefaultStyle,
                                            ...radioCellStyle,
                                        }}
                                    >
                                        <Box sx={{ ...boxSx }}>
                                            <Radio
                                                name="plu"
                                                value={item.plu ? item.plu : ""}
                                                checked={handleCheckIsRadioChecked(
                                                    item.id,
                                                    "plu",
                                                    item.plu
                                                )}
                                                onChange={(e) =>
                                                    handleChangeRadioAttributes(
                                                        e,
                                                        item.plu,
                                                        item.id
                                                    )
                                                }
                                                disabled={!masterSelected}
                                            />
                                            <Typography variant="caption">
                                                {item.plu ? item.plu : "-"}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            ...cellDefaultStyle,
                                            ...radioCellStyle,
                                        }}
                                    >
                                        <Box sx={{ ...boxSx }}>
                                            <Radio
                                                name="min_in_stock"
                                                value={
                                                    item.min_in_stock
                                                        ? item.min_in_stock
                                                        : ""
                                                }
                                                checked={handleCheckIsRadioChecked(
                                                    item.id,
                                                    "min_in_stock",
                                                    item.min_in_stock
                                                )}
                                                onChange={(e) =>
                                                    handleChangeRadioAttributes(
                                                        e,
                                                        item.min_in_stock,
                                                        item.id
                                                    )
                                                }
                                                disabled={!masterSelected}
                                            />
                                            <Typography variant="caption">
                                                {item.min_in_stock
                                                    ? item.min_in_stock
                                                    : "-"}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            ...cellDefaultStyle,
                                            ...radioCellStyle,
                                        }}
                                    >
                                        <Box sx={{ ...boxSx }}>
                                            <Radio
                                                name="quantity_to_be_ordered"
                                                value={
                                                    item.quantity_to_be_ordered
                                                        ? item.quantity_to_be_ordered
                                                        : ""
                                                }
                                                checked={handleCheckIsRadioChecked(
                                                    item.id,
                                                    "quantity_to_be_ordered",
                                                    item.quantity_to_be_ordered
                                                )}
                                                onChange={(e) =>
                                                    handleChangeRadioAttributes(
                                                        e,
                                                        item.quantity_to_be_ordered,
                                                        item.id
                                                    )
                                                }
                                                disabled={!masterSelected}
                                            />
                                            <Typography variant="caption">
                                                {item.quantity_to_be_ordered
                                                    ? item.quantity_to_be_ordered
                                                    : "-"}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            ...cellDefaultStyle,
                                            ...radioCellStyle,
                                            ".MuiFormHelperText-root": {
                                                p: 0,
                                                m: 0,
                                                mt: -1.5,
                                                pl: 5,
                                            },
                                        }}
                                    >
                                        {item.restaurant_product_portions?.map(
                                            (
                                                portion: RestaurantProductPortionModel
                                            ) => {
                                                const isInArr = portions?.find(
                                                    (obj: any) =>
                                                        obj.id === portion.id
                                                );

                                                return (
                                                    <Checkbox
                                                        sx={{
                                                            "& + .MuiFormControlLabel-label":
                                                                {
                                                                    whiteSpace:
                                                                        "nowrap",
                                                                },
                                                            "& + .MuiFormHelperText-root":
                                                                {
                                                                    color: "#ff00ff",
                                                                },
                                                        }}
                                                        key={`portion_${portion.id}`}
                                                        id={`${portion.id}_checkbox`}
                                                        checked={
                                                            isInArr?.id ===
                                                            portion.id
                                                        }
                                                        onClick={() =>
                                                            handleClickPortionCheckbox(
                                                                portion.id,
                                                                portion.portion
                                                                    .id
                                                            )
                                                        }
                                                        helperText={`(${portion?.plu})`}
                                                        label={`${
                                                            portion.portion.name
                                                        } ${
                                                            portion.portion.size
                                                        }
                                                        ${
                                                            unitSymbol[
                                                                portion.portion
                                                                    .unit as keyof typeof unitSymbol
                                                            ]
                                                        }`}
                                                    />
                                                );
                                            }
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        }
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell
                            sx={{
                                ...stickyColStyle,
                                borderRight: "1px solid rgba(224, 224, 224, 1)",
                            }}
                        />
                        <TableCell sx={cellDefaultStyle}>-</TableCell>
                        <TableCell sx={cellDefaultStyle}>
                            <TextField
                                id="attributes.name"
                                value={state.atributes.name.value}
                                fullWidth
                                onChange={handleChangeInput}
                            />
                        </TableCell>
                        <TableCell sx={cellDefaultStyle}>
                            <Price
                                id="attributes.price"
                                value={state.atributes.price.value}
                                fullWidth
                                onChange={handleChangeInput}
                            />
                        </TableCell>
                        <TableCell sx={cellDefaultStyle}>
                            <Price
                                id="attributes.market_price"
                                value={state.atributes.market_price.value}
                                fullWidth
                                onChange={handleChangeInput}
                            />
                        </TableCell>
                        <TableCell sx={cellDefaultStyle}>
                            <NumberField
                                id="attributes.plu"
                                value={
                                    state.atributes.plu.value
                                        ? state.atributes.plu.value
                                        : ""
                                }
                                fullWidth
                                onChange={handleChangeInput}
                            />
                        </TableCell>
                        <TableCell sx={cellDefaultStyle}>
                            <TextField
                                id="attributes.min_in_stock"
                                value={
                                    state.atributes.min_in_stock.value
                                        ? state.atributes.min_in_stock.value
                                        : ""
                                }
                                fullWidth
                                onChange={handleChangeInput}
                            />
                        </TableCell>
                        <TableCell sx={cellDefaultStyle}>
                            <TextField
                                id="attributes.quantity_to_be_ordered"
                                value={
                                    state.atributes.quantity_to_be_ordered.value
                                        ? state.atributes.quantity_to_be_ordered
                                              .value
                                        : ""
                                }
                                fullWidth
                                onChange={handleChangeInput}
                            />
                        </TableCell>
                        <TableCell sx={cellDefaultStyle} />
                    </TableRow>

                    <TableRow>
                        <TableCell
                            colSpan={2}
                            sx={{
                                ...stickyColStyle,
                                fontSize: "1rem",
                                fontWeight: 700,
                            }}
                        >
                            {trans(
                                "inv.restaurantProducts.modal.RestaurantProductConnectModal.totalStock"
                            )}{" "}
                            {Number.isInteger(stock) ? stock : stock.toFixed(2)}
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </Form>
    );
};

export default RestaurantProductConnectTable;
