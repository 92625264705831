import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";

const requests = {
    appSettings: (config: AxiosRequestConfig) => api.get(`/settings`, config),
    translate: (config: AxiosRequestConfig) => api.get(`/translate`, config),
    update: (data: object, config: AxiosRequestConfig) =>
        api.put(`/settings`, data, config),
};

export default requests;
