import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlId from "types/RequestUrlId";

const requests = {
    bulk: {
        category: (data: object, config: AxiosRequestConfig) =>
            api.put(`/restaurant-products/bulk/category`, data, config),
        showUnit: (data: object, config: AxiosRequestConfig) =>
            api.put(`/restaurant-products/bulk/show-unit`, data, config),
        sizeShowUnit: (data: object, config: AxiosRequestConfig) =>
            api.put(`/restaurant-products/bulk/size-show-unit`, data, config),
    },
    destroy: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.delete(`/restaurant-products/${url.id}`, config),
    destroyPortions: (data: object, config: AxiosRequestConfig) =>
        api.put(`/restaurant-products/bulk/portions-delete`, data, config),
    fetch: (config: AxiosRequestConfig) =>
        api.get(`/restaurant-products`, config),
    get: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/restaurant-products/${url.id}`, config),
    getPriceHistory: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`restaurant-products/${url.id}/price-history`, config),
    product: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.post(`/restaurant-products/product/${url.id}`, data, config),
    store: (data: object, config: AxiosRequestConfig) =>
        api.post(`/restaurant-products`, data, config),
    storePortions: (data: object, config: AxiosRequestConfig) =>
        api.post(`restaurant-products/bulk/portions`, data, config),
    update: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.put(`/restaurant-products/${url.id}`, data, config),
    combineProducts: (
        data: object,
        config: AxiosRequestConfig,
        url: RequestUrlId
    ) => api.put(`/restaurant-products/${url.id}/combine`, data, config),
    hideProduct: (
        data: object,
        config: AxiosRequestConfig,
        url: RequestUrlId
    ) => api.put(`/restaurant-products/${url.id}/hide`, config),
    bulkHideProducts: (data: object, config: AxiosRequestConfig) =>
        api.put(`/restaurant-products/bulk/hide`, data, config),
};

export default requests;
