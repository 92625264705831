import api from "./api";
import types from "./types";

import appApi from "app/App/api";
import { appSettings as appSettingsRequest } from "app/App/actions";
import { request } from "api/apiSaga";
import { call, put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";
import { snackbarShow } from "app/App/actions";
import trans from "helpers/trans";

function* sync(action: AnyAction): any {
    const response = yield call(request, api.sync, action, "post");
    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("modules.gastrofix.saga.content.sync"),
            })
        );
    }
}

function* update(action: AnyAction): any {
    const response = yield call(request, api.update, action, "put");
    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: trans("modules.kdr.saga.content.update"),
            })
        );
        yield call(appSettings, appSettingsRequest());
    }
}

function* appSettings(action: AnyAction): any {
    return yield call(request, appApi.appSettings, action);
}

export default function* saga() {
    yield takeLatest(types.SYNC_REQUEST, sync);
    yield takeLatest(types.UPDATE_REQUEST, update);
}
