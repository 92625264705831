import { accounts, api } from "apiConnections";
import { AxiosRequestConfig } from "axios";

const requests = {
    logout: (data: object, config: AxiosRequestConfig) =>
        accounts.post(`/api/logout`, data, config),
    me: (config: AxiosRequestConfig) => api.get(`/user/me`, config),
    permissions: (config: AxiosRequestConfig) =>
        api.get(`/user/permissions`, config),
};

export default requests;
