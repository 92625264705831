const types = {
    DESTROY_FAILED: "MODULES/DESTROY_FAILED",
    DESTROY_REQUEST: "MODULES/DESTROY_REQUEST",
    DESTROY_SUCCESS: "MODULES/DESTROY_SUCCESS",

    FETCH_FAILED: "MODULES/FETCH_FAILED",
    FETCH_REQUEST: "MODULES/FETCH_REQUEST",
    FETCH_SUCCESS: "MODULES/FETCH_SUCCESS",

    ACTIVATE_FAILED: "MODULES/ACTIVATE_FAILED",
    ACTIVATE_REQUEST: "MODULES/ACTIVATE_REQUEST",
    ACTIVATE_SUCCESS: "MODULES/ACTIVATE_SUCCESS",
};

export default types;