import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";

const requests = {
    sync: (data: object, config: AxiosRequestConfig) =>
        api.post(`/modules/KDR/sync`, data, config),
    update: (data: object, config: AxiosRequestConfig) =>
        api.put(`/modules/KDR`, data, config),
};

export default requests;
