import api from "./api";
import types from "./types";
import { AnyAction } from "redux";
import { request } from "api/apiSaga";
import { call, takeLatest } from "redux-saga/effects";

function* all(action: AnyAction) {
    yield call(request, api.all, action);
}

function* fetch(action: AnyAction) {
    yield call(request, api.fetch, action);
}

export default function* saga() {
    yield takeLatest(types.FETCH_REQUEST, fetch);
    yield takeLatest(types.FETCH_ALL_REQUEST, all);
}
