import { FC } from "react";
import { Icon, IconButton, Box } from "@mui/material";
import { ISupplierTable } from "../interfaces";
import { Table, TextField } from "ui";
import SupplierSelect from "app/Suppliers/components/SupplierSelect";
import trans from "helpers/trans";
import { get } from "lodash";
import { IProductSupplier } from "../interfaces";

const SupplierTable: FC<ISupplierTable> = ({
    context,
    id,
    disabled = false,
}) => {
    const handleDeleteRow = (itemIdx: number) => {
        context.onChange([
            {
                id,
                value: get(context.data, id, []).filter(
                    (item: IProductSupplier, idx: number) => idx !== itemIdx
                ),
            },
        ]);
    };

    const renderSupplier = (item: IProductSupplier, idx: number) => {
        return (
            <SupplierSelect
                id={`${id}.${idx}.supplier_id`}
                disabled={
                    disabled ||
                    context.data.product.suppliers[idx].has_delivery_slip_lines
                }
                fullWidth
                sx={{ minWidth: "150px" }}
            />
        );
    };

    const renderSupplierNumber = (item: IProductSupplier, idx: number) => (
        <Box sx={{ width: "100%", minWidth: "150px", display: "flex" }}>
            <TextField
                id={`${id}.${idx}.number`}
                disabled={
                    disabled ||
                    context.data.product.suppliers[idx].has_delivery_slip_lines
                }
                variant="outlined"
                fullWidth
                size="small"
            />
        </Box>
    );

    const renderInPackage = (item: IProductSupplier, idx: number) => (
        <Box sx={{ width: "100%", minWidth: "150px", display: "flex" }}>
            <TextField
                id={`${id}.${idx}.in_package`}
                disabled={disabled}
                variant="outlined"
                fullWidth
                size="small"
                type="number"
                InputProps={{ inputProps: { min: 1 } }}
            />
        </Box>
    );

    return (
        <Table
            calculateHeight={false}
            id="SuppliersTable"
            calculateWidth={true}
            columns={[
                {
                    field: "supplier",
                    headName: trans(
                        "inv.restaurantProducts.table.supplerTable.headName.supplier"
                    ),
                    cellProps: {
                        size: "small",
                        sx: { whiteSpace: "nowrap" },
                    },
                    renderCell: (item, column, idx) => ({
                        children: renderSupplier(item, idx),
                    }),
                },
                {
                    field: "number",
                    headName: trans(
                        "inv.restaurantProducts.table.supplerTable.headName.number"
                    ),
                    cellProps: {
                        size: "small",
                        sx: { whiteSpace: "nowrap" },
                    },
                    renderCell: (item, column, idx) => ({
                        children: renderSupplierNumber(item, idx),
                    }),
                },
                {
                    field: "in_package",
                    headName: trans(
                        "inv.restaurantProducts.table.supplerTable.headName.inPackage"
                    ),
                    cellProps: {
                        size: "small",
                        sx: { whiteSpace: "nowrap" },
                    },
                    renderCell: (item, column, idx) => ({
                        children: renderInPackage(item, idx),
                    }),
                },
                {
                    field: "actions",
                    renderCell: (item, column, idx) => {
                        return {
                            children: (
                                <IconButton
                                    size="small"
                                    disabled={
                                        disabled ||
                                        context.data.product.suppliers[idx]
                                            .has_delivery_slip_lines
                                    }
                                    onClick={() => handleDeleteRow(idx)}
                                >
                                    <Icon>delete</Icon>
                                </IconButton>
                            ),
                        };
                    },
                },
            ]}
            rows={get(context.data, id, [])}
        />
    );
};

export default SupplierTable;
