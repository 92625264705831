export type SupplierType = {
    id: number;
    name: string;
    status: "FULLY" | "ONLY_FORWARD" | "SOON";
    org_no?: string;
    address_1?: string;
    address_2?: string;
    zip_code?: string;
    city?: string;
    phone?: string;
    contact_email?: string;
    source_key?: string;
    order_email?: string;
    web_page?: string;
    created_at?: string;
    updated_at?: string;
};

export const FETCH_FAILED = "SUPPLIERS/FETCH_FAILED";
export const FETCH_SUCCESS = "SUPPLIERS/FETCH_SUCCESS";
export const FETCH_REQUEST = "SUPPLIERS/FETCH_REQUEST";

const types = {
    FETCH_FAILED,
    FETCH_SUCCESS,
    FETCH_REQUEST,

    FETCH_ALL_REQUEST: "SUPPLIERS/FETCH_ALL_REQUEST",
    FETCH_ALL_FAILED: "SUPPLIERS/FETCH_ALL_FAILED",
    FETCH_ALL_SUCCESS: "SUPPLIERS/FETCH_ALL_SUCCESS",
};

export default types;
