import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlId from "types/RequestUrlId";

const baseUrl = "/pos/products";
const posCategoriesUrl = "/pos/categories";

const requests = {
    fetchPosProducts: (config: AxiosRequestConfig) =>
        api.get(`${baseUrl}`, config),
    fetchPosProductCategories: (config: AxiosRequestConfig) =>
        api.get(`${posCategoriesUrl}`, config),
    connectProducts: (
        data: object,
        config: AxiosRequestConfig,
        url: RequestUrlId
    ) => {
        return api.post(`${baseUrl}/connect/${url.id}`, data, config);
    },
    autoConnectProducts: (config: AxiosRequestConfig) =>
        api.post(`${baseUrl}/auto-connect`, config),
};

export default requests;
