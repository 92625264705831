import types from "./types";
import { request } from "api/actions";
import { AxiosRequestConfig } from "axios";

export const invoicesFetch = (config?: AxiosRequestConfig) => request(
    types.FETCH_REQUEST,
    types.FETCH_FAILED,
    types.FETCH_SUCCESS,
    `invoicesFetch()`,
    {
        config
    }
);

export const invoicesGet = (id: number, config?: object) => request(
    types.GET_REQUEST,
    types.GET_FAILED,
    types.GET_SUCCESS,
    `invoiceGet(${id})`,
    {
        config,
        url: {
            id,
        }
    }
);

export const invoicesTableUpdate = (action: string, data: any) => ({
    type: types.TABLE_UPDATE,
    action,
    data
})
