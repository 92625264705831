export const UPDATE_FAILED = "TAXES/UPDATE_FAILED";
export const UPDATE_REQUEST = "TAXES/UPDATE_REQUEST";
export const UPDATE_SUCCESS = "TAXES/UPDATE_SUCCESS";

export const CREATE_FAILED = "TAXES/CREATE_FAILED";
export const CREATE_REQUEST = "TAXES/CREATE_REQUEST";
export const CREATE_SUCCESS = "TAXES/CREATE_SUCCESS";

export const FETCH_REQUEST = "TAXES/FETCH_REQUEST";
export const FETCH_FAILED = "TAXES/FETCH_FAILED";
export const FETCH_SUCCESS = "TAXES/FETCH_SUCCESS";

export const DELETE_REQUEST = "TAXES/DELETE_REQUEST";
export const DELETE_FAILED = "TAXES/DELETE_FAILED";
export const DELETE_SUCCESS = "TAXES/DELETE_SUCCESS";

export const GET_REQUEST = "TAXES/GET_REQUEST";
export const GET_FAILED = "TAXES/GET_FAILED";
export const GET_SUCCESS = "TAXES/GET_SUCCESS";

export const TABLE_UPDATE = "TAXES/TABLE_UPDATE";

const types = {
    UPDATE_FAILED,
    UPDATE_REQUEST,
    UPDATE_SUCCESS,

    CREATE_FAILED,
    CREATE_REQUEST,
    CREATE_SUCCESS,

    FETCH_REQUEST,
    FETCH_FAILED,
    FETCH_SUCCESS,

    DELETE_REQUEST,
    DELETE_FAILED,
    DELETE_SUCCESS,

    GET_REQUEST,
    GET_FAILED,
    GET_SUCCESS,

    TABLE_UPDATE,
};

export default types;
