import { IBottle } from "../../interfaces";
import { FC } from "react";

const ChampagneBottle: FC<IBottle> = ({ offset }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="138.537"
            height="100%"
            data-bx-workspace="master"
            version="1.1"
            viewBox="0 0 36.654 126.084"
        >
            <defs>
                <linearGradient
                    id="gradient-1"
                    x1="111.917"
                    x2="111.917"
                    y1="208.641"
                    y2="84.021"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={`${offset}%`} stopColor="#e3d7af"></stop>
                    <stop offset="0%" stopColor="#315525"></stop>
                </linearGradient>
            </defs>
            <path
                fill="url(#gradient-1)"
                stroke="#315525"
                d="M106.693 84.154v2.188a.132.132 135 01-.132.133h-.552a.132.132 135 00-.132.132v3.11a.132.132 45 00.132.133h.195a.132.132 45 01.132.132v19.878c0 .073-.002.191-.005.264-.048 1.28-.5 11.53-2.755 16.976l-.102.243c-2.307 5.478-5.655 16.547-6.046 17.85l-.075.252c-.346 1.174-2.967 10.178-3.03 13.85l-.001.265v45.388c0 .073.005.191.014.264.084.633.603 3.343 3.381 3.427.073.002.191.002.265.002h27.87c.073 0 .191 0 .264-.002 2.779-.084 3.298-2.795 3.38-3.428.01-.072.015-.19.015-.263V159.56v-.265c-.064-3.673-2.683-12.678-3.028-13.85l-.076-.253c-.392-1.306-3.739-12.373-6.045-17.85l-.103-.243c-2.255-5.447-2.708-15.702-2.756-16.976a7.667 7.667 0 01-.005-.263V89.982a.132.132 135 01.132-.132h.196a.132.132 135 00.132-.133v-3.11a.132.132 45 00-.132-.132h-.554a.132.132 45 01-.132-.133v-2.188a.132.132 45 00-.132-.133h-10.183a.132.132 135 00-.132.133z"
                transform="translate(-93.59 -83.289)"
            ></path>
        </svg>
    );
};

export default ChampagneBottle;
