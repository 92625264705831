import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlId from "types/RequestUrlId";

const baseUrl = "/recipes";

const requests = {
    fetch: (config: AxiosRequestConfig) => api.get(`${baseUrl}`, config),
    create: (data: object, config: AxiosRequestConfig) =>
        api.post(baseUrl, data, config),
    delete: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.delete(`${baseUrl}/${url.id}`, config),
    duplicate: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.post(`${baseUrl}/${url.id}/duplicate`),
    get: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`${baseUrl}/${url.id}`, config),
    update: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.put(`${baseUrl}/${url.id}`, data, config),
};

export default requests;
