import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlId from "types/RequestUrlId";

const requests = {
    fetch: (config: AxiosRequestConfig) =>
        api.get("/settings/portions", config),
    create: (data: object, config: AxiosRequestConfig) =>
        api.post("/settings/portions", data, config),
    delete: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.delete(`/settings/portions/${url.id}`, config),
    get: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/settings/portions/${url.id}`, config),
    update: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.put(`/settings/portions/${url.id}`, data, config),
};

export default requests;
