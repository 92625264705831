export const FETCH_SUCCESS = "ORDERS/FETCH_SUCCESS";
export const TABLE_UPDATE = "ORDERS/TABLE_UPDATE";

const types = {
    DESTROY_FAILED: "ORDERS/DESTROY_FAILED",
    DESTROY_REQUEST: "ORDERS/DESTROY_REQUEST",
    DESTROY_SUCCESS: "ORDERS/DESTROY_SUCCESS",

    FETCH_FAILED: "ORDERS/FETCH_FAILED",
    FETCH_REQUEST: "ORDERS/FETCH_REQUEST",
    FETCH_SUCCESS,

    GET_FAILED: "ORDERS/GET_FAILED",
    GET_REQUEST: "ORDERS/GET_REQUEST",
    GET_SUCCESS: "ORDERS/GET_SUCCESS",

    STORE_FAILED: "ORDERS/STORE_FAILED",
    STORE_REQUEST: "ORDERS/STORE_REQUEST",
    STORE_SUCCESS: "ORDERS/STORE_SUCCESS",

    UPDATE_FAILED: "ORDERS/UPDATE_FAILED",
    UPDATE_REQUEST: "ORDERS/UPDATE_REQUEST",
    UPDATE_SUCCESS: "ORDERS/UPDATE_SUCCESS",

    POST_FAILED: "ORDER/POST_FAILED",
    POST_REQUEST: "ORDER/POST_REQUEST",
    POST_SUCCESS: "ORDER/POST_SUCCESS",

    TABLE_UPDATE,

    FETCH_LOCATIONS_REQUEST: "ORDERS/FETCH_LOCATIONS_REQUEST",
    FETCH_LOCATIONS_FAILED: "ORDERS/FETCH_LOCATIONS_FAILED",
    FETCH_LOCATIONS_SUCCESS: "ORDERS/FETCH_LOCATIONS_SUCCESS",

    ORDERS_PROPOSALS_FAILED: "ORDERS_PROPOSALS/FETCH_FAILED",
    ORDERS_PROPOSALS_REQUEST: "ORDERS_PROPOSALS/FETCH_REQUEST",
    ORDERS_PROPOSALS_SUCCESS: "ORDERS_PROPOSALS/FETCH_SUCCESS",
};

export default types;
