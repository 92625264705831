import { Box, Paper } from "@mui/material";
import useRequest from "api/useRequest";
import { modalHide } from "app/App/actions";
import { AxiosResponse } from "axios";
import trans from "helpers/trans";
import { FC, useEffect, useMemo, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Loader,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
    RequestMessage,
} from "ui";
import {
    restaurantProductsCombine,
    restaurantProductsFetch,
    restaurantProductsTableUpdate,
} from "../actions";
import {
    IReducerStateProductConnect,
    RestaurantProductConnectModel,
    RestaurantProductModel,
    RestaurantProductPortionModel,
} from "../interfaces";
import restaurantProductConnectReducer, {
    restaurantProductConnectReducerInitState,
} from "../reducers/RestaurantProductConnectReducer";
import RestaurantProductConnectTable from "../tables/connectProducts/RestaurantProductConnectTable";

interface IRestaurantProductConnectModal {
    selected: number[];
    onSuccess: Function;
}

const RestaurantProductConnectModal: FC<IRestaurantProductConnectModal> = ({
    selected,
    onSuccess,
}) => {
    const dispatch = useDispatch();

    const {
        request: combineProductsRequest,
        errors: combineProductsErrors,
        isLoading: combineProductsLoading,
        status: combineProductsStatus,
        message: combineProductsMessage,
    } = useRequest();

    const [state, stateDispatch] = useReducer(
        restaurantProductConnectReducer,
        restaurantProductConnectReducerInitState
    );

    const [portions, setPortions] = useState<
        { id: number; portion_id: number }[]
    >([]);

    const _sort = useSelector(
        (state: any) => state.RestaurantProductsTable.sort
    );

    const { currentData, isError, isLoading, status } = useRequest(
        restaurantProductsFetch({
            params: {
                _filters: { ids_filter: selected },
                _sort,
                _with: [
                    "product",
                    "restaurant_product_portions",
                    "restaurant_product_portions.portion",
                ],
            },
        })
    );

    const globalProducts: RestaurantProductModel[] = useMemo(
        () => currentData?.data.filter((item: any) => item.product?.is_global),
        [currentData]
    );

    const productsStock = useMemo(() => {
        return currentData?.data.reduce(
            (total: number, current: RestaurantProductModel) =>
                total + current.stock,
            0
        );
    }, [currentData]);

    useEffect(() => {
        if (currentData?.data) {
            let portions: { id: number; portion_id: number }[] = [];
            currentData?.data.forEach((item: RestaurantProductConnectModel) => {
                item.restaurant_product_portions?.forEach(
                    (item: RestaurantProductPortionModel) => {
                        const isInArr = portions.find(
                            (obj: any) => obj.portion_id === item.portion.id
                        );
                        if (isInArr) {
                            return;
                        } else {
                            return portions.push({
                                id: item.id,
                                portion_id: item.portion.id,
                            });
                        }
                    }
                );
            });
            setPortions(portions);
        }
    }, [currentData]);

    useEffect(() => {
        if (globalProducts?.length > 0) {
            const id = globalProducts[0].id;
            stateDispatch({
                type: "default_global",
                payload: {
                    name: "global",
                    value: "",
                    id: id,
                    default: {
                        target: id,
                        atributes: {
                            name: { id, value: globalProducts[0].name },
                            price: { id, value: globalProducts[0].price },
                            market_price: {
                                id,
                                value: globalProducts[0].market_price,
                            },
                            plu: { id, value: globalProducts[0].plu },
                            min_in_stock: {
                                id,
                                value: globalProducts[0].min_in_stock,
                            },
                            quantity_to_be_ordered: {
                                id,
                                value: globalProducts[0].quantity_to_be_ordered,
                            },
                        },
                    },
                },
            });
        }
    }, [globalProducts]);

    const handleCloseModal = () => {
        dispatch(modalHide("RestaurantProductConnectModal"));
    };

    const handleSubmitConnectProducts = () => {
        const toCombine = currentData?.data
            .filter((item: RestaurantProductModel) => item.id !== state.target)
            .map((item: RestaurantProductModel) => item.id);

        const attributes: {
            [innerKey: string]: string | null | number;
        } = {};

        for (const key in state.atributes) {
            const innerKey =
                state.atributes[
                    key as keyof IReducerStateProductConnect["atributes"]
                ];
            attributes[key] = innerKey.value === "" ? null : innerKey.value;
        }

        const portionsToCombine = portions.map((portion) => portion.id);

        combineProductsRequest(
            restaurantProductsCombine(
                +state.target,
                {
                    to_combine: toCombine,
                    attributes: {
                        ...attributes,
                        restaurant_product_portions: portionsToCombine,
                    },
                },
                (response: AxiosResponse) => {
                    if (response.status < 300) {
                        dispatch(modalHide("RestaurantProductConnectModal"));
                        dispatch(
                            restaurantProductsTableUpdate("selection", [])
                        );
                        onSuccess();
                    }
                }
            )
        );
    };

    if (isLoading || combineProductsLoading) return <Loader />;

    if (isError) return <RequestMessage status={status} />;

    return (
        <Modal open fullWidth maxWidth="xl">
            <ModalTitle onClose={handleCloseModal}>
                {trans(
                    "inv.restaurantProducts.modal.RestaurantProductConnectModal.modalTitle"
                )}
            </ModalTitle>
            <ModalContent>
                <Paper
                    sx={{
                        overflowX: "scroll",
                        width: "100%",
                    }}
                >
                    <RequestMessage
                        status={combineProductsStatus}
                        message={combineProductsMessage}
                        sx={{ mb: 1 }}
                    />
                    <RestaurantProductConnectTable
                        dispatch={stateDispatch}
                        rows={currentData?.data}
                        state={state}
                        isGlobalProduct={globalProducts?.length > 0}
                        stock={productsStock}
                        formErrors={combineProductsErrors}
                        portions={portions}
                        setPortions={setPortions}
                    />
                </Paper>
            </ModalContent>
            <ModalActions>
                <Box display="flex" width="100%" textAlign="right">
                    <Button
                        onClick={handleCloseModal}
                        color="inherit"
                        variant="text"
                        sx={{ mr: 1 }}
                    >
                        {trans("global.button.cancel")}
                    </Button>
                    <Button
                        sx={{ flex: 1 }}
                        onClick={handleSubmitConnectProducts}
                        disabled={!state.target}
                    >
                        {trans("global.button.save")}
                    </Button>
                </Box>
            </ModalActions>
        </Modal>
    );
};

export default RestaurantProductConnectModal;
