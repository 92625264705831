import { TABLE_UPDATE } from "../types";
import { tableUpdate } from "helpers/reducers";
import trans from "helpers/trans";
import { IGPTable } from "ui/Table/Table";
import { AnyAction } from "redux";

function InvoiceDetalisTable(
    state: IGPTable = {
        id: "InvoiceDetailsTable",
        columns: [
            {
                field: "name",
                headName: trans(
                    "inv.invoices.reducer.invoiceDetailsTableReducer.headName.name"
                ),
            },
            {
                field: "number",
                headName: trans(
                    "inv.invoices.reducer.invoiceDetailsTableReducer.headName.number"
                ),
            },
        ],
        filters: {},
        search: "",
        sort: {
            // name: "asc"
        },
    },
    action: AnyAction
) {
    switch (action.type) {
        case TABLE_UPDATE:
            return tableUpdate(state, action);
        default:
            return state;
    }
}

export default InvoiceDetalisTable;
