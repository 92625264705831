import { countFilters, getDefaultFilters } from "helpers/reducers";
import { flatten, uniq } from "lodash";
import store from "rootStore";

export function prepareColumns(data: any) {
    const tableId = data.id.includes(".") ? data.id.split(".")[0] : data.id;
    const state = store.getState()[tableId];

    const columns = data.columns.reduce((cols: any, column: any) => {
        let key = "align";
        if (column.align === "right" || column.align === "left" || column.align === "center") {
            column.cellProps = { align: column.align };
        } 
        delete column[key];
        cols.push(column);
        return cols;
    }, []);

    const { withParams, withCountParams } = data.columns.reduce(
        (
            params: { withParams: string[]; withCountParams: string[] },
            column: any
        ) => {
            if (column.additionalRelations.length !== 0) {
                params.withParams = [
                    ...params.withParams,
                    ...column.additionalRelations,
                ];
            }

            const splited = column.field.split(".");
            if (splited.length > 1) {
                const columnName = splited.pop();
                if (columnName === "_count") {
                    params.withCountParams.push(splited.join("."));
                } else {
                    params.withParams.push(splited.join("."));
                }
            }

            return params;
        },
        { withParams: [], withCountParams: [] }
    );

    const checkChildren = (filterValue: any, tempArr: any) => {
        tempArr.push(filterValue.value);
        if (filterValue.children) {
            filterValue.children.forEach((child: any) => {
                checkChildren(child, tempArr);
            });
        }
    };

    let newFilters: any = {};

    for (const currentFilterId in state.filters) {
        data.filters_data.forEach((filter: any) => {
            if (filter.id === currentFilterId) {
                const currentFilterValue = state.filters[currentFilterId];

                if (filter.values) {
                    let filterValues: any[] = [];

                    filter.values?.forEach((filterValue: any) => {
                        checkChildren(filterValue, filterValues);
                    });

                    if (Array.isArray(currentFilterValue)) {
                        const tempFilter = currentFilterValue.filter(
                            (item: any) => filterValues.includes(item)
                        );

                        newFilters[currentFilterId] = tempFilter;
                    } else if (
                        filterValues.indexOf(currentFilterValue) !== -1
                    ) {
                        newFilters[currentFilterId] = currentFilterValue;
                    }
                } else {
                    newFilters[currentFilterId] = currentFilterValue;
                }
            }
        });
    }

    let defaultFilters: { [key: string]: any } = {
        ...newFilters,
        ...getDefaultFilters(data.filters_data),
    };

    return {
        id: tableId,
        ...(data.id.includes(".") && { type: data.id.split(".")[1] }),
        availableColumns: data.available_columns,
        columns,
        filterData: data.filters_data,
        filters: defaultFilters,
        filterSettings: {
            ...state.filterSettings,
            total: countFilters(data.filters_data, defaultFilters),
        },
        with: uniq(flatten(withParams)),
        withCount: uniq(flatten(withCountParams)),
    };
}
