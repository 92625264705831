export interface RecipeCategoryModel {
    [key: string]: number | string | undefined;
    id: number;
    name: string;
    type: string;
    extra_cost: number;
    created_at?: string;
    updated_at?: string;
}

export const FETCH_SUCCESS = "LOCATIONCATEGORIES/FETCH_SUCCESS";
export const TABLE_UPDATE = "LOCATIONCATEGORIES/TABLE_UPDATE";

const types = {
    FETCH_SUCCESS,
    FETCH_FAILED: "LOCATIONCATEGORIES/FETCH_FAILED",
    FETCH_REQUEST: "LOCATIONCATEGORIES/FETCH_REQUEST",

    UPDATE_FAILED: "LOCATIONCATEGORIES/UPDATE_FAILED",
    UPDATE_REQUEST: "LOCATIONCATEGORIES/UPDATE_REQUEST",
    UPDATE_SUCCESS: "LOCATIONCATEGORIES/UPDATE_SUCCESS",

    CREATE_REQUEST: "LOCATIONCATEGORIES/CREATE_REQUEST",
    CREATE_SUCCESS: "LOCATIONCATEGORIES/CREATE_SUCCESS",
    CREATE_FAILED: "LOCATIONCATEGORIES/CREATE_FAILED",

    DELETE_REQUEST: "LOCATIONCATEGORIES/DELETE_REQUEST",
    DELETE_SUCCESS: "LOCATIONCATEGORIES/DELETE_SUCCESS",
    DELETE_FAILED: "LOCATIONCATEGORIES/DELETE_FAILED",

    GET_FAILED: "LOCATIONCATEGORIES/GET_FAILED",
    GET_REQUEST: "LOCATIONCATEGORIES/GET_REQUEST",
    GET_SUCCESS: "LOCATIONCATEGORIES/GET_SUCCESS",

    TABLE_UPDATE,
};

export default types;
