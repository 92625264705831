import { restaurantProductsCreatePortions } from "../actions";
import { IBulkActionModal } from "../interfaces";

import { Box, MenuItem } from "@mui/material";
import useRequest from "api/useRequest";
import { modalHide } from "app/App/actions";
import { settingsPortionsFetch } from "app/SettingsPortions/actions";
import { SettingsPortionsModel } from "app/SettingsPortions/interfaces";
import { AxiosResponse } from "axios";
import trans from "helpers/trans";
import { unitSymbol } from "helpers/units";
import { FC, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import {
    Button,
    Form,
    Loader,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
    NumberField,
    RequestMessage,
    Select,
    Settings,
    SettingsItem,
} from "ui";
import { IGPFormRef } from "ui/Form/Form";

const PortionsAddModal: FC<IBulkActionModal> = ({ selected, onSuccess }) => {
    const dispatch = useDispatch();
    const formRef = useRef<IGPFormRef | null>();

    const {
        request,
        errors,
        isLoading: isLoadingAdd,
        isSuccess: isSuccessAdd,
    } = useRequest();

    const { data, isError, isPending, status } = useRequest(
        settingsPortionsFetch({ params: { _sort: { name: "asc" } } })
    );

    const renderPortion = (portion: SettingsPortionsModel) => {
        return (
            <MenuItem
                value={portion.id}
                key={`portion_id-${portion.id}`}
                id={portion.default_category_id}
            >
                {portion.name} {portion.size}
                {unitSymbol[portion.unit as keyof typeof unitSymbol]}
            </MenuItem>
        );
    };
    const portions = useMemo(() => {
        if (!data) return [];
        const preparePortions: Array<React.ReactNode> = [];
        data.data.forEach((portion: SettingsPortionsModel) => {
            preparePortions.push(renderPortion(portion));
        });
        return preparePortions;
    }, [data]);

    const handleCloseModal = () => {
        dispatch(modalHide("PortionsAddModal"));
    };

    const handleAddPortions = () => {
        if (formRef.current !== null) {
            const data: any = formRef?.current?.getData();
            request(
                restaurantProductsCreatePortions(
                    {
                        ...data,
                        ids: selected,
                    },
                    (response: AxiosResponse) => {
                        if (response.status === 200) {
                            dispatch(modalHide("PortionsAddModal"));
                            onSuccess();
                        }
                    }
                )
            );
        }
    };

    if (isPending) return <Loader />;

    if (isError) return <RequestMessage status={status} />;

    return (
        <Modal open fullWidth maxWidth="md">
            <ModalTitle onClose={handleCloseModal}>
                {trans("inv.restaurantProducts.modal.portionsAddModal.title")}
            </ModalTitle>
            <ModalContent>
                <Form
                    ref={formRef}
                    data={data}
                    errors={errors}
                    fields={{
                        portion_id: {},
                        plu_method: { default: "NONE" },
                        prefix: {},
                        sufix: {},
                        plu: {},
                    }}
                    loading={isLoadingAdd}
                    onSubmit={handleAddPortions}
                    onSuccess={isSuccessAdd}
                    unsaved={false}
                >
                    {(context: any) => {
                        return (
                            <Settings>
                                <SettingsItem
                                    label={trans(
                                        "inv.restaurantProducts.modal.portionsAddModal.label.portion"
                                    )}
                                >
                                    <Select fullWidth id="portion_id">
                                        <MenuItem value="">
                                            <em>
                                                {trans(
                                                    "inv.restaurantProducts.modal.portionsAddModal.select.portion"
                                                )}
                                            </em>
                                        </MenuItem>
                                        {portions}
                                    </Select>
                                </SettingsItem>
                                <SettingsItem
                                    label={trans(
                                        "inv.restaurantProducts.modal.portionsAddModal.label.method"
                                    )}
                                >
                                    <Select fullWidth id="plu_method">
                                        <MenuItem value="">
                                            <em>
                                                {trans(
                                                    "inv.restaurantProducts.modal.portionsAddModal.select.method"
                                                )}
                                            </em>
                                        </MenuItem>
                                        {["NONE", "PREFIX", "SUFIX"].map(
                                            (item, idx) => {
                                                return (
                                                    <MenuItem
                                                        value={item}
                                                        key={`plu_method-${item}-${idx}`}
                                                        id={`plu_method-${item}-${idx}`}
                                                    >
                                                        {item}
                                                    </MenuItem>
                                                );
                                            }
                                        )}
                                    </Select>
                                </SettingsItem>
                                {context?.data?.plu_method !== "NONE" && (
                                    <SettingsItem
                                        label={trans(
                                            "inv.restaurantProducts.modal.portionsAddModal.plu"
                                        )}
                                    >
                                        <NumberField fullWidth id="plu" />
                                    </SettingsItem>
                                )}
                            </Settings>
                        );
                    }}
                </Form>
            </ModalContent>
            <ModalActions>
                <Box display="flex" width="100%" textAlign="right">
                    <Button
                        onClick={handleCloseModal}
                        color="inherit"
                        variant="text"
                        sx={{ mr: 1 }}
                    >
                        {trans("global.button.cancel")}
                    </Button>
                    <Button sx={{ flex: 1 }} onClick={handleAddPortions}>
                        {trans("global.button.save")}
                    </Button>
                </Box>
            </ModalActions>
        </Modal>
    );
};

export default PortionsAddModal;
