import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import grey from "@mui/material/colors/grey";

interface ISettingsHeader {
    title?: string;
    subtitle?: string;
}
function SettingsHeader({ title, subtitle }: ISettingsHeader) {
    return (
        <Box
            sx={{
                borderBottom: "1px solid",
                borderBottomColor: "grey.300",
                px: 2,
                py: 1,
                display: "flex",
                alignItems: "center",
                gap: 1,
            }}
        >
            <Typography variant="h6">{title}</Typography>
            <Typography variant="caption" sx={{ color: grey[600], pt: 0.5 }}>
                {subtitle}
            </Typography>
        </Box>
    );
}

SettingsHeader.defaultProps = {};

export default SettingsHeader;
