import { request } from "api/apiSaga";
import { snackbarShow } from "app/App/actions";
import { AxiosResponse } from "axios";
import trans from "helpers/trans";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import api from "./api";
import types from "./types";

function* menuFetch(action: AnyAction) {
    yield call(request, api.menuFetch, action);
}

function* menuCreate(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.menuCreate,
        action,
        "post"
    );

    if (response.status < 300) {
        yield put(
            snackbarShow({
                content: trans("inv.menus.saga.content.create"),
            })
        );
    }
}

function* menuDuplicate(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.menuDuplicate,
        action,
        "post"
    );

    if (response.status < 300) {
        yield put(
            snackbarShow({
                content: trans("inv.menus.saga.content.duplicate"),
            })
        );
    }
}

function* menuGet(action: AnyAction) {
    yield call(request, api.menuGet, action);
}

function* menuUpdate(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.menuUpdate,
        action,
        "put"
    );

    if (response.status < 300) {
        yield put(
            snackbarShow({
                content: trans("inv.menus.saga.content.update"),
            })
        );
    }
}

function* menuDelete(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.menuDelete,
        action,
        "delete"
    );

    if (response.status < 300) {
        yield put(
            snackbarShow({
                content: trans("inv.menus.saga.content.delete"),
            })
        );
    }
}

export default function* saga() {
    yield takeLatest(types.FETCH_REQUEST, menuFetch);
    yield takeLatest(types.CREATE_REQUEST, menuCreate);
    yield takeLatest(types.GET_REQUEST, menuGet);
    yield takeLatest(types.UPDATE_REQUEST, menuUpdate);
    yield takeLatest(types.DELETE_REQUEST, menuDelete);
    yield takeLatest(types.DUPLICATE_REQUEST, menuDuplicate);
}
