import types from "./types";

import { request } from "api/apiSaga"
import { appSettings as appSettingsRequest, snackbarShow } from "app/App/actions";
import api from "app/App/api";
import trans from "helpers/trans";
import {AnyAction} from "redux"
import { call, takeLatest, put } from "redux-saga/effects";

function* update(action: AnyAction): any {
    const response = yield call(request, api.update, action, "put");

    if (response.status === 200) {
        yield put(
            snackbarShow({
                content: trans("inv.settings.saga.content.update"),
            })
        );
        yield call(appSettings, appSettingsRequest());

    }
}
function* appSettings(action: AnyAction): any {
    return yield call(request, api.appSettings, action);
}

export default function* saga() {
    yield takeLatest(types.UPDATE_SETTINGS_REQUEST, update);
}
