import { ICounter } from "../interfaces";
import CounterKeyboard from "./CounterKeyboard";
import ProductSlider from "./ProductSlider/ProductSlider";

import { Box, Icon, IconButton, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { shapeOfSlider } from "helpers/shapeOfSlider";
import { convertUnit, unitSymbol } from "helpers/units";
import { FC, useEffect, useMemo, useState } from "react";
import { Button } from "ui";

export const QTY_PRECISION = 2;

const QtyButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.grey[400],
    borderRadius: 50,
    color: "#000",
    height: 32,
    minWidth: 32,
    padding: 0,
    width: 32,
    "&:hover": {
        backgroundColor: theme.palette.grey[400],
    },
}));

const getInitState = (quantity: number) => {
    if (quantity % 1 === 0) {
        return {
            doublePos: 1,
            doubleValue: false,
            value: quantity.toFixed(QTY_PRECISION),
            valueNumeric: quantity,
            usedKeyboard: false,
        };
    } else {
        return {
            doublePos: 1 + QTY_PRECISION,
            doubleValue: true,
            value: quantity.toFixed(QTY_PRECISION),
            valueNumeric: quantity,
            usedKeyboard: false,
        };
    }
};

const Counter: FC<ICounter> = ({
    addQuantity,
    item,
    onAddSubquantity,
    onSwitch,
    onUpdate,
    view,
    unit,
    ...props
}) => {
    const [quantity, setQuantity] = useState(getInitState(props.quantity));
    const shape = useMemo(() => shapeOfSlider(item.category), [item.category]);

    const defaultUnit = item.category.default_unit || item.show_unit;

    const maxValue: number = useMemo(() => {
        return convertUnit(item.size, item.unit, defaultUnit);
    }, [defaultUnit, item.size, item.unit]);

    const productUnit = useMemo(() => {
        return unitSymbol[defaultUnit as keyof typeof unitSymbol];
    }, [defaultUnit]);

    useEffect(() => {
        onUpdate(quantity.valueNumeric);
        // eslint-disable-next-line
    }, [quantity.value]);

    const handleClickAdd = () => {
        setQuantity((prevData) => {
            const valueNumeric = prevData.valueNumeric + 1;

            return {
                ...prevData,
                value: valueNumeric.toFixed(QTY_PRECISION),
                valueNumeric,
                usedKeyboard: false,
            };
        });
    };

    const handleClickClear = () => {
        setQuantity((prevData) => ({
            ...prevData,
            doublePos: 1,
            doubleValue: false,
            value: "1.00",
            valueNumeric: 1,
            usedKeyboard: false,
        }));
    };

    const handleClickComma = () => {
        setQuantity((prevData) => ({
            ...prevData,
            doubleValue: true,
            usedKeyboard: true,
        }));
    };

    const handleClickNumberKey = (val: number) => () => {
        let value = val;

        if (quantity.usedKeyboard) {
            if (quantity.doubleValue) {
                if (quantity.doublePos === 1 + QTY_PRECISION) {
                    return;
                }

                const multiplyBy = Math.pow(10, quantity.doublePos);

                value = (quantity.valueNumeric * multiplyBy + val) / multiplyBy;

                setQuantity((prevData) => ({
                    ...prevData,
                    doublePos: prevData.doublePos + 1,
                    value: value.toFixed(QTY_PRECISION),
                    valueNumeric: value,
                    usedKeyboard: true,
                }));
                return;
            } else {
                value = Number(quantity.value) * 10 + val;
            }
        }

        setQuantity((prevData) => ({
            ...prevData,
            doublePos: 1,
            doubleValue: false,
            value: value.toFixed(QTY_PRECISION),
            valueNumeric: value,
            usedKeyboard: true,
        }));
    };

    const handleClickOdd = () => {
        if (quantity.valueNumeric >= 1) {
            setQuantity((prevData) => {
                const valueNumeric = prevData.valueNumeric - 1;
                return {
                    ...prevData,
                    value: valueNumeric.toFixed(QTY_PRECISION),
                    valueNumeric,
                    usedKeyboard: false,
                };
            });
        }
    };

    const handleSliderChange = (newQuantity: number) => {
        setQuantity((prevData) => ({
            ...prevData,
            doublePos: 3,
            doubleValue: true,
            value: newQuantity.toFixed(QTY_PRECISION),
            valueNumeric: newQuantity,
            usedKeyboard: false,
        }));
    };

    const renderSwitchButton = () => {
        return (
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={0}
                // pl={2}
                // gap={1}
                position="absolute"
                right={0}
            >
                <IconButton
                    sx={{
                        color: "#000",
                        justifyContent: "center",
                        // position: "absolute",
                        // right: 40,
                        // mr: 1,
                    }}
                    onClick={() => onAddSubquantity()}
                >
                    <Icon>add</Icon>
                </IconButton>
                {shape && item?.size > 0 && (
                    <IconButton
                        sx={{
                            color: "#000",
                            justifyContent: "center",
                            mr: 1,
                        }}
                        onClick={() => onSwitch()}
                    >
                        <Icon>
                            {view === "slider" ? "keyboard" : "swipe_vertical"}
                        </Icon>
                    </IconButton>
                )}
            </Stack>
        );
    };

    return (
        <Stack
            sx={{
                alignItems: "center",
                width: "100%",
            }}
        >
            <Box alignItems="center" display="flex">
                <Box display="flex" justifyContent="center" alignItems="center">
                    <QtyButton
                        onClick={handleClickOdd}
                        disabled={+quantity.value < 1 || view === "slider"}
                    >
                        <Icon>remove</Icon>
                    </QtyButton>
                    <Typography
                        sx={{
                            fontSize: 18,
                            fontWeight: 500,
                            px: 2,
                        }}
                    >
                        {quantity.value}
                    </Typography>

                    <QtyButton
                        onClick={handleClickAdd}
                        disabled={view === "slider"}
                    >
                        <Icon>add</Icon>
                    </QtyButton>
                </Box>
                {renderSwitchButton()}
            </Box>
            <Box display="flex" justifyContent="center" my={1}>
                Total quantity: {props.totalQuantity.toFixed(QTY_PRECISION)}
            </Box>
            <Box
                sx={{
                    backgroundColor: "grey.200",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                }}
            >
                {view === "slider" ? (
                    <ProductSlider
                        shape={shape}
                        onSlider={handleSliderChange}
                        quantity={quantity.valueNumeric}
                        unit={productUnit}
                        maxValue={maxValue}
                        value={quantity.valueNumeric}
                    />
                ) : (
                    <CounterKeyboard
                        onClear={handleClickClear}
                        onComma={handleClickComma}
                        onNumberKey={handleClickNumberKey}
                        quantity={quantity}
                    />
                )}
            </Box>
        </Stack>
    );
};

export default Counter;
