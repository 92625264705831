import TextField, { IGPTextField } from "./TextField";
interface IGPTextarea extends IGPTextField {}

function GPTextarea({ minRows = 2, ...restProps }: IGPTextarea) {
    return <TextField {...restProps} multiline={true} minRows={minRows} />;
}

GPTextarea.propTypes = {};

GPTextarea.defaultProps = {};

export default GPTextarea;
