import { IBottle } from "../../interfaces";
import { FC } from "react";

const WineBottle: FC<IBottle> = ({ offset }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="189.348"
            height="100%"
            data-bx-workspace="master"
            version="1.1"
            viewBox="0 0 50.098 196.48"
        >
            <defs>
                <linearGradient
                    id="gradient-1"
                    x1="47.011"
                    x2="47.011"
                    y1="209.926"
                    y2="15.111"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={`${offset}%`} stopColor="#ddc0de"></stop>
                    <stop offset="0%" stopColor="#315525"></stop>
                </linearGradient>
            </defs>
            <path
                fill="url(#gradient-1)"
                stroke="#315525"
                d="M38.582 15.111a.545.545 0 00-.545.53l-.057 2.103a.545.545 0 01-.544.53h-.668a.53.53 0 00-.53.529v4.916c0 .292.237.529.53.529h.546c.293 0 .531.237.532.53.007 4.217.05 32.358.006 37.193-.003.292-.008.768-.051 1.056-.32 2.115-2.446 6.155-7.457 11.08-.209.205-.551.534-.762.737-4.637 4.46-6.257 5.886-6.717 9.029-.042.29-.067.762-.068 1.053-.037 8.86.303 107.461.08 117.709-.006.292-.024.766-.025 1.058-.016 4.828 2.45 5.86 3.556 6.08.286.058.76.062 1.053.065 2.19.023 10.842.104 19.55.086 8.71.018 17.36-.063 19.55-.086.292-.003.768-.007 1.054-.065 1.106-.22 3.57-1.252 3.555-6.08-.001-.292-.019-.766-.025-1.058-.224-10.248.116-108.85.08-117.71a9.027 9.027 0 00-.069-1.052c-.46-3.143-2.08-4.57-6.717-9.03-.21-.202-.55-.53-.76-.736-5.01-4.925-7.14-8.965-7.458-11.08-.044-.288-.048-.764-.051-1.056-.045-4.835 0-32.976.008-37.194a.53.53 0 01.53-.529h.546a.53.53 0 00.53-.53v-4.915a.53.53 0 00-.53-.53h-.668a.543.543 0 01-.543-.529l-.059-2.103a.543.543 0 00-.543-.53h-8.43z"
                className="UnoptimicedTransforms"
                transform="translate(-21.962 -14.279)"
            ></path>
        </svg>
    );
}

export default WineBottle;
