import api from "./api";
import types from "./types";
import { request } from "api/apiSaga";
import { call, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";

function* compareInventoriesFetch(action: AnyAction) {
    yield call(request, api.compareInventoriesFetch, action);
}

function* costAndVarianceFetch(action: AnyAction) {
    yield call(request, api.costAndVarianceFetch, action);
}

export default function* saga() {
    yield takeLatest(
        types.COMPARE_INVENTORIES_FETCH_REQUEST,
        compareInventoriesFetch
    );
    yield takeLatest(
        types.COST_AND_VARIANCE_FETCH_REQUEST,
        costAndVarianceFetch
    );
}
