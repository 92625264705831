import trans from "helpers/trans";
import { useState, useContext, useMemo, ChangeEvent, FC } from "react";
import useRequest from "api/useRequest";
import { suppliersAll } from "../../Suppliers/actions";
import { Loader, RequestMessage, TextField } from "ui";
import FormContext from "ui/Form/FormContext";
import { getValue } from "ui/Form/helpers";
import SupplierSelectModal from "./SupplierSelectModal";
import { SupplierType } from "../types";
import { Box, Chip } from "@mui/material";
import { ISupplierSelect } from "../interfaces";
import { statusColors } from "app/LocationSuppliers/interfaces";
import usePermission from "hooks/usePermission";

const SupplierSelect: FC<ISupplierSelect> = ({
    id,
    label,
    onChange,
    errorText,
    value,
    popularCountries,
    helperText,
    ...restProps
}) => {
    const permission = usePermission("Suppliers", "read");
    const { currentData, status, isError, isLoading } = useRequest(
        suppliersAll({ params: { _sort: { name: "asc" } } })
    );

    const suppliersList = useMemo(() => {
        if (currentData) return currentData.data;
    }, [currentData]);

    const [openSupplierSelectModal, setOpenSupplierSelectModal] =
        useState(false);
    const [searchSupplierValue, setSearchSupplierValue] = useState("");

    const context = useContext(FormContext);
    const supplier = getValue(id, context, value);

    const selectedSupplier = useMemo(() => {
        return suppliersList?.find(
            (item: SupplierType) => item.id === supplier
        );
    }, [suppliersList, supplier]);

    const handleChangeSupplier = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        item: SupplierType
    ) => {
        if (!!onChange) {
            onChange({ id, value: item.id }, context);
        } else {
            context?.onChange({ id, value: item.id });
        }

        setOpenSupplierSelectModal(false);
    };
    if (!permission) return null;

    if (isError) return <RequestMessage status={status} />;

    if (isLoading) return <Loader variant="content" />;

    if (!suppliersList) return null;
    return (
        <Box sx={{ position: "relative" }}>
            <TextField
                {...restProps}
                id={id}
                label={label}
                value={selectedSupplier?.name}
                onClick={() =>
                    !restProps.disabled && setOpenSupplierSelectModal(true)
                }
                InputProps={{
                    readOnly: true,
                    endAdornment: selectedSupplier?.status && (
                        <Chip
                            label={selectedSupplier.status}
                            size="small"
                            color={
                                // @ts-ignore
                                statusColors[selectedSupplier.status]
                            }
                        />
                    ),
                }}
            />
            <SupplierSelectModal
                onChangeSearch={(
                    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => setSearchSupplierValue(e.target.value)}
                onClickSupplier={handleChangeSupplier}
                onCloseModal={() => setOpenSupplierSelectModal(false)}
                openModal={openSupplierSelectModal}
                search={searchSupplierValue}
                title={trans("inv.suppliers.component.supplierSelect.title")}
                suppliersList={suppliersList}
            />
        </Box>
    );
};

export default SupplierSelect;
