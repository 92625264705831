import trans from "helpers/trans";
import { FC } from "react";
import { Divider, Box, Chip } from "@mui/material";
import { Modal, ModalTitle, ModalContent, ModalActions } from "ui";
import { SupplierType } from "../types";
import {
    OutlinedInput,
    List,
    ListItem,
    ListItemText,
    InputAdornment,
    Icon,
} from "@mui/material";
import { ISupplierSelectModal } from "../interfaces";
import { statusColors } from "app/LocationSuppliers/interfaces";

const SupplierSelectModal: FC<ISupplierSelectModal> = ({
    onCloseModal,
    openModal,
    title,
    onChangeSearch,
    search,
    onClickSupplier,
    suppliersList,
}) => {
    return (
        <Modal
            onClose={onCloseModal}
            open={openModal}
            maxWidth="xs"
            fullWidth
            PaperProps={{ sx: { position: "fixed", top: 0 } }}
        >
            <ModalTitle onClose={onCloseModal}>{title}</ModalTitle>
            <ModalActions>
                <OutlinedInput
                    autoFocus
                    fullWidth
                    onChange={onChangeSearch}
                    placeholder={trans(
                        "inv.suppliers.component.supplierSelectModal.search"
                    )}
                    size="small"
                    startAdornment={
                        <InputAdornment position="start">
                            <Icon>search</Icon>
                        </InputAdornment>
                    }
                    value={search}
                />
            </ModalActions>
            <ModalContent sx={{ pt: 0, px: 2 }}>
                <List component="nav" sx={{ pt: 0 }}>
                    {suppliersList.map((item: SupplierType) => {
                        if (
                            search.length > 0 &&
                            !item.name
                                .toLocaleLowerCase()
                                .includes(search.toLowerCase())
                        ) {
                            return null;
                        }

                        return (
                            <Box key={`${item.id}`}>
                                <ListItem
                                    button
                                    onClick={(event) =>
                                        onClickSupplier(event, item)
                                    }
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "100%",
                                            justifyContent: "space-between",
                                            flexWrap: "nowrap",
                                            gap: 1,
                                        }}
                                    >
                                        <ListItemText primary={item.name} />
                                        {item.status && (
                                            <Chip
                                                label={item.status}
                                                size="small"
                                                color={
                                                    // @ts-ignore
                                                    statusColors[item.status]
                                                }
                                            />
                                        )}
                                    </Box>
                                </ListItem>
                                <Divider />
                            </Box>
                        );
                    })}
                </List>
            </ModalContent>
        </Modal>
    );
};

export default SupplierSelectModal;
