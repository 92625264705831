import api from "./api";
import {
    APP_INIT,
    APP_LOADED,
    BROADCASTER_CONNECTED,
    BROADCASTER_DISCONNECTED,
    BROADCASTER_MESSAGE,
    LOCALE_REQUEST,
} from "types";
import {
    appSettings as appSettingsRequest,
    appTranslate,
    changeLocale,
} from "./actions";

import { call, fork, put, take, takeLatest } from "@redux-saga/core/effects";

import {
    me as meRequest,
    permissions as permissionsRequest,
} from "app/Me/actions";

import { ME_SUCCESS, PERMISSIONS_SUCCESS } from "app/Me/types";
import Broadcaster from "broadcasting";
import setLocationHeader from "helpers/setLocationHeader";
import pathToAuth from "helpers/pathToAuth";
import { loadModules } from "moduleSaga";
import { request } from "api/apiSaga";
import { AnyAction } from "redux";
import { eventChannel } from "redux-saga";
import { ILocation, IOrganisation } from "app/Me/interfaces";

interface IApplication {
    id: number;
    key: string;
    name: string;
}

interface IMe {
    response: {
        data: {
            data: {
                applications: IApplication[];
                email: string;
                first_name: string;
                last_name: string;
                organisations: IOrganisation[];
                permissions: Array<string>;
                phone: string;
                role: string;
            };
        };
    };
}

interface IPermissions {
    response: {
        data: {
            data: {
                permissions: Array<string>;
                role: string;
            };
        };
    };
}

export interface IBroadcasterEvent {
    event: string;
    data: any;
}

function* initApp() {
    yield put(meRequest());
    const me: IMe = yield take(ME_SUCCESS);

    const application = me.response.data.data.applications.find(
        (application: IApplication) =>
            application.key === process.env.REACT_APP_APPLICATION_UID
    );

    if (!application) {
        window.location.href = pathToAuth();
        return;
    }

    const organisationsWithActiveApp = me.response.data.data.organisations;

    //if user dont have any locations redirect to accounts
    if (organisationsWithActiveApp.length === 0) {
        window.location.href = pathToAuth();
        return;
    }

    let locations: { [key: number]: ILocation } = {};
    let activeLocation: ILocation | null = null;
    const pathname = window.location.pathname.substring(1).split("/");

    for (let organisation of organisationsWithActiveApp) {
        for (let location of organisation.locations) {
            locations[location.id] = location;

            if (location.uid === pathname[0]) {
                activeLocation = location;
            }
        }
    }

    if (activeLocation === null) {
        activeLocation = locations[parseInt(Object.keys(locations)[0])];
        window.location.href = "/" + activeLocation.uid;
        return;
    }

    setLocationHeader(activeLocation.id);
    localStorage.removeItem("reloadedApp");

    yield put(permissionsRequest());
    const permissions: IPermissions = yield take(PERMISSIONS_SUCCESS);

    yield call(translate, appTranslate());
    // @ts-ignore
    const settings = yield call(appSettings, appSettingsRequest());
    globalThis.broadcaster = new Broadcaster(process.env.REACT_APP_PUSHER_APP_KEY as string);
    yield fork(broadcasting);

    //register routing, reducers etc
    yield call(
        loadModules,
        permissions.response.data.data.permissions,
        settings.data,
        activeLocation
    );

    // yield delay(2000);
    yield put({
        type: APP_LOADED,
        locationId: activeLocation.id,
        locations,
        organisationsWithActiveApp,
        app: "App",
    });

    // const showNewReleaseInfo = localStorage.getItem(
    //     `release-${process.env.REACT_APP_VERSION}`
    // );

    // if (
    //     showNewReleaseInfo === null &&
    //     permissions.response.data.data.role === "admin"
    // ) {
    //     yield put(modalShow("ReleaseModal", {}));
    // }
}

export function* appSettings(action: AnyAction): any {
    return yield call(request, api.appSettings, action);
}

function* broadcasting() {
    const broadcasterService = globalThis.broadcaster.get();
    const channel = eventChannel((listener) => {
        const handleChange = (event: string, data: any) => {
            listener({ event, data });
        };

        broadcasterService.connection.bind_global(handleChange);

        return () => broadcasterService.connection.unbind_global(handleChange);
    });

    while (true) {
        const listener: IBroadcasterEvent = yield take(channel);
        switch (listener.event) {
            case "connected":
                yield put({ type: BROADCASTER_CONNECTED });
                break;

            case "disconnected":
                yield put({ type: BROADCASTER_DISCONNECTED });
                channel.close();
                return;

            case "message":
                yield put({ type: BROADCASTER_MESSAGE, ...listener.data });
                break;
            //no default
        }
    }
}

function* translate(action: AnyAction): any {
    const response = yield call(request, api.translate, action);

    if (response.status === 200) {
        (window as any).translate = response.data.data;
        document.documentElement.lang = response.data.locale;
    }
}

function* locale(action: AnyAction): any {
    yield call(translate, changeLocale(action.axiosConfig.config));
}

export const appSagas = [
    takeLatest(APP_INIT, initApp),
    takeLatest(LOCALE_REQUEST, locale),
];
