import { restaurantProductsDeletePortions } from "../actions";
import { IBulkActionModal } from "../interfaces";

import { Box, MenuItem } from "@mui/material";
import useRequest from "api/useRequest";
import { modalHide } from "app/App/actions";
import { settingsPortionsFetch } from "app/SettingsPortions/actions";
import { SettingsPortionsModel } from "app/SettingsPortions/interfaces";
import { AxiosResponse } from "axios";
import trans from "helpers/trans";
import { unitSymbol } from "helpers/units";
import { FC, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import {
    Button,
    Form,
    Loader,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
    RequestMessage,
    Select,
    Settings,
    SettingsItem,
} from "ui";
import { IGPFormRef } from "ui/Form/Form";

const PortionsDeleteModal: FC<IBulkActionModal> = ({ selected, onSuccess }) => {
    const dispatch = useDispatch();
    const formRef = useRef<IGPFormRef | null>();

    const {
        request,
        errors,
        isLoading: isLoadingDelete,
        isSuccess: isSuccessDelete,
    } = useRequest();

    const { data, isError, isPending, status } = useRequest(
        settingsPortionsFetch({ params: { _sort: { name: "asc" } } })
    );

    const renderPortion = (portion: SettingsPortionsModel) => {
        return (
            <MenuItem
                value={portion.id}
                key={`portion_id-${portion.id}`}
                id={portion.default_category_id}
            >
                {portion.name} {portion.size}
                {unitSymbol[portion.unit as keyof typeof unitSymbol]}
            </MenuItem>
        );
    };
    const portions = useMemo(() => {
        if (!data) return [];
        const preparePortions: Array<React.ReactNode> = [];
        data.data.forEach((portion: SettingsPortionsModel) => {
            preparePortions.push(renderPortion(portion));
        });
        return preparePortions;
    }, [data]);

    const handleCloseModal = () => {
        dispatch(modalHide("PortionsDeleteModal"));
    };

    const handleDeletePortions = () => {
        if (formRef.current !== null) {
            const data: any = formRef?.current?.getData();
            request(
                restaurantProductsDeletePortions(
                    {
                        ...data,
                        ids: selected,
                    },
                    (response: AxiosResponse) => {
                        if (response.status === 204) {
                            dispatch(modalHide("PortionsDeleteModal"));
                            onSuccess();
                        }
                    }
                )
            );
        }
    };

    if (isPending) return <Loader />;

    if (isError) return <RequestMessage status={status} />;

    return (
        <Modal open fullWidth maxWidth="md">
            <ModalTitle onClose={handleCloseModal}>
                {" "}
                {trans(
                    "inv.restaurantProducts.modal.portionsDeleteModal.title"
                )}
            </ModalTitle>
            <ModalContent>
                <Form
                    ref={formRef}
                    data={data}
                    errors={errors}
                    fields={{
                        portion_ids: { default: [] },
                    }}
                    loading={isLoadingDelete}
                    onSubmit={handleDeletePortions}
                    onSuccess={isSuccessDelete}
                    unsaved={false}
                >
                    <Settings>
                        <SettingsItem
                            label={trans(
                                "inv.restaurantProducts.modal.portionsDeleteModal.label.portion"
                            )}
                        >
                            <Select fullWidth id="portion_ids" multiple={true}>
                                <MenuItem value="">
                                    <em>
                                        {trans(
                                            "inv.restaurantProducts.modal.portionsDeleteModal.select.portions"
                                        )}
                                    </em>
                                </MenuItem>
                                {portions}
                            </Select>
                        </SettingsItem>
                    </Settings>
                </Form>
            </ModalContent>
            <ModalActions>
                <Box display="flex" width="100%" textAlign="right">
                    <Button
                        onClick={handleCloseModal}
                        color="inherit"
                        variant="text"
                        sx={{ mr: 1 }}
                    >
                        {trans("global.button.cancel")}
                    </Button>
                    <Button sx={{ flex: 1 }} onClick={handleDeletePortions}>
                        {trans("global.button.save")}
                    </Button>
                </Box>
            </ModalActions>
        </Modal>
    );
};

export default PortionsDeleteModal;
