import { FC, useEffect } from "react";
import { IChooseCategoryStep } from "app/Products/interfaces";
import { Typography, Grid, Paper } from "@mui/material";
import { useSelector } from "react-redux";
import ICategory from "types/ICategory";

const ChooseCategoryStep: FC<IChooseCategoryStep> = ({
    current_category_id,
    handleNextStep,
    handleChangeCategory,
}) => {
    const { categories, mainGroups } = useSelector((state: any) => {
        return {
            categories: state.app.settings.categories,
            mainGroups: state.app.settings.categoriesMainGroups,
        };
    });

    useEffect(() => {
        if (
            current_category_id &&
            categories[current_category_id].childrens.length === 0
        ) {
            handleNextStep();
        }
    }, [current_category_id, categories, handleNextStep]);

    const handleClickCategory = (category_id: number) => {
        handleChangeCategory(category_id);
    };

    const renderItem = (category: ICategory) => {
        return (
            <Grid
                key={category.name}
                item
                sx={{
                    cursor: "pointer",
                }}
                xs={1}
            >
                <Paper
                    elevation={3}
                    sx={{
                        bgcolor: category.bgcolor
                            ? category.bgcolor
                            : "grey.300",
                        p: 1,
                        minHeight: "100px",
                    }}
                    onClick={() => {
                        handleClickCategory(category.id);
                    }}
                >
                    <Typography
                        variant="h6"
                        align="center"
                        sx={{
                            color: (theme) =>
                                theme.palette.getContrastText(
                                    category.bgcolor
                                        ? category.bgcolor
                                        : theme.palette.grey[300]
                                ),
                        }}
                    >
                        {category.name}
                    </Typography>
                </Paper>
            </Grid>
        );
    };

    const renderList = () => {
        if (!current_category_id) {
            return mainGroups.map((item: number) => {
                return renderItem(categories[item]);
            });
        } else if (current_category_id) {
            const cat = categories[current_category_id];

            return cat.childrens.map((item: number) => {
                return renderItem(categories[item]);
            });
        }
    };

    return (
        <>
            <Grid container columns={2} spacing={2}>
                {renderList()}
            </Grid>
        </>
    );
};

export default ChooseCategoryStep;
