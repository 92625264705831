import { restaurantProductsGet, restaurantProductsUpdate } from "../actions";
import { IPortionModal } from "../interfaces";
import PortionsTable from "../tables/PortionsTable";

import { Icon, Stack } from "@mui/material";
import useRequest from "api/useRequest";
import { modalHide } from "app/App/actions";
import { AxiosResponse } from "axios";
import trans from "helpers/trans";
import { FC, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import {
    Button,
    Form,
    Loader,
    LoadingButton,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
    RequestMessage,
    Settings,
    SettingsItem,
} from "ui";
import { get } from "lodash";
import { IGPFormRef } from "ui/Form/Form";

const PortionsModal: FC<IPortionModal> = ({ id, itemId, reload }) => {
    const { errors, isLoading, request } = useRequest();
    const formRef = useRef<IGPFormRef | null>();
    const dispatch = useDispatch();

    const { currentData, isError, isPending, status } = useRequest(
        restaurantProductsGet(itemId, {
            params: {
                _with: [
                    "product.category",
                    "restaurant_product_portions.portion",
                ],
            },
        })
    );

    const product = useMemo(() => {
        if (!currentData) return null;
        currentData.data.restaurant_product_portions.forEach((item: any) => {
            if (!item.plu) item.plu = "";
        });
        return currentData.data;
    }, [currentData]);

    const handleAddPortion = (context: any, id: string) => {
        context.onChange([
            {
                id,
                value: [
                    ...get(context.data, id, []),
                    {
                        portion_id: "",
                        plu: "",
                        id: null,
                    },
                ],
            },
        ]);
    };

    const handlePortion = () => {
        if (formRef.current !== null) handleSubmit(formRef?.current?.getData());
    };
    const handleCloseModal = () => dispatch(modalHide(id));

    const handleSubmit = (data: any) => {
        request(
            restaurantProductsUpdate(
                itemId,
                data,
                (response: AxiosResponse) => {
                    if (response.status === 200) {
                        reload();
                        dispatch(modalHide(id));
                    }
                }
            )
        );
    };
    if (isPending) return <Loader />;

    if (isError) return <RequestMessage status={status} />;

    return (
        <Modal maxWidth="md" fullWidth open>
            <ModalTitle onClose={handleCloseModal}>
                {trans("inv.restaurantProducts.modal.portionsModal.title")}
            </ModalTitle>
            <ModalContent sx={{ display: "flex", flexDirection: "column" }}>
                <Form
                    ref={formRef}
                    data={product}
                    errors={errors}
                    fields={{
                        restaurant_product_portions: { default: [] },
                    }}
                    loading={isLoading}
                    onSubmit={handleSubmit}
                    unsaved={false}
                >
                    {(context: any) => {
                        return (
                            <Settings>
                                <SettingsItem
                                    label={trans(
                                        "inv.restaurantProducts.modal.portionsModal.label"
                                    )}
                                    actions={
                                        <Button
                                            onClick={() =>
                                                handleAddPortion(
                                                    context,
                                                    "restaurant_product_portions"
                                                )
                                            }
                                            startIcon={<Icon>add</Icon>}
                                        >
                                            {trans(
                                                "inv.restaurantProducts.modal.portionsModal.label"
                                            )}
                                        </Button>
                                    }
                                >
                                    {context?.data?.restaurant_product_portions
                                        ?.length !== 0 && (
                                        <PortionsTable
                                            context={context}
                                            item={product}
                                            id="restaurant_product_portions"
                                        />
                                    )}
                                </SettingsItem>
                            </Settings>
                        );
                    }}
                </Form>
            </ModalContent>
            <ModalActions
                sx={{
                    flexDirection: "column",
                    justifyContent: "center",
                    p: 0,
                }}
            >
                <Stack
                    direction="row"
                    sx={{
                        backgroundColor: "grey.200",
                        marginLeft: "0!important",
                        p: 1,
                        width: "100%",
                    }}
                >
                    <LoadingButton
                        loading={isLoading}
                        loadingPosition="start"
                        fullWidth
                        onClick={handlePortion}
                        startIcon={<Icon>save</Icon>}
                    >
                        {trans("global.button.save")}
                    </LoadingButton>
                </Stack>
            </ModalActions>
        </Modal>
    );
};

export default PortionsModal;
