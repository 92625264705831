import Checkbox from "./Checkbox";
import FormContext from "./FormContext";
import { getValue } from "./helpers";
import React, { useContext } from "react";

interface ICheckboxList {
    checkedItems: [];
    children: any;
    id: number;
    onChange: Function;
}

function CheckboxList({
    checkedItems,
    children,
    id,
    onChange,
    ...restProps
}: ICheckboxList) {
    const context = useContext(FormContext);

    const onChangeValue = ({ value, checkboxValue }: any) => {
        const data = getValue(id, context, checkedItems);
        let returnValue = [];

        if (Array.isArray(data)) {
            if (value) {
                returnValue = [...data, checkboxValue];
            } else {
                returnValue = data.filter((item) => item !== checkboxValue);
            }
        } else {
            if (value) {
                returnValue = [checkboxValue];
            }
        }

        if (!!onChange) {
            onChange({ id, value: returnValue }, context);
        } else {
            context?.onChange({ id, value: returnValue });
        }
    };

    let checkedList = getValue(id, context, checkedItems);
    if (!Array.isArray(checkedList)) {
        checkedList = [];
    }

    const setProps = (childrens: React.ReactNode): React.ReactNode => {
        return React.Children.map(childrens, (children) => {
            if (React.isValidElement(children)) {
                if (children.type === Checkbox) {
                    return React.cloneElement(children, {
                        ...children.props,
                        checked:
                            checkedList.indexOf(children.props.value) !== -1,
                        onChange: onChangeValue,
                    });
                } else if (children.props?.children) {
                    return React.cloneElement(children, {
                        ...children.props,
                        children: setProps(children.props.children),
                    });
                }
            }

            return children;
        });
    };

    return setProps(children);
}

export default CheckboxList;
