import { registerModals } from "modals";
import OrdersTable from "./reducers/OrdersTableReducer";
import OrdersDeliverySlips from "./reducers/OrdersDeliverySlipsReducer"
import OrdersSaga from "./saga";
import OrderLinesSearchModal from "./modals/OrderLinesSearchModal";
import OrderDeliveryDateModal from "./modals/OrderDeliveryDateModal";
import { registerRoutings } from "rootRouting";
import store from "rootStore";
import { permissionsType, configType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";

export const permissionKey = "orders";
export const url = "/orders";
export const proposalsUrl = `${url}/proposals`;
interface IConfig extends Omit<configType, "permissions"> {
    permissions: {
        create: boolean;
        delete: boolean;
        read: boolean;
        update: boolean;
        proposals: boolean;
    };
}
const getConfig = (
    permissions: permissionsType,
    settings: any,
    activeLocation: any
) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: IConfig = {
        permissions: {
            create: false,
            delete: false,
            read: false,
            update: false,
            proposals: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (
        modulePermissions.indexOf(permissionKey + ".create") !== -1 &&
        (activeLocation.type === "SATELLITE" || activeLocation.type === "STORE")
    ) {
        config.permissions.create = true;

        routing.push({
            path: `${url}/create`,
            element: <AsyncComponent path="Orders/pages/OrdersCreatePage" />,
        });
    }

    if (
        modulePermissions.indexOf(permissionKey + ".delete") !== -1 &&
        (activeLocation.type === "SATELLITE" || activeLocation.type === "STORE")
    ) {
        config.permissions.delete = true;
    }
    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        store.injectSaga?.("OrdersSaga", OrdersSaga);
    }

    if (
        modulePermissions.indexOf(permissionKey + ".read") !== -1 &&
        (activeLocation.type === "SATELLITE" || activeLocation.type === "STORE")
    ) {
        config.permissions.read = true;
        registerModals({
            OrderLinesSearchModal,
        });

        store.reducerManager?.add("OrdersTable", OrdersTable);
        // store.injectSaga?.("OrdersSaga", OrdersSaga);
        routing.push({
            path: url,
            element: <AsyncComponent path="Orders/pages/OrdersIndexPage" />,
        });
    }

    if (
        modulePermissions.indexOf(permissionKey + ".update") !== -1 &&
        (activeLocation.type === "SATELLITE" || activeLocation.type === "STORE")
    ) {
        store.reducerManager?.add("OrdersDeliverySlips", OrdersDeliverySlips)
        config.permissions.update = true;
        registerModals({
            OrderDeliveryDateModal,
        });
        routing.push({
            path: `${url}/:id/edit`,
            element: <AsyncComponent path="Orders/pages/OrdersEditPage" />,
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".proposals") !== -1) {
        config.permissions.proposals = true;

        routing.push({
            path: proposalsUrl,
            element: <AsyncComponent path="Orders/pages/OrdersProposalsPage" />,
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
