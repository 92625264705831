import { List, Typography } from "@mui/material";
import SearchProductListItem from "../../Inventory/components/SearchProductListItem";
import useRequest from "api/useRequest";
import { modalHide } from "app/App/actions";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { productsFetch } from "app/Products/actions";

import {
    Modal,
    ModalTitle,
    ModalContent,
    RequestMessage,
    ModalActions,
} from "ui";
import Search from "app/Inventory/components/Search";
import trans from "helpers/trans";
import { IProduct } from "../../Products/types";
import { useSelector } from "react-redux";

interface IOrderLinesSearchModal {
    selectProduct: Function;
    onClose: Function;
    supplierId: number;
    isReturn: boolean;
}

const OrderLinesSearchModal = (props: IOrderLinesSearchModal) => {
    const [data, setData] = useState([]);
    const { selectProduct } = props;
    const locationType = useSelector(
        (state: any) => state.app.locations[state.app.locationId].type
    );
    const dispatch = useDispatch();
    const { cancel, currentData, isError, isLoading, request, status } =
        useRequest();

    const onClose = () => {
        if (!!props.onClose) {
            props.onClose();
        }
        dispatch(modalHide("OrderLinesSearchModal"));
    };

    const onChangeSearch = (value: string) => {
        if (value.length > 0) {
            request(
                productsFetch({
                    params: {
                        _search: value,
                        supplier_location_id: props.supplierId,
                        without_recipes: 1,
                        local: locationType === "STORE" ? 1 : locationType === "SATELLITE" && props.isReturn ? 1 : 0,
                    },
                })
            );
        } else {
            setData([]);
        }
    };

    const onClickProduct = (product: IProduct) => {
        selectProduct(data[product._idx]);
    };

    useEffect(() => {
        if (!isLoading && status === 200) {
            setData(currentData.data);
        }
    }, [currentData?.data, isLoading, status]);

    const renderContent = () => {
        if (isLoading) return null;

        if (isError)
            return status === 404 ? (
                <Typography variant="body1" sx={{ textAlign: "center", mb: 2 }}>
                    {trans("inv.orders.modal.orderLinesSearchModal.notFound")}
                </Typography>
            ) : (
                <RequestMessage status={status} />
            );

        return (
            <>
                {data.map((item: any, idx: number) => {
                    return (
                        <SearchProductListItem
                            key={`search-ingredient-${item.id}`}
                            idx={idx}
                            product={{
                                ...item,
                                // Add restaurant_product because this component use this key for display additional data about product
                                ...(item.restaurant_products && {
                                    restaurant_product: {
                                        ...item.restaurant_product,
                                        delivery_slip_line_numbers:
                                            item.delivery_slip_line_numbers,
                                        location_suppliers:
                                            item.location_suppliers,
                                        name: item.name,
                                        price_history: item.price_history,
                                    },
                                }),
                                _idx: idx,
                            }}
                            onClick={onClickProduct}
                        />
                    );
                })}
            </>
        );
    };

    return (
        <Modal
            maxWidth="xs"
            fullWidth
            onClose={onClose}
            open
            PaperProps={{ sx: { position: "fixed", top: 0 } }}
        >
            <ModalTitle onClose={onClose}>
                {trans("inv.orders.modal.orderLinesSearchModal.search")}
            </ModalTitle>
            <ModalActions sx={{ px: 2, gap: 1 }}>
                <Search
                    autoFocus={true}
                    cancelRequest={cancel}
                    isLoading={isLoading}
                    placeholder={trans(
                        "inv.orders.modal.orderLinesSearchModal.placeholder"
                    )}
                    onChange={onChangeSearch}
                />
            </ModalActions>
            <ModalContent sx={{ py: 0, position: "relative" }}>
                <List>{renderContent()}</List>
            </ModalContent>
        </Modal>
    );
};

export default OrderLinesSearchModal;
