import { getModule } from "moduleConfig";
import { useMemo } from "react";

const usePermission = (moduleName, permission) => {
    return useMemo(() => {
        const module = getModule(moduleName);
        if (!!module) {
            return module.permissions[permission];
        }

        return false;
    }, [moduleName, permission]);
};

export default usePermission;
