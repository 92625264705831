import { useMemo } from "react";
import { IFormChangeResponse } from "ui/Form/Form";
import Checkbox from "../Form/Checkbox";
import { IGPTableSelection } from "./Table";

interface ITableSelectorBody {
    id: string;
    data: {
        [key: string]: never;
    };
    selection: IGPTableSelection;
    tableUpdate: Function;
}

function TableSelectorBody({
    id,
    data,
    selection,
    tableUpdate,
}: ITableSelectorBody) {
    const handleChange = ({ value }: IFormChangeResponse) => {
        let items = [];

        if (value) {
            items = [...selection.selected, data[selection.id]];
        } else {
            items = selection.selected.filter(
                (item: any) => item !== data[selection.id]
            );
        }

        tableUpdate("selection", items);
    };

    const checked = useMemo(() => {
        return selection.selected.indexOf(data[selection.id]) !== -1;
    }, [data, selection.id, selection.selected]);

    return (
        <Checkbox
            id={`${id}-row-selection-${data[selection.id]}`}
            onChange={handleChange}
            checked={checked}
            formControlProps={{
                onClick: (event: MouseEvent) => event.stopPropagation(),
            }}
            formControlLabelProps={{
                sx: {
                    m: 0,
                },
            }}
            sx={{ p: "5px" }}
        />
    );
}

TableSelectorBody.defaultProps = {};

export default TableSelectorBody;
