import { Box, Icon, Typography } from "@mui/material";
import trans from "helpers/trans";
interface IGPTableNoData {
    rows: number;
}
function GPTableNoData({ rows }: IGPTableNoData) {
    if (rows > 0) {
        return null;
    }

    return (
        <Box
            sx={{
                alignItems: "center",
                background: "grey.100",
                color: "grey.700",
                display: "flex",
                flexDirection: "column",
                fontSize: 16,
                justifyContent: "center",
                padding: 8,
            }}
        >
            <Icon fontSize="large">inbox</Icon>
            <Typography pt={1}>{trans("ui.table.tableNoData.noData")}</Typography>
        </Box>
    );
}

GPTableNoData.defaultProps = {};

export default GPTableNoData;
