import Box from "@mui/material/Box";
import { BoxProps } from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import React from "react";
import { Typography } from "@mui/material";

interface IPageHeader extends Omit<BoxProps, "title"> {
    actions?: React.ReactNode;
    breadcrumps?: React.ReactNode;
    title?: string | React.ReactElement;
}

function PageHeader({
    actions,
    breadcrumps,
    title,
    ...restProps
}: IPageHeader) {
    return (
        <Box {...restProps}>
            {breadcrumps}

            <Stack alignItems="center" direction="row">
                <Box sx={{ minWidth: 0 }}>
                    {!!title && (
                        <Typography
                            variant="h5"
                            sx={{
                                // alignItems: "center",
                                // display: "flex",
                                fontWeight: 500,
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                            }}
                        >
                            {title}
                        </Typography>
                    )}
                </Box>
                <Box display="flex" flex={1} justifyContent="flex-end">
                    {actions}
                </Box>
            </Stack>
        </Box>
    );
}

PageHeader.defaultProps = {
    pt: { xs: 1, sm: 2 },
    px: { xs: 1.5, sm: 3 },
};

export default PageHeader;
