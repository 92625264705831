export type InventoryReportModel = {
    [key: string]: string | number | null | undefined;
    id: number;
    created_at: string;
    end_date: string | null;
    updated_at?: string;
    location_id?: number;
    user_id?: number;
};

export type TInvetoryReportDownload = {
    id: number;
    type: number;
};

export const DESTROY_FAILED = "INVENTORY_REPORT/DESTROY_FAILED";
export const DESTROY_REQUEST = "INVENTORY_REPORT/DESTROY_REQUEST";
export const DESTROY_SUCCESS = "INVENTORY_REPORT/DESTROY_SUCCESS";

export const FETCH_FAILED = "INVENTORY_REPORT/FETCH_FAILED";
export const FETCH_REQUEST = "INVENTORY_REPORT/FETCH_REQUEST";
export const FETCH_SUCCESS = "INVENTORY_REPORT/FETCH_SUCCESS";

export const GET_FAILED = "INVENTORY_REPORT/GET_FAILED";
export const GET_REQUEST = "INVENTORY_REPORT/GET_REQUEST";
export const GET_SUCCESS = "INVENTORY_REPORT/GET_SUCCESS";

export const REOPEN_FAILED = "INVENTORY/REOPEN_FAILED";
export const REOPEN_REQUEST = "INVENTORY/REOPEN_REQUEST";
export const REOPEN_SUCCESS = "INVENTORY/REOPEN_SUCCESS";

export const REPORT_DOWNLOAD_FAILED = "INVENTORY_REPORT/REPORT_DOWNLOAD_FAILED";
export const REPORT_DOWNLOAD_REQUEST =
    "INVENTORY_REPORT/REPORT_DOWNLOAD_REQUEST";
export const REPORT_DOWNLOAD_SUCCESS =
    "INVENTORY_REPORT/REPORT_DOWNLOAD_SUCCESS";

export const STORE_FAILED = "INVENTORY_REPORT/STORE_FAILED";
export const STORE_REQUEST = "INVENTORY_REPORT/STORE_REQUEST";
export const STORE_SUCCESS = "INVENTORY_REPORT/STORE_SUCCESS";

export const UPDATE_FAILED = "INVENTORY_REPORT/UPDATE_FAILED";
export const UPDATE_REQUEST = "INVENTORY_REPORT/UPDATE_REQUEST";
export const UPDATE_SUCCESS = "INVENTORY_REPORT/UPDATE_SUCCESS";

export const TABLE_UPDATE = "INVENTORY_REPORT/TABLE_UPDATE";

const types = {
    DESTROY_FAILED,
    DESTROY_REQUEST,
    DESTROY_SUCCESS,

    FETCH_FAILED,
    FETCH_REQUEST,
    FETCH_SUCCESS,

    GET_FAILED,
    GET_REQUEST,
    GET_SUCCESS,

    REOPEN_FAILED,
    REOPEN_REQUEST,
    REOPEN_SUCCESS,

    REPORT_DOWNLOAD_FAILED,
    REPORT_DOWNLOAD_REQUEST,
    REPORT_DOWNLOAD_SUCCESS,

    STORE_FAILED,
    STORE_REQUEST,
    STORE_SUCCESS,

    UPDATE_FAILED,
    UPDATE_REQUEST,
    UPDATE_SUCCESS,

    TABLE_UPDATE,
};

export default types;
