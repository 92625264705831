export const APP_INIT = "APP_INIT";

export const APP_HANDLE_ERROR = "APP_HANDLE_ERROR";
export const APP_LOADED = "APP_LOADED";

export const APP_SETTINGS_FAILED = "APP_SETTINGS_FAILED";
export const APP_SETTINGS_REQUEST = "APP_SETTINGS_REQUEST";
export const APP_SETTINGS_SUCCESS = "APP_SETTINGS_SUCCESS";

export const APP_TRANSLATE_FAILED = "APP_TRANSLATE_FAILED";
export const APP_TRANSLATE_REQUEST = "APP_TRANSLATE_REQUEST";
export const APP_TRANSLATE_SUCCESS = "APP_TRANSLATE_SUCCESS";

export const BROADCASTER_CONNECTED = "BROADCASTER_CONNECTED";
export const BROADCASTER_DISCONNECTED = "BROADCASTER_DISCONNECTED";
export const BROADCASTER_MESSAGE = "BROADCASTER_MESSAGE";

export const LOCALE_REQUEST = "LOCALE_REQUEST";
export const LOCALE_SUCCESS = "LOCALE_SUCCESS";
export const LOCALE_FAILED = "LOCALE_FAILED";

export const CONFIRMATION_CONFIRMED = "CONFIRMATION_CONFIRMED";
export const CONFIRMATION_UNCONFIRMED = "CONFIRMATION_UNCONFIRMED";

export const MODAL_HIDE = "MODAL_HIDE";
export const MODAL_SHOW = "MODAL_SHOW";

export const SNACKBAR_HIDE = "SNACKBAR_HIDE";
export const SNACKBAR_SHOW = "SNACKBAR_SHOW";

export const SIDE_NAV_TYPE = "SIDE_NAV_TYPE";

export const TABLE_SETTINGS_FAILED = "TABLE_SETTINGS_FAILED";
export const TABLE_SETTINGS_REQUEST = "TABLE_SETTINGS_REQUEST";
export const TABLE_SETTINGS_SUCCESS = "TABLE_SETTINGS_SUCCESS";

export const TABLE_UPDATE_FAILED = "TABLE_UPDATE_FAILED";
export const TABLE_UPDATE_REQUEST = "TABLE_UPDATE_REQUEST";
export const TABLE_UPDATE_SUCCESS = "TABLE_UPDATE_SUCCESS";

export const TABLE_UPDATE_MANUALLY = "TABLE_UPDATE_MANUALLY";

export const TABLE_UPDATE = "TABLE_UPDATE";
