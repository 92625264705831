import { api } from "apiConnections";

const setLocationHeader = (location: number | null = null) => {
    if (location) {
        api.defaults.headers.common["X-GP-LOCATION-ID"] = location;
    } else {
        delete api.defaults.headers.common["X-GP-LOCATION-ID"];
    }
};

export default setLocationHeader;
