import { request } from "api/apiSaga";
import { snackbarShow } from "app/App/actions";
import { AxiosResponse } from "axios";
import trans from "helpers/trans";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import api from "./api";
import types from "./types";

function* menuItemsFetch(action: AnyAction) {
    yield call(request, api.menuItemsFetch, action);
}

function* menuItemsCreate(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.menuItemCreate,
        action,
        "post"
    );

    if (response.status < 300) {
        yield put(
            snackbarShow({
                content: trans("inv.menusItems.saga.content.create"),
            })
        );
    }
}

function* menuItemsGet(action: AnyAction) {
    yield call(request, api.menuItemGet, action);
}

function* menuItemsCostAvgGet(action: AnyAction) {
    yield call(request, api.menuItemsCostAvg, action);
}

function* menuItemsUpdate(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.menuItemUpdate,
        action,
        "put"
    );

    if (response.status < 300) {
        yield put(
            snackbarShow({
                content: trans("inv.menusItems.saga.content.update"),
            })
        );
    }
}

function* menuItemsDelete(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.menuItemDelete,
        action,
        "delete"
    );

    if (response.status < 300) {
        yield put(
            snackbarShow({
                content: trans("inv.menusItems.saga.content.delete"),
            })
        );
    }
}

export default function* saga() {
    yield takeLatest(types.FETCH_REQUEST, menuItemsFetch);
    yield takeLatest(types.CREATE_REQUEST, menuItemsCreate);
    yield takeLatest(types.GET_REQUEST, menuItemsGet);
    yield takeLatest(types.UPDATE_REQUEST, menuItemsUpdate);
    yield takeLatest(types.DELETE_REQUEST, menuItemsDelete);
    yield takeLatest(types.GET_AVG_COST_REQUEST, menuItemsCostAvgGet);
}
