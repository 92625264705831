import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { modalHide } from "app/App/actions";
import { Divider, Box } from "@mui/material";
import { Modal, ModalTitle, ModalContent, ModalActions } from "ui";
import { countriesList } from "CountriesISO";
import {
    OutlinedInput,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    InputAdornment,
    Icon,
} from "@mui/material";

export type CountryModel = {
    id: string;
    flag: string;
    name: string;
    search: string;
    country: string;
};

interface ICountrySelectModal {
    title: string;
    onClickCountry: (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        item: CountryModel
    ) => void;
    popularCountries?: string[];
    searchPlaceHolder?: string;
}

const CountrySelectModal: FC<ICountrySelectModal> = ({
    title,
    onClickCountry,
    popularCountries,
    searchPlaceHolder,
}) => {
    const dispatch = useDispatch();
    const [searchCountryValue, setSearchCountryValue] = useState("");

    const onClose = () => {
        dispatch(modalHide("ChooseCountryModal"));
    };

    const renderPopularCountries = (countries: string[]) => {
        const popularCountries = countriesList.filter((item) => {
            if (countries.includes(item.country)) {
                return item;
            }

            return null;
        });

        if (popularCountries)
            return (
                <>
                    {popularCountries.map((item, idx) => (
                        <Box key={item.country}>
                            <ListItem
                                button
                                onClick={(event) => onClickCountry(event, item)}
                            >
                                <ListItemIcon>
                                    <div
                                        className={`fflag ff-md fflag-${item.country.toUpperCase()}`}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={item.name} />
                            </ListItem>
                            {idx === popularCountries.length - 1 ? null : (
                                <Divider />
                            )}
                        </Box>
                    ))}
                    <hr />
                </>
            );
    };

    return (
        <Modal
            onClose={onClose}
            open
            maxWidth="xs"
            fullWidth
            PaperProps={{ sx: { position: "fixed", top: 0 } }}
        >
            <ModalTitle onClose={onClose}>{title}</ModalTitle>
            <ModalActions>
                <OutlinedInput
                    autoFocus
                    fullWidth
                    onChange={(e) => setSearchCountryValue(e.target.value)}
                    placeholder={searchPlaceHolder}
                    size="small"
                    startAdornment={
                        <InputAdornment position="start">
                            <Icon>search</Icon>
                        </InputAdornment>
                    }
                    value={searchCountryValue}
                />
            </ModalActions>
            <ModalContent sx={{ pt: 0, px: 2 }}>
                {!searchCountryValue && popularCountries
                    ? renderPopularCountries(popularCountries)
                    : null}
                <List component="nav" sx={{ pt: 0 }}>
                    {countriesList.map((item) => {
                        if (
                            searchCountryValue.length > 0 &&
                            item.search.indexOf(
                                searchCountryValue.toLowerCase()
                            ) === -1
                        ) {
                            return null;
                        }

                        return (
                            <Box key={`flag-${item.country}`}>
                                <ListItem
                                    button
                                    onClick={(event) =>
                                        onClickCountry(event, item)
                                    }
                                >
                                    <ListItemIcon>
                                        <div
                                            className={`fflag ff-md fflag-${item.country.toUpperCase()}`}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={item.name} />
                                </ListItem>
                                <Divider />
                            </Box>
                        );
                    })}
                </List>
            </ModalContent>
        </Modal>
    );
};

CountrySelectModal.defaultProps = {
    searchPlaceHolder: "Search",
};

export default CountrySelectModal;
