import types from "./types";
import { request } from "api/actions";
import { IGastrofixIntegration, IGastrofixISync, IGastrofixUpdate } from "./interfaces";

export const gastrofixIntegrate = (
    data: IGastrofixIntegration,
    onResponse?: Function
) => {
    return request(
        types.INTEGRATE_REQUEST,
        types.INTEGRATE_FAILED,
        types.INTEGRATE_SUCCESS,
        "gastrofixIntegrate",
        { data },
        onResponse
    );
};

export const gastrofixSync = (
    data: IGastrofixISync,
    onResponse?: Function
) => {
    return request(
        types.SYNC_REQUEST,
        types.SYNC_FAILED,
        types.SYNC_SUCCESS,
        "gastrofixSync",
        { data },
        onResponse
    );
};

export const gastrofixUpdate = (
    data: IGastrofixUpdate,
    onResponse?: Function
) => {
    return request(
        types.UPDATE_REQUEST,
        types.UPDATE_FAILED,
        types.UPDATE_SUCCESS,
        "gastrofixUpdate",
        { data },
        onResponse
    );
};