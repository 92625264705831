import { Button, ButtonGroup } from "@mui/material";
import trans from "helpers/trans";
import { getUnitGroupByUnit } from "helpers/units";
import { FC } from "react";

type TCounterUnit = {
    productUoM: string;
    onChange: Function;
    unit: string;
};

export type TUnitGroupOption = {
    value: string;
    label: string;
};

export type TUnitGroup = {
    id: string;
    label: String;
    options: TUnitGroupOption[];
};

const CounterUnit: FC<TCounterUnit> = ({ unit, productUoM, onChange }) => {
    const availableUnits = getUnitGroupByUnit(productUoM);
    if (!availableUnits || availableUnits.id === "count") {
        return null;
    }

    const renderUnitItem = (item: TUnitGroupOption) => {
        return (
            <Button
                key={`counter-unit-btn-${item.value}`}
                onClick={() => onChange(item.value)}
                size="small"
                variant={unit === item.value ? "contained" : "outlined"}
            >
                {trans(item.label)}
            </Button>
        );
    };

    return (
        <ButtonGroup>
            {availableUnits.options.map((item) => renderUnitItem(item))}
            {renderUnitItem({ value: "UNIT", label: "unit" })}
        </ButtonGroup>
    );
};

export default CounterUnit;
