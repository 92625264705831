import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormControlLabelProps,
    FormHelperText,
} from "@mui/material";
import { CheckboxProps } from "@mui/material";
import React, { useContext } from "react";

import FormContext from "./FormContext";
import { getHelperText, getValue } from "./helpers";

interface IGPCheckbox extends Omit<CheckboxProps, "onChange"> {
    checked?: boolean;
    errorText?: string;
    formControlProps?: {};
    formControlLabelProps?: Omit<FormControlLabelProps, "control" | "label">;
    helperText?: string;
    label: string;
    id: string;
    value?: boolean | null;
    onChange?: Function;
}

function GPCheckbox({
    id,
    label,
    onChange,
    checked,
    errorText,
    formControlLabelProps,
    formControlProps,
    helperText,
    value,
    ...restProps
}: IGPCheckbox) {
    const context = useContext(FormContext);

    const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = value !== undefined ? value : event.target.checked;

        if (!!onChange) {
            onChange(
                {
                    id,
                    value: val,
                    checkboxValue: event.target.value,
                    event: event,
                },
                context
            );
        } else {
            context?.onChange({
                id,
                value: event.target.checked,
                checkboxValue: event.target.value,
            });
        }
    };

    const { inputHelperText, isError } = getHelperText(
        id,
        context,
        errorText,
        helperText
    );

    return (
        <FormControl {...formControlProps} error={isError}>
            <FormControlLabel
                {...formControlLabelProps}
                sx={{
                    mx: 0,
                    ...formControlLabelProps?.sx,
                }}
                control={
                    <Checkbox
                        {...restProps}
                        checked={Boolean(getValue(id, context, checked))}
                        onChange={onChangeValue}
                    />
                }
                label={label}
            />
            {inputHelperText.length > 0 && (
                <FormHelperText>{inputHelperText}</FormHelperText>
            )}
        </FormControl>
    );
}

GPCheckbox.defaultProps = {
    label: "",
    errorText: "",
    formControlLabelProps: {},
    formControlProps: {},
    helperText: "",
};

export default GPCheckbox;
