import { registerModals } from "modals";
import { registerRoutings } from "rootRouting";
import store from "rootStore";
import { configType, permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";
import { menusUrl } from "../Menus/config";
import MenuItemsTable from "./reducers/MenuItemsTableReducer";
import MenuItemsSaga from "./saga";
import MenusItemsCreateModal from "./modals/MenusItemsCreateModal";
import MenusItemsChangePortionModal from "./modals/MenusItemsChangePortionModal";

export const baseMenuItemsUrl = "/items";
export const menusItemsUrl = `${menusUrl}/:menuId/items`;
const permissionKey = "menus.items";

const getConfig = (permissions: permissionsType) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: configType = {
        permissions: {
            create: false,
            delete: false,
            update: false,
            read: false,
        },
        routing: [],
        url: menusItemsUrl,
    };
    let routing: routingType = [];

    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;

        store.reducerManager?.add("MenuItemsTable", MenuItemsTable);
        store.injectSaga?.("MenuItemsSaga", MenuItemsSaga);

        registerModals({ MenusItemsCreateModal });

        routing.push({
            path: menusItemsUrl,
            element: (
                <AsyncComponent path="MenuItems/pages/MenusItemsIndexPage" />
            ),
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".create") !== -1) {
        config.permissions.create = true;

        routing.push({
            path: `${menusItemsUrl}/create`,
            element: (
                <AsyncComponent path="MenuItems/pages/MenusItemsCreatePage" />
            ),
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
        config.permissions.update = true;

        registerModals({ MenusItemsChangePortionModal });

        routing.push({
            path: `${menusItemsUrl}/:id/edit`,
            element: (
                <AsyncComponent path="MenuItems/pages/MenusItemsEditPage" />
            ),
        });
    }

    if (modulePermissions.indexOf(permissionKey + ".delete") !== -1) {
        config.permissions.delete = true;
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
