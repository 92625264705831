import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import { IProductsVintage } from "./interfaces";
import RequestUrlId from "types/RequestUrlId";

const requests = {
    destroy: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.delete(`/products/${url.id}`, config),
    fetch: (config: AxiosRequestConfig) => api.get(`/products`, config),
    get: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/products/${url.id}`, config),
    store: (data: object, config: AxiosRequestConfig) =>
        api.post(`/products`, data, config),
    update: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.put(`/products/${url.id}`, data, config),
    vintage: (
        data: object,
        config: AxiosRequestConfig,
        url: IProductsVintage
    ) => api.post(`/products/${url.productId}/vintage`, data, config),
    productSuppliers: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/products/suppliers`, config),
};

export default requests;
