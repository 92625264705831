import TextField from "@mui/material/TextField";
import React, { forwardRef, useContext } from "react";
import { TextFieldProps } from "@mui/material/TextField";

import FormContext from "./FormContext";
import { getHelperText, getValue, regexValue } from "./helpers";
export interface IGPTextField extends Omit<TextFieldProps, "onChange"> {
    errorText?: string;
    id: string;
    onChange?: Function;
    regex?: string;
}

const GPTextField = forwardRef<any, IGPTextField>((props, ref) => {
    const { errorText, helperText, id, onChange, regex, value, ...restProps } =
        props;

    const context = useContext(FormContext);

    const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = regexValue(id, context, regex, event.target.value);

        if (!!onChange) {
            onChange({ id, value: val }, context);
        } else {
            context?.onChange({ id, value: val });
        }
    };

    const { inputHelperText, isError } = getHelperText(
        id,
        context,
        errorText,
        helperText
    );

    return (
        <TextField
            {...restProps}
            error={isError}
            helperText={inputHelperText}
            onChange={onChangeValue}
            ref={ref}
            value={getValue(id, context, value)}
        />
    );
});

GPTextField.defaultProps = {
    errorText: "",
    helperText: "",
    size: "small",
};

export default GPTextField;
