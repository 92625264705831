import SettingsModules from "./saga";

import { url as settingsUrl } from "app/Settings/config";
import { registerRoutings } from "rootRouting";
import store from "rootStore";
import { permissionsType, routingType } from "types/configTypes";
import AsyncComponent from "ui/AsyncComponent";

export const url = `${settingsUrl}/modules`;
export const permissionKey = "module";

interface IConfig {
    permissions: {
        activate: boolean;
        delete: boolean;
        read: boolean;
        update: boolean;
    };
    routing: Array<string>;
    url: string;
}

const getConfig = (permissions: permissionsType) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: IConfig = {
        permissions: {
            activate: false,
            delete: false,
            read: false,
            update: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (modulePermissions.indexOf(permissionKey + ".delete") !== -1) {
        config.permissions.delete = true;
    }

    if (modulePermissions.indexOf(permissionKey + ".activate") !== -1) {
        config.permissions.activate = true;
    }

    if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
        config.permissions.update = true;
    }
    
    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;

        store.injectSaga?.("SettingsModules", SettingsModules);

        routing.push({
            path: url,
            element: (
                <AsyncComponent path="SettingsModules/pages/ModulesIndexPage" />
            ),
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
