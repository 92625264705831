export const FETCH_REQUEST = "MENU_CATEGORIES/FETCH_REQUEST";
export const FETCH_SUCCESS = "MENU_CATEGORIES/FETCH_SUCCESS";
export const FETCH_FAILED = "MENU_CATEGORIES/FETCH_FAILED";

export const CREATE_REQUEST = "MENU_CATEGORIES/CREATE_REQUEST";
export const CREATE_SUCCESS = "MENU_CATEGORIES/CREATE_SUCCESS";
export const CREATE_FAILED = "MENU_CATEGORIES/CREATE_FAILED";

export const UPDATE_REQUEST = "MENU_CATEGORIES/UPDATE_REQUEST";
export const UPDATE_SUCCESS = "MENU_CATEGORIES/UPDATE_SUCCESS";
export const UPDATE_FAILED = "MENU_CATEGORIES/UPDATE_FAILED";

export const DELETE_REQUEST = "MENU_CATEGORIES/DELETE_REQUEST";
export const DELETE_SUCCESS = "MENU_CATEGORIES/DELETE_SUCCESS";
export const DELETE_FAILED = "MENU_CATEGORIES/DELETE_FAILED";

export const GET_REQUEST = "MENU_CATEGORIES/GET_REQUEST";
export const GET_SUCCESS = "MENU_CATEGORIES/GET_SUCCESS";
export const GET_FAILED = "MENU_CATEGORIES/GET_FAILED";

export const TABLE_UPDATE = "MENU_CATEGORIES/TABLE_UPDATE";

const types = {
    FETCH_REQUEST,
    FETCH_SUCCESS,
    FETCH_FAILED,

    CREATE_REQUEST,
    CREATE_SUCCESS,
    CREATE_FAILED,

    UPDATE_REQUEST,
    UPDATE_SUCCESS,
    UPDATE_FAILED,

    GET_REQUEST,
    GET_SUCCESS,
    GET_FAILED,

    DELETE_REQUEST,
    DELETE_SUCCESS,
    DELETE_FAILED,

    TABLE_UPDATE,
};

export default types;
