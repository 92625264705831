import { Box } from "@mui/material";
import { modalHide } from "app/App/actions";
import setImgAPI from "modules/Gastrofix/assets/setImgAPI.png";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalContent } from "ui";

const ShowImage: FC = () => {
    const dispatch = useDispatch();

    return (
        <Modal
            maxWidth="md"
            fullWidth
            open
            onClose={() => dispatch(modalHide("ShowImage"))}
            fullScreen={false}
        >
            <ModalContent
                sx={{
                    display: "flex",
                }}
            >
                <Box
                    component="img"
                    sx={{
                        maxWidth: "100%",
                        height: "auto",
                        objectFit: "contain",
                    }}
                    src={setImgAPI}
                />
            </ModalContent>
        </Modal>
    );
};

export default ShowImage;
