export interface MenuItemPriceModel {
    id?: number;
    menu_item_id?: number;
    tax_id: number;
    netto: number;
    gross: number;
    created_at?: number;
    updated_at?: number;
}

export type nettoPriceType = "NETTO";
export type grossPriceType = "GROSS";

export type pricesType = nettoPriceType | grossPriceType;

export enum pricesEnum {
    netto = "NETTO",
    gross = "GROSS",
}

export interface IMenuItemsFilters extends Record<string, any> {
    active?: boolean;
    menu_category_id: number[];
}

export interface MenuItemUpdateModel {
    id?: number;
    menu_id?: number;
    menu_category_id?: number;
    restaurant_product_id?: number;
    restaurant_product?: {
        plu?: number;
    };
    restaurant_product_portion_id?: number;
    restaurant_product_portion?: { plu?: number };
    name?: string;
    active?: boolean;
    prices?: MenuItemPriceModel[];
    created_at?: string;
    updated_at?: string;
    recommended?: boolean;
    product?: {
        image: string;
    };
}

export interface MenuItemUpdateModel {
    id?: number;
    menu_id?: number;
    menu_category_id?: number;
    restaurant_product_id?: number;
    restaurant_product?: {
        plu?: number;
    };
    restaurant_product_portion_id?: number;
    restaurant_product_portion?: { plu?: number };
    name?: string;
    active?: boolean;
    prices?: MenuItemPriceModel[];
    created_at?: string;
    updated_at?: string;
}

export interface IMenuCategory {
    calculation_fixed_amount: any;
    calculation_method: string;
    calculation_portion_value: any;
    calculation_value: number;
    id: number;
    item_cost_average: any;
    menu: any;
    menu_id: number;
    name: string;
}
export interface MenuItemModel {
    id?: number;
    menu_id?: number;
    menu?: {
        menu_categories?: IMenuCategory[];
    };
    menu_category?: {
        subtranslations: {
            name: { lang_en: string; lang_nb: string };
        };
    };
    menu_category_id?: number;
    restaurant_product_id?: number;
    restaurant_product: {
        plu: number;
        product: {
            images: any;
        }
    };
    restaurant_product_portion_id?: number;
    restaurant_product_portion: { plu: number };
    name?: string;
    active?: boolean;
    prices?: MenuItemPriceModel[];
    created_at?: string;
    updated_at?: string;
    recommended?: boolean;
    product?: {
        image: string;
    };
}

export type formDataType = {
    menu_category_id: number;
    name: string;
    active: boolean;
    restaurant_product_id: number;
    prices: MenuItemPriceModel[];
};

export interface IUpdateField {
    item: MenuItemModel;
    reload: Function;
}

export interface IRestaurantProductPortion {
    id: number;
    plu: number;
    portion: {
        created_at: string;
        default_category?: number;
        default_category_id?: null;
        id: number;
        name: string;
        size: number;
        unit: string;
        updated_at?: string;
    };
}

export const SELECT_PRODUCT_VIEW = "SELECT_PRODUCT";
export const SELECT_PORTION_VIEW = "SELECT_PORTION";

export type SearchModalView =
    | typeof SELECT_PRODUCT_VIEW
    | typeof SELECT_PORTION_VIEW;
