export const ME_FAILED = "ME_FAILED";
export const ME_REQUEST = "ME_REQUEST";
export const ME_SUCCESS = "ME_SUCCESS";

export const LOGOUT_FAILED = "LOGOUT_FAILED";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const PERMISSIONS_FAILED = "PERMISSIONS_FAILED";
export const PERMISSIONS_REQUEST = "PERMISSIONS_REQUEST";
export const PERMISSIONS_SUCCESS = "PERMISSIONS_SUCCESS";

const types = {
    ME_FAILED,
    ME_REQUEST,
    ME_SUCCESS,

    LOGOUT_FAILED,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,

    PERMISSIONS_FAILED,
    PERMISSIONS_REQUEST,
    PERMISSIONS_SUCCESS,
};

export default types;
