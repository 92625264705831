import { IGtinTable, IGtin } from "../interfaces";

import { Icon, IconButton, Box, MenuItem } from "@mui/material";
import trans from "helpers/trans";
import { get } from "lodash";
import { FC } from "react";
import { Table, TextField, Select } from "ui";

const GtinTable: FC<IGtinTable> = ({ context, disabled = false, id }) => {
    const handleDeleteRow = (itemIdx: number) => {
        context.onChange([
            {
                id,
                value: get(context.data, id, []).filter(
                    (item: IGtin, idx: number) => idx !== itemIdx
                ),
            },
        ]);
    };

    const renderGtin = (item: IGtin, idx: number) => {
        return (
            <Box sx={{ width: "100%", minWidth: "200px", display: "flex" }}>
                <TextField
                    type="number"
                    id={`${id}.${idx}.gtin`}
                    variant="outlined"
                    fullWidth
                    InputProps={{ inputProps: { min: 0 } }}
                    size="small"
                    disabled={disabled}
                />
            </Box>
        );
    };

    const renderPackage = (item: IGtin, idx: number) => {
        return (
            <Box sx={{ width: "100%", minWidth: "200px", display: "flex" }}>
                <TextField
                    id={`${id}.${idx}.package_quantity`}
                    type="number"
                    // {...(gtinArray?.[idx]?.unit_of_measure === 1 && {
                    //     value: 1,
                    // })}
                    disabled={disabled}
                    variant="outlined"
                    fullWidth
                    InputProps={{ inputProps: { min: 1 } }}
                    size="small"
                />
            </Box>
        );
    };
    const renderUnitOfMeasure = (item: IGtin, idx: number) => {
        return (
            <Box sx={{ width: "100%", minWidth: "200px", display: "flex" }}>
                <Select
                    fullWidth
                    id={`${id}.${idx}.unit_of_measure`}
                    type="number"
                    disabled={disabled}
                >
                    <MenuItem value="">
                        <em>
                            {trans(
                                "inv.restaurantProducts.table.gtinTable.select.unit"
                            )}
                        </em>
                    </MenuItem>
                    {[1, 2].map((unit: number, id: number) => {
                        return (
                            <MenuItem
                                key={`unit-of-measure-${id}`}
                                value={unit}
                            >
                                {unit === 1 ? "item" : "package"}
                            </MenuItem>
                        );
                    })}
                </Select>
            </Box>
        );
    };

    return (
        <Table
            calculateHeight={false}
            id="GtinTable"
            calculateWidth={true}
            columns={[
                {
                    field: "gtin",
                    headName: trans(
                        "inv.restaurantProducts.table.gtinTable.headName.gtin"
                    ),
                    cellProps: {
                        align: "right",
                        size: "small",
                        sx: { whiteSpace: "nowrap" },
                    },
                    renderCell: (item, column, idx) => ({
                        children: renderGtin(item, idx),
                    }),
                },
                {
                    field: "unit_of_measure",
                    headName: trans(
                        "inv.restaurantProducts.table.gtinTable.headName.unitOfMeasure"
                    ),
                    cellProps: {
                        align: "right",
                        size: "small",
                        sx: { whiteSpace: "nowrap" },
                    },
                    renderCell: (item, column, idx) => ({
                        children: renderUnitOfMeasure(item, idx),
                    }),
                },
                {
                    field: "package_quantity",
                    headName: trans(
                        "inv.restaurantProducts.table.gtinTable.headName.packageQuantity"
                    ),
                    cellProps: {
                        align: "right",
                        size: "small",
                        sx: { whiteSpace: "nowrap" },
                    },
                    renderCell: (item, column, idx) => {
                        return {
                            children: renderPackage(item, idx),
                        };
                    },
                },
                {
                    field: "actions",
                    renderCell: (item, column, idx) => {
                        return {
                            children: (
                                <IconButton
                                    size="small"
                                    disabled={disabled}
                                    onClick={() => handleDeleteRow(idx)}
                                >
                                    <Icon>delete</Icon>
                                </IconButton>
                            ),
                        };
                    },
                },
            ]}
            rows={get(context.data, id, [])}
        />
    );
};

export default GtinTable;
