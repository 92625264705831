import api from "./api";
import types from "./types";

import { request } from "api/apiSaga";
import { snackbarShow } from "app/App/actions";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

function* store(action: AnyAction): any {
    yield call(request, api.store, action, "post");
}

function* update(action: AnyAction): any {
    const response = yield call(request, api.update, action, "put");

    if (response.status === 204) {
        yield put(
            snackbarShow({
                content: "Products imported",
            })
        );
    }
}

export default function* saga() {
    yield takeLatest(types.PRODUCT_IMPORT_REQUEST, store);
    yield takeLatest(types.UPDATE_REQUEST, update);
}
