import { DialogActions, DialogActionsProps } from "@mui/material";

interface IGPModalActions extends DialogActionsProps {}

function GPModalActions(props: IGPModalActions) {
    const { children, ...restProps } = props;

    return <DialogActions {...restProps}>{children}</DialogActions>;
}

GPModalActions.defaultProps = {
    sx: {
        px: 2,
        pb: 2,
    },
};

export default GPModalActions;
