import SettingsSaga from "./saga"

import { routingType } from "types/configTypes";
import { registerRoutings } from "rootRouting";
import store from "rootStore";
import AsyncComponent from "ui/AsyncComponent";
interface IConfig {
    permissions: {
        update: boolean;
    };
    routing: Array<string>;
    url: string;
}

export const permissionKey = "settings";
export const url = "/settings";

const getConfig = (permissions: Array<string>) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: IConfig = {
        permissions: {
            update: false,
        },
        routing: [],
        url,
    };

    let routing: routingType = [];

    if (modulePermissions.indexOf(permissionKey + ".update") !== -1) {
        config.permissions.update = true;
        store.injectSaga?.("SettingsSaga", SettingsSaga);
        
        routing.push({
            path: url,
            element: (
                <AsyncComponent path="Settings/pages/SettingsIndexPage" />
            ),
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
