import { AnyAction } from "redux";
import { ORDER_DELIVERY_SUCCESS } from "app/DeliverySlips/types";
import { IDeliverySlip } from "app/DeliverySlips/interfaces";

interface IState {
    delivery_slips: IDeliverySlip[];
}
function OrdersDeliverySlips(
    state: IState = {
        delivery_slips: [],
    },
    action: AnyAction
) {
    switch (action.type) {
        case ORDER_DELIVERY_SUCCESS:
            return {
                delivery_slips: action.response.data.data,
            };
        default:
            return state;
    }
}

export default OrdersDeliverySlips;
