import SettingsProductImportSaga from "./saga";
import AsyncComponent from "ui/AsyncComponent";
import { registerRoutings } from "rootRouting";
import store from "rootStore";
import { url as settingsUrl } from "app/Settings/config";

interface IConfig {
    permissions: {
        import: boolean;
    };
    routing: Array<string>;
    url: string;
}

export const permissionKey = "products";

export const url = `${settingsUrl}/product-import`;

const getConfig = (permissions: Array<string>) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: IConfig = {
        permissions: {
            import: false,
        },
        routing: [],
        url,
    };

    let routing = [];

    if (modulePermissions.indexOf(permissionKey + ".import") !== -1) {
        config.permissions.import = true;
        store.injectSaga?.("SettingsProductImportSaga", SettingsProductImportSaga);

        routing.push({
            path: url,
            element: (
                <AsyncComponent path="SettingsProductImport/pages/ProductImportIndexPage" />
            ),
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
