import { gastrofixSync } from "../actions";

import { Box, Icon } from "@mui/material";
import useRequest from "api/useRequest";
import { modalHide } from "app/App/actions";
import { AxiosResponse } from "axios";
import trans from "helpers/trans";
import { FC } from "react";
import { useDispatch } from "react-redux";
import {
    LoadingButton,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
} from "ui";

const GastrofixSyncModal: FC = () => {
    const dispatch = useDispatch();
    const { request, isLoading } = useRequest();

    const handleCloseModal = () => {
        dispatch(modalHide("GastrofixSyncModal"));
    };

    const handleSync = () => {
        request(
            gastrofixSync({}, (response: AxiosResponse) => {
                if (response.status === 204) {
                    handleCloseModal();
                }
            })
        );
    };

    return (
        <Modal open fullWidth maxWidth="xs">
            <ModalTitle onClose={handleCloseModal}>
                {trans("modules.gastrofix.modal.gastrofixSyncModal.title")}
            </ModalTitle>
            <ModalContent sx={{ pl: 0 }}></ModalContent>
            <ModalActions>
                <Box display="flex" width="100%" textAlign="right">
                    <LoadingButton
                        loading={isLoading}
                        sx={{ flex: 1 }}
                        loadingPosition="start"
                        fullWidth
                        onClick={handleSync}
                        startIcon={<Icon>download</Icon>}
                    >
                        {trans(
                            "modules.gastrofix.modal.gastrofixSyncModal.sync"
                        )}
                    </LoadingButton>
                </Box>
            </ModalActions>
        </Modal>
    );
};

export default GastrofixSyncModal;
