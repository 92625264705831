import api from "./api";
import types from "./types";
import { request } from "api/apiSaga";
import { AnyAction } from "redux";
import { call, takeLatest } from "redux-saga/effects";

function* activate(action: AnyAction): any {
    yield call(request, api.activate, action);
}

function* destroy(action: AnyAction): any {
    yield call(request, api.destroy, action, "delete");
}

function* fetch(action: AnyAction) {
    yield call(request, api.fetch, action);
}

export default function* saga() {
    yield takeLatest(types.FETCH_REQUEST, fetch);
    yield takeLatest(types.ACTIVATE_REQUEST, activate);
    yield takeLatest(types.DESTROY_REQUEST, destroy);
}
