import { modalHide } from "app/App/actions";
import trans from "helpers/trans";
import { FC, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import {
    Button,
    Form,
    Loader,
    LoadingButton,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
    Settings,
    SettingsItem,
    TextField,
} from "ui";
import { IInventoryDetailsModal } from "../interfaces";
import { Alert, Box, Stack } from "@mui/material";
import { IGPFormRef } from "ui/Form/Form";
import { deliverySlipsStatsFetch } from "app/DeliverySlips/actions";
import useRequest from "api/useRequest";

const InventoryDetailsModal: FC<IInventoryDetailsModal> = ({
    errors,
    loading,
    onSubmit,
}) => {
    const formRef = useRef<IGPFormRef | null>();
    const dispatch = useDispatch();
    const { currentData, isPending } = useRequest(
        deliverySlipsStatsFetch({
            params: { _columns: ["open_since_last_inventory"] },
        })
    );
    const [isAlert, setIsAlert] = useState<boolean>(false);

    useEffect(() => {
        if (currentData)
            setIsAlert(currentData.open_since_last_inventory > 0);
    }, [currentData]);

    const handleCloseModal = () => {
        dispatch(modalHide("InventoryDetailsModal"));
    };

    const handleAddName = () => {
        if (formRef.current !== null) {
            const data: any = formRef?.current?.getData();
            onSubmit(data);
        }
    };
    if (isPending) return <Loader />;

    return (
        <Modal maxWidth="md" fullWidth open>
            <ModalTitle onClose={handleCloseModal}>
                {trans("inv.inventory.modal.inventoryDetailsModal.title")}
            </ModalTitle>
            <ModalContent>
                <>
                    {isAlert && (
                        <Alert
                            severity="warning"
                            sx={{
                                mb: 1,
                            }}
                            action={
                                <Stack spacing={1} direction="row">
                                    <Button
                                        variant="contained"
                                        color="warning"
                                        size="small"
                                        sx={{ fontWeight: 500 }}
                                        onClick={() => handleCloseModal()}
                                    >
                                        {trans(
                                            "inv.inventory.modal.inventoryDetailsModal.no"
                                        )}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="success"
                                        size="small"
                                        onClick={() => setIsAlert(false)}
                                    >
                                        {trans(
                                            "inv.inventory.modal.inventoryDetailsModal.yes"
                                        )}
                                    </Button>
                                </Stack>
                            }
                        >
                            {trans(
                                "inv.inventory.modal.inventoryDetailsModal.alert",
                                {
                                    deliverySlip: trans(
                                        currentData?.open_since_last_inventory >
                                            1
                                            ? "inv.inventory.modal.inventoryDetailsModal.deliverySlips"
                                            : "inv.inventory.modal.inventoryDetailsModal.deliverySlip"
                                    ),
                                    stats: currentData?.open_since_last_inventory,
                                }
                            )}
                        </Alert>
                    )}

                    {!isAlert && (
                        <Form
                            ref={formRef}
                            data={{}}
                            errors={errors}
                            fields={{
                                name: {},
                            }}
                            loading={loading}
                            onSubmit={handleAddName}
                            unsaved={false}
                        >
                            <Settings>
                                <SettingsItem
                                    label={trans(
                                        "inv.inventory.modal.inventoryDetailsModal.name"
                                    )}
                                >
                                    <TextField id="name" fullWidth autoFocus />
                                </SettingsItem>
                            </Settings>
                        </Form>
                    )}
                </>
            </ModalContent>
            <ModalActions>
                <Box display="flex" width="100%" textAlign="right">
                    <Button
                        onClick={handleCloseModal}
                        color="inherit"
                        variant="text"
                        sx={{ mr: 1 }}
                    >
                        {trans("global.button.cancel")}
                    </Button>
                    <LoadingButton
                        sx={{ flex: 1 }}
                        loading={loading}
                        onClick={handleAddName}
                        disabled={isAlert}
                    >
                        {trans(
                            "inv.inventory.modal.inventoryDetailsModal.save"
                        )}
                    </LoadingButton>
                </Box>
            </ModalActions>
        </Modal>
    );
};

export default InventoryDetailsModal;
