import types, { InventoryModel } from "./types";
import { request } from "api/actions";
import { AxiosRequestConfig } from "axios";

export const inventoryCountReload = () => ({
    type: types.COUNT_RELOAD,
});

export const inventoryCountStart = (inventoryId: number, areaId: number) => ({
    type: types.COUNT_START,
    inventoryId,
    areaId,
});

export const inventoryCountStop = () => ({
    type: types.COUNT_STOP,
});

export const inventoryCountProduct = (
    productId?: number,
    gtin?: string,
    addQuantity: boolean = false,
) => ({
    type: types.COUNT_PRODUCT,
    addQuantity,
    productId,
    gtin,
});

export const inventoryCountProductChangeVintage = (
    onClose: Function,
    product: any,
    inventoryProductId?: number,
    isUpdate: boolean = false
) => ({
    type: types.COUNT_PRODUCT_CHANGE_VINTAGE,
    onClose,
    product,
    inventoryProductId,
    isUpdate,
});

export const inventoryCreate = (data: InventoryModel, onResponse?: Function) =>
    request(
        types.STORE_REQUEST,
        types.STORE_FAILED,
        types.STORE_SUCCESS,
        "inventoryCreate",
        { data },
        onResponse
    );

export const inventoryDelete = (id: number, onResponse?: Function) =>
    request(
        types.DESTROY_REQUEST,
        types.DESTROY_FAILED,
        types.DESTROY_SUCCESS,
        "inventoryDelete",
        {
            url: {
                id,
            },
        },
        onResponse
    );

export const inventoryFetch = (config?: AxiosRequestConfig) =>
    request(
        types.FETCH_REQUEST,
        types.FETCH_FAILED,
        types.FETCH_SUCCESS,
        "inventoryFetch",
        {
            config,
        }
    );

export const inventoryFinalize = (id: number, onResponse?: Function) =>
    request(
        types.FINALIZE_REQUEST,
        types.FINALIZE_FAILED,
        types.FINALIZE_SUCCESS,
        "inventoryFinalize",
        {
            url: {
                id,
            },
        },
        onResponse
    );

export const inventoryGet = (id: number, config?: object) =>
    request(
        types.GET_REQUEST,
        types.GET_FAILED,
        types.GET_SUCCESS,
        "inventoryGet",
        {
            config,
            url: {
                id,
            },
        }
    );

export const inventoryGetOpen = () =>
    request(
        types.GET_OPEN_REQUEST,
        types.GET_OPEN_FAILED,
        types.GET_OPEN_SUCCESS,
        "inventoryOpen"
    );

export const inventoryProductsCreate = (
    inventoryId: number,
    data: InventoryModel,
    onResponse?: Function
) =>
    request(
        types.PRODUCT_STORE_REQUEST,
        types.PRODUCT_STORE_FAILED,
        types.PRODUCT_STORE_SUCCESS,
        "inventoryProductsCreate",
        {
            data,
            url: {
                inventoryId,
            },
        },
        onResponse
    );

export const inventoryProductChange = (
    inventoryId: number,
    inventoryProductId: number,
    productId: number,
    onResponse?: Function
) =>
    request(
        types.PRODUCT_CHANGE_REQUEST,
        types.PRODUCT_CHANGE_FAILED,
        types.PRODUCT_CHANGE_SUCCESS,
        "inventoryProductChange",
        {
            url: {
                inventoryId,
                inventoryProductId,
                productId,
            },
        },
        onResponse
    );

export const inventoryProductsDelete = (
    inventoryId: number,
    inventoryProductId: number,
    onResponse?: Function
) =>
    request(
        types.PRODUCT_DESTROY_REQUEST,
        types.PRODUCT_DESTROY_FAILED,
        types.PRODUCT_DESTROY_SUCCESS,
        "inventoryProductsDelete",
        {
            url: {
                inventoryId,
                inventoryProductId,
            },
        },
        onResponse
    );

export const inventoryProductsUpdate = (
    inventoryId: number,
    inventoryProductId: number,
    data: InventoryModel,
    onResponse?: Function
) => {
    return request(
        types.PRODUCT_UPDATE_REQUEST,
        types.PRODUCT_UPDATE_FAILED,
        types.PRODUCT_UPDATE_SUCCESS,
        "inventoryProductsUpdate",
        {
            data,
            url: {
                inventoryId,
                inventoryProductId,
            },
        },
        onResponse
    );
};

export const inventoryTableUpdate = (action: string, data: any) => ({
    type: types.TABLE_UPDATE,
    action,
    data,
});

export const inventorySearch = (searchValue?: string) => ({
    type: types.SEARCH_PRODUCTS,
    searchValue,
});

export const inventoryUpdate = (
    id: number,
    data: InventoryModel,
    onResponse?: Function
) => {
    return request(
        types.UPDATE_REQUEST,
        types.UPDATE_FAILED,
        types.UPDATE_SUCCESS,
        "inventoryUpdate",
        { data, url: { id } },
        onResponse
    );
};

export const inventoryAreaListFetch = (
    id: number,
    config?: AxiosRequestConfig
) =>
    request(
        types.FETCH_AREAS_REQUEST,
        types.FETCH_AREAS_FAILED,
        types.FETCH_AREAS_SUCCESS,
        "inventoryAreaListFetch",
        {
            config,
            url: {
                id,
            },
        }
    );

export const suggestedProductsFetch = (config?: AxiosRequestConfig) =>
    request(
        types.FETCH_SUGGESTED_PRODUCTS_REQUEST,
        types.FETCH_SUGGESTED_PRODUCTS_FAILED,
        types.FETCH_SUGGESTED_PRODUCTS_SUCCESS,
        "inventoryFetchSuggestedProducts",
        {
            config,
        }
    );

    export const lastInventoryGet = (
        config?: AxiosRequestConfig
    ) =>
        request(
            types.GET_LAST_INVENTORY_REQUEST,
            types.GET_LAST_INVENTORY_FAILED,
            types.GET_LAST_INVENTORY_SUCCESS,
            "lastInventoryGet",
            {
                config,
            }
        );