import { IProductSlider } from "../../interfaces";
import { Slider, Stack } from "@mui/material";
import trans from "helpers/trans";
import ChampagneBottle from "./ChampagneBottle";
import Rectangle from "./Rectangle";
import WineBottle from "./WineBottle";
import { FC, useMemo, useState } from "react";

function valuetext(value: number) {
    return `${value} cl`;
}
const ProductSlider: FC<IProductSlider> = ({
    value,
    shape,
    onSlider,
    unit,
    maxValue,
}) => {
    const [sliderValue, setSliderValue] = useState<number>(maxValue * value);

    const labelStep = useMemo(() => {
        let numberOfSteps = 4;

        if (shape === "wine") {
            numberOfSteps = maxValue / 15;
        } else if (shape === "champagne") {
            numberOfSteps = maxValue / 15;
        }

        if (numberOfSteps > 10) {
            numberOfSteps = 10;
        }

        const steps = maxValue / numberOfSteps;

        return steps !== 0 ? steps : 1;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const marks = useMemo(() => {
        let labels = [];
        for (let i = 0; i <= maxValue; i += labelStep) {
            let iFixed = i < 1 ? parseFloat(i.toFixed(2)) : Math.round(i);

            labels.push({
                value: iFixed,
                label: `${iFixed} ${unit}`,
            });
        }
        return labels;
    }, [maxValue, labelStep, unit]);

    const handleOnChange = (event: Event, newValue: number | number[]) => {
        if (typeof newValue === "number") {
            if (sliderValue !== newValue) {
                let newSliderValue = newValue / maxValue;
                onSlider(newSliderValue);
                setSliderValue(newValue);
            }
        }
    };

    const renderProduct = () => {
        if (shape === "champagne") {
            return <ChampagneBottle offset={(sliderValue / maxValue) * 80} />;
        } else if (shape === "wine") {
            return <WineBottle offset={(sliderValue / maxValue) * 80} />;
        } else if (shape === "rectangle") {
            return <Rectangle offset={(sliderValue / maxValue) * 100} />;
        } else return null;
    };

    return (
        <Stack
            mb={4}
            mt={4}
            sx={{
                height: 300,
                justifyContent: "center",
                alignItems: "center",
                gap: "4rem",
                position: "relative",
                width: "100%",
            }}
            spacing={1}
            direction="row"
        >
            {renderProduct()}
            <Slider
                value={parseFloat(sliderValue.toFixed(2))}
                onChange={handleOnChange}
                aria-label={trans(
                    "inv.inventory.component.productSlider.ariaLabel"
                )}
                orientation="vertical"
                getAriaValueText={valuetext}
                valueLabelDisplay="on"
                marks={marks}
                defaultValue={0}
                min={0}
                step={maxValue > 1 ? 1 : labelStep / 2}
                max={maxValue}
                sx={{
                    position: "absolute",
                    right: 65,
                    bottom: 0,
                    height: shape === "rectangle" ? 300 : 240,
                }}
            />
        </Stack>
    );
};

export default ProductSlider;
