import { Box, Chip, ListItem, ListItemText } from "@mui/material";
import { RestaurantProductModel } from "app/RestaurantProducts/interfaces";
import { isWine } from "helpers/isWine";
import trans from "helpers/trans";
import { formatUnit } from "helpers/units";
import NoImageIcon from "icons/NoImageIcon";
// import moment from "moment";
import { FC } from "react";

interface ISearchProductListItem {
    restaurantProduct: RestaurantProductModel;
    onClick: Function;
}

const SearchProductItem: FC<ISearchProductListItem> = ({
    restaurantProduct,
    onClick,
}) => {
    const product = restaurantProduct.product;

    const renderImage = () => {
        let image = <NoImageIcon sx={{ color: "grey.300", fontSize: 35 }} />;

        if (product.images.length > 0) {
            image = (
                <Box
                    component="img"
                    src={product.images[0].url}
                    sx={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                    }}
                />
            );
        }

        return (
            <Box
                sx={{
                    alignItems: "center",
                    display: "flex",
                    flexShrink: 0,
                    height: 50,
                    justifyContent: "center",
                    minWidth: 50,
                    mr: 1,
                    width: 50,
                }}
            >
                {image}
            </Box>
        );
    };

    const renderVintage = () => {
        if (isWine(product.category) && product.vintage)
            return <Chip color="secondary" label={product.vintage} />;
    };

    const renderProductInfo = () => {
        const productName = () => {
            if (String(product.unit).toUpperCase() !== "NA" && product.unit) {
                return `${restaurantProduct.name} (${formatUnit(
                    product.size,
                    product.unit,
                    product.show_unit
                )})`;
            }
            return restaurantProduct.name;
        };

        const productOriginalName = () => {
            if (
                restaurantProduct?.name &&
                restaurantProduct?.name === product.name
            ) {
                return null;
            }

            if (restaurantProduct?.name) return product.name;
        };

        const productSupplier = () => {
            const suppliers = restaurantProduct?.location_suppliers?.map(
                (item) => item?.contact_name
            );

            return suppliers?.length > 0
                ? trans(
                      "inv.inventory.component.searchProductListItem.supplier",
                      { supplier: suppliers?.join(", ") }
                  )
                : null;
        };

        const productProducer = () => {
            if (product.producer) {
                return trans(
                    "inv.inventory.component.searchProductListItem.producer",
                    { producer: product.producer }
                );
            }
        };

        const productEdpAndArticleNumber = () => {
            const articleNumbers = restaurantProduct?.delivery_slip_line_numbers
                ? restaurantProduct.delivery_slip_line_numbers.join(", ")
                : null;

            if (product.epd && articleNumbers && articleNumbers?.length > 0) {
                return trans(
                    "inv.inventory.component.searchProductListItem.epdArticleNumber",
                    { epd: product.epd, articleNumber: articleNumbers }
                );
            }

            if (product.epd) {
                return trans(
                    "inv.inventory.component.searchProductListItem.epd",
                    { epd: product.epd }
                );
            }

            if (articleNumbers) {
                return trans(
                    "inv.inventory.component.searchProductListItem.articleNumber",
                    { articleNumber: articleNumbers }
                );
            }
        };

        const productPrice = (): string | null => {
            // if (priceHistoryData.data?.length > 0) {
            //     const lastPrice =
            //         priceHistoryData?.data[priceHistoryData.data?.length - 1];
            //     return trans(
            //         "inv.inventory.component.searchProductListItem.lastPrice",
            //         {
            //             lastPrice: lastPrice?.price.price(),
            //             date: moment(lastPrice?.date).format("DD/MM/YYYY"),
            //         }
            //     );
            // }
            if (restaurantProduct.price) {
                return trans(
                    "inv.inventory.component.searchProductListItem.lastPrice",
                    {
                        lastPrice: restaurantProduct.price.price(),
                    }
                );
            }
            return null;
        };

        return (
            <Box
                sx={{
                    flexGrow: 1,
                }}
            >
                <ListItemText primary={productName()} />
                <ListItemText
                    secondary={productOriginalName()}
                    secondaryTypographyProps={{
                        fontSize: 12,
                    }}
                />
                <ListItemText
                    secondary={productSupplier()}
                    secondaryTypographyProps={{
                        fontSize: 12,
                    }}
                />
                <ListItemText
                    secondary={productEdpAndArticleNumber()}
                    secondaryTypographyProps={{
                        fontSize: 12,
                    }}
                />

                <ListItemText
                    secondary={productPrice()}
                    secondaryTypographyProps={{
                        fontSize: 12,
                    }}
                />

                <ListItemText
                    secondary={productProducer()}
                    secondaryTypographyProps={{ fontSize: 12 }}
                />
            </Box>
        );
    };

    return (
        <ListItem
            button
            sx={{
                borderBottomColor: "grey.300",
                borderBottomStyle: "solid",
                borderBottomWidth: 1,
                "&:last-child": {
                    border: "none",
                },
            }}
            onClick={() => onClick(restaurantProduct)}
        >
            {renderImage()}
            {renderProductInfo()}
            {renderVintage()}
        </ListItem>
    );
};

export default SearchProductItem;
