export const BULK_CATEGORY_FAILED = "RESTAURANT_PRODUCTS/BULK_CATEGORY_FAILED";
export const BULK_CATEGORY_REQUEST =
    "RESTAURANT_PRODUCTS/BULK_CATEGORY_REQUEST";
export const BULK_CATEGORY_SUCCESS =
    "RESTAURANT_PRODUCTS/BULK_CATEGORY_SUCCESS";

export const BULK_SHOW_UNIT_FAILED =
    "RESTAURANT_PRODUCTS/BULK_SHOW_UNIT_FAILED";
export const BULK_SHOW_UNIT_REQUEST =
    "RESTAURANT_PRODUCTS/BULK_SHOW_UNIT_REQUEST";
export const BULK_SHOW_UNIT_SUCCESS =
    "RESTAURANT_PRODUCTS/BULK_SHOW_UNIT_SUCCESS";

export const BULK_SIZE_SHOW_UNIT_FAILED =
    "RESTAURANT_PRODUCTS/BULK_SIZE_SHOW_UNIT_FAILED";
export const BULK_SIZE_SHOW_UNIT_REQUEST =
    "RESTAURANT_PRODUCTS/BULK_SIZE_SHOW_UNIT_REQUEST";
export const BULK_SIZE_SHOW_UNIT_SUCCESS =
    "RESTAURANT_PRODUCTS/BULK_SIZE_SHOW_UNIT_SUCCESS";

export const DESTROY_FAILED = "RESTAURANT_PRODUCTS/DESTROY_FAILED";
export const DESTROY_REQUEST = "RESTAURANT_PRODUCTS/DESTROY_REQUEST";
export const DESTROY_SUCCESS = "RESTAURANT_PRODUCTS/DESTROY_SUCCESS";

export const DESTROY_PORTIONS_FAILED =
    "RESTAURANT_PRODUCTS/DESTROY_PORTIONS_FAILED";
export const DESTROY_PORTIONS_SUCCESS =
    "RESTAURANT_PRODUCTS/DESTROY_PORTIONS_REQUEST";
export const DESTROY_PORTIONS_REQUEST =
    "RESTAURANT_PRODUCTS/DESTROY_PORTIONS_SUCCESS";

export const FETCH_FAILED = "RESTAURANT_PRODUCTS/FETCH_FAILED";
export const FETCH_REQUEST = "RESTAURANT_PRODUCTS/FETCH_REQUEST";
export const FETCH_SUCCESS = "RESTAURANT_PRODUCTS/FETCH_SUCCESS";

export const GET_FAILED = "RESTAURANT_PRODUCTS/GET_FAILED";
export const GET_REQUEST = "RESTAURANT_PRODUCTS/GET_REQUEST";
export const GET_SUCCESS = "RESTAURANT_PRODUCTS/GET_SUCCESS";

export const GET_PRICE_HISTORY_REQUEST =
    "RESTAURANT_PRODUCTS_PRICE_HISTORY/GET_REQUEST";
export const GET_PRICE_HISTORY_FAILED =
    "RESTAURANT_PRODUCTS_PRICE_HISTORY/GET_FAILED";
export const GET_PRICE_HISTORY_SUCCESS =
    "RESTAURANT_PRODUCTS_PRICE_HISTORY/GET_SUCCESS";

export const PRODUCT_REQUEST = "RESTAURANT_PRODUCTS/PRODUCT_REQUEST";
export const PRODUCT_FAILED = "RESTAURANT_PRODUCTS/PRODUCT_FAILED";
export const PRODUCT_SUCCESS = "RESTAURANT_PRODUCTS/PRODUCT_SUCCESS";

export const STORE_FAILED = "RESTAURANT_PRODUCTS/STORE_FAILED";
export const STORE_REQUEST = "RESTAURANT_PRODUCTS/STORE_REQUEST";
export const STORE_SUCCESS = "RESTAURANT_PRODUCTS/STORE_SUCCESS";

export const STORE_PORTIONS_FAILED =
    "RESTAURANT_PRODUCTS/STORE_PORTIONS_FAILED";
export const STORE_PORTIONS_REQUEST =
    "RESTAURANT_PRODUCTS/STORE_PORTIONS_REQUEST";
export const STORE_PORTIONS_SUCCESS =
    "RESTAURANT_PRODUCTS/STORE_PORTIONS_SUCCESS";

export const UPDATE_FAILED = "RESTAURANT_PRODUCTS/UPDATE_FAILED";
export const UPDATE_REQUEST = "RESTAURANT_PRODUCTS/UPDATE_REQUEST";
export const UPDATE_SUCCESS = "RESTAURANT_PRODUCTS/UPDATE_SUCCESS";

export const COMBINE_PRODUCTS_REQUEST =
    "RESTAURANT_PRODUCTS/COMBINE_PRODUCTS_REQUEST";
export const COMBINE_PRODUCTS_FAILED =
    "RESTAURANT_PRODUCTS/COMBINE_PRODUCTS_FAILED";
export const COMBINE_PRODUCTS_SUCCESS =
    "RESTAURANT_PRODUCTS/COMBINE_PRODUCTS_SUCCESS";

export const HIDE_PRODUCT_REQUEST = "RESTAURANT_PRODUCTS/HIDE_REQUEST";
export const HIDE_PRODUCT_SUCCESS = "RESTAURANT_PRODUCTS/HIDE_SUCCESS";
export const HIDE_PRODUCT_FAILED = "RESTAURANT_PRODUCTS/HIDE_FAILED";

export const BULK_HIDE_PRODUCTS_REQUEST =
    "RESTAURANT_PRODUCTS/BULK_HIDE_REQUEST";
export const BULK_HIDE_PRODUCTS_SUCCESS =
    "RESTAURANT_PRODUCTS/BULK_HIDE_SUCCESS";
export const BULK_HIDE_PRODUCTS_FAILED = "RESTAURANT_PRODUCTS/BULK_HIDE_FAILED";

export const TABLE_UPDATE = "RESTAURANT_PRODUCTS/TABLE_UPDATE";

export const PORTION_HAS_BEEN_SELECTED =
    "RESTAURANT_PRODUCTS_COMBINE/PORTION_IS_SELECTED";

export const REVIEW_REQUEST = "RESTAURANT_PRODUCTS/REVIEW_REQUEST";
export const REVIEW_FAILED = "RESTAURANT_PRODUCTS/REVIEW_FAILED";
export const REVIEW_SUCCESS = "RESTAURANT_PRODUCTS/REVIEW_SUCCESS";

const types = {
    BULK_CATEGORY_FAILED,
    BULK_CATEGORY_REQUEST,
    BULK_CATEGORY_SUCCESS,

    BULK_SHOW_UNIT_FAILED,
    BULK_SHOW_UNIT_REQUEST,
    BULK_SHOW_UNIT_SUCCESS,

    BULK_SIZE_SHOW_UNIT_FAILED,
    BULK_SIZE_SHOW_UNIT_REQUEST,
    BULK_SIZE_SHOW_UNIT_SUCCESS,

    DESTROY_FAILED,
    DESTROY_REQUEST,
    DESTROY_SUCCESS,

    DESTROY_PORTIONS_REQUEST,
    DESTROY_PORTIONS_FAILED,
    DESTROY_PORTIONS_SUCCESS,

    FETCH_FAILED,
    FETCH_REQUEST,
    FETCH_SUCCESS,

    GET_FAILED,
    GET_REQUEST,
    GET_SUCCESS,

    GET_PRICE_HISTORY_REQUEST,
    GET_PRICE_HISTORY_FAILED,
    GET_PRICE_HISTORY_SUCCESS,

    PRODUCT_REQUEST,
    PRODUCT_FAILED,
    PRODUCT_SUCCESS,

    STORE_FAILED,
    STORE_REQUEST,
    STORE_SUCCESS,

    HIDE_PRODUCT_FAILED,
    HIDE_PRODUCT_REQUEST,
    HIDE_PRODUCT_SUCCESS,

    BULK_HIDE_PRODUCTS_REQUEST,
    BULK_HIDE_PRODUCTS_SUCCESS,
    BULK_HIDE_PRODUCTS_FAILED,

    STORE_PORTIONS_FAILED,
    STORE_PORTIONS_REQUEST,
    STORE_PORTIONS_SUCCESS,

    UPDATE_FAILED,
    UPDATE_REQUEST,
    UPDATE_SUCCESS,

    COMBINE_PRODUCTS_REQUEST,
    COMBINE_PRODUCTS_FAILED,
    COMBINE_PRODUCTS_SUCCESS,

    REVIEW_REQUEST,
    REVIEW_FAILED,
    REVIEW_SUCCESS,

    PORTION_HAS_BEEN_SELECTED,

    TABLE_UPDATE,
};

export default types;
