import { request } from "api/apiSaga";
import { snackbarShow } from "app/App/actions";
import { AxiosResponse } from "axios";
import trans from "helpers/trans";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import api from "./api";
import types from "./types";

function* fetch(action: AnyAction) {
    yield call(request, api.fetch, action);
}

function* store(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.store,
        action,
        "post"
    );

    if (response.status < 300) {
        yield put(
            snackbarShow({
                content: trans("inv.menusLists.saga.content.create"),
            })
        );
    }
}

function* get(action: AnyAction) {
    yield call(request, api.get, action);
}

function* update(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.update,
        action,
        "put"
    );

    if (response.status < 300) {
        yield put(
            snackbarShow({
                content: trans("inv.menusLists.saga.content.update"),
            })
        );
    }
}

function* destroy(action: AnyAction) {
    const response: AxiosResponse = yield call(
        request,
        api.destroy,
        action,
        "delete"
    );

    if (response.status < 300) {
        yield put(
            snackbarShow({
                content: trans("inv.menusLists.saga.content.delete"),
            })
        );
    }
}

export default function* saga() {
    yield takeLatest(types.FETCH_REQUEST, fetch);
    yield takeLatest(types.CREATE_REQUEST, store);
    yield takeLatest(types.GET_REQUEST, get);
    yield takeLatest(types.UPDATE_REQUEST, update);
    yield takeLatest(types.DELETE_REQUEST, destroy);
}
