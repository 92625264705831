import InvoicesTable from "./reducers/InvoicesTableReducer";
import InvoiceDetalisTable from "./reducers/InvoiceDetailsTableReducer";
import InvoicesSaga from "./saga";
import AsyncComponent from "ui/AsyncComponent";
import { registerRoutings } from "rootRouting";
import store from "rootStore";
import { registerModals } from "modals";
import { permissionsType } from "types/configTypes";
import InvoicesModal from "./modals/InvoicesModal";

const permissionKey = "delivery.invoices";
export const url = "/invoices";

interface IConfig {
    permissions: {
        read: boolean;
    };
    routing: string[];
    url: string;
}

type TRouting = { path: string; element: JSX.Element };

const getConfig = (permissions: permissionsType) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const config: IConfig = {
        permissions: {
            read: false,
        },
        routing: [],
        url,
    };

    let routing: TRouting[] = [];

    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;

        store.reducerManager?.add("InvoicesTable", InvoicesTable);
        store.reducerManager?.add("InvoiceDetailsTable", InvoiceDetalisTable);
        store.injectSaga?.("InvoicesSaga", InvoicesSaga);

        registerModals({
            InvoicesModal,
        });

        routing.push({
            path: url,
            element: <AsyncComponent path="Invoices/pages/InvoicesIndexPage" />,
        });
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
