export const getHelperText = (
    id: number | string,
    context: any,
    errorText: any,
    helperText: any
) => {
    let inputHelperText = errorText;
    if (!!context) {
        const contextErrorText = context.getFieldError(id);
        if (contextErrorText.length > 0) {
            inputHelperText = contextErrorText;
        }
    }

    const isError = inputHelperText.length > 0;

    if (!isError && helperText.length > 0) {
        inputHelperText = helperText;
    }

    return {
        inputHelperText,
        isError,
    };
};

export const getValue = (id: number | string, context: any, value: any) => {
    let fieldValue = value;
    if (value === undefined && !!context) {
        fieldValue = context.getParam(id);
    }

    return fieldValue;
};

export const regexValue = (
    id: string | number,
    context: any,
    regex: any,
    value: any
) => {
    let fieldRegex = regex;
    if (regex === undefined && !!context) {
        fieldRegex = context.getFieldSettings(id).regex;
    }

    if (fieldRegex !== undefined) {
        return value.replace(fieldRegex, "");
    }
    return value;
};
