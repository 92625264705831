import web from "./api";
import appApi from "../App/api";
import types, { SETTINGS_FAILED, SETTINGS_SUCCESS } from "./types";
import { request } from "api/apiSaga";
import { call, put, race, take, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";
import { webMenuSettings, webMenuTableUpdate } from "./actions";
import { loadWebModules } from "moduleSaga";
import { isEmpty, omit } from "lodash";
import {
    APP_INIT,
    APP_LOADED,
    APP_TRANSLATE_REQUEST,
    APP_TRANSLATE_SUCCESS,
    LOCALE_REQUEST,
} from "types";
import { appTranslate, changeLocale } from "app/App/actions";
import queryString from "query-string";
import { addPrefixFilters } from "helpers/urlParams";
import rootStore from "rootStore";
import { tableUpdate } from "helpers/reducers";

interface IResponse {
    response: {
        data: any;
    };
}

interface IRaceEffect {
    failed?: any;
    success?: any;
}

function* initApp() {
    const uuid = window.location.pathname.match(/\/menu\/([A-Za-z0-9-]+)/)?.[1];
    const searchParams = window.location.search;
    let paramsObject: any = queryString.parse(searchParams, {
        arrayFormat: "bracket",
        parseNumbers: true,
    });

    if (paramsObject?.price_range) {
        const newPrice = (paramsObject.price_range as any).map((price: any) =>
            queryString.parse(price, {
                arrayFormat: "bracket",
                parseNumbers: true,
            })
        );
        paramsObject = {
            ...paramsObject,
            price_range: newPrice,
        };
    }
    if (paramsObject?.volume) {
        const newVolume = (paramsObject.volume as any).map((volume: any) => {
            return volume.split(",").map((vol: string) => vol)
        });
        paramsObject = {
            ...paramsObject,
            volume: newVolume,
        };
    }
    if (paramsObject?.sort) {
        const newSort = queryString.parse(paramsObject.sort as any, {
            arrayFormat: "bracket",
            parseNumbers: true,
        });
        paramsObject = {
            ...paramsObject,
            sort: newSort,
        };
    }

    const { search, sort, page, ...restParams } = paramsObject;

    let newParamsObject = {
        _filters: addPrefixFilters(restParams, true),
        ...((sort || sort === "") && { sort: sort || {} }),
        ...(search && { search }),
        ...(page && { page }),
    };

    if (uuid) {
        yield call(loadWebModules);
        yield put(appTranslate());
        const translate: IResponse = yield take(APP_TRANSLATE_SUCCESS);
        if (translate) {
            if (!isEmpty(newParamsObject._filters)) {
                if (!newParamsObject._filters?.menu_list_country_filter) {
                    newParamsObject._filters = omit(newParamsObject._filters, [
                        "menu_list_region_filter",
                        "menu_list_sub_region_filter",
                    ]);
                } else if (!paramsObject.menu_list_region_filter) {
                    newParamsObject._filters = omit(newParamsObject._filters, [
                        "menu_list_sub_region_filter",
                    ]);
                }
            }
            let params: any = {};
            if (!isEmpty(newParamsObject._filters)) {
                params._filters = newParamsObject._filters;
            }
            if (newParamsObject.search) {
                params._search = newParamsObject.search;
            }

            yield put(
                webMenuSettings(uuid, {
                    params,
                })
            );

            const raceResult: IRaceEffect = yield race({
                failed: take(SETTINGS_FAILED),
                success: take(SETTINGS_SUCCESS),
            });

            if (raceResult.success) {
                const filtersData =
                    raceResult.success.response.data?.filters_data?.map?.(
                        (item: any) => item.id
                    );
                if (filtersData) {
                    newParamsObject._filters = Object.keys(
                        newParamsObject._filters
                    ).reduce((newParamsFiltered: any, key) => {
                        if (filtersData.includes(key)) {
                            newParamsFiltered[key] =
                                newParamsObject._filters[key];
                        }
                        return newParamsFiltered;
                    }, {});
                }
                if (!isEmpty(newParamsObject._filters)) {
                    yield put(
                        webMenuTableUpdate("filter", newParamsObject._filters)
                    );
                }
                if (newParamsObject.search) {
                    yield put(
                        webMenuTableUpdate("search", newParamsObject.search)
                    );
                }
                if (!newParamsObject.search) {
                    yield put(
                        webMenuTableUpdate(
                            "sort",
                            newParamsObject.sort
                                ? newParamsObject.sort
                                : { "restaurant_product.stock": "desc" }
                        )
                    );
                } else if (newParamsObject.search) {
                    yield put(
                        webMenuTableUpdate(
                            "sort",
                            newParamsObject.sort ? newParamsObject.sort : {}
                        )
                    );
                }

                if (newParamsObject.page) {
                    yield put(webMenuTableUpdate("page", newParamsObject.page));
                }
                yield put({
                    type: APP_LOADED,
                    app: "WebMenu",
                    uuid,
                });
            } else {
                yield put({
                    type: APP_LOADED,
                    app: "WebMenu",
                    uuid: null,
                    status: raceResult.failed.response.data.message,
                });
            }
            return;
        }
    }
}
function* fetch(action: AnyAction) {
    yield call(request, web.fetch, action);
}

function* get(action: AnyAction) {
    yield call(request, web.get, action);
}

function* getSimilar(action: AnyAction) {
    yield call(request, web.getSimilar, action);
}

function* recommended(action: AnyAction) {
    yield call(request, web.recommended, action);
}

function* settings(action: AnyAction) {
    yield call(request, web.settings, action);
}

function* translate(action: AnyAction): any {
    const response = yield call(request, appApi.translate, action);
    if (response.status === 200) {
        (window as any).translate = response.data.data;
        document.documentElement.lang = response.data.locale;
    }
}

function* locale(action: AnyAction): any {
    yield call(translate, changeLocale(action.axiosConfig.config));
    yield call(updateSettings, action);
}

function* updateSettings(action: AnyAction): any {
    const state = rootStore.getState().web;
    const { loaded } = rootStore.getState().app;
    if (!loaded) return;
    const { filters, search } = tableUpdate(state, action);

    const params: any = {};
    if (!isEmpty(filters)) {
        params._filters = filters;
    }
    if (search) {
        params._search = search;
    }
    if (
        action.type === "LOCALE_REQUEST" ||
        action.action === "filter" ||
        action.action === "clearFilters" ||
        action.action === "search"
    ) {
        yield put(
            webMenuSettings(state.uuid, {
                params,
            })
        );
    }
}
export const webMenuSagas = [
    takeLatest(APP_INIT, initApp),
    takeLatest(types.TABLE_UPDATE, updateSettings),
    takeLatest(types.FETCH_REQUEST, fetch),
    takeLatest(types.GET_REQUEST, get),
    takeLatest(types.GET_SIMILAR_REQUEST, getSimilar),
    takeLatest(LOCALE_REQUEST, locale),
    takeLatest(types.RECOMMENDED_REQUEST, recommended),
    takeLatest(types.SETTINGS_REQUEST, settings),
    takeLatest(APP_TRANSLATE_REQUEST, translate),
];
