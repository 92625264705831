import { FC } from "react";
import {
    Button,
    Loader,
    Modal,
    ModalActions,
    ModalContent,
    ModalTitle,
} from "ui";
import { invoicesGet } from "app/Invoices/actions";
import useRequest from "api/useRequest";
import trans from "helpers/trans";
import { Table, RequestMessage } from "ui";
import { useDispatch, useSelector } from "react-redux";
import { invoicesTableUpdate } from "app/Invoices/actions";
import { modalHide } from "../../App/actions";
import useTableProps from "hooks/useTableProps";
import { IInvoicesModal } from "../interfaces";

const InvoicesModal: FC<IInvoicesModal> = ({ id }) => {
    const dispatch = useDispatch();
    const table = useSelector((state: any) => {
        return state.InvoiceDetailsTable;
    });
    const tableParams = useTableProps(table);

    const { currentData, isError, isLoading, isPending, status } = useRequest(
        invoicesGet(id, { params: tableParams })
    );

    const handleCloseMdoal = () => dispatch(modalHide("InvoicesModal"));

    const tableUpdate = (action: string, value: any) => {
        dispatch(invoicesTableUpdate(action, value));
    };

    if (isPending) {
        return <Loader />;
    }

    if (isError) return <RequestMessage status={status} />;

    return (
        <Modal maxWidth="md" fullWidth open>
            <ModalTitle onClose={handleCloseMdoal}>
                {trans("inv.invoices.modal.invoicesModal.buttonClose", {
                    invoiceNumber: currentData.data.invoice_number,
                })}
            </ModalTitle>
            <ModalContent>
                <Table
                    {...table}
                    loading={isLoading}
                    columns={table.columns}
                    rows={!!currentData ? currentData.data.invoice_lines : []}
                    tableUpdate={tableUpdate}
                />
            </ModalContent>
            <ModalActions>
                <Button
                    onClick={handleCloseMdoal}
                    variant="text"
                    color="secondary"
                    sx={{ fontWeight: 500 }}
                >
                    {trans("inv.invoices.modal.invoicesModal.buttonClose")}
                </Button>
            </ModalActions>
        </Modal>
    );
};

export default InvoicesModal;
