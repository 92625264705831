export const AUTO_CONNECT_PRODUCTS_REQUEST =
    "POS_PRODUCTS/AUTO_CONNECT_REQUEST";
export const AUTO_CONNECT_PRODUCTS_SUCCESS =
    "POS_PRODUCTS/AUTO_CONNECT_SUCCESS";
export const AUTO_CONNECT_PRODUCTS_FAILED = "POS_PRODUCTS/AUTO_CONNECT_FAILED";

export const CONNECT_PRODUCTS_REQUEST = "POS_PRODUCTS/CONNECT_REQUEST";
export const CONNECT_PRODUCTS_SUCCESS = "POS_PRODUCTS/CONNECT_SUCCESS";
export const CONNECT_PRODUCTS_FAILED = "POS_PRODUCTS/CONNECT_FAILED";

export const RESTAURANT_PRODUCTS_FETCH_REQUEST =
    "POS_PRODUCTS/RESTAURANT_PRODUCTS_FETCH_REQUEST";
export const RESTAURANT_PRODUCTS_FETCH_SUCCESS =
    "POS_PRODUCTS/RESTAURANT_PRODUCTS_FETCH_SUCCESS";
export const RESTAURANT_PRODUCTS_FETCH_FAILED =
    "POS_PRODUCTS/RESTAURANT_PRODUCTS_FETCH_FAILED";

export const POS_PRODUCT_CATEGORIES_FETCH_REQUEST =
    "POS_PRODUCTS/CATEGORIES_FETCH_REQUEST";
export const POS_PRODUCT_CATEGORIES_FETCH_SUCCESS =
    "POS_PRODUCTS/CATEGORIES_FETCH_SUCCESS";
export const POS_PRODUCT_CATEGORIES_FETCH_FAILED =
    "POS_PRODUCTS/CATEGORIES_FETCH_FAILED";

export const POS_PRODUCTS_FETCH_REQUEST =
    "POS_PRODUCTS/POS_PRODUCTS_FETCH_REQUEST";
export const POS_PRODUCTS_FETCH_SUCCESS =
    "POS_PRODUCTS/POS_PRODUCTS_FETCH_SUCCESS";
export const POS_PRODUCTS_FETCH_FAILED =
    "POS_PRODUCTS/POS_PRODUCTS_FETCH_FAILED";

export const RESTAURANT_PRODUCTS_TABLE_UPDATE =
    "POS_PRODUCTS/RESTAURANT_PRODUCTS_TABLE_UPDATE";

export const POS_PRODUCTS_TABLE_UPDATE =
    "POS_PRODUCTS/POS_PRODUCTS_TABLE_UPDATE";

const types = {
    AUTO_CONNECT_PRODUCTS_FAILED,
    AUTO_CONNECT_PRODUCTS_SUCCESS,
    AUTO_CONNECT_PRODUCTS_REQUEST,

    CONNECT_PRODUCTS_REQUEST,
    CONNECT_PRODUCTS_SUCCESS,
    CONNECT_PRODUCTS_FAILED,

    RESTAURANT_PRODUCTS_FETCH_REQUEST,
    RESTAURANT_PRODUCTS_FETCH_SUCCESS,
    RESTAURANT_PRODUCTS_FETCH_FAILED,

    POS_PRODUCT_CATEGORIES_FETCH_REQUEST,
    POS_PRODUCT_CATEGORIES_FETCH_SUCCESS,
    POS_PRODUCT_CATEGORIES_FETCH_FAILED,

    POS_PRODUCTS_FETCH_REQUEST,
    POS_PRODUCTS_FETCH_SUCCESS,
    POS_PRODUCTS_FETCH_FAILED,

    RESTAURANT_PRODUCTS_TABLE_UPDATE,
    POS_PRODUCTS_TABLE_UPDATE,
};

export default types;
