import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlId from "types/RequestUrlId";

const requests = {
    destroy: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.delete(`/orders/${url.id}`, config),
    fetch: (config: AxiosRequestConfig) => api.get(`/orders`, config),
    get: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/orders/${url.id}`, config),
    store: (data: object, config: AxiosRequestConfig) =>
        api.post(`/orders`, data, config),
    update: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.put(`/orders/${url.id}`, data, config),
    send: (data: object, config: AxiosRequestConfig, url: RequestUrlId) =>
        api.put(`/orders/${url.id}/send`, data, config),
    fetchLocations: (config: AxiosRequestConfig) =>
        api.get(`/locations/all`, config),
    ordersProposals: (config: AxiosRequestConfig) => api.get("/orders/proposals", config),
};

export default requests;
